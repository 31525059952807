import React, { ReactElement } from 'react';

import { PageTitle } from 'components';
import { path } from 'utils';

import { useFintelCheckSettings } from '../../hooks/useFintelCheckSettings';
import en from '../../locales/en.json';

const ToolSettingsPageTitle = (): ReactElement => {
  const { isPageLoading } = useFintelCheckSettings();

  return (
    <PageTitle isLoading={isPageLoading} loadingText={en.settings.toggle.loadingMessage}>
      {path.fintelCheckSettings.name}
    </PageTitle>
  );
};

export default ToolSettingsPageTitle;
