import { sideMenuTheme, SIDE_MENU_INDENT_INCREMENT, SIDE_MENU_BASE_INDENT } from '../../../styles/theme';
import { ISideMenuItemStyle } from '../types';

/**
 * Get the side menu item style based on the level
 */
export const getSideMenuItemStyle = (level: number, managementItem = false): ISideMenuItemStyle => {
  const maxDefinedLevel = Math.max(...Array.from(sideMenuTheme.keys()));
  // If current item is rendered under a management item, we need to adjust the level
  const adjustedLevel = managementItem ? level : level + 2;

  if (adjustedLevel <= maxDefinedLevel) {
    return sideMenuTheme.get(adjustedLevel) as ISideMenuItemStyle;
  }

  // For the case if we don't have a preset for the current level
  // We need to calculate the indent based on the last defined preset
  const lastPreset = sideMenuTheme.get(maxDefinedLevel);
  const totalIndent = SIDE_MENU_BASE_INDENT + (adjustedLevel - 2) * SIDE_MENU_INDENT_INCREMENT;

  return {
    ...lastPreset,
    indent: `${totalIndent}rem`,
  } as ISideMenuItemStyle;
};
