import { gql } from '@apollo/client';

export const TOGGLE_FCHECK_SETTINGS = gql`
  mutation toggleFintelCheckSettings($input: FintelCheckSettingsInput!) {
    toggleFintelCheckSettings(input: $input) {
      settings {
        merchantId
        enabled
      }
    }
  }
`;
