import styled from 'styled-components';

import { colors } from '../../styles/theme';
import { toRem } from '../../utils';

interface ISideMenuContainerProps {
  isCollapsed: boolean;
}

export const SideMenuContainer = styled.div<ISideMenuContainerProps>`
  width: ${({ isCollapsed }) => (isCollapsed ? `${toRem(60)}` : `${toRem(220)}`)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: ${colors.color1};
  position: relative;
  z-index: 30;
`;
