import React, { ReactElement } from 'react';

import * as Styled from './styles';

export const LoadingDots = ({ isWhite = false }: { isWhite?: boolean }): ReactElement => (
  <Styled.WrapperStyled>
    <Styled.EllipsisStyled>
      <Styled.DotsStyled isWhite={isWhite} />
      <Styled.DotsStyled isWhite={isWhite} />
      <Styled.DotsStyled isWhite={isWhite} />
      <Styled.DotsStyled isWhite={isWhite} />
    </Styled.EllipsisStyled>
  </Styled.WrapperStyled>
);
