import React from 'react';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppWrapper, PageTitle, Pagination, ReportPlaceholder,
  ErrorBanner,
} from '../../../../components';
import { Table } from '../../../../components/Table';
import { path, USER_TYPES_ID } from '../../../../utils';
import { useDetailedPayments } from './hooks/useDetailedPaymentRecords';
import { columns } from './contracts/columns';
import { DETAILED_RECORDS } from '../enums';
import { DefaultPropsType } from '../../../../types';
import { useUserInfo } from '../../../../hooks';

import * as Styled from '../styles';

type DetailedPaymentRecordsProps = DefaultPropsType;

const DetailedPaymentRecords = ({ permissionsCodeList = [] }: DetailedPaymentRecordsProps) => {
  const hooks = useDetailedPayments();
  const userHook = useUserInfo();
  const isMerchant = userHook.hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT || userHook.hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT;
  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        isOpen={!!hooks.hookErrorMessage}
        message={hooks.hookErrorMessage}
      />
      <Styled.HeaderStyled>
        <PageTitle
          isLoading={hooks.hookLoading}
          loadingText={DETAILED_RECORDS.LOADING_TEXT}
        >
          {path.detailedPaymentRecords.name}
        </PageTitle>
        <Styled.ButtonStyled
          theme={DETAILED_RECORDS.BUTTON_THEME}
          onClick={hooks.hookHandleBack}
        >
          {DETAILED_RECORDS.BACK}
        </Styled.ButtonStyled>
      </Styled.HeaderStyled>
      <Styled.ButtonWrapperStyled>
        {isMerchant ? (
          <Styled.ButtonStyled
            theme={DETAILED_RECORDS.BUTTON_THEME}
            onClick={hooks.hookDownloadMerchantCSV}
          >
            <FontAwesomeIcon icon={faFileDownload} />
            {DETAILED_RECORDS.DONWLOAD}
          </Styled.ButtonStyled>
        ) : (
          <Styled.ButtonStyled
            theme={DETAILED_RECORDS.BUTTON_THEME}
            onClick={hooks.hookDownloadCSV}
          >
            <FontAwesomeIcon icon={faFileDownload} />
            {DETAILED_RECORDS.DONWLOAD}
          </Styled.ButtonStyled>
        )}
      </Styled.ButtonWrapperStyled>
      {hooks.hookTableData.length > 0
      && (
        <>
          <Table
            columns={columns(isMerchant)}
            data={hooks.hookTableData}
            footerData={hooks.hookFooterValues}
            onSort={hooks.hookOnsort}
            sortColumn={hooks.hookSort}
          />

          <Pagination
            currentPage={hooks.hookCurrentPage}
            total={hooks.hookTotalPages}
            onPageChange={hooks.hookHandleChangePage}
          />
        </>
      )}
      {hooks.hookDataLoad && hooks.hookTableData.length === 0
      && (
      <ReportPlaceholder
        submitted
        text={DETAILED_RECORDS.NO_RESULTS}
        subtext={DETAILED_RECORDS.RUN_ESTIMATE}
      />
      )}
    </AppWrapper>
  );
};

export default DetailedPaymentRecords;
