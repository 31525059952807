import { gql } from '@apollo/client';

export const SHOW_PUBLISHER = gql`
  query companies($input: ShowCompanyInput) {
    showCompany(input: $input) {
      id
      companyName
      features
      activityStatus
      accountStatus
    }
  }
`;
