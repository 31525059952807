import styled from 'styled-components';

import { fonts } from 'styles/theme';

export const PlaceholderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10.5rem;
  ${fonts.lato.H16M}
`;

export const InstructionsWrapper = styled.div`
  width: 18rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.5rem 0.5rem;
  line-height: 1.5em;
  ${fonts.lato.H14}
`;
