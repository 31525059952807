import React, { ReactElement } from 'react';

import { Spinner } from '../Spinner';

import * as Styled from './styles';

type PageTitleProps = {
  children: React.ReactNode;
  isLoading?: boolean;
  loadingText?: string;
  className?: string;
};

export const PageTitle = ({
  children,
  loadingText = 'Loading',
  isLoading,
  className,
}: PageTitleProps): ReactElement => (
  <Styled.WrapperStyled className={className}>
    {children}
    {isLoading && (
      <Styled.LoadingStyled>
        <span>{loadingText}</span> <Spinner theme="secondary" />
      </Styled.LoadingStyled>
    )}
  </Styled.WrapperStyled>
);
