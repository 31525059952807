import styled from 'styled-components';

import { Button } from 'components';

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1.5rem;
`;

export const PeriodBtn = styled(Button)``;
