import { gql } from '@apollo/client';

export const GET_SUMMARY_DASHBOARD_DATA = gql`
  query getSummaryDashboard($input: SummaryDashboardInput!) {
    getSummaryDashboardData(input: $input) {
      checks {
        total
        urlsMonitored
        urlsIncomplete
        urlsNotApplicable
      }
      passed {
        total
        firstCheck
        newFinding
        closed
        awaitingInternalAction
        awaitingExternalAction
      }
      failed {
        total
        firstCheck
        newFinding
        closed
        awaitingInternalAction
        awaitingExternalAction
      }
      incomplete {
        open
      }
      notApplicable {
        open
      }
    }
  }
`;
