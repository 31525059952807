export const RULE_SUMMARY_DASHBOARD = {
  URLS_MONITORED: 'URLs Monitored',
  URLS_INCOMPLETE: 'URLs Incomplete',
  PASSED: 'Passed',
  FAILED: 'Failed',
  INCOMPLETE: 'Incomplete',
  NOT_APPLICABLE: 'Not Applicable',
  CHECKS: 'Checks',
  SET_CLOSE_CHECKS: 'Close all',
  CONFIRMATION_MODAL: {
    TITLE: 'Are you sure you want to close all checks?',
    DESCRIPTION_START: 'You are about to set ',
    DESCRIPTION_END: ' check record(s) to Closed - are you sure?',
    CONFIRM: 'Submit',
    CANCEL: 'Cancel',
  },
};

export type CloseChecksTypes = 'Passed' | 'Incomplete';
