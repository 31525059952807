import styled from 'styled-components';
import { colors, fonts } from '../../../styles/theme';
import { Tooltip } from '../../Tooltip';

type RadioProps = {
  readonly disabled?: boolean;
  readonly partial?: 'full' | 'half' | 'none';
  checked?: boolean;
};

export const LabelStyled = styled.div`
  ${fonts.lato.H16};
  margin-left: 1rem;
`;

export const InsideStyled = styled.div`
  width: 8px;
  background: ${colors.color4};
`;

export const BodyStyled = styled.div<RadioProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background: ${colors.color4};
  border: ${({ checked, partial }) =>
    checked || partial ? `1px solid ${colors.color2}` : `1px solid ${colors.color28}`};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${InsideStyled} {
    height: ${({ partial }) => {
      switch (partial) {
        case 'full':
          return '8px';
        case 'half':
          return '4px';
        case 'none':
          return '0';
        default:
          return '8px';
      }
    }};
    border-radius: ${({ partial }) => {
      switch (partial) {
        case 'full':
          return '100%';
        case 'half':
          return '0';
        case 'none':
          return '0';
        default:
          return '100%';
      }
    }};

    ${({ checked }) => checked && `background: ${colors.color2};`}
    ${({ partial }) => partial && `background: ${colors.color2};`}
  }
`;

export const TooltipStyled = styled(Tooltip)`
  height: auto;

  div {
    margin-left: 2px;
  }
`;

export const WrapperStyled = styled.label`
  cursor: pointer;
  display: flex;

  input {
    display: none;
  }
`;
