import _ from 'lodash';

import { LegalReferenceItemsType, ProductFeedSectionType } from './types';

// Function from fintel-app for transforming productFeed Names to display
export const humanizeProductFeed = (str: string): string => {
  if (!str) return '';
  const parts = str.split(':');
  if (parts.length > 1 && parts[0] === 'legalReferenceItems') {
    if (parts[1]) return `Legal Reference: ${parts[1]}`;
    return 'Legal Reference Item';
  }
  if (parts[0] === 'APY' || parts[0] === 'apyRate') return 'APY';
  if (parts[0] === 'APR' || parts[0] === 'mortgageAprRate') return 'APR';
  return parts[0]
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};

export const getFeedValue = (criteria: string, feed: ProductFeedSectionType | undefined): string => {
  let feedValue;
  if (!feed) return criteria;
  const feedItem = criteria.split(':');
  if (feedItem.length > 1) {
    feedValue = _.find(feed.legalReferenceItems as LegalReferenceItemsType, {
      name: feedItem[1],
    })?.description;
  } else {
    feedValue = feed[criteria] as string;
  }
  return feedValue || '';
};
