import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import {
  ChangeMonitoringDetailsInput,
  ChangeMonitoringDetailsOutput,
  GET_MONITORING_DETAILS_DATA,
  MonitoringDetailsType,
  ReviewStatusChangesType,
} from '../graphql/queries';
import { useChangeMonitoring } from '../../hooks/useChangeMonitoring';

export const useMonitoringDetails = () => {
  const [changedData, setChangedData] = useState<MonitoringDetailsType>();
  const [reviewStatusData, setReviewStatusData] = useState<ReviewStatusChangesType[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const RECORDS_PER_PAGE = 5;

  const [getMonitoringDetailsData, { loading: getMonitoringDetailsDataLoading }] = useLazyQuery<
    ChangeMonitoringDetailsOutput,
    ChangeMonitoringDetailsInput
  >(GET_MONITORING_DETAILS_DATA);

  const fetchPageData = async (id: string) => {
    const { data, error } = await getMonitoringDetailsData({
      variables: {
        input: {
          id,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (error) {
      setErrorMessage(error.message);
      return;
    }
    if (data && data.changeMonitoringDetailsData) {
      setChangedData({
        ...data.changeMonitoringDetailsData,
        /* Initially sorted by review date */
        statusChanges: data.changeMonitoringDetailsData.statusChanges
          ? data.changeMonitoringDetailsData.statusChanges.sort(
              (a, b) => new Date(b.reviewDate).getTime() - new Date(a.reviewDate).getTime()
            )
          : [],
      });

      /* Review Status table */
      // Check if statusChanges is not null and is an array
      if (data.changeMonitoringDetailsData && Array.isArray(data.changeMonitoringDetailsData.statusChanges)) {
        setReviewStatusData(data.changeMonitoringDetailsData.statusChanges.slice(0, RECORDS_PER_PAGE));
      } else {
        setReviewStatusData([]); // or handle the null case appropriately
      }
      setCurrentPage(1);
      const pages = Math.ceil((data?.changeMonitoringDetailsData?.statusChanges || []).length / RECORDS_PER_PAGE);
      setTotalPages(pages > 1 ? pages : 1);
    }
  };

  const handleOnChangeCurrentPage = (chosenPage: number) => {
    if (!changedData) {
      setCurrentPage(1);
      return;
    }
    setReviewStatusData(
      changedData.statusChanges.slice((chosenPage - 1) * RECORDS_PER_PAGE, chosenPage * RECORDS_PER_PAGE)
    );
    setCurrentPage(chosenPage);
  };

  // Update review status for the current change monitoring record
  const updateReviewStatusData = async (changeId: string, monitoringHook: ReturnType<typeof useChangeMonitoring>) => {
    // If no status is selected and no note is provided, do not update the status
    if (!monitoringHook.selectedReviewStatus && monitoringHook.reviewStatusNote.length === 0) {
      return;
    }
    // Get the current status of the change monitoring record to send it to the backend in case the status is not changed
    const currentStatus = monitoringHook.changeMonitoringData.find((item) => item.id === changeId)?.status || '';
    // Call the hook to update the status
    await monitoringHook.handleOnSetReviewStatus(monitoringHook.selectedReviewStatus?.value || currentStatus, changeId);

    // Wait to fetch the data again to update the status changes
    setTimeout(() => {
      fetchPageData(changeId);
    }, 1000);
  };

  return {
    errorMessage,

    fetchPageData,
    changedData,
    isLoading: getMonitoringDetailsDataLoading,

    reviewStatusData,
    updateReviewStatusData,

    handleOnChangeCurrentPage,
    currentPage,
    totalPages,
  };
};
