import React, { ReactElement } from 'react';

import SideMenuCollapseButton from './components/SideMenuCollapseButton/SideMenuCollapseButton.component';
import SideMenuFaqButton from './components/SideMenuFaqButton/SideMenuFaqButton.component';
import SideMenuItems from './components/SideMenuItems/SideMenuItems.component';
import SideMenuLogo from './components/SideMenuLogo/SideMenuLogo.component';
import { SideMenuProvider } from './SideMenu.context';
import { SideMenuContainer } from './SideMenu.styles';

interface ISideMenuProps {
  isCollapsed: boolean;
  collapseFn: () => void;
  className?: string;
}

export const SideMenu = ({ isCollapsed, collapseFn, className = '' }: ISideMenuProps): ReactElement | null => {
  return (
    <SideMenuProvider isCollapsed={isCollapsed} toggleCollapse={collapseFn}>
      <SideMenuContainer isCollapsed={isCollapsed} className={className}>
        <SideMenuLogo />
        <SideMenuItems />
        <SideMenuFaqButton />
        <SideMenuCollapseButton />
      </SideMenuContainer>
    </SideMenuProvider>
  );
};
