import React, { ReactElement, FC } from 'react';

import { useUserInfo } from '../../../../hooks';
import { useSideMenu } from '../../hooks/useSideMenu';
import { ISideMenuItem } from '../../types';

import SideMenuItemAsHref from './components/SideMenuItemAsHref/SideMenuItemAsHref.component';
import SideMenuItemExpandable from './components/SideMenuItemExpandable/SideMenuItemExpandable.component';

interface ISideMenuItemProps {
  sideMenuItem: ISideMenuItem;
}

const SideMenuItem: FC<ISideMenuItemProps> = ({ sideMenuItem }): ReactElement | null => {
  const { isSideMenuItemAvailable } = useSideMenu();
  const { hookGetPermissionByCode } = useUserInfo();

  const permission = hookGetPermissionByCode(sideMenuItem.key as string);

  if (!permission.skip && !isSideMenuItemAvailable(sideMenuItem)) return null;

  return sideMenuItem.href ? (
    <SideMenuItemAsHref menuItem={sideMenuItem} />
  ) : (
    <SideMenuItemExpandable menuItem={sideMenuItem} />
  );
};

export default SideMenuItem;
