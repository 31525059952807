export const MODAL_SECTIONS = {
  CHECK_RESULTS: 'Report Check Results',
  RULE_ELIGIBILITY: 'Rule Eligibility',
  RULE_CRITERIA: 'Rule Criteria',
  REVIEW_STATUS: 'Report Review Status',
};

export const MODAL_TITLE = 'Latest Check - Detailed View';

export const RULE_STATUS_SETTING_TITLE = 'Rule Status Setting:';
export const REPORT_RULE_STATUS = 'Report Rule Status:';
export const RULE_STATUS_TITLE = 'Rule Status';
export const CHECK_DATE_TITLE = 'Report Check Date:';
export const FEEDBACK_TITLE = 'Feedback';
export const RULE_TEXT_TITLE = 'Rule Text';

export const RULE_STATUS = {
  Pass: 'PASS',
  Fail: 'FAIL',
  Incomplete: 'INCOMPLETE',
  NotApplicable: 'NOT_APPLICABLE',
};

export const RULE_TYPES = {
  MONITORING: 'MONITORING',
  EVALUATION__PRODUCT: 'EVALUATION_PRODUCT',
  EVALUATION__TEXT: 'EVALUATION_TEXT',
};
