import styled from 'styled-components';

import { fonts } from 'styles/theme';

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  ${fonts.lato.H16}
`;

export const LabelWrapper = styled.div`
  margin-right: 1rem;
`;
