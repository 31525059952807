import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import React, { ReactElement } from 'react';

import { useSideMenu } from '../../hooks/useSideMenu';

import { FAQ_BUTTON } from './SideMenuFaqButton.constants';
import { FaqButtonContainer, FaqIcon } from './SideMenuFaqButton.styles';

const SideMenuFaqButton = (): ReactElement => {
  const { isCollapsed } = useSideMenu();

  return (
    <FaqButtonContainer href={FAQ_BUTTON.FAQ_URL} target="_blank" isCollapsed={isCollapsed}>
      <FaqIcon icon={faCircleQuestion} />
      {!isCollapsed && FAQ_BUTTON.HELP_TEXT}
    </FaqButtonContainer>
  );
};

export default SideMenuFaqButton;
