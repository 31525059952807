import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { toRem } from '../../../../../../../../../utils'
import { URLS_LIST } from '../../../enums'
import { CellCenterWrapper, IconButtonStyled } from '../../styles'
import { TableProps } from '../../types'

export const Delete = ({ val, row, setOpenModal, setModalInfo, isReadOnly }: TableProps): JSX.Element => (
  <CellCenterWrapper>
    <IconButtonStyled
      onClick={() => {
        setOpenModal()
        setModalInfo({
          id: row.id,
          manualUrl: row.url,
          type: URLS_LIST.MODAL.TYPES.REMOVE,
        })
      }}
      theme="quaternary"
      disabled={isReadOnly}
    >
      <FontAwesomeIcon icon={val} fontSize={toRem(16)} />
    </IconButtonStyled>
  </CellCenterWrapper>
)
