import React from 'react';
import { CellProps } from '../../types';
import { CategoriesWrapper, CellWrapper } from '../../styles';

export const Categories = ({ val }: CellProps) => {
  const categories = !Array.isArray(val) || val.length === 0 ? '' : val.join(', ');

  return (
    <CellWrapper>
      <CategoriesWrapper>{categories}</CategoriesWrapper>
    </CellWrapper>
  );
};
