import { faArrowDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  ReportPlaceholder, Table, RecordsDropdown, Pagination,
} from '../../../../components';
import { EstimateModal } from './components/EstimateModal';
import { GenerateModal } from './components/GenerateModal';
import { columns } from './contracts/columns';
import { RECORDS_PER_PAGE_OPTIONS_WITH_150, MONTH_NAMES } from '../../../../utils';
import { GENERATE_PAYMENT } from './enums';
import { usePaymentGenerator } from '../hooks/usePaymentGenerator';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type PaymentGeneratorProps = DefaultPropsType;

const PaymentGenerator = ({ permissionsCodeList = [] }: PaymentGeneratorProps) => {
  const { hookIsReadOnlyList, ...hooks } = usePaymentGenerator(permissionsCodeList);
  const [isGeneratePaymentsReadOnly, isEstimatePaymentsReadOnly] = hookIsReadOnlyList;

  return (
    <div>
      <Styled.HeaderStyled>
        <Styled.HeaderPeriodStyled>{`${MONTH_NAMES[Number(hooks.month) - 1]} ${hooks.year}`}</Styled.HeaderPeriodStyled>
        <Styled.HeaderFiltersStyled>
          <Styled.HeaderFilterStyled>
            <RecordsDropdown
              selected={hooks.hookSelectedRecords}
              options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
              onChange={hooks.hookHandleSetSelectedRecord}
              currentPage={hooks.hookCurrentPage}
              totalRecords={hooks.hookTotalRecords}
            />
            <Styled.ButtonsStyled
              isMargin
              theme={GENERATE_PAYMENT.BUTTON_THEME}
              loading={hooks.hookTableLoading}
              disabled={hooks.hookTableLoading}
              onClick={hooks.hookHandleDownload}
            >
              <FontAwesomeIcon icon={faArrowDown} />
              {GENERATE_PAYMENT.DOWNLOAD}
            </Styled.ButtonsStyled>
          </Styled.HeaderFilterStyled>

          <Styled.HeaderActionsStyled>
            <Styled.ButtonsStyled
              theme={GENERATE_PAYMENT.BUTTON_THEME}
              onClick={hooks.hookHandleSetEstimateOpen}
              loading={hooks.hookTableLoading || hooks.hookGenerateLoading}
              disabled={isEstimatePaymentsReadOnly}
            >
              <FontAwesomeIcon icon={faPlus} />
              {GENERATE_PAYMENT.ESTIMATE}
            </Styled.ButtonsStyled>
            <Styled.ButtonsStyled
              isMargin
              onClick={hooks.hookHandleSetGenerateOpen}
              loading={hooks.hookTableLoading || hooks.hookGenerateLoading}
              disabled={hooks.hookGeneratePaymentsDisabled || isGeneratePaymentsReadOnly}
            >
              <FontAwesomeIcon icon={faPlus} />
              {GENERATE_PAYMENT.GENERATE}
            </Styled.ButtonsStyled>
          </Styled.HeaderActionsStyled>
        </Styled.HeaderFiltersStyled>
      </Styled.HeaderStyled>
      {hooks.hookTableData.length === 0 && !hooks.hookTableLoading && (
      <ReportPlaceholder
        submitted={false}
        text={GENERATE_PAYMENT.TEXT}
      />
      )}
      <EstimateModal hooks={hooks} />
      <GenerateModal hooks={hooks} />

      {!(hooks.hookTableData.length === 0 && !hooks.hookTableLoading) && (
      <Table
        data={hooks.hookTableData}
        columns={columns}
        onRowClick={hooks.hookOnRowClick}
        onSort={hooks.hookOnSort}
        sortColumn={hooks.hookSort}
        isLoading={hooks.hookTableLoading}
      />
      )}

      {!(hooks.hookTableData.length === 0 && !hooks.hookTableLoading) && (
      <Pagination
        total={hooks.hookTotalPages}
        currentPage={hooks.hookCurrentPage}
        onPageChange={hooks.hookChangePage}
      />
      )}
    </div>
  );
};

export default PaymentGenerator;
