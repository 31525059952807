import styled from 'styled-components';

import { colors, fonts } from '../../../../../../styles/theme';
import { hexToRGB, toRem } from '../../../../../../utils';
import { sideMenuActiveStyles } from '../../SideMenuItem.styles';

interface IMenuItemContainerProps {
  show: boolean;
  isCollapsed: boolean;
}

export const ItemHeaderContainer = styled.button`
  width: 100%;
  text-align: left;
  display: grid;
  padding: 1rem;
  align-content: center;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  ${fonts.gotham.H12M}

  p {
    margin-top: ${toRem(2)};
  }
`;

export const ItemContentContainer = styled.div`
  overflow: hidden;
  max-height: 0;
`;

export const MenuItemContainer = styled.div<IMenuItemContainerProps>`
  overflow: hidden;

  ${ItemContentContainer} {
    border-left: ${toRem(3)} solid ${({ show }) => (show ? colors.color2 : 'transparent')};
    background: ${({ show }) => (show ? `rgba(${hexToRGB(colors.color4)}, 0.1)` : 'transparent')};

    ${({ show, isCollapsed }) => show && !isCollapsed && `max-height: ${toRem(1000)};`};
  }

  ${ItemHeaderContainer} {
    transition: background 0.1s linear;
    border-left: 3px solid ${({ show }) => (show ? colors.color2 : 'transparent')};
    background: ${({ show }) => (show ? `rgba(${hexToRGB(colors.color4)}, 0.1)` : 'transparent')};
    color: ${({ show }) => (show ? colors.color4 : colors.color7)};
    grid-template-columns: ${({ isCollapsed }) => (isCollapsed ? '1fr' : `${toRem(30)} 1fr ${toRem(10)}`)};
    white-space: nowrap;

    &:hover {
      ${sideMenuActiveStyles}
    }

    p:nth-child(2) {
      display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
    }

    svg {
      margin-right: ${({ isCollapsed }) => (isCollapsed ? '0' : '0.7rem')};
      font-size: ${({ isCollapsed }) => (isCollapsed ? '1.3rem' : '1rem')};

      &:nth-child(3) {
        font-size: 0.8rem;
        margin-top: ${toRem(3)};
        transition: all 0.2s;
        transform: rotate(${({ show }) => (show ? '90deg' : '0')});
        display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
      }
    }
  }
`;
