import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { Button, Radio, Select } from '../../../../../components';
import { colors, fonts } from '../../../../../styles/theme';

type ReportProps = {
  readonly show: boolean;
};

export const HeaderStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleStyled = styled.h4`
  ${fonts.gotham.H20M}
  color: ${colors.color1};
`;

export const HeaderLeftStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const HeaderRightStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;

export const LastCheckStyled = styled.div<ReportProps>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background-color: ${colors.color19};
  color: ${colors.color1};
  padding: 0.5rem;
  ${fonts.lato.H12}
  border-radius: 0.25rem;
  margin-left: 1rem;
`;

export const InfoStyled = styled.div<ReportProps>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  gap: 0.5rem;
  ${fonts.lato.H16}
  color: ${colors.color19};
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

export const FilterWrapperStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  row-gap: 2rem;
  margin: 2rem 0;
`;

export const WrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const EllipsisStyled = styled.div`
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
`;

export const ButtonStyled = styled(Button)`
  grid-column: 4;
  justify-self: end;
`;

export const ReportWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

export const PlaceholderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const OptionsRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const LeftStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.lato.H14}
  gap: 1rem;
`;

export const RightStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  ${ButtonStyled} {
    margin-left: 1rem;
  }
`;

export const SelectStyled = styled(Select)``;

export const SetStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: ${colors.color4};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px 4px;
  position: fixed;
  bottom: 0;
  padding: 1.25rem;
  padding-top: 1.5rem;
  padding-bottom: 2.5rem;
  width: 55%;
  align-self: center;

  animation-name: slideup;
  animation-delay: 0s;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  @keyframes slideup {
    0% {
      bottom: -50px;
    }
    100% {
      bottom: 0;
    }
  }
`;

export const SetStatusContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
`;

export const SubtitleStyled = styled.p`
  color: ${colors.color1};
  ${fonts.gotham.H18B};
`;

export const FeedbackModalContent = styled.div`
  width: 25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;

export const FeedbackModalTitle = styled.p`
  ${fonts.gotham.H18B};
  color: ${colors.color1};
  margin-bottom: 1rem;
`;
export const FeedbackModalText = styled.p`
  ${fonts.lato.H16};
  color: ${colors.color1};
  margin-bottom: 1rem;
`;

export const FeedbackModalRadioWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const FeedbackModalRadio = styled(Radio)`
  width: max-content;
`;

export const FeedbackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  gap: 1rem;
`;

export const InternalUrlLinkStyled = styled.div`
  ${fonts.lato.H16};
  color: ${colors.color2};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
`;
