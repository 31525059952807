import styled from 'styled-components';

import { colors, fonts } from '../../../../../../styles/theme';
import { toRem } from '../../../../../../utils';

interface IMenuItemCompanyMenuProps {
  readonly show: boolean;
}

interface IMenuItemCompanySelectionProps {
  readonly isOpen: boolean;
}

export const MenuItemCompanyMenu = styled.div<IMenuItemCompanyMenuProps>`
  height: ${({ show }) => (show ? '100%' : '0')};
  overflow: hidden;

  &:last-of-type {
    padding-bottom: ${({ show }) => (show ? '1rem' : '0')};
  }
`;

export const MenuItemCompanySelection = styled.button<IMenuItemCompanySelectionProps>`
  width: 100%;
  text-align: left;
  display: flex;
  padding: 1rem 1.2rem 1rem 2rem;
  align-items: center;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  ${fonts.gotham.H12M}
  background: transparent;
  color: ${colors.color4};
  justify-content: space-between;

  P {
    width: 80%;
  }

  svg:last-child {
    font-size: 0.8rem;
    margin-top: ${toRem(3)};
    transition: transform 0.3s;
    transform: rotate(${({ isOpen }) => (isOpen ? '90deg' : '0')});
  }
`;

export const MenuItemSeparator = styled.div`
  height: 1px;
  background: ${colors.color7};
  width: 90%;
  margin: 1rem auto 0;
`;
