import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from '../../../../../styles/theme';
import { Button } from '../../../../../components';

type ReportProps = {
  readonly show: boolean
}

export const HeaderStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderLeftStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const HeaderRightStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;

export const TitleStyled = styled.h4`
  ${fonts.gotham.H20M}
  color: ${colors.color1};
`;

export const LastCheckStyled = styled.div<ReportProps>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background-color: ${colors.color19};
  color: ${colors.color1};
  padding: 0.5rem;
  ${fonts.lato.H12}
  border-radius: 4px;
  margin-left: 1rem;
`;

export const InfoStyled = styled.div<ReportProps>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  gap: 0.5rem;
  ${fonts.lato.H16}
  color: ${colors.color19};
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

export const FilterWrapperStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  row-gap: 2rem;
  margin: 2rem 0;
`;

export const ButtonStyled = styled(Button)`
  grid-column: 4;
  justify-self: end;
`;

export const ReportWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

export const PlaceholderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center
`;

export const OptionsRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const LeftStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.lato.H14}
  gap: 1rem;
`;

export const RightStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  ${ButtonStyled} {
    margin-left: 1rem;
  }
`;
