import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  TIRED_RATE_OPTIONS, RATE_EMPTY_VALUE, MINIMUM_USAGE_FEE_DEFAULT, MINIMUM_BALANCE_DEFAULT, TIRED_RATES, RATE_LEVEL_KEY, PRODUCT_CATEGORY_OPTIONS, PRODUCT_CATEGORY_ENUM, validationfields,
} from '../contracts';
import { useValidation } from '../../../../utils/validation';
import { formateDollarAmount, path } from '../../../../utils';
import { ACCOUNT_BALANCE_STATS } from '../graphql/queries';
import { UPDATE_ACCOUNT_BALANCE_STATS, UPDATE_NEW_ACCOUNT_BALANCE_STATS } from '../graphql/mutation';
import { ACCOUNT_STATS } from '../graphql/queries/accountStats';
import { Permission } from '../../../../entities';

export const useAccountBalanceManagementNewStats = (permissionsCodeList: string[] = []) => {
  const formatTieredRate = (accountBalanceStats: SystemTierRate[]) => {
    const newUseTieredRates: string[] = [];

    accountBalanceStats.forEach((item: any, index: number) => {
      if (index === 0) {
        newUseTieredRates.push(`$0 to $${Number(item.newAdminTierCommission).toFixed(2)}: ${Number(item.newAdminTierRate).toFixed(2)}%`);
      }
      if (index > 0) {
        newUseTieredRates.push(`$${(Number(accountBalanceStats[index - 1].newAdminTierCommission) + 0.01).toFixed(2)} to $${Number(item.newAdminTierCommission).toFixed(2)}: ${Number(item.newAdminTierRate).toFixed(2)}%`);
      }
      if (accountBalanceStats.length === index + 1) {
        newUseTieredRates.push(`Above $${Number(item.newAdminTierCommission).toFixed(2)}: ${Number(item.newAdminAfterTierRate).toFixed(2)}%`);
      }
    });
    return newUseTieredRates;
  };

  const formatCatgories = (statActiveCategory: string[], managementFeeCategories: {[key: string]: string}) => {
    const categories:{[key: string]: string} = {};
    statActiveCategory.forEach((category: string) => {
      const fomateLadel = category.replaceAll(' ', '').toUpperCase();
      categories[PRODUCT_CATEGORY_ENUM[fomateLadel]] = `${formateDollarAmount(parseFloat(managementFeeCategories[PRODUCT_CATEGORY_ENUM[fomateLadel]]))}`;
    });
    return categories;
  };
  const location = useLocation();
  const navigate = useNavigate();
  const validtionHook = useValidation();

  const [getProgram] = useLazyQuery(ACCOUNT_BALANCE_STATS);
  const [updateAccountBalanceStats] = useMutation(UPDATE_ACCOUNT_BALANCE_STATS);
  const [updateNewAccountBalanceStats] = useMutation(UPDATE_NEW_ACCOUNT_BALANCE_STATS);

  const [merchantInfo, setMerchantInfo] = useState<{name: string, merchantId: string, programId: string}>();
  const [enableNewStats, setEnableNewStats] = useState(false);
  const [programRateSettings, setProgramRateSettings] = useState<boolean>();

  const [activeCategories, setActiveCategories] = useState<string[]>([]);
  const [newActiveCategories, setNewActiveCategories] = useState<string[]>([]);

  const [currentSystemRate, setCurrentSystemRate] = useState<string[]>([]);
  const [rateType, setRateType] = useState(TIRED_RATE_OPTIONS[0].value);
  const [tierRateLevels, setTierRateLevels] = useState<SystemTierRate[]>([RATE_EMPTY_VALUE]);
  const [flatRateLevel, setFlatRateLevel] = useState('0.00%');

  const [managementCurrentFee, setManagementCurrentFee] = useState('');
  const [newManagementFee, setNewManagementFee] = useState<NewManagementFeeStats>({});
  const [newManagementFeeBasic, setNewManagementFeeBasic] = useState('');

  const [minUsageFee, setMinUsageFee] = useState(MINIMUM_USAGE_FEE_DEFAULT);
  const [currentMinUsageFee, setCurrentMinUsageFee] = useState('$0.00');

  const [minimumBalance, setMinimumBalance] = useState(MINIMUM_BALANCE_DEFAULT);
  const [currentMinimumBalance, setCurrentMinimumBalance] = useState('$0.00');

  const [modalOpen, setModalOpen] = useState(false);

  const [errors, setErrors] = useState<{[key: string]: string}>({});
  const [rateErrors, setRateErrors] = useState<{[key: string]: string}>({});
  const [hasCategoryError, setHasCategoryError] = useState(false);

  const [getAccountStats, { loading: getAccountStatsLoading }] = useLazyQuery(ACCOUNT_STATS);

  const handleAccountStats = async () => {
    setEnableNewStats(true);
    const { data } = await getAccountStats({
      variables: {
        input: {
          programId: location?.state?.programId,
          currentDate: new Date().toUTCString(),
        },
      },
    });

    if (data?.accountStats?.accountStats !== undefined) {
      const oldAccount = data.accountStats.accountStats[0];
      const newAccount = data.accountStats.accountStats[1];
      setActiveCategories(newAccount.activeCategories || []);
      setFlatRateLevel(`${oldAccount.adminRate}%`);
      setTierRateLevels(oldAccount.newUseTieredRate || []);
      setRateType(oldAccount.newUseTieredRate?.length > 0 ? TIRED_RATE_OPTIONS[1].value : TIRED_RATE_OPTIONS[0].value);
      setMinUsageFee({ minUsageFee: `$${oldAccount.minUsageFee}` });
      setMinimumBalance({ minimumBalance: `$${oldAccount.minBalanceRequired}` || '$0' });
      let categories = formatCatgories(oldAccount.activeCategories, oldAccount.managementFee);
      setNewManagementFee(categories);

      setCurrentMinUsageFee(`$${newAccount.minUsageFee}`);
      setNewActiveCategories(oldAccount.activeCategories);
      setProgramRateSettings(newAccount.useRateSettings);
      setCurrentMinimumBalance(newAccount.minimumBalanceRequired || '$0');
      const tierLevels = newAccount.newUseTieredRate;
      const systemRate = tierLevels && tierLevels?.length > 0 ? formatTieredRate(tierLevels) : [`${newAccount?.adminRate || '0.00'}%`];
      setCurrentSystemRate(systemRate);

      categories = formatCatgories(newAccount.activeCategories, newAccount.managementFee);
      setManagementCurrentFee(`${Object.keys(categories).map((cate) => `${cate}: ${categories[cate]}`).join(', ')}` || '0.00');
    }
  };

  const setNewActiveCategoriesHandler = (value: string) => {
    setNewActiveCategories([...activeCategories, value]);
  };

  const getCurrentStats = async (nextMonthStatus = false) => {
    const programIdState = location.state?.programId;
    const {
      data: {
        programV2,
      },
    } = await getProgram({
      variables: {
        id: programIdState,
        currentPeriodAccountStats: nextMonthStatus,
      },
    });

    if (programV2?.enableNewStats) {
      await handleAccountStats();
      if (!nextMonthStatus) {
        setMerchantInfo({
          name: programV2.merchant.companyName,
          merchantId: programV2.merchant.id,
          programId: location.state?.programId,
        });
      }
    } else if (programV2) {
      if (nextMonthStatus) {
        setNewActiveCategories(programV2.accountStat.activeCategories || []);
        setProgramRateSettings(programV2.accountBalanceStats.useRateSettings);

        setCurrentMinUsageFee(programV2.accountBalanceStats?.minUsageFee || '0.00');

        setCurrentMinimumBalance(programV2.accountBalanceStats?.minBalanceRequired || '0.00');

        const tierLevels = programV2.accountBalanceStats?.newUseTieredRate;
        const systemRate = tierLevels && tierLevels?.length > 0 ? formatTieredRate(tierLevels) : [`${programV2.accountBalanceStats?.adminRate || '0.00'}%`];
        setCurrentSystemRate(systemRate);
      } else {
        setEnableNewStats(programV2.enableNewStats);
        setActiveCategories(programV2.accountStat.activeCategories || []);
        setFlatRateLevel(`${programV2.accountBalanceStats?.adminRate || '0.00'}%`);

        const tierLevels = programV2.accountBalanceStats?.newUseTieredRate;
        setTierRateLevels(tierLevels || []);

        setRateType(tierLevels && tierLevels.length > 0 ? TIRED_RATE_OPTIONS[1].value : TIRED_RATE_OPTIONS[0].value);

        setMinUsageFee({
          minUsageFee: formateDollarAmount(parseFloat(programV2.accountBalanceStats?.minUsageFee || '0.00')),
        });
        setMinimumBalance({
          minimumBalance: formateDollarAmount(parseFloat(programV2.accountBalanceStats?.minBalanceRequired || '0.00')),
        });

        setMerchantInfo({
          name: programV2.merchant.companyName,
          merchantId: programV2.merchant.id,
          programId: location.state?.programId,
        });
      }
      if (nextMonthStatus) {
        setManagementCurrentFee(programV2.accountBalanceStats.managementFee || '0.00');
      } else {
        setNewManagementFeeBasic(`$${programV2.accountBalanceStats?.managementFee || '0.00'}`);
      }
    }
  };

  const valuesToNumber = (rateLevels:SystemTierRate[]) => rateLevels.map((level) => ({
    id: level.id,
    newAdminAfterTierRate: parseFloat(`${level.newAdminAfterTierRate}`),
    newAdminTierCommission: parseFloat(`${level.newAdminTierCommission}`),
    newAdminTierRate: parseFloat(`${level.newAdminTierRate}`),
  }));

  const handleValidation = () => {
    const newManagementFeeValids: {[key: string]: number | string} = { ...newManagementFee };

    Object.keys(PRODUCT_CATEGORY_ENUM).forEach((fee: string) => {
      newManagementFeeValids[PRODUCT_CATEGORY_ENUM[fee]] = newManagementFeeValids[PRODUCT_CATEGORY_ENUM[fee]]?.toString()?.replace(/\$/g, '') || '0';
    });

    const validationValues: {[key: string]: string} = {
      ...newManagementFeeValids,
      minUsageFee: minUsageFee.minUsageFee.replace(/\$/g, ''),
      minBalanceRequired: minimumBalance.minimumBalance.replace(/\$/g, ''),
    };

    const valid = validtionHook.validateAll(validationValues, validationfields, setErrors, true);

    const rates = rateType === TIRED_RATES.TIER ? valuesToNumber(tierRateLevels) : flatRateLevel.replace(/%/g, '');
    const rateValid = validtionHook.validateStatRates(
      rates,
      rateType,
      setRateErrors,
    );

    const categoryValid = validtionHook.validateArrayAllValues(newActiveCategories, activeCategories);
    setHasCategoryError(!categoryValid);

    const formattedManagementFee : {[key: string]: number } = {};
    Object.keys(PRODUCT_CATEGORY_ENUM).forEach((fee: string) => {
      const feeValue = newManagementFeeValids[PRODUCT_CATEGORY_ENUM[fee]];
      formattedManagementFee[PRODUCT_CATEGORY_ENUM[fee]] = (typeof feeValue === 'string') ? parseFloat(feeValue) : Number(feeValue);
    });

    return {
      valid: valid && rateValid && categoryValid,
      values: {
        managementFee: formattedManagementFee,
        adminRate: rateType === TIRED_RATES.TIER ? 0 : parseFloat(flatRateLevel.replace(/%/g, '')),
        minUsageFee: parseFloat(minUsageFee.minUsageFee.replace(/\$/g, '')),
        minBalanceRequired: parseFloat(minimumBalance.minimumBalance.replace(/\$/g, '')),
        activeCategories: newActiveCategories,
        newUseTieredRate: rateType === TIRED_RATES.TIER ? rates : [],
        currentDate: new Date(),
        useRateSettings: rateType !== TIRED_RATES.TIER,
      },
    };
  };

  const handleValidationOrginalStats = () => {
    const validationValues: {[key: string]: string} = {
      minUsageFee: minUsageFee.minUsageFee.replace(/\$/g, ''),
      minBalanceRequired: minimumBalance.minimumBalance.replace(/\$/g, ''),
      managementFee: newManagementFeeBasic.replace(/\$/g, ''),
    };

    const valid = validtionHook.validateAll(validationValues, validationfields, setErrors, true);

    const rates = rateType === TIRED_RATES.TIER ? valuesToNumber(tierRateLevels) : flatRateLevel.replace(/%/g, '');
    const rateValid = validtionHook.validateStatRates(
      rates,
      rateType,
      setRateErrors,
    );
    return {
      valid: valid && rateValid,
      values: {
        ...validationValues,
        adminRate: flatRateLevel.replace(/%/g, ''),
        newUseTieredRate: rateType === TIRED_RATES.TIER ? rates : [],
        useRateSettings: rateType !== TIRED_RATES.TIER,
      },
    };
  };

  const updateNewStatsHandler = async () => {
    const valid = handleValidation();

    if (valid.valid) {
      try {
        const input = {
          ...valid.values,
          programId: merchantInfo?.programId,
        };
        await updateNewAccountBalanceStats({
          variables: {
            input,
          },
        });
        navigate(path.merchantAccountBalanceStats.href, {
          state: {
            merchantId: merchantInfo?.merchantId,
            merchantName: merchantInfo?.name,
            programId: merchantInfo?.programId,
          },
        });
      } catch (error: any) {
        setErrors({ message: error.message });
      }
    }
  };

  const updateStatsHandler = async () => {
    if (enableNewStats) {
      updateNewStatsHandler();
    } else {
      const valid = handleValidationOrginalStats();
      if (valid.valid) {
        try {
          const input = {
            id: merchantInfo?.programId,
            accountBalanceStats: { ...valid.values },
          };

          await updateAccountBalanceStats({
            variables: {
              input,
            },
          });
          navigate(path.merchantAccountBalanceStats.href, {
            state: {
              merchantId: merchantInfo?.merchantId,
              merchantName: merchantInfo?.name,
              programId: merchantInfo?.programId,
            },
          });
        } catch (error: any) {
          setErrors({ message: error.message });
        }
      }
    }
  };

  const updatedNewActiveCategoriesHandler = (updateActiveCategories: string[]) => {
    setNewActiveCategories(updateActiveCategories);
    const updatedNewManagementFee: {[key: string]: string} = {};

    const currentList: {[key: string]: string} = newManagementFee;
    PRODUCT_CATEGORY_OPTIONS.forEach((option) => {
      if (updateActiveCategories.includes(option.label)) {
        updatedNewManagementFee[PRODUCT_CATEGORY_ENUM[option.value.toUpperCase()]] = currentList[PRODUCT_CATEGORY_ENUM[option.value]] || '$0.00';
      }
    });
    setNewManagementFee(updatedNewManagementFee);
    setModalOpen(false);
  };

  const setTierRateLevelsHandler = (value: string, field: string, id: number) => {
    const updatedValue = tierRateLevels.map((level) => {
      if (level.id === id) {
        switch (field) {
          case RATE_LEVEL_KEY.COMMISSION:
            return {
              ...level,
              newAdminTierCommission: value,
            };
          case RATE_LEVEL_KEY.ADMIN_TIER:
            return {
              ...level,
              newAdminTierRate: value,
            };
          case RATE_LEVEL_KEY.AFTER_RATE:
            return {
              ...level,
              newAdminAfterTierRate: value,
            };
          default:
            return level;
        }
      }
      return level;
    });
    setTierRateLevels(updatedValue);
  };

  const addNewTierHandler = () => {
    const newTier = {
      ...RATE_EMPTY_VALUE,
      id: tierRateLevels[tierRateLevels.length - 1] === undefined ? 1 : tierRateLevels[tierRateLevels.length - 1].id + 1,
    };
    setTierRateLevels([...tierRateLevels, newTier]);
  };

  const removeNewTierHandler = (id: number) => {
    const updatedTier = tierRateLevels.filter((tierRow) => tierRow.id !== id);
    setTierRateLevels(updatedTier);
  };

  const setFlatRateLevelsHandler = (value: React.ChangeEvent<HTMLInputElement>) => {
    setFlatRateLevel(`${value.target.value.replace(/%/g, '')}%`);
  };

  const setNewManagementFeeHandler = (value: React.ChangeEvent<HTMLInputElement>, field?: string) => {
    if (field) {
      const updateValues: {[key: string]: string} = { ...newManagementFee };
      updateValues[field] = `$${value.target.value.replace(/\$/g, '')}`;
      setNewManagementFee(updateValues);
    } else {
      setNewManagementFeeBasic(`$${value.target.value.replace(/\$/g, '')}`);
    }
  };

  const setMinUsageFeeHandler = (value: React.ChangeEvent<HTMLInputElement>) => {
    setMinUsageFee({ minUsageFee: `$${value.target.value.replace(/\$/g, '')}` });
  };

  const setMinimumBalanceHandler = (value: React.ChangeEvent<HTMLInputElement>) => {
    setMinimumBalance({ minimumBalance: `$${value.target.value.replace(/\$/g, '')}` });
  };

  const setRateTypeHandler = (value: string) => {
    setRateType(value);

    if (value === TIRED_RATES.FLAT) {
      setTierRateLevels([RATE_EMPTY_VALUE]);
    }
  };

  const setModalOpenHandler = (value: boolean) => {
    setModalOpen(value);
  };

  useEffect(() => {
    // get active stats values
    getCurrentStats();
    // get next month stats values
    getCurrentStats(true);
  }, []);

  return {
    hookActiveCategories: activeCategories,
    hookSetNewActiveCategories: setNewActiveCategoriesHandler,
    hookNewActiveCategories: newActiveCategories,

    hookCurrentSystemRate: currentSystemRate,

    hookRateType: rateType,
    hookSetRateType: setRateTypeHandler,
    hookTierRateLevels: tierRateLevels,
    hookSetRateLevels: setTierRateLevelsHandler,
    hookFlatRateLevel: flatRateLevel,
    hookSetFlatRateLevels: setFlatRateLevelsHandler,

    hookManagementCurrentFee: managementCurrentFee,
    hookNewManagementFee: newManagementFee,
    hookSetNewManagementFee: setNewManagementFeeHandler,

    hookMinUsageFee: minUsageFee,
    hookSetMinUsageFee: setMinUsageFeeHandler,
    hookCurrentMinUsageFee: currentMinUsageFee,
    hookNewManagementFeeBasic: newManagementFeeBasic,

    hookMinimumBalance: minimumBalance,
    hookSetMinimumBalance: setMinimumBalanceHandler,
    hookCurrentMinimumBalance: currentMinimumBalance,

    hookModalOpen: modalOpen,
    hookSetModalOpen: setModalOpenHandler,
    hookUpdatedNewActiveCategories: updatedNewActiveCategoriesHandler,

    hookAddNewTier: addNewTierHandler,
    hookRemoveNewTier: removeNewTierHandler,

    hookUpdateStats: updateStatsHandler,
    hookErrors: errors,
    hookRateErrors: rateErrors,
    hookHasCategoryError: hasCategoryError,

    hookMerchantInfo: merchantInfo,
    hookEnableNewStats: enableNewStats,

    hookGetAccountStatsLoading: getAccountStatsLoading,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
