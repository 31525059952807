import { ApolloError } from '@apollo/client';

import { IGraphQLResult } from 'api/types';

export const handleGraphQLResponse = <T, K extends keyof T>(
  operation: string,
  result: IGraphQLResult<T>,
  dataField: string
): T[K] => {
  const { data, errors } = result;

  if (errors && errors.length > 0) {
    throw new Error(`GraphQL errors in ${operation}: ${errors.map((e) => e.message).join(', ')}`);
  }

  if (!data || typeof data !== 'object' || !(dataField in data)) {
    throw new Error(`No data returned from ${operation} for field ${String(dataField)}`);
  }

  return data[dataField as K];
};

export const handleOperationError = (operation: string, error: unknown): never => {
  if (error instanceof ApolloError && error.networkError) {
    throw new Error(`Network error in ${operation}: ${error.networkError.message}`);
  }

  if (error instanceof ApolloError && error.graphQLErrors.length > 0) {
    throw new Error(`GraphQL errors in ${operation}: ${error.graphQLErrors.map((e) => e.message).join(', ')}`);
  }

  throw error;
};
