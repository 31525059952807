import { gql } from '@apollo/client';

export const GET_FINTEL_CHECK_REPORT_RESULTS = gql`
  query getFintelCheckReport($input: FintelCheckReportInput!) {
    fintelCheckReport(input: $input) {
      count
      totalPages
      currentPage
      reportResults {
        id
        reviewStatus
        previousReviewStatus
        reviewDate
        ruleStatus
        merchant
        ruleType
        pageUrl
        ruleName
        ruleGroup
        publisher
        createDatetime
        productCategory
        productId
        productName
        brandName
        eligibility {
          type
          value
          status
        }
        ruleCriteria {
          fieldName
          expectedValue
          found
          status
        }
      }
    }
  }
`;

export type RuleCriteriaType = {
  fieldName: string;
  expectedValue: string;
  found: boolean;
  status: string;
  hasFeedback: boolean;
};

export type RuleReportType = {
  id: string;
  merchant: string;
  pageUrl: string;
  urlSearch: string;
  ruleName: string;
  ruleGroup: string;
  ruleStatus: string;
  createDatetime: string;
  publisher: string;
  reviewStatus: string;
  reviewDate: string;
  previousReviewStatus: string;
  checked: boolean;
  productCategory: string;
  productId: string;
  productName: string;
  brandName: string;
  eligibility: [{ type: string; value: string; status: boolean }];
  ruleType: string;
  ruleCriteria: RuleCriteriaType[];
};
