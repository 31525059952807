import { gql } from '@apollo/client';

export const TOGGLE_TRACKING_URL_MONITORING = gql`
  mutation toggleTrackingUrlMonitoring($input: ToggleTrackingUrlMonitoringInput!) {
    toggleTrackingUrlMonitoring(input: $input) {
      settings {
        id
        urlCheckType
      }
    }
  }
`;
