import React, { useEffect } from 'react';

import { useTabs } from './hooks';
import * as Styled from './styles';

type TabsProps = {
  tabNames: string[];
  elements: React.ReactNode[];
  className?: string;
  activeTab?: number;
  isModalOpen?: boolean;
  hookOveride?: {
    activeTabOveride: any;
    setActiveTab: any;
  };
  onTabChange?: (tabName: string) => void;
};

export const Tabs: React.FC<TabsProps> = ({
  tabNames,
  elements,
  className,
  activeTab,
  isModalOpen,
  hookOveride,
  onTabChange,
}: TabsProps) => {
  const { hookActiveTab, hookSetActiveTab } = useTabs(
    activeTab,
    hookOveride?.setActiveTab,
    hookOveride?.activeTabOveride
  );

  useEffect(() => {
    if (isModalOpen) hookSetActiveTab(0);
  }, [isModalOpen]);

  const handleTabClick = (index: number): void => {
    hookSetActiveTab(index);
    if (onTabChange) {
      onTabChange(tabNames[index]);
    }
  };

  const tabRender = (): JSX.Element[] =>
    tabNames.map((tab, index) => (
      <Styled.ButtonStyled
        key={index}
        isActive={index === (hookOveride?.activeTabOveride ?? hookActiveTab)}
        onClick={() => handleTabClick(index)}
      >
        {tab}
      </Styled.ButtonStyled>
    ));

  return (
    <Styled.WrapperStyled className={className}>
      <Styled.TopAreaStyled>{tabRender()}</Styled.TopAreaStyled>

      <Styled.ContentStyled>{elements[hookOveride?.activeTabOveride ?? hookActiveTab]}</Styled.ContentStyled>
    </Styled.WrapperStyled>
  );
};
