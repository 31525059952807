/* eslint-disable @typescript-eslint/no-explicit-any */
import Joi from 'joi';

import { IFintelCheckToolSettings } from '../types';

import { toolSettingsSchema } from './schemas/toolSettings.schema';

const createValidation =
  (schema: Joi.Schema) =>
  (value: any): string | null => {
    const { error } = schema.validate(value);
    return error ? error.details[0].message : null;
  };

export const validationRules: Record<keyof IFintelCheckToolSettings, (value: any) => string | null> = Object.entries(
  toolSettingsSchema
).reduce(
  (acc, [key, schema]) => ({
    ...acc,
    [key]: createValidation(schema),
  }),
  {} as Record<keyof IFintelCheckToolSettings, (value: any) => string | null>
);
