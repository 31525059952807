import { RULE_TYPES } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/enums';

export const SUMMARY = {
  TITLE: 'Summary Report',
  GENERATE_REPORT: 'Generate Report',
  DOWNLOAD_CSV: 'Download CSV',
  DOWNLOAD_IMAGE: 'Download Image',
};

export const ALL_PRODUCTS = 'All Products';
export const ALL_STATUSES = 'All Statuses';
export const NO_PRODUCTS = 'No Products';

export const RULE_STATUSES = [
  { label: 'All Statuses', value: 'All Statuses' },
  { label: 'Pass', value: 'PASS' },
  { label: 'Fail', value: 'FAIL' },
  { label: 'Check Incomplete', value: 'INCOMPLETE' },
  { label: 'Not Applicable', value: 'NOT_APPLICABLE' },
];

export const RULE_TYPE_OPTIONS = [
  { label: 'All Rule Types', value: 'All Rule Types' },
  { label: RULE_TYPES.EVAL_PRODUCT, value: RULE_TYPES.EVAL_PRODUCT },
  { label: RULE_TYPES.EVAL_TEXT, value: RULE_TYPES.EVAL_TEXT },
];

export const RULE_STATUS_LABEL = 'Rule Status';
export const REVIEW_STATUS_LABEL = 'Review Status';
export const RULE_GROUPS_LABEL = 'Rule Groups';
export const PUBLISHERS_LABEL = 'Publishers';
export const MERCHANT_LABEL = 'Merchants';
export const PRODUCT_CATEGORY_LABEL = 'Product Category';
export const PRODUCT_LABEL = 'Product';

export const URL_SEARCH = {
  TITLE: 'URL Search',
  PLACEHOLDER: 'Search for URL',
};

export const CHECK_RULES_DEFAULT = { label: 'All Rules', value: 'All Rules' };
export const PUBLISHERS_DEFAULT = [
  { label: 'All Publisher and Manual Urls', value: 'All Publishers and Manual Urls' },
  { label: 'All Publisher', value: 'allpublishers' },
  { label: 'Manual Urls', value: 'manualurls' },
];

export const MERCHANTS_DEFAULT = [{ label: 'All Merchants', value: 'All Merchants' }];

export const PRODUCT_CATEGORY_DEFAULT = [
  { label: 'All Product Categories', value: 'All Product Categories' },
  { label: 'No Product Categories', value: 'empty' },
];

export const PRODUCT_DEFAULT = [
  { label: 'All Products', value: 'All Products' },
  { label: 'No Products', value: 'empty' },
];

export const CHECK_RULE_GROUP_DEFAULT = [
  { label: 'All Rule Groups', value: 'All Rule Groups' },
  { label: 'No Rule Group', value: 'empty' },
];

export const RULE_REVIEW_STATUSES = [
  { label: 'All Open Statuses', value: 'All Open Statuses' },
  { label: 'First Check', value: 'First Check' },
  { label: 'New Finding', value: 'New Finding' },
  { label: 'Awaiting Internal Action', value: 'Awaiting Internal Action' },
  { label: 'Awaiting External Action', value: 'Awaiting External Action' },
  { label: 'Closed', value: 'Closed' },
];

export const BULK_RULE_REVIEW_STATUSES = [
  { label: 'New Finding', value: 'New Finding' },
  { label: 'Awaiting Internal Action', value: 'Awaiting Internal Action' },
  { label: 'Awaiting External Action', value: 'Awaiting External Action' },
  { label: 'Closed', value: 'Closed' },
];

export const SET_REVIEW_STATUS_TITLE = 'Review Status';

export const SET_REVIEW_STATUS_BUTTON_TITLE = 'Update Status';

export const FEEDBACK_MODAL = {
  TITLE: 'Fintel Check Feedback',
  DESCRIPTION: 'Do you consider this status to be correct?',
  YES: 'Yes',
  NO: 'No',
  CANCEL: 'Cancel',
  SUBMIT: 'Submit',
};
