import { gql } from '@apollo/client';

export const UPDATE_FCHECK_SETTINGS = gql`
  mutation updateFintelCheckSettings($input: FintelCheckSettingsInput!) {
    updateFintelCheckSettings(input: $input) {
      settings {
        id
        merchantId
        frequencySettings
        brandName
        publisherSettings
        crawlDepthSettings
        checkAllDomains
        trackingDomainDaySetting
        trackingDomainDayNumberSetting
        enabled
        dayToRun
        urlCheckType
        timesToWait
        blacklistDomains
      }
    }
  }
`;
