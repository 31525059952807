/**
 * Find duplicates in the url query params and can be passed the additional query params
 * to check for merge with the original query params and return the duplicates
 */
export const findQueryParamsDuplicates = (baseURL: string, additionalParams: string): string[] => {
  let url: URL;

  try {
    url = new URL(baseURL);
  } catch (error) {
    throw new Error(`Error in parsing the URL: ${baseURL}`);
  }

  const combinedParams = new URLSearchParams(url.search);

  try {
    new URLSearchParams(additionalParams).forEach((value, key) => {
      combinedParams.append(key, value);
    });
  } catch (error) {
    throw new Error(`Invalid additional params: ${additionalParams}`);
  }

  const seenParams = new Set<string>();
  const duplicates = new Set<string>();

  combinedParams.forEach((_, key) => {
    const trimmedKey = key.trim();

    if (seenParams.has(trimmedKey)) {
      duplicates.add(trimmedKey);
    } else {
      seenParams.add(trimmedKey);
    }
  });

  return Array.from(duplicates);
};
