import styled from 'styled-components';

import { Button, Select } from '../../../../../../components';
import { colors, fonts } from '../../../../../../styles/theme';

export const DescriptionStyled = styled.p`
  margin: 2rem 0 0.5rem;
  ${fonts.gotham.H16M}
  color: ${colors.color1};
`;

export const InstructionStyled = styled.p`
  ${fonts.lato.H14}
  color: ${colors.color1};
  line-height: 1.5;
  margin: 0.5rem 0 2rem;
`;

export const SampleStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${fonts.lato.H14}
  color: ${colors.color2};
  line-height: 1.5;
  margin: 0.5rem 0 2rem;
`;

export const SelectStyled = styled(Select)`
  margin-bottom: 2rem;
`;

export const SelectWrapperStyled = styled.div`
  margin-top: 2rem;
  width: 25%;
`;

export const ButtonsWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 2rem;
  margin-right: 2rem;
  width: 100%;
`;

export const ButtonStyled = styled(Button)``;
