import { getSideMenuItemStyle } from 'components/SideMenu/utils/getMenuItemStyle.utils';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { colors, fonts } from 'styles/theme';
import { hexToRGB, toRem } from 'utils';

interface IIconAsLinkProps {
  isCollapsed?: boolean;
}

interface IMenuItemProps {
  level: number;
  isUnderManagement: boolean;
}

export const sideMenuActiveStyles = css`
  border-left: 3px solid ${colors.color2};
  background: rgba(${hexToRGB(colors.color4)}, 0.1);
  color: ${colors.color4};
`;

export const MenuItem = styled(NavLink).withConfig({
  shouldForwardProp: (prop) => !['level', 'isUnderManagement'].includes(prop),
})<IMenuItemProps>`
  ${({ level, isUnderManagement }) => {
    const style = getSideMenuItemStyle(level, isUnderManagement);
    return css`
      color: ${style.color};
      border-left: ${toRem(3)} solid transparent;
      padding: 0.5rem 1rem 0.5rem ${style.indent};
      background: ${style.backgroundColor};
      display: flex;
      text-decoration: none;
      font-size: 0.9rem;
      ${fonts.gotham.H12M}
      line-height: 1.5;
      white-space: break-spaces;

      &:hover {
        color: ${style.hoverColor};
      }

      &.active {
        color: ${style.activeColor};
      }
    `;
  }}
`;

export const IconAsLink = styled(NavLink).withConfig({
  shouldForwardProp: (prop) => !['isCollapsed'].includes(prop),
})<IIconAsLinkProps>`
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
  color: ${colors.color7};

  &:active,
  &:hover {
    color: ${colors.color7};
  }
`;
