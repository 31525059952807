import { faPlus, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppWrapper,
  Button,
  PageTitle,
  Pagination,
  Select,
  ErrorBanner,
  RecordsDropdown,
  ResizeTable,
} from 'components';
import React from 'react';
import { DefaultPropsType } from 'types';
import { path, RECORDS_PER_PAGE_OPTIONS_WITH_150 } from 'utils';

import { EditEvaluationRule, AddEvaluationRule, AddMonitoringRule } from './components';
import { columns } from './contracts';
import { RULE_MANAGER } from './enums';
import { useFintelRuleManager } from './hooks';
import AddRuleGroupModal from './modal/AddRuleGroupModal';
import DeleteRuleModal from './modal/DeleteRuleModal';
import * as Styled from './styles';

type FintelCheckRuleManagerProps = DefaultPropsType;

const FintelCheckRuleManager = ({ permissionsCodeList = [] }: FintelCheckRuleManagerProps): JSX.Element => {
  const hook: ReturnType<typeof useFintelRuleManager> = useFintelRuleManager(permissionsCodeList);
  const [isReadOnly] = hook.isReadOnlyList;
  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.WrapperStyled>
        {hook.stateMachine.matches('RuleList') && (
          <>
            <ErrorBanner isOpen={!!hook.errorMessage} message={hook.errorMessage} />
            <Styled.HeaderStyled>
              <PageTitle isLoading={hook.dropdownsLoading || hook.ruleManagerLoading} loadingText={hook.loadingMessage}>
                {path.ruleManager.name}
              </PageTitle>

              <Styled.HeaderButtonsStyled>
                <Button theme="tertiary" onClick={() => hook.setIsRuleGroupModalOpen(true)} disabled={isReadOnly}>
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
                  {RULE_MANAGER.ADD_RULE_GROUP_TITLE}
                </Button>

                <Button theme="tertiary" onClick={() => hook.send({ type: 'AddMonitoringRule' })} disabled={isReadOnly}>
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
                  {RULE_MANAGER.ADD_MONITORING_RULE}
                </Button>

                <Button theme="tertiary" onClick={() => hook.send({ type: 'AddEvaluationRule' })} disabled={isReadOnly}>
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
                  {RULE_MANAGER.ADD_EVALUATION_RULE}
                </Button>
              </Styled.HeaderButtonsStyled>
            </Styled.HeaderStyled>
            <Styled.FiltersStyled>
              <Select
                label={RULE_MANAGER.LABELS.PRODUCT_CATEGORY}
                name="category"
                onChange={hook.setSelectedProductCategory}
                options={hook.productCategoriesOptions}
                selected={hook.selectedProductCategory}
                isLoading={hook.dropdownsLoading}
                isDisabled={hook.dropdownsLoading || !!hook.dropdownsError}
              />
              <Select
                label={RULE_MANAGER.LABELS.RULE_TYPES}
                name="ruleType"
                onChange={hook.selectedRuleTypeHandler}
                options={hook.defaultRuleTypesOptions}
                selected={hook.selectedRuleType}
                isLoading={hook.dropdownsLoading}
                isDisabled={hook.dropdownsLoading || !!hook.dropdownsError}
              />
              <Select
                label={RULE_MANAGER.LABELS.RULE_GROUPS}
                name="ruleGroup"
                onChange={hook.selectedRuleGroupHandler}
                options={hook.ruleGroupOptions}
                selected={hook.selectedRuleGroup}
                isLoading={hook.dropdownsLoading}
                isDisabled={hook.dropdownsLoading || !!hook.dropdownsError}
              />
              <Select
                label={RULE_MANAGER.LABELS.PRODUCT_NAME}
                name="product"
                onChange={hook.setSelectedProductName}
                options={hook.productNameOptions}
                selected={hook.selectedProductName}
                isLoading={hook.dropdownsLoading}
                isDisabled={hook.dropdownsLoading || !!hook.dropdownsError}
              />
              <Select
                label={RULE_MANAGER.LABELS.STATUS}
                name="status"
                onChange={hook.setSelectedStatus}
                options={hook.statusOptions}
                selected={hook.selectedStatus}
                isLoading={hook.dropdownsLoading}
                isDisabled={hook.dropdownsLoading || !!hook.dropdownsError}
              />
              <Select
                label={RULE_MANAGER.LABELS.RULES}
                name="rules"
                onChange={hook.setSelectedRule}
                options={hook.ruleNameOptions}
                selected={hook.selectedRule}
                isLoading={hook.dropdownsLoading}
                isDisabled={hook.dropdownsLoading || !!hook.dropdownsError}
              />
            </Styled.FiltersStyled>

            <Styled.RecordsStyled>
              <Styled.ExtendedRecordsStyled>
                <RecordsDropdown
                  selected={hook.selectedRecordsAmount}
                  options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
                  currentPage={hook.page}
                  totalRecords={hook.totalValues}
                  onChange={hook.setSelectedRecordsAmount}
                />
              </Styled.ExtendedRecordsStyled>

              <Styled.ClearFormCSVSection>
                <Button theme="text-only" onClick={hook.resetDropdowns}>
                  {RULE_MANAGER.CLEAR_FORM_BUTTON}
                </Button>
                <Styled.ButtonStyled theme="tertiary" onClick={() => hook.downloadMonitoringReportCSV()} width="10rem">
                  <Styled.IconStyled icon={faFileDownload} />
                  {RULE_MANAGER.RULE_CSV_BUTTON_TITLE}
                </Styled.ButtonStyled>
              </Styled.ClearFormCSVSection>
            </Styled.RecordsStyled>

            <ResizeTable
              columns={columns(isReadOnly, hook.handleDelete, hook.handleEdit)}
              data={hook.tableData}
              sortColumn={hook.sortColumn}
              onSort={hook.sortByHandler}
              setOpenModal={() => hook.setIsDetailsModalOpenHandler(true)}
              setModalInfo={hook.setModalRuleIdHandler}
              isLoading={hook.tableLoading}
            />
            {hook.tableData && (
              <Pagination
                total={hook.totalPages}
                currentPage={hook.tableData.length > 0 ? hook.page : 0}
                onPageChange={hook.changePage}
              />
            )}

            <AddRuleGroupModal hook={hook} />
          </>
        )}
        {hook.stateMachine.matches('AddMonitoringRule') && (
          <AddMonitoringRule
            stateMachine={hook.stateMachine}
            send={hook.send}
            closeModalFn={hook.setIsAddMonitoringRuleModalOpen}
            newRuleCreated={hook.refreshRuleManager}
            isReadOnly={isReadOnly}
            ruleGroupOptions={hook.ruleGroupOptions}
          />
        )}
        {hook.stateMachine.matches('AddEvaluationRule') && (
          <AddEvaluationRule
            stateMachine={hook.stateMachine}
            send={hook.send}
            refreshRuleManager={hook.refreshRuleManager}
            isReadOnly={isReadOnly}
          />
        )}

        {hook.stateMachine.matches('EditRule') && (
          <EditEvaluationRule
            stateMachine={hook.stateMachine}
            send={hook.send}
            refreshRuleManager={hook.refreshRuleManager}
            handleDelete={hook.handleDelete}
            isReadOnly={isReadOnly}
          />
        )}
        <DeleteRuleModal hook={hook} />
      </Styled.WrapperStyled>
    </AppWrapper>
  );
};

export default FintelCheckRuleManager;
