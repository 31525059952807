import React from 'react';
// General App Imports
import { Button, ErrorBanner } from 'components';
import { AnyEventObject, SnapshotFrom } from 'xstate';
// Custom Components
import { PageWrapper } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/styles';
import { machine } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/machine';

import { MonitoringRuleSummaryModal } from '../MonitoringRuleSummaryModal';

import { MonitoringNameRuleGroup, MonitoringRuleCriteria, CancelAddMonitoringRule } from './components';
// Custom Hooks
import { useAddMonitoringRule } from './hooks';
// Custom Styles
import * as Styled from './styles';

interface IAddMonitoringRuleProps {
  closeModalFn: (state: boolean) => void;
  newRuleCreated: () => void;
  stateMachine: SnapshotFrom<typeof machine>;
  send: (event: AnyEventObject) => void;
  isReadOnly: boolean;
  ruleGroupOptions: SelectOption[];
}

export const AddMonitoringRule = ({
  closeModalFn,
  newRuleCreated,
  stateMachine,
  send,
  isReadOnly,
  ruleGroupOptions,
}: IAddMonitoringRuleProps): JSX.Element => {
  const hook = useAddMonitoringRule(closeModalFn, newRuleCreated, stateMachine, send);

  return (
    <PageWrapper>
      <ErrorBanner isOpen={!!hook.errorMessage} message={hook.errorMessage} />
      <Styled.PageTitleStyled>
        {!stateMachine.matches({ AddMonitoringRule: 'NameRuleGroup' }) && (
          <Styled.BackButton
            onClick={() => {
              send({ type: 'AddMonitoringRule.previous' });
            }}
          >
            &lt;
          </Styled.BackButton>
        )}
        {stateMachine.context.titleContent}
      </Styled.PageTitleStyled>

      {stateMachine.matches({ AddMonitoringRule: 'NameRuleGroup' }) && (
        <MonitoringNameRuleGroup
          ruleGroupOptions={ruleGroupOptions}
          closeModalFn={closeModalFn}
          newRuleCreated={newRuleCreated}
          stateMachine={stateMachine}
          send={send}
        />
      )}
      {stateMachine.matches({ AddMonitoringRule: 'RuleCriteria' }) && (
        <MonitoringRuleCriteria stateMachine={stateMachine} hook={hook} />
      )}
      {stateMachine.matches({ AddMonitoringRule: 'RuleSummary' }) && (
        <MonitoringRuleSummaryModal
          isNewRule
          closeModalFn={closeModalFn}
          newRuleCreated={newRuleCreated}
          stateMachine={stateMachine}
          send={send}
        />
      )}

      <Styled.RuleButtonsWrapper>
        <Button theme="tertiary" width="9rem" onClick={() => hook.cancelButtonHandler(true)}>
          Cancel
        </Button>
        {!stateMachine.matches({ AddMonitoringRule: 'NameRuleGroup' }) && (
          <Button
            theme="secondary"
            width="9rem"
            onClick={() => {
              send({ type: 'AddMonitoringRule.previous' });
            }}
          >
            Back
          </Button>
        )}
        {!stateMachine.matches({ AddMonitoringRule: 'RuleSummary' }) && (
          <Button
            width="9rem"
            theme="primary"
            onClick={() => send({ type: 'AddMonitoringRule.next' })}
            disabled={!stateMachine.can({ type: 'AddMonitoringRule.next' })}
          >
            Next
          </Button>
        )}
        {stateMachine.matches({ AddMonitoringRule: 'RuleSummary' }) && (
          <Button theme="primary" disabled={isReadOnly} onClick={() => hook.createRule()} width="9rem">
            Create Rule
          </Button>
        )}
      </Styled.RuleButtonsWrapper>

      <CancelAddMonitoringRule
        isOpen={hook.cancelOpen}
        cancelButtonHandler={hook.cancelButtonHandler}
        exitAddRuleModalHandler={hook.exitAddRuleModalHandler}
      />
    </PageWrapper>
  );
};
