import styled from 'styled-components';

import { colors, fonts } from 'styles/theme';

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  color: ${colors.color1};
  ${fonts.gotham.H16M}
  margin-bottom: 0.5rem;
`;

export const SubTitleContainer = styled.div`
  ${fonts.lato.H14}
`;

export const DescriptionContainer = styled.div`
  width: 60%;
  padding: 1rem 0.5rem;
`;

export const ContentContainer = styled.div`
  width: 40%;
  padding: 1rem 0.5rem;
`;
