import { gql } from '@apollo/client';

export const LIST_FINTEL_CHECK_RULE = gql`
  query FintelCheckRules($input: FintelCheckRulesInput!) {
    fintelCheckRules(input: $input) {
      count
      rules {
        id
        status
        applicableProducts
        productName
        brandName
        startDate
        endDate
        failCount
        lastRun
        merchantId
        nfCount
        productCategory
        productFeed {
          productFeed
          productFeedData
          required
        }
        eligibility {
          type
          value
        }
        product {
          id
          customizedProductId
          name
          description
          status
          productFeedSection {
            apyRate
            interestRate
            legalReferenceItems {
              name
              description
            }
            mortgageAprRate
            annualFee
            balanceTransferRegular
            balanceTransferIntroRate
          }
          productCategory
        }
        productId
        reviewCount
        ruleName
        ruleType
        ruleGroup
        fieldChecks {
          fieldName
          expectedValue
        }
        ruleStatusSettings {
          fieldsRequired
          fieldState
          ruleState
        }
      }
    }
  }
`;
