import React from 'react';
import _ from 'lodash';
// General App Imports
import { InputText, Select, Spacer } from 'components';
import { AnyEventObject, SnapshotFrom } from 'xstate';
// Custom Hooks
import { useAddMonitoringRule } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddMonitoringRule/hooks';
// Custom Components
import { PageWrapper } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/styles';
import { machine } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/machine';

// Custom Styled Components
import * as Styled from '../styles';
// Custom Enums
import { MONITORING_NAME_RULEGROUP } from '../enums';

interface IMonitoringNameRuleGroupProps {
  ruleGroupOptions: SelectOption[];
  closeModalFn: (state: boolean) => void;
  newRuleCreated: () => void;
  stateMachine: SnapshotFrom<typeof machine>;
  send: (event: AnyEventObject) => void;
}

export const MonitoringNameRuleGroup = ({
  ruleGroupOptions,
  closeModalFn,
  newRuleCreated,
  stateMachine,
  send,
}: IMonitoringNameRuleGroupProps): JSX.Element => {
  const hook = useAddMonitoringRule(closeModalFn, newRuleCreated, stateMachine, send);

  return (
    <PageWrapper>
      <Spacer direction="vertical" size="medium" />
      <Styled.DropdownSectionStyled>
        <InputText
          required
          type="text"
          name="ruleName"
          label={MONITORING_NAME_RULEGROUP.RULE_NAME.LABEL}
          tooltip={MONITORING_NAME_RULEGROUP.RULE_NAME.TOOLTIP}
          placeholder={MONITORING_NAME_RULEGROUP.RULE_NAME.PLACEHOLDER}
          value={stateMachine.context.ruleName}
          onChange={hook.setRuleName}
          error={hook.ruleNameError}
          width="40vw"
          tooltipExtraMarginLeft={60}
        />
      </Styled.DropdownSectionStyled>
      <Styled.DropdownSectionStyled>
        <Select
          name="ruleGroup"
          label={MONITORING_NAME_RULEGROUP.GROUP_RULES.LABEL}
          tooltip={MONITORING_NAME_RULEGROUP.GROUP_RULES.TOOLTIP}
          placeholder={MONITORING_NAME_RULEGROUP.GROUP_RULES.PLACEHOLDER}
          selected={
            stateMachine.context.ruleGroup
              ? _.find(ruleGroupOptions, { value: stateMachine.context.ruleGroup })
              : undefined // This ensures that the placeholder is shown when the rule group is not set
          }
          options={ruleGroupOptions}
          onChange={hook.setSelectedCheckRuleGroupHandler}
          width="40vw"
        />
      </Styled.DropdownSectionStyled>
    </PageWrapper>
  );
};
