import styled from 'styled-components';

import { Button, InputText, Select } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

export const SelectStyled = styled(Select)`
  width: 192px;
`;

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
`;

export const HeaderButtonAreaStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const FiltersStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

export const ExtendedFiltersStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 550px;
`;

export const FilterByLabelStyled = styled.div`
  ${fonts.lato.H16}
  color: ${colors.color1};
`;

export const InputTextStyled = styled(InputText)`
  width: calc(100% - 570px);
`;

export const RecordsDropdownWrapper = styled.div`
  margin: 0 0 1.5rem 0;
`;

export const ToggleLabelStyled = styled.p`
  ${fonts.lato.H16}
  color: ${colors.color1};
`;

export const PaginationWrapper = styled.div`
  margin-bottom: 5rem;
`;

export const DynamicFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: ${colors.color4};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px 4px;
  position: fixed;
  bottom: 0;
  padding: 1.25rem;
  padding-top: 1.5rem;
  padding-bottom: 2.5rem;
  width: 55vw;
  align-self: center;

  animation-name: slideup;
  animation-delay: 0s;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  @keyframes slideup {
    0% {
      bottom: -50px;
    }
    100% {
      bottom: 0;
    }
  }
  & > div {
    width: 100%;
  }
`;

export const SubtitleStyled = styled.p`
  color: ${colors.color1};
  ${fonts.gotham.H18B};
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
