/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash';
import { PageUrlCell } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/contracts/Component/PageUrlCell/index';
import * as CStyled from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/styles';
import React from 'react';
import Styled from 'styled-components';
import { colors } from 'styles/theme';

const CellWrapperStyled = Styled.div`
  color: ${colors.color1};
  height: 1.6rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const columns = (handleOnScreenshotClick?: any): TableColumn[] =>
  [
    {
      dataField: 'createDatetime',
      text: 'Check Date',
      width: '12vw',
      formatter: (value) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'reviewStatus',
      text: 'Review Status',
      width: '12vw',
      formatter: (value) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'ruleStatus',
      text: 'Status',
      width: '8vw',
      formatter: (value) => <CellWrapperStyled>{_.startCase(value.toLowerCase())}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'urlSearch',
      text: 'Url',
      width: '25vw',
      formatter: (val) => <PageUrlCell val={val} />,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'ruleName',
      text: 'Rule',
      width: '12vw',
      formatter: (value) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'ruleGroup',
      text: 'Rule Group',
      width: '12vw',
      formatter: (value) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: '',
      text: 'Screenshot',
      width: '9.375vw',
      formatter: (_, row) => (
        <CStyled.InternalUrlLinkStyled onClick={(e) => e.stopPropagation()}>
          <div onClick={(e) => handleOnScreenshotClick(row.id)}>View Screenshot</div>
        </CStyled.InternalUrlLinkStyled>
      ),
    },
    {
      dataField: 'productCategory',
      text: 'Product Category',
      width: '15vw',
      formatter: (value) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'productName',
      text: 'Product',
      width: '15vw',
      formatter: (value) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'publisher',
      text: 'Publisher',
      width: '15vw',
      formatter: (value) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
  ] as TableColumn[];
