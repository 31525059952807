import React from 'react';
import { Modal } from 'components';
import { URL_STATUSES } from 'utils';

import { CHECK_TYPES_OPTIONS, URLS_CATEGORIES_OPTIONS, URLS_LIST } from '../enums';
import { useUrlsList } from '../hooks';

import * as Styled from './styles';

type UrlModalProps = {
  hook: ReturnType<typeof useUrlsList>;
};

const UrlModal = ({ hook }: UrlModalProps): JSX.Element => (
  <Modal isOpen={hook.isOpen}>
    <Styled.Wrapper>
      <Styled.TitleStyled>
        {hook.modalType === URLS_LIST.MODAL.TYPES.ADD && URLS_LIST.MODAL.TITLE.ADD}
        {hook.modalType === URLS_LIST.MODAL.TYPES.EDIT && URLS_LIST.MODAL.TITLE.EDIT}
        {(hook.modalType === URLS_LIST.MODAL.TYPES.REMOVE || hook.modalType === URLS_LIST.MODAL.TYPES.REMOVE_MANY) &&
          URLS_LIST.MODAL.TITLE.REMOVE}
      </Styled.TitleStyled>
      {hook.modalType !== URLS_LIST.MODAL.TYPES.REMOVE && hook.modalType !== URLS_LIST.MODAL.TYPES.REMOVE_MANY ? (
        <>
          <Styled.InputStyled
            value={hook.url}
            onChange={hook.setUrl}
            type="url"
            label="url"
            required
            error={hook.urlError}
            placeholder="https://www.fintelconnect.com"
            disabled={hook.modalType !== URLS_LIST.MODAL.TYPES.ADD}
            /* set to 2001 to trigger error message -> save button is disabled on error */
            maxLength={2001}
          />
          <Styled.SelectStyled
            label="Category"
            placeholder="Select a category"
            required
            selected={hook.category}
            onChange={hook.setCategory}
            options={URLS_CATEGORIES_OPTIONS}
            error={hook.categoryError}
          />
          <Styled.SelectStyled
            label="Check Type"
            placeholder="Select a check type"
            required
            selected={hook.checkType}
            onChange={hook.setCheckType}
            options={CHECK_TYPES_OPTIONS}
            error={hook.checkTypeError}
          />
        </>
      ) : (
        <Styled.RemovePromptStyled>
          You are about to remove the following URL(s) from <Styled.BoldText>Manual URLs List</Styled.BoldText>{' '}
          <Styled.UrlWrapper>
            {hook.modalType === URLS_LIST.MODAL.TYPES.REMOVE ? (
              <Styled.BoldTextDiv>{hook.url}</Styled.BoldTextDiv>
            ) : (
              hook.selectedUrls.map((item: string) => <Styled.BoldTextDiv key={item}>{item}</Styled.BoldTextDiv>)
            )}
          </Styled.UrlWrapper>
          Are you sure you want to continue?
        </Styled.RemovePromptStyled>
      )}
      <Styled.ButtonsWrapperStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={() => {
            hook.setIsOpen();
            hook.resetModal();
          }}
        >
          {URLS_LIST.MODAL.BUTTON.CANCEL}
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          loading={
            hook.validateLoading ||
            hook.updateManualUrlLoading ||
            hook.createManualUrlLoading ||
            hook.removeManualUrlLoading ||
            hook.removeBulkManualUrlsLoading
          }
          disabled={
            hook.validateLoading ||
            hook.updateManualUrlLoading ||
            hook.createManualUrlLoading ||
            hook.removeManualUrlLoading ||
            hook.removeBulkManualUrlsLoading ||
            (hook.urlError !== URL_STATUSES.ACTIVE_WEBSITE.ERROR && hook.urlError.length > 0) ||
            hook.categoryError.length > 0 ||
            hook.checkTypeError.length > 0
          }
          onClick={hook.handleModalRightButton}
        >
          {hook.modalType === URLS_LIST.MODAL.TYPES.ADD && URLS_LIST.MODAL.BUTTON.ADD}
          {hook.modalType === URLS_LIST.MODAL.TYPES.EDIT && URLS_LIST.MODAL.BUTTON.EDIT}
          {hook.modalType === URLS_LIST.MODAL.TYPES.REMOVE && URLS_LIST.MODAL.BUTTON.REMOVE}
          {hook.modalType === URLS_LIST.MODAL.TYPES.REMOVE_MANY && URLS_LIST.MODAL.BUTTON.REMOVE}
        </Styled.ButtonStyled>
      </Styled.ButtonsWrapperStyled>
    </Styled.Wrapper>
  </Modal>
);

export default UrlModal;
