import { Button, Modal } from 'components';
import { CANCEL_ADD_RULE } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/enums';
import * as Styled from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/styles';
import React from 'react';

type CancelAddRuleModalProps = {
  isOpen: boolean;
  cancelButtonHandler: (state: boolean) => void;
  exitAddRuleModalHandler: () => void;
};

export const CancelAddRuleModal = ({
  isOpen,
  cancelButtonHandler,
  exitAddRuleModalHandler,
}: CancelAddRuleModalProps): JSX.Element => (
  <Modal isOpen={isOpen}>
    <Styled.ModalWrapperStyled>
      <Styled.PageTitleStyled>{CANCEL_ADD_RULE.TITLE}</Styled.PageTitleStyled>
      <Styled.TextPaddedStyled>{CANCEL_ADD_RULE.WARNING}</Styled.TextPaddedStyled>
      <Styled.ModalFooterStyled>
        <div />
        <Styled.NavButtonsWrapperStyled>
          <Button theme="secondary" onClick={() => cancelButtonHandler(false)} width="120px">
            {CANCEL_ADD_RULE.CANCEL_BUTTON}
          </Button>
          <Button theme="primary" onClick={() => exitAddRuleModalHandler()} width="120px">
            {CANCEL_ADD_RULE.LEAVE_BUTTON}
          </Button>
        </Styled.NavButtonsWrapperStyled>
      </Styled.ModalFooterStyled>
    </Styled.ModalWrapperStyled>
  </Modal>
);
