import { gql } from '@apollo/client';

export const MERCHANT_PROGRAM = gql`
  query merchantProgram($merchantId: String!) {
    merchantProgram(merchantId: $merchantId) {
      program {
        id
        merchant {
          companyName
          id
        }
        products {
          productCategory
        }
      }
    }
  }
`;
