import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { SHOW_PROGRAM } from '../graphql/queries';
import {
  VISIBILITY_OPTIONS, REGEX_VALIDATORS, ERROR_TYPES, URL_STATUSES, ERROR_MESSAGES,
} from '../../../../../utils';
import { useToast, useUserInfo } from '../../../../../hooks';
import { UPDATE_PROGRAM } from '../graphql/mutations';
import { useValidation } from '../../../../../utils/validation';
import { Permission } from '../../../../../entities';

type visibilitySelectOptions = {
  label: string
  value: boolean
}

interface programSettingsObjectType {
  CookieDuration: string
  CookieDurationVisibility: boolean
  descriptionDetailed: string
  descriptionHighLevel: string
  id: string
  membersOffer: string
  membersOfferVisibility: boolean
  primaryContact: string
  primaryContactEmail: string
  primaryContactPhone: string
  productMatrixUrl: string
  signupBonus: string
  signupBonusVisibility: boolean
  statsFrequency: string
  statsFrequencyVisibility: boolean
  updatedAt: string
  updatedBy: {
    firstName: string
    lastName: string
  }
  termsConditionsTitle: string,
  termsConditions: string
}

export const useProgramSettings = (permissionsCodeList: string[] = []) => {
  const userHook = useUserInfo();
  const { hookShowToast } = useToast();

  const visibilityLabel = (value: boolean) => {
    if (value === false) {
      return VISIBILITY_OPTIONS[1].label;
    }
    return VISIBILITY_OPTIONS[0].label;
  };

  const [highLevelDescription, setHighLevelDescription] = useState('');
  const [programID, setProgramID] = useState('');
  const [signUpBonus, setSignUpBonus] = useState('');
  const [cookieLength, setCookieLength] = useState(0 as any);
  const [cookieDurationVisibility, setCookieDurationVisibility] = useState<visibilitySelectOptions>({ label: 'Hide', value: false });
  const [descriptionDetailed, setDescriptionDetailed] = useState('');
  const [memberOnlyOffer, setMemberOnlyOffer] = useState('');
  const [programContactName, setProgramContactName] = useState('');
  const [programContactEmail, setProgramContactEmail] = useState('');
  const [programContactPhone, setProgramContactPhone] = useState('');
  const [statsFrequencyVisibility, setStatsFrequencyVisibilty] = useState<visibilitySelectOptions>({ label: 'Hide', value: false });
  const [memberOfferVisibility, setMemberOfferVisibility] = useState<visibilitySelectOptions>({ label: 'Hide', value: false });
  const [signupBonusVisibility, setSignUpBonusVisibility] = useState<visibilitySelectOptions>({ label: 'Hide', value: false });
  const [updateFrequency, setUpdateFrequency] = useState<SelectOption>();
  const [productMatrixURL, setProductMatrixUrl] = useState<string>('');
  const [createUserErrors, setCreateUserErrors] = useState<{ [key: string]: string }>({});
  const [secondRender, setSecondRender] = useState<boolean>(false);
  const [contentLoaded, setContentLoaded] = useState<boolean>(false);
  const [urlStatus, setUrlStatus] = useState('');
  const [urlError, setUrlError] = useState('');

  const [termsAndConditionsUpdateTime, setTermsAndConditionsUpdateTime] = useState<string>('');
  const [termsAndConditionsUpdateBy, setTermsAndConditionsUpdateBy] = useState<string>('');
  const [termsConditions, setTermsConditions] = useState<string>('');
  const [termsConditionsTitle, setTermsConditionsTitle] = useState<string>('');
  const [editTermsConditions, setEditTermsConditions] = useState<boolean>(false);
  const [previewTermsConditions, setPreviewTermsConditions] = useState<boolean>(false);

  const [termsAndConditionsTitleError, setTermsAndConditionsTitleError] = useState<string>('');
  const [defaultTCTitle, setTCTitle] = useState<string>('');
  const [defaultTCMessage, setTCMessage] = useState<string>('');

  const setEditTermsConditionsHandler = async (show: boolean) => {
    setEditTermsConditions(show);
  };

  const setPreviewTermsConditionsHandler = async (show: boolean) => {
    setPreviewTermsConditions(show);
  };

  const setTermsConditionsHandler = async (e: string) => {
    setTermsConditions(e);
  };

  const setTermsConditionsTitleHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAndConditionsTitleError('');
    setTermsConditionsTitle(e.target.value);
  };

  const [getProgramSettings] = useLazyQuery(SHOW_PROGRAM);
  const [editProgram] = useMutation(UPDATE_PROGRAM);

  const setDescriptionHighLevelHandler = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHighLevelDescription(e?.target.value);
  };

  const setDescriptionDetailedHandler = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescriptionDetailed(e?.target.value);
  };

  const setProgramIDHandler = async (value: string) => {
    setProgramID(value);
  };

  const setCookieDurationHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberRegex = /^[0-9]+$/;
    if (e.target.value < '1' || !e.target.value.match(numberRegex)) return;
    setCookieLength(+e.target.value);
  };

  const setStatsFrequencyVisibilityHandler = async (value: visibilitySelectOptions) => {
    setStatsFrequencyVisibilty(value);
  };

  const setMemberOfferVisibilityHandler = async (value: visibilitySelectOptions) => {
    setMemberOfferVisibility(value);
  };

  const setSignUpBonusVisibilityHandler = async (value: visibilitySelectOptions) => {
    setSignUpBonusVisibility(value);
  };

  const setCookieDurationVisibilityHandler = async (value: visibilitySelectOptions) => {
    setCookieDurationVisibility(value);
  };

  const setProgramContactNameHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setProgramContactName(e?.target.value);
  };

  const setProgramContactEmailHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setProgramContactEmail(e?.target.value);
  };

  const setProgramContactPhoneHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e) setProgramContactPhone(e.toString());
    else setProgramContactPhone('');
  };

  const setMemberOfferHandler = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMemberOnlyOffer(e?.target.value);
  };

  const setSignUpBonusHandler = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSignUpBonus(e?.target.value);
  };

  const setUpdateFrequencyHandler = async (value?: SelectOption) => {
    setUpdateFrequency(value);
  };

  const setProductMatrixUrlHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductMatrixUrl(e?.target.value);
  };

  const setProgramHandler = async () => {
    const { data } = await getProgramSettings({
      variables: {
        id: userHook.hookUserInfo.merchantId || userHook.hookWhoAmI.programId,
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.program) {
      const programDetails: programSettingsObjectType = data.program;
      setHighLevelDescription(programDetails.descriptionHighLevel);
      setDescriptionDetailed(programDetails.descriptionDetailed);
      setProgramIDHandler(programDetails.id);
      setCookieLength(programDetails.CookieDuration);
      setProgramContactName(programDetails.primaryContact);
      setProgramContactEmail(programDetails.primaryContactEmail);
      setProgramContactPhone(programDetails.primaryContactPhone);
      setMemberOnlyOffer(programDetails.membersOffer);
      setSignUpBonus(programDetails?.signupBonus);
      setCookieDurationVisibility({ label: visibilityLabel(programDetails.CookieDurationVisibility), value: programDetails.CookieDurationVisibility });
      setStatsFrequencyVisibilty({ label: visibilityLabel(programDetails.statsFrequencyVisibility), value: programDetails.statsFrequencyVisibility });
      setMemberOfferVisibility({ label: visibilityLabel(programDetails.membersOfferVisibility), value: programDetails.membersOfferVisibility });
      setSignUpBonusVisibility({ label: visibilityLabel(programDetails.signupBonusVisibility), value: programDetails.signupBonusVisibility });
      setTermsAndConditionsUpdateTime((new Date(programDetails.updatedAt).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })));
      setProductMatrixUrl(programDetails.productMatrixUrl);
      setUpdateFrequency({ label: programDetails.statsFrequency, value: programDetails.statsFrequency });

      setTermsAndConditionsUpdateBy(`${programDetails.updatedBy.firstName} ${programDetails.updatedBy.lastName}`);
      setTermsConditionsTitle(programDetails.termsConditionsTitle);
      setTermsConditions(programDetails.termsConditions);
      setTCTitle(programDetails.termsConditionsTitle);
      setTCMessage(programDetails.termsConditions);

      setContentLoaded(true);
    }
  };
  const vali = useValidation();
  const values: { [key: string]: string } = {
    descriptionHighLevel: highLevelDescription || '',
    descriptionDetailed: descriptionDetailed || '',
    primaryContact: programContactName || '',
    primaryContactEmail: programContactEmail || '',
    primaryContactPhone: programContactPhone || '',
    productMatrixUrl: productMatrixURL || '',
    CookieDurationVisibility: cookieDurationVisibility?.toString() || '',
    membersOfferVisibility: memberOfferVisibility?.toString() || '',
    statsFrequencyVisibility: statsFrequencyVisibility?.toString() || '',
    signupBonusVisibility: signupBonusVisibility?.toString() || '',
  };
  const fields = {
    descriptionHighLevel: ERROR_TYPES.NOT_EMPTY,
    descriptionDetailed: ERROR_TYPES.NOT_EMPTY,
    primaryContact: ERROR_TYPES.NOT_EMPTY,
    primaryContactEmail: ERROR_TYPES.EMAIL,
    primaryContactPhone: ERROR_TYPES.PHONE,
    productMatrixUrl: REGEX_VALIDATORS.URL,
    CookieDurationVisibility: ERROR_TYPES.SELECTION_REQUIRED,
    membersOfferVisibility: ERROR_TYPES.SELECTION_REQUIRED,
    statsFrequencyVisibility: ERROR_TYPES.SELECTION_REQUIRED,
    signupBonusVisibility: ERROR_TYPES.SELECTION_REQUIRED,
  };

  const handleValidation = () => {
    let noWebsiteErrors = true;
    if (urlStatus !== URL_STATUSES.ACTIVE_WEBSITE.STATUS && urlStatus !== URL_STATUSES.INACTIVE_WEBSITE.STATUS && urlStatus !== URL_STATUSES.UNSAFE_WEBSITE.STATUS) {
      noWebsiteErrors = false;
    }
    const pass = vali.validateAll(values, fields, setCreateUserErrors, secondRender);
    return pass && noWebsiteErrors;
  };

  const validateWebsite = () => {
    if (productMatrixURL !== '') {
      if (REGEX_VALIDATORS.URL_NO_HTTPS.REGEX.test(productMatrixURL)) {
        setProductMatrixUrl(`https://${productMatrixURL}`);
      } else {
        vali.validateUrlStatus(productMatrixURL, setUrlStatus);
        if (urlStatus) vali.renderUrlCheck(urlStatus, setUrlError);
      }
    }
  };

  useEffect(() => {
    if (urlStatus) vali.renderUrlCheck(urlStatus, setUrlError);
  }, [urlStatus]);

  /* Needed to allow errors to show after failed submits. */
  useEffect(() => {
    handleValidation();
  }, [secondRender]);

  // Validate website after user stops typing for 500ms
  useEffect(() => {
    const handler = setTimeout(() => {
      if (!productMatrixURL) {
        setUrlError(ERROR_MESSAGES.PRODUCT_MATRIX_URL);
        return;
      }

      validateWebsite();
      handleValidation();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [productMatrixURL]);

  const updateProgramHandler = async () => {
    setSecondRender(true);
    const noErrors = handleValidation();
    if (!noErrors) return;
    const { errors } = await editProgram({
      variables: {
        input: {
          id: userHook.hookWhoAmI.programId,
          descriptionHighLevel: highLevelDescription,
          signupBonus: signUpBonus,
          descriptionDetailed,
          membersOffer: memberOnlyOffer,
          CookieDurationVisibility: cookieDurationVisibility.value,
          membersOfferVisibility: memberOfferVisibility.value,
          statsFrequencyVisibility: statsFrequencyVisibility.value,
          signupBonusVisibility: signupBonusVisibility.value,
          CookieDuration: cookieLength ? cookieLength.toString() : '0',
          statsFrequency: updateFrequency?.value,
          primaryContact: programContactName,
          primaryContactEmail: programContactEmail,
          primaryContactPhone: programContactPhone,
          productMatrixUrl: productMatrixURL,
        },
      },
    });
    if (errors) {
      hookShowToast(errors[0].message);
    } else {
      hookShowToast('Program updated successfully');
    }
  };

  const updateProgramTermsConditionsHandler = async () => {
    const { errors } = await editProgram({
      variables: {
        input: {
          id: userHook.hookWhoAmI.programId,
          termsConditionsTitle,
          termsConditions,
        },
      },
    });
    if (errors) {
      hookShowToast(errors[0].message);
    } else {
      hookShowToast('Updated Terms and Conditions successfully');
    }
    setEditTermsConditionsHandler(false);
    setPreviewTermsConditionsHandler(false);
  };

  const resetFields = () => {
    setTermsAndConditionsTitleError('');
    setTermsConditionsTitle(defaultTCTitle);
    setTermsConditions(defaultTCMessage);
  };

  const previewTC = () => {
    if (termsConditionsTitle === '') setTermsAndConditionsTitleError('Please provide a valid title');
    if (termsConditions === '<p><br></p>') hookShowToast('Please provide a valid details message');
    if (termsConditionsTitle !== '' && termsConditions !== '<p><br></p>') {
      setEditTermsConditionsHandler(false);
      setPreviewTermsConditionsHandler(true);
    }
  };

  useEffect(() => {
    setProgramHandler();
  }, []);

  useEffect(() => {
    validateWebsite();
  }, [contentLoaded]);

  return {
    hookHighLevelDescription: highLevelDescription,
    hookSetHighLevelDescription: setDescriptionHighLevelHandler,

    hookDetailedDescription: descriptionDetailed,
    hookSetDetailedDescription: setDescriptionDetailedHandler,

    hookProgramID: programID,
    hookSetProgramID: setProgramIDHandler,

    hookCookieDuration: cookieLength,
    hookSetCookieDuration: setCookieDurationHandler,

    hookProgramContactName: programContactName,
    hookSetProgramContactname: setProgramContactNameHandler,

    hookProgramContactEmail: programContactEmail,
    hookSetProgramContactEmail: setProgramContactEmailHandler,

    hookProgramContactPhone: programContactPhone,
    hookSetProgramContactPhone: setProgramContactPhoneHandler,

    hookMemberOffer: memberOnlyOffer,
    hookSetMemberOffer: setMemberOfferHandler,

    hookSignUpBonus: signUpBonus,
    hookSetSignUpBonus: setSignUpBonusHandler,

    hookCookieDurationVisibility: cookieDurationVisibility,
    hookSetCookieDurationVisibility: setCookieDurationVisibilityHandler,

    hookUpdateFrequency: updateFrequency,
    hookSetUpdateFrequencyHander: setUpdateFrequencyHandler,

    hookStatsFrequencyVisibility: statsFrequencyVisibility,
    hookSetStatsFrequencyVisibility: setStatsFrequencyVisibilityHandler,

    hookMemberOfferVisibility: memberOfferVisibility,
    hookSetMemberOfferVisibility: setMemberOfferVisibilityHandler,

    hookSignUpBonusVisibility: signupBonusVisibility,
    hookSetSignUpBonusVisibility: setSignUpBonusVisibilityHandler,

    hookUpdateProgram: updateProgramHandler,

    hookProductMatrixURL: productMatrixURL,
    hookSetProductMatrixUrlHandler: setProductMatrixUrlHandler,

    hookCreateUserErrors: createUserErrors,
    hookSetSecondRender: setSecondRender,
    hookHandleValidation: handleValidation,

    hookUrlError: urlError,
    hookValidateWebsite: validateWebsite,

    hookTermsAndConditionsUpdateTime: termsAndConditionsUpdateTime,
    hookTermsAndConditionsUpdateBy: termsAndConditionsUpdateBy,
    hookTermsConditions: termsConditions,
    hookSetTermsConditionsHandler: setTermsConditionsHandler,
    hookTermsConditionsTitle: termsConditionsTitle,
    hookTermsConditionsTitleHandler: setTermsConditionsTitleHandler,
    hookEditTermsConditions: editTermsConditions,
    hookSetEditTermsConditionsHandler: setEditTermsConditionsHandler,
    hookPreviewTermsConditions: previewTermsConditions,
    hookSetPreviewTermsConditionsHandler: setPreviewTermsConditionsHandler,
    hookUpdateProgramTermsConditionsHandler: updateProgramTermsConditionsHandler,

    hookResetFields: resetFields,
    hookPreviewPage: previewTC,
    hookTitleError: termsAndConditionsTitleError,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
