import React, { ReactElement } from 'react';

import { Toggle } from 'components';

import { useFintelCheckSettings } from '../../hooks/useFintelCheckSettings';
import { ToolSettings } from '../../types';
import en from '../../locales/en.json';

import { ToggleContainer, LabelWrapper } from './ToolSettingsToggle.styles';

const ToolSettingsToggle = (): ReactElement => {
  const { isToolSettingsEnabled, isReadOnlyPermissions, isPageLoading, updateToolSetting } = useFintelCheckSettings();

  const isToggleDisabled = isReadOnlyPermissions || isPageLoading;

  const handleToggleChanged = async (): Promise<void> => {
    await updateToolSetting(ToolSettings.Enabled, !isToolSettingsEnabled);
  };

  return (
    <ToggleContainer>
      <LabelWrapper>{en.settings.toggle.label}</LabelWrapper>
      <Toggle checked={isToolSettingsEnabled} onChange={handleToggleChanged} disabled={isToggleDisabled} />
    </ToggleContainer>
  );
};

export default ToolSettingsToggle;
