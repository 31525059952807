import styled from 'styled-components';

import { toRem } from '../../../../utils';

interface ILogoContainerProps {
  isCollapsed: boolean;
}

export const LogoContainer = styled.figure<ILogoContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${toRem(26)} auto;
  height: ${toRem(70)};
  width: ${({ isCollapsed }) => (isCollapsed ? `${toRem(30)}` : `${toRem(100)}`)};
`;
