import React from 'react';

import {
  AppWrapper, PageTitle, Pagination, Select, FixedFooter, ErrorBanner, Table,
} from '../../../components';
import {
  path, RECORDS_PER_PAGE_OPTIONS, USER_TYPES_ID,
} from '../../../utils';
import { columns, columnsMerchant } from './contracts';
import { useUserInfo } from '../../../hooks';
import { usePendingTransactions } from './hooks';
import { ApproveDeclineModal } from './ApproveDeclineModal';
import { PENDING_TRANSACTIONS } from './enum';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type PendingTransactionsProps = DefaultPropsType

const PendingTransactions = ({ permissionsCodeList }: PendingTransactionsProps) => {
  const userHook = useUserInfo();

  const { hookIsReadOnlyList, ...hook } = usePendingTransactions(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.WrapperStyled>
        <ErrorBanner
          isOpen={!!hook.hookErrorMessage}
          message={hook.hookErrorMessage}
        />
        <Styled.HeaderStyled>
          <PageTitle
            loadingText={hook.hookLoadingMessage}
            isLoading={hook.hookIsLoading}
          >
            {path.pendingTransactions.name}
          </PageTitle>

          {/* display when is acting as merchant */}
          {(userHook.hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT || userHook.hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT) && (
            <Styled.FiltersSelectStyled>
              <span>{PENDING_TRANSACTIONS.RECORD_DROPDOWN.TEXT}</span>
              <Styled.SelectStyled
                label={PENDING_TRANSACTIONS.RECORD_DROPDOWN.LABEL}
                name={PENDING_TRANSACTIONS.RECORD_DROPDOWN.NAME}
                options={RECORDS_PER_PAGE_OPTIONS}
                selected={hook.hookRecordsSelected}
                onChange={hook.hookHandleRecordsSelected}
              />
            </Styled.FiltersSelectStyled>
          )}
        </Styled.HeaderStyled>

        {/* remove when is acting as merchant */}
        {userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.MERCHANT && userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT && (
          <Styled.FiltersStyled>
            <Select
              label={PENDING_TRANSACTIONS.MERCHANT.LABEL}
              name={PENDING_TRANSACTIONS.MERCHANT.NAME}
              selected={hook.hookMerchantSelected}
              onChange={hook.hookHandleMerchantSelected}
              options={hook.hookMerchantsList}
              width="30%"
              isDisabled={hook.hookIsLoading}
            />

            <Styled.FiltersSelectStyled>
              <span>{PENDING_TRANSACTIONS.RECORD_DROPDOWN.TEXT}</span>
              <Styled.SelectStyled
                label={PENDING_TRANSACTIONS.RECORD_DROPDOWN.LABEL}
                name={PENDING_TRANSACTIONS.RECORD_DROPDOWN.NAME}
                options={RECORDS_PER_PAGE_OPTIONS}
                selected={hook.hookRecordsSelected}
                onChange={hook.hookHandleRecordsSelected}
                isDisabled={hook.hookIsLoading}
              />
            </Styled.FiltersSelectStyled>
          </Styled.FiltersStyled>
        )}

        {!hook.hookIsLoading && (
          <Table
            columns={(userHook.hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT || userHook.hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT) ? columnsMerchant(isReadOnly) : columns(isReadOnly)}
            data={hook.hookData}
            headerCheckBox={hook.hookHeaderCheck}
            handleHeaderCheckBox={hook.hookHeaderCheckOnChange}
            handleCheckBox={hook.hookHandleChangeCheck}
            isLoading={hook.hookIsLoading}
            sortColumn={hook.hookSortColumn}
            onSort={hook.hookSortByHandler}
          />
        )}

        <ApproveDeclineModal
          isOpen={hook.hookIsApproveModalOpen}
          onClose={() => hook.hookHandleSetModalIsOpen('Approve')}
          onConfirm={() => hook.hookHandleUpdateTransactions('Approved')}
          title={PENDING_TRANSACTIONS.APRROVE_MODAL.TITLE}
          desc={PENDING_TRANSACTIONS.APRROVE_MODAL.DESCRIPTION}
          onCloseText={PENDING_TRANSACTIONS.APRROVE_MODAL.CLOSE_TEXT}
          onConfirmText={PENDING_TRANSACTIONS.APRROVE_MODAL.CONFIRM_TEXT}
          modalInformation={hook.hookCheckedInformation}
          isLoading={hook.hookIsLoading}
          errorMessage={hook.hookModalErrorMessage}
        />

        <ApproveDeclineModal
          isOpen={hook.hookIsDeclineModalOpen}
          onClose={() => hook.hookHandleSetModalIsOpen('Decline')}
          onConfirm={() => hook.hookHandleUpdateTransactions('Declined')}
          title={PENDING_TRANSACTIONS.DECLINE_MODAL.TITLE}
          desc={PENDING_TRANSACTIONS.DECLINE_MODAL.DESCRIPTION_ONE.concat(hook.hookCheckedInformation.reduce((total, item) => (total + item.transactions), 0).toString(), PENDING_TRANSACTIONS.DECLINE_MODAL.DESCRIPTION_TWO)}
          onCloseText={PENDING_TRANSACTIONS.DECLINE_MODAL.CLOSE_TEXT}
          onConfirmText={PENDING_TRANSACTIONS.DECLINE_MODAL.CONFIRM_TEXT}
          value={hook.hookInputValue}
          handleChange={hook.hookHandleDeclineReasonInput}
          isLoading={hook.hookIsLoading}
          errorMessage={hook.hookModalErrorMessage}
        />

        {!hook.hookIsLoading && (
        <Styled.PaginationBoxStyled>
          <Pagination
            total={hook.hookTotalPages}
            currentPage={hook.hookCurrentPage}
            onPageChange={hook.hookOnPageChange}
          />
        </Styled.PaginationBoxStyled>
        )}

        <FixedFooter>
          <Styled.FooterButtonsAreaStyled>
            <Styled.FooterButtonsStyled
              theme={PENDING_TRANSACTIONS.FOOTER.THEME}
              onClick={() => hook.hookHandleSetModalIsOpen('Decline')}
              disabled={hook.hookAreButtonDisabled || isReadOnly}
            >
              Decline
            </Styled.FooterButtonsStyled>
            <Styled.FooterButtonsStyled
              onClick={() => hook.hookHandleSetModalIsOpen('Approve')}
              disabled={hook.hookAreButtonDisabled || isReadOnly}
            >
              Approve
            </Styled.FooterButtonsStyled>
          </Styled.FooterButtonsAreaStyled>
        </FixedFooter>
      </Styled.WrapperStyled>
    </AppWrapper>
  );
};

export default PendingTransactions;
