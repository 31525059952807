import styled from 'styled-components';
import { colors, fonts } from 'styles/theme';

export const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const WebsiteStyled = styled.a`
  ${fonts.lato.H14}
  text-decoration: none;
  color: ${colors.color2};
`;

export const CategoriesWrapper = styled.p`
  display: flex;
  flex-wrap: wrap;
  white-space: normal;
  line-height: 1.5;
`;

export const CellStyled = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.color1};
`;
