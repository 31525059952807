export enum RuleStatusFieldsRequired {
  ALL = 'All',
  ANY = 'Any',
}

export const RuleStatusFieldsRequiredOptions = [
  { label: RuleStatusFieldsRequired.ALL, value: RuleStatusFieldsRequired.ALL },
  { label: RuleStatusFieldsRequired.ANY, value: RuleStatusFieldsRequired.ANY },
];

export enum RuleStatusFieldState {
  FOUND = 'Found',
  NOT_FOUND = 'Not Found',
}

export const RuleStatusFieldStateOptions = [
  { label: RuleStatusFieldState.FOUND, value: RuleStatusFieldState.FOUND },
  { label: RuleStatusFieldState.NOT_FOUND, value: RuleStatusFieldState.NOT_FOUND },
];

export enum RuleStatusRuleState {
  PASS = 'Pass',
  FAIL = 'Fail',
}

export const RuleStatusRuleStateOptions = [
  { label: RuleStatusRuleState.PASS, value: RuleStatusRuleState.PASS },
  { label: RuleStatusRuleState.FAIL, value: RuleStatusRuleState.FAIL },
];

export type RuleStatusSettingsType = {
  fieldsRequired: RuleStatusFieldsRequired | null | string;
  fieldState: RuleStatusFieldState | null | string;
  ruleState: RuleStatusRuleState | null | string;
};

export enum RuleStatusSettingEnum {
  IF = 'If',
  TEXT_VALUES = 'text phrase(s) are',
  THIS_RULE_SHOULD = 'on this page, this rule should',
}

export enum AllOptions {
  ALL_PRODUCTS = 'All Products',
  ALL_CATEGORIES = 'All Categories',
  ALL_RULES = 'All Rules',
  ALL_STATUS = 'All Status',
  ALL_RULE_TYPES = 'All Rule Types',
  ALL_RULE_GROUPS = 'All Rule Groups',
}
