import React from 'react';
import {
  faArrowDown, faGrip, faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppWrapper,
  Button,
  Calendar,
  Checkbox,
  Modal,
  PageTitle,
  Pagination,
  RecordsDropdown,
  ReportPlaceholder,
  Table,
} from '../../../components';
import { path, RECORDS_PER_PAGE_OPTIONS_WITH_150, SUBSCRIPTIONS_OPTIONS } from '../../../utils';
import { reportColumns } from './contracts';
import { usePublisherSearch } from './hooks';
import CustomizeColumnsModal from './Modals/CustomizeColumns';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type PublisherManagementProps = DefaultPropsType;

const PublisherManagement = ({ permissionsCodeList = [] }: PublisherManagementProps) => {
  const hook = usePublisherSearch();

  return (
    <AppWrapper
      permissionsCodeList={permissionsCodeList}
      modalOpen={hook.hookModal || hook.hookOpenStartCalendar || hook.hookOpenEndCalendar}
    >
      <PageTitle
        loadingText={hook.hookLoadingMessage}
        isLoading={hook.hookLoading}
      >
        {path.publisherSearch.name}
        &nbsp;Publisher
      </PageTitle>

      <Styled.FiltersStyled>
        <Styled.InputTextStyled
          faIcon={faSearch}
          type="text"
          placeholder="Search for Name/ID/URL..."
          value={hook.hookSearch}
          onChange={hook.hookSetSearch}
          disabled={hook.hookOptionsLoading}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !hook.hookLoading) hook.hookSearchButtonHandler();
          }}
        />
      </Styled.FiltersStyled>

      <Styled.FiltersStyled>
        <Styled.SelectStyled
          name="accountStatus"
          placeholder="Account Status"
          onChange={hook.hookSetAccountStatus}
          selected={hook.hookAccountStatus}
          options={hook.hookNetworkStatusOptions}
          isDisabled={hook.hookOptionsLoading}
          isLoading={hook.hookOptionsLoading}
        />
        <Styled.SelectStyled
          name="taxStatus"
          placeholder="Tax Status"
          onChange={hook.hookSetTaxStatus}
          selected={hook.hookTaxStatus}
          options={hook.hookTaxStatusOptions}
          isDisabled={hook.hookOptionsLoading}
          isLoading={hook.hookOptionsLoading}
        />

        <Styled.LeftSideStyled>
          <Checkbox
            label="Show Advanced Search"
            onChange={hook.hookSetShowAdvancedSearch}
            checked={hook.hookShowAdvancedSearch}
            disabled={hook.hookLoading}
          />
        </Styled.LeftSideStyled>

        <Styled.RightSideStyled>
          <Button
            theme="text-only"
            onClick={hook.hookReset}
          >
            Clear Form
          </Button>
        </Styled.RightSideStyled>
      </Styled.FiltersStyled>

      <Styled.AdvancedSearchStyled isOpen={hook.hookShowAdvancedSearch}>
        <Styled.AdvancedSearchTitleStyled>Publisher Overview</Styled.AdvancedSearchTitleStyled>
        <Styled.AdvancedSelectStyled
          placeholder="Primary Audience Market"
          name="market"
          onChange={hook.hookSetPrimaryAudienceMarket}
          selected={hook.hookPrimaryAudienceMarket}
          options={hook.hookPrimaryAudienceMarketOptions}
          isDisabled={hook.hookOptionsLoading}
          isLoading={hook.hookOptionsLoading}
        />
        <Styled.AdvancedSelectStyled
          placeholder="Product Category"
          name="category"
          onChange={hook.hookSetProductCategory}
          selected={hook.hookProductCategory}
          options={hook.hookProductCategoryOptions}
          isDisabled={hook.hookOptionsLoading}
          isLoading={hook.hookOptionsLoading}
        />
        <Styled.AdvancedSelectStyled
          placeholder="Promotion Method"
          name="method"
          onChange={hook.hookSetPromotionMethod}
          selected={hook.hookPromotionMethod}
          options={hook.hookPromotionMethodOptions}
          isDisabled={hook.hookOptionsLoading}
          isLoading={hook.hookOptionsLoading}
        />
        <Styled.AdvancedSelectStyled
          placeholder="Average Monthly Audience Size"
          name="audience"
          onChange={hook.hookSetAverageMonthlyAudienceSize}
          selected={hook.hookAverageMonthlyAudienceSize}
          options={hook.hookAverageMonthlyAudienceSizeOptions}
          isDisabled={hook.hookOptionsLoading}
          isLoading={hook.hookOptionsLoading}
        />
        <Styled.AdvancedSelectStyled
          placeholder="Activity Status"
          name="activity"
          onChange={hook.hookSetActivityStatus}
          selected={hook.hookActivityStatus}
          options={hook.hookActivityStatusOptions}
          isDisabled={hook.hookOptionsLoading}
          isLoading={hook.hookOptionsLoading}
        />

        <Styled.AdvancedSearchTitleStyled>Audience Demographics</Styled.AdvancedSearchTitleStyled>
        <Styled.AdvancedSelectStyled
          placeholder="Age"
          name="age"
          onChange={hook.hookSetAge}
          selected={hook.hookAge}
          options={hook.hookAgeOptions}
          isDisabled={hook.hookOptionsLoading}
          isLoading={hook.hookOptionsLoading}
        />
        <Styled.AdvancedSelectStyled
          placeholder="Gender"
          name="gender"
          onChange={hook.hookSetGender}
          selected={hook.hookGender}
          options={hook.hookGenderOptions}
          isDisabled={hook.hookOptionsLoading}
          isLoading={hook.hookOptionsLoading}
        />
        <Styled.AdvancedSelectStyled
          placeholder="Credit Score Rating"
          name="score"
          onChange={hook.hookSetCreditScoreRating}
          selected={hook.hookCreditScoreRating}
          options={hook.hookCreditScoreRatingOptions}
          isDisabled={hook.hookOptionsLoading}
          isLoading={hook.hookOptionsLoading}
        />
        <Styled.AdvancedSelectStyled
          placeholder="Average Income"
          name="income"
          onChange={hook.hookSetAverageIncome}
          selected={hook.hookAverageIncome}
          options={hook.hookAverageIncomeOptions}
          isDisabled={hook.hookOptionsLoading}
          isLoading={hook.hookOptionsLoading}
        />

        <Styled.AdvancedSearchTitleStyled>Publisher Account information</Styled.AdvancedSearchTitleStyled>
        <Styled.AdvancedSelectStyled
          name="subscriptionStatus"
          placeholder="Subscription Status"
          selected={hook.hookSubscription}
          onChange={hook.hookSetSubscription}
          options={SUBSCRIPTIONS_OPTIONS}
          isDisabled={hook.hookOptionsLoading}
          isLoading={hook.hookOptionsLoading}
        />
        <Styled.AdvancedSelectStyled
          name="publisherCountry"
          placeholder="Publisher Country"
          onChange={hook.hookSetCountry}
          selected={hook.hookCountry}
          options={hook.hookCountryOptions}
          isDisabled={hook.hookOptionsLoading}
          isLoading={hook.hookOptionsLoading}
        />
        <Styled.AdvancedSelectStyled
          placeholder="Publisher Payment Method"
          onChange={hook.hookSetPaymentMethod}
          selected={hook.hookPaymentMethod}
          options={hook.hookPaymentMethodOptions}
          isDisabled={hook.hookOptionsLoading}
          isLoading={hook.hookOptionsLoading}
        />

        <Styled.AdvanceFilterSectionStyled>
          <Styled.RegistrationTitleStyled>Registration Date Range</Styled.RegistrationTitleStyled>
          <Styled.CalendarFilter
            placeholder="From Date"
            value={hook.hookStartDate}
            onClick={hook.hookStartOpenClose}
            isDisabled={hook.hookOptionsLoading}
          />

          <Styled.CalendarFilter
            placeholder="End Date"
            value={hook.hookEndDate}
            onClick={hook.hookEndOpenClose}
            isDisabled={hook.hookOptionsLoading}
          />
        </Styled.AdvanceFilterSectionStyled>
      </Styled.AdvancedSearchStyled>

      <Styled.FilterFooterStyled>
        <RecordsDropdown
          selected={hook.hookRecordsPerPage}
          options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
          currentPage={hook.hookCurrentPage}
          totalRecords={hook.hookTotalRecords}
          onChange={hook.hookSetRecordsPerPage}
          isDisabled={hook.hookLoading}
          isLoading={hook.hookLoading}
        />
        <Styled.FilterButtonsStyled>
          <Styled.SearchButtonStyled
            onClick={hook.hookCSV}
            theme="secondary"
            disabled={hook.hookLoading}
          >
            <FontAwesomeIcon icon={faArrowDown} />
            {' '}
            Download CSV
          </Styled.SearchButtonStyled>
          <Styled.SearchButtonStyled
            onClick={hook.hookSetModal}
            disabled={hook.hookLoading}
            theme="tertiary"
          >
            <FontAwesomeIcon icon={faGrip} />
            {' '}
            Customize Columns
          </Styled.SearchButtonStyled>
          <Styled.SearchButtonStyled
            onClick={hook.hookSearchButtonHandler}
            disabled={hook.hookOptionsLoading || hook.hookLoading}
            loading={hook.hookLoading}
            width="120px"
          >
            <FontAwesomeIcon
              icon={faSearch}
            />
            {' '}
            Search
          </Styled.SearchButtonStyled>
        </Styled.FilterButtonsStyled>
      </Styled.FilterFooterStyled>

      {((hook.hookPublisherList && hook?.hookPublisherList?.length > 0) || (hook.hookLoading && hook.hookSearchClicked)) && (
        <>
          <Table
            columns={reportColumns(hook.hookColumns)}
            data={hook.hookPublisherList}
            isLoading={hook.hookLoading}
            onSort={hook.hookHandleSort}
            sortColumn={hook.hookSortColumn}
            onRowClick={hook.hookHandleRowClickActAs}
            checkInactive={hook.hookCheckInactive}
          />
          <Pagination
            currentPage={hook.hookCurrentPage}
            total={hook.hookTotalPages}
            onPageChange={hook.hookChangePage}
          />
        </>
      )}

      {((!hook.hookPublisherList || hook.hookPublisherList.length < 1) && !(hook.hookLoading && hook.hookSearchClicked)) && (
      <ReportPlaceholder
        submitted={hook.hookSearchClicked}
        text={hook.hookSearchClicked ? 'Sorry! No results found' : 'Click search to get started'}
        subtext={hook.hookSearchClicked ? "We couldn't find what you're looking for. Please try it again." : ''}
      />
      )}

      <Calendar
        onCancel={hook.hookStartOpenClose}
        isOpen={hook.hookOpenStartCalendar}
        onApply={hook.hookOnApplyStart}
        isSingle
      />

      <Calendar
        onCancel={hook.hookEndOpenClose}
        isOpen={hook.hookOpenEndCalendar}
        onApply={hook.hookOnApplyEnd}
        isSingle
      />

      <Modal isOpen={hook.hookModal}>
        <CustomizeColumnsModal
          submitFn={hook.hookSetColumns}
          closeFn={hook.hookSetModal}
          columns={hook.hookColumns}
        />
      </Modal>
    </AppWrapper>
  );
};

export default PublisherManagement;
