import React, { ReactElement } from 'react';

import { useFintelCheckSettings } from '../../../../../hooks/useFintelCheckSettings';
import ToolSettingsTabItem from '../../../../ToolSettingsTabItem/ToolSettingsTabItem.component';
import { CheckFrequencySettingEnum, ToolSettings } from '../../../../../types';
import en from '../../../../../locales/en.json';

import { PeriodBtn, ControlsContainer } from './FrequencySetting.styles';

interface FrequencyOption {
  key: CheckFrequencySettingEnum;
  label: string;
  onSelect: () => void;
}

const FrequencySetting = (): ReactElement => {
  const { isReadOnlyPermissions, toolSettings, updateToolSetting } = useFintelCheckSettings();

  const frequencyOptions: FrequencyOption[] = [
    {
      key: CheckFrequencySettingEnum.Weekly,
      label: en.settings.tabs.check.items.frequency.buttons.weekly,
      onSelect: () => updateToolSetting(ToolSettings.FrequencySettings, CheckFrequencySettingEnum.Weekly),
    },
    {
      key: CheckFrequencySettingEnum.Biweekly,
      label: en.settings.tabs.check.items.frequency.buttons.biweekly,
      onSelect: () => updateToolSetting(ToolSettings.FrequencySettings, CheckFrequencySettingEnum.Biweekly),
    },
    {
      key: CheckFrequencySettingEnum.Monthly,
      label: en.settings.tabs.check.items.frequency.buttons.monthly,
      onSelect: () => updateToolSetting(ToolSettings.FrequencySettings, CheckFrequencySettingEnum.Monthly),
    },
  ];

  const isOptionSelected = (frequency: CheckFrequencySettingEnum): boolean => {
    return toolSettings?.frequencySettings === frequency;
  };

  return (
    <ToolSettingsTabItem
      title={en.settings.tabs.check.items.frequency.title}
      description={en.settings.tabs.check.items.frequency.description}
    >
      <ControlsContainer>
        {frequencyOptions.map(({ key, label, onSelect }) => (
          <PeriodBtn
            key={key}
            theme={isOptionSelected(key) ? 'tertiary' : 'secondary'}
            onClick={onSelect}
            disabled={isReadOnlyPermissions}
          >
            {label}
          </PeriodBtn>
        ))}
      </ControlsContainer>
    </ToolSettingsTabItem>
  );
};

export default FrequencySetting;
