import styled from 'styled-components';
import { colors } from 'styles/theme';
import { Button } from 'components';

export const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const CellCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CellStyled = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.color1};
`;

export const IconButtonStyled = styled(Button)`
  color: ${colors.color1};

  &:hover {
    color: ${colors.color2};
  }
`;
