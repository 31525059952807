import React, { ReactElement, ReactNode, FC } from 'react';

import {
  SectionContainer,
  TitleContainer,
  SubTitleContainer,
  DescriptionContainer,
  ContentContainer,
} from './ToolSettingsTabItem.styles';

interface IToolSettingsTabItemProps {
  title: string;
  description: string;
  children: ReactNode;
}

const ToolSettingsTabItem: FC<IToolSettingsTabItemProps> = ({ title, description, children }): ReactElement => {
  return (
    <SectionContainer>
      <DescriptionContainer>
        <TitleContainer>{title}</TitleContainer>
        <SubTitleContainer>{description}</SubTitleContainer>
      </DescriptionContainer>
      <ContentContainer>{children}</ContentContainer>
    </SectionContainer>
  );
};

export default ToolSettingsTabItem;
