import * as ICONS from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, ReactElement } from 'react';

import { ActAsPublisherType, ActAsMerchantType } from '../../../../../../context';
import { useSideMenu } from '../../../../hooks/useSideMenu';
import { ISideMenuItem } from '../../../../types';
import { SIDE_MENU_LEVELS } from '../../SideMenuItem.constants';

import { MANAGEMENT_MENU_ITEMS } from './SideMenuCompanySection.constants';
import { MenuItemCompanyMenu, MenuItemCompanySelection, MenuItemSeparator } from './SideMenuCompanySection.styles';

export enum CompanyManagementTypes {
  MERCHANT = 'MERCHANT',
  PUBLISHER = 'PUBLISHER',
}

interface ISideMenuCompanySectionProps {
  type: CompanyManagementTypes;
  menuItem: ISideMenuItem;
  actingAs: ActAsPublisherType | ActAsMerchantType;
  pathName: string;
  renderNestedMenuItem: (nestedMenuItem: ISideMenuItem, level: number) => ReactElement | null;
}

const SideMenuCompanySection: FC<ISideMenuCompanySectionProps> = ({
  type,
  menuItem,
  actingAs,
  pathName,
  renderNestedMenuItem,
}): ReactElement | null => {
  const { toggleSideMenuItem, isSideMenuItemOpened } = useSideMenu();

  if (!actingAs?.id || !menuItem.management || menuItem.management.label !== pathName) return null;

  const isOpen = isSideMenuItemOpened(SIDE_MENU_LEVELS.SUB_LEVEL, MANAGEMENT_MENU_ITEMS[type]);

  return (
    <>
      <MenuItemSeparator />
      <MenuItemCompanySelection
        onClick={() =>
          toggleSideMenuItem(MANAGEMENT_MENU_ITEMS[type], SIDE_MENU_LEVELS.SUB_LEVEL, !!menuItem.subSections)
        }
        isOpen={isOpen}
      >
        <p>{actingAs.name}</p>
        <FontAwesomeIcon icon={ICONS.faChevronRight} />
      </MenuItemCompanySelection>
      {menuItem.subSections && (
        <MenuItemCompanyMenu show={isOpen}>
          {isOpen &&
            menuItem.subSections.map((nestedMenuItem: ISideMenuItem) =>
              renderNestedMenuItem(nestedMenuItem, SIDE_MENU_LEVELS.SUB_SUB_LEVEL)
            )}
        </MenuItemCompanyMenu>
      )}
    </>
  );
};

export default SideMenuCompanySection;
