import React, { ReactElement } from 'react';

import ToolSettingsPageTitle from '../ToolSettingsPageTitle/ToolSettingsPageTitle.component';
import ToolSettingsToggle from '../ToolSettingsToggle/ToolSettingsToggle.component';
import { useFintelCheckSettings } from '../../hooks/useFintelCheckSettings';

import { HeaderContainer } from './ToolSettingsHeader.styles';

const ToolSettingsHeader = (): ReactElement | null => {
  const { isPageFullyLoaded } = useFintelCheckSettings();

  if (!isPageFullyLoaded) {
    return null;
  }

  return (
    <HeaderContainer>
      <ToolSettingsPageTitle />
      <ToolSettingsToggle />
    </HeaderContainer>
  );
};

export default ToolSettingsHeader;
