import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

export const GROUP_BY_OPTIONS: SelectOption[] = [
  {
    label: 'Merchant',
    value: 'MERCHANT',
  },
  {
    label: 'Publisher (ID)',
    value: 'PUBLISHER',
  },
  {
    label: 'Publisher (ID, Name, URL)',
    value: 'PUBLISHER_NAME',
  },
  {
    label: 'Date',
    value: 'DATE',
  },
  {
    label: 'Month',
    value: 'MONTH',
  },
  {
    label: 'Ad (ID)',
    value: 'AD_ID',
  },
  {
    label: 'Tracking Profile (ID)',
    value: 'TRACKING_PROFILE',
  },
  {
    label: 'Product',
    value: 'PRODUCT',
  },
  {
    label: 'Product Category',
    value: 'PRODUCT_CATEGORY',
  },
  {
    label: 'Ad Campaign',
    value: 'AD_CAMPAIGN',
  },
];

export const monthNames: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const RECORDS_PER_PAGE_OPTIONS = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '100',
    value: '100',
  },
  {
    label: '200',
    value: '200',
  },
];

export const DEACTIVATION_FORM_FIELDS = [
  {
    label: 'Full Name',
    value: 'full name',
  },
  {
    label: 'Email Address',
    value: 'email address',
  },
];

export const PREFERED_LANGAUGE_OPTIONS = [
  {
    label: 'English',
    value: 'English',
  },
  {
    label: 'French',
    value: 'French',
  },
];

export const PUBLISHER_SUBTYPE_OPTIONS = [
  {
    label: 'Publisher Superuser',
    value: '5',
  },
  {
    label: 'Publisher view',
    value: '6',
  },
];

export const SUPERUSER_SUBTYPE_IDS = ['5', '3'];

export const MERCHANT_SUBTYPE_OPTIONS = [
  {
    label: 'Merchant Superuser',
    value: '3',
  },
  {
    label: 'Merchant view',
    value: '4',
  },
];

export const COLUMNS_FIELDS = [
  {
    label: 'Inactive',
    value: 'Inactive',
  },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Fist Name Data Field',
    value: 'firstName',
  },
  {
    label: 'Full Name Text Field',
    value: 'Full Name',
  },
  {
    label: 'Security Role Datafield',
    value: 'securtiyRole',
  },
  {
    label: 'Security Role Text',
    value: 'Securtiy Role',
  },
  {
    label: 'Last Login Data Field',
    value: 'lastLogin',
  },
  {
    label: 'Last Login text',
    value: 'Last Login',
  },
  {
    label: 'Null Value',
    value: 'Never logged',
  },
  {
    label: 'Is Active Data Field',
    value: 'isActive',
  },
  {
    label: 'Status Text',
    value: 'Status',
  },
  {
    label: 'Toggle Column Theme',
    value: 'ToggleColumn',
  },
  {
    label: 'OnClick Temporary Alert',
    value: 'The right arrow button has been clicked!!',
  },
];

export const RECORDS_PER_PAGE_OPTIONS_WITH_150 = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '100',
    value: '100',
  },
  {
    label: '150',
    value: '150',
  },
  {
    label: '200',
    value: '200',
  },
];

export const REDEEMABLE_OPTIONS = [
  {
    label: 'Redeemable',
    value: 'Redeembale',
  },
  {
    label: 'Non-redeemable',
    value: 'Non-redeembale',
  },
];

export const TERMS = [
  {
    label: '30 days',
    value: '30 days',
  },
  {
    label: '60 days',
    value: '60 days',
  },
  {
    label: '90 days',
    value: '90 days',
  },
  {
    label: '120 days',
    value: '120 days',
  },
  {
    label: '180 days',
    value: '180 days',
  },
  {
    label: '270 days',
    value: '270 days',
  },
  {
    label: '1 years',
    value: '1 years',
  },
  {
    label: '2 years',
    value: '2 years',
  },
  {
    label: '3 years',
    value: '3 years',
  },
  {
    label: '4 years',
    value: '4 years',
  },
  {
    label: '5 years',
    value: '5 years',
  },
  {
    label: '6 years',
    value: '6 years',
  },
  {
    label: '7 years',
    value: '7 years',
  },
  {
    label: '8 years',
    value: '8 years',
  },
  {
    label: '9 years',
    value: '9 years',
  },
  {
    label: '10 years',
    value: '10 years',
  },
];

export const ADS_SORT_BY_OPTIONS = [
  {
    label: 'Ad Performance',
    value: 'campaignId', // TODO: what is this
  },
  {
    label: 'Ad ID',
    value: 'id',
  },
  {
    label: 'Ad Group Campaign',
    value: 'campaignName',
  },
  {
    label: 'Ad Height',
    value: 'height',
  },
  {
    label: 'Ad Width',
    value: 'width',
  },
  {
    label: 'Ad Name',
    value: 'name',
  },
  {
    label: 'Date Created',
    value: 'createdAt',
  },
  {
    label: 'Merchant',
    value: 'merchantName',
  },
  {
    label: 'Product',
    value: 'productName',
  },
  {
    label: 'Product Category',
    value: 'productCategoryName',
  },
  {
    label: 'Ad Type',
    value: 'typeName',
  },
];

export const CAMPAIGN_SORT_BY_OPTIONS = [
  {
    label: 'Campaign ID',
    value: 'id',
  },
  {
    label: 'Campaign Name',
    value: 'name',
  },
];

export const VISIBILITY_OPTIONS = [
  {
    label: 'Show Members only',
    value: true,
  },
  {
    label: 'Hide',
    value: false,
  },
];

export const STATS_UPDATE_FREQUENCY = [
  {
    label: 'Every 24 hours',
    value: 'Every 24 hours',
  },
];
export const TABLE_FOOTER = 'Total';

export const TABLE_FOOTER_CAD = 'Total CAD';

export const TABLE_FOOTER_USD = 'Total USD';

export const MERCHANT_PREFIX = '/merchant';

export const PUBLISHER_PREFIX = '/publisher';

export const defaultOption = {
  merchant: {
    label: 'All Merchants',
    value: '',
  },

  dateType: {
    label: 'Transaction Date',
    value: '',
  },

  transactionDate: {
    label: 'Transaction Date',
    value: 'Transaction Date',
  },

  processedDate: {
    label: 'Processed Date',
    value: 'Processed Date',
  },

  publisher: {
    label: 'All Publishers',
    value: '',
  },

  product: {
    label: 'All Products',
    value: '',
  },

  transactionStatus: {
    label: 'All Status',
    value: '',
  },

  trackingProfile: {
    label: 'All Tracking Profiles',
    value: '',
  },

  publisherGroup: {
    label: 'All Publisher Groups',
    value: '',
  },

  ad: {
    label: 'All Ad IDs',
    value: '',
  },

  adCampaign: {
    label: 'All Ad Campaigns',
    value: '',
  },

  productCategory: {
    label: 'All Categories',
    value: '',
  },

  commissionType: {
    label: 'All Commission Types',
    value: '',
  },
};

export const TRACKING_PROFILE_TYPE = [
  {
    label: 'Website',
    value: 'website',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const TRACKING_PROFILE = {
  WEBSITE: 'website',
  OTHER: 'other',
};

export const MERCHANT_INVITATION = [
  {
    label: 'Open to Invites',
    value: false,
  },
  {
    label: 'Invites on Hold',
    value: true,
  },
];

export const TRANSACTION_CURRENCY = [
  {
    label: 'Canadian Dollars (CAD $)',
    value: 'CAD',
  },
  {
    label: 'US Dollars (USD $)',
    value: 'USD',
  },
];

export const GLOBAL_CURRENCIES = [
  {
    label: 'Canadian Dollars',
  },
];

export const TRANSACTION_FILTER = [
  {
    label: 'Transaction ID',
    value: 'id',
  },
  {
    label: 'Transaction Date',
    value: 'createdAt',
  },
  {
    label: 'Order ID',
    value: 'orderId',
  },
  {
    label: 'Customer ID',
    value: 'customerId',
  },
  {
    label: 'Ad ID',
    value: 'adId',
  },
  {
    label: 'Tracking Profile ID',
    value: 'trackingProfileId',
  },
  {
    label: 'Product Name',
    value: 'productName',
  },
  {
    label: 'Publisher ID',
    value: 'publisherId',
  },
  {
    label: 'Net Income',
    value: 'netSale',
  },
  {
    label: 'Gross Sales',
    value: 'grossSale',
  },
];

export const PAGE_TYPE = [
  {
    label: 'All types',
    value: '',
  },
  {
    label: 'Blog',
    value: 'Blog',
  },
  {
    label: 'Not Blog',
    value: 'Not Blog',
  },
];

export const QUEUE_STATUS = [
  {
    label: 'All Status',
    value: '',
  },
  {
    label: 'Completed',
    value: 'Completed',
  },
  {
    label: 'Failed',
    value: 'Failed',
  },
  {
    label: 'Queued',
    value: 'Queued',
  },
  {
    label: 'Uploaded',
    value: 'Uploaded',
  },
];

export const PAYMENT_STATUS = [
  {
    label: 'All Statuses',
    value: 'any',
  },
  {
    label: 'Pending Approval',
    value: 'Pending Approval',
  },
  {
    label: 'Pending Payment',
    value: 'Pending Payment',
  },
  {
    label: 'Processing Payment',
    value: 'Processing Payment',
  },
  {
    label: 'Paid Payment',
    value: 'Paid Payment',
  },
  {
    label: 'Failed Payment',
    value: 'Failed Payment',
  },
];

export const PAYMENT_CURRENCY = [
  {
    label: 'All Currencies',
    value: 'any',
  },
  {
    label: 'CAD',
    value: 'CAD',
  },
  {
    label: 'USD',
    value: 'USD',
  },
];

export const PAYMENT_METHOD = [
  {
    label: 'All Methods',
    value: 'any',
  },
  {
    label: 'Paypal',
    value: 'paypal',
  },
  {
    label: 'Cheque',
    value: 'cheque',
  },
  { label: 'Cheque Payment for $1000', value: 'CHeque Payment for $1000' },
  {
    label: 'Wire Transfer',
    value: 'wireTransfer',
  },
];

export const PAYMENT_OPTIONS_ACCOUNT_DETAILS = [
  {
    label: 'Paypal',
    value: 'Paypal',
  },
  {
    label: 'Cheque',
    value: 'Cheque',
  },

  {
    label: 'Cheque Payment for $1000',
    value: 'Cheque Payment for $1000',
  },
  {
    label: 'Wire Transfer',
    value: 'Wire Transfer',
  },
];

export const dateOptionsThisAndLastMonth = (hasNoEstimate: boolean) => {
  const today = new Date();
  const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastMonth = moment(thisMonth).subtract(1, 'months').toDate();
  const options = [];
  const endM = thisMonth.getMonth();
  const endY = thisMonth.getFullYear();
  const lastM = lastMonth.getMonth();
  const lastY = lastMonth.getFullYear();
  options.push({
    label: `${monthNames[endM]} ${endY}`,
    value: `${(thisMonth.getMonth() + 1).toString()}/${thisMonth.getFullYear().toString()}`,
  });
  if (!hasNoEstimate) {
    options.push({
      label: `${monthNames[lastM]} ${lastY}`,
      value: `${(lastMonth.getMonth() + 1).toString()}/${lastMonth.getFullYear().toString()}`,
    });
  }
  return options;
};

export const BONUS_TYPE = [
  { label: 'Manual Bonus', value: 'ManualBonus' },
  { label: 'Manual Commission', value: 'ManualCommission' },
  { label: 'Gift Card', value: 'GiftCard' },
  { label: 'Other', value: 'Other' },
];

export const PAYMENT_METHOD_DEPOSIT = [
  {
    label: 'Paypal',
    value: 'paypal',
  },
  {
    label: 'Cheque',
    value: 'cheque',
  },
  {
    label: 'Wire Transfer',
    value: 'wireTransfer',
  },
];

export const USER_STATUS = [
  {
    label: 'All Statuses',
    value: 'any',
  },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
];

export const ORDER_STATUS = [
  {
    label: 'All Statuses',
    value: 'any',
  },
  {
    label: 'Denied',
    value: 'Denied',
  },
  {
    label: 'Completed',
    value: 'Completed',
  },
  {
    label: 'Pending',
    value: 'Pending',
  },
  {
    label: 'Appproved',
    value: 'Approved',
  },
];

export const NUMBERS_OPTIONS = {
  DAYS: {
    WEEKLY: [
      {
        label: '1',
        value: '1',
      },
      {
        label: '2',
        value: '2',
      },
      {
        label: '3',
        value: '3',
      },
      {
        label: '4',
        value: '4',
      },
      {
        label: '5',
        value: '5',
      },
      {
        label: '6',
        value: '6',
      },
      {
        label: '7',
        value: '7',
      },
    ],
    BIWEEKLY: [
      {
        label: '1',
        value: '1',
      },
      {
        label: '2',
        value: '2',
      },
      {
        label: '3',
        value: '3',
      },
      {
        label: '4',
        value: '4',
      },
      {
        label: '5',
        value: '5',
      },
      {
        label: '6',
        value: '6',
      },
      {
        label: '7',
        value: '7',
      },
      {
        label: '8',
        value: '8',
      },
      {
        label: '9',
        value: '9',
      },
      {
        label: '10',
        value: '10',
      },
      {
        label: '11',
        value: '11',
      },
      {
        label: '12',
        value: '12',
      },
      {
        label: '13',
        value: '13',
      },
      {
        label: '14',
        value: '14',
      },
    ],
    MONTHLY: [
      {
        label: '1',
        value: '1',
      },
      {
        label: '2',
        value: '2',
      },
      {
        label: '3',
        value: '3',
      },
      {
        label: '4',
        value: '4',
      },
      {
        label: '5',
        value: '5',
      },
      {
        label: '6',
        value: '6',
      },
      {
        label: '7',
        value: '7',
      },
      {
        label: '8',
        value: '8',
      },
      {
        label: '9',
        value: '9',
      },
      {
        label: '10',
        value: '10',
      },
      {
        label: '11',
        value: '11',
      },
      {
        label: '12',
        value: '12',
      },
      {
        label: '13',
        value: '13',
      },
      {
        label: '14',
        value: '14',
      },
      {
        label: '15',
        value: '15',
      },
      {
        label: '16',
        value: '16',
      },
      {
        label: '17',
        value: '17',
      },
      {
        label: '18',
        value: '18',
      },
      {
        label: '19',
        value: '19',
      },
      {
        label: '20',
        value: '20',
      },
      {
        label: '21',
        value: '21',
      },
      {
        label: '22',
        value: '22',
      },
      {
        label: '23',
        value: '23',
      },
      {
        label: '24',
        value: '24',
      },
      {
        label: '25',
        value: '25',
      },
      {
        label: '26',
        value: '26',
      },
      {
        label: '27',
        value: '27',
      },
      {
        label: '28',
        value: '28',
      },
      {
        label: '29',
        value: '29',
      },
      {
        label: '30',
        value: '30',
      },
      {
        label: '31',
        value: '31',
      },
    ],
  },
  WEEKS: {
    BIWEEKLY: [
      {
        label: '1',
        value: '1',
      },
      {
        label: '2',
        value: '2',
      },
    ],
    MONTHLY: [
      {
        label: '1',
        value: '1',
      },
      {
        label: '2',
        value: '2',
      },
      {
        label: '3',
        value: '3',
      },
      {
        label: '4',
        value: '4',
      },
    ],
  },
};

export const DAYS_LABEL = [
  {
    label: 'Days',
    value: 'days',
  },
];

export const DUO_OPTIONS = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

export const USER_SORT_BY_OPTIONS = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Role',
    value: 'role',
  },
  {
    label: 'Status',
    value: 'status',
  },
  {
    label: 'Last Login',
    value: 'lastLogin',
  },
];

export const SUBSCRIPTIONS_OPTIONS = [
  {
    label: 'Any Subscription Status',
    value: 'any',
  },
  {
    label: 'Subscribed to Marketing Emails List',
    value: 'subscribed',
  },
  {
    label: 'Not on Marketing Emails List',
    value: 'not subscribed',
  },
];

export const SUBSCRIBED = {
  ANY: 'any',
  YES: 'subscribed',
  NO: 'not subscribed',
};

export const PERIOD_OPTIONS = [
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Daily', value: 'Daily' },
  { label: 'Other', value: 'Other' },
];

export const SEARCH_PUBLISHER_COLUMNS = [
  'Member Since',
  'Audience Markets',
  'Product Categories',
  'Promotion Method',
  'Monthly Audience Size',
  'Last Login',
  'Status',
  'AUDIENCE DEMOGRAPHICS',
  'Age',
  'Gender',
  'Average Income',
  'Credit Score Rating',
  'ACCOUNT INFORMATION',
  'Address Info',
  'Country',
  'Tax Info',
  'Payment Method',
  'Payment Currency',
];

export const FILE_EXTENSIONS = {
  CSV: '.csv',
  GIF: '.gif',
  JPEG: '.jpeg',
  JPG: '.jpg',
  MS_EXCEL: 'application/vnd.ms-excel',
  PNG: '.png',
  SPREADSHEET: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  TXT: '.txt',
  ZIP: '.zip',
};

export const NO_RESULTS = {
  SORRY: 'Sorry! No results found',
  LOOKING: "We couldn't find what you're looking for. Please try it again.",
};

export const REGEX_VALIDATORS = {
  TAXATION_STRING: {
    REGEX: /^\d{9}$/,
    MESSAGE: 'Please enter a valid tax number',
  },
  NAME: {
    REGEX: /^[a-z]+([a-z- ',.-]?)+[a-z.]+$/i,
    MESSAGE: 'Please enter a valid name.',
  },
  ADDRESS: {
    REGEX: /.*[a-zA-Z]+.*/,
    MESSAGE: 'Please enter a valid address.',
  },
  EMAIL: {
    REGEX:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    MESSAGE: 'Please enter a valid email address.',
  },
  EMAIL_OPTIONAL: {
    REGEX:
      /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    MESSAGE: 'Please enter a valid email address.',
  },
  LETTERS_NUMBERS_ONLY: {
    REGEX: /^[A-Za-z0-9\s]+$/,
    MESSAGE: 'Please avoid using special characters: !@#$%^&*.,<>/\'";:?',
  },
  LETTERS_ONLY: {
    REGEX: /^[a-zA-Z\s]+$/,
    MESSAGE: 'Please enter a valid input',
  },
  NUMBERS_ONLY: {
    REGEX: /^[0-9]+$/,
    MESSAGE: 'Please enter a valid number',
  },
  NUMBERS_DECIMALS: {
    REGEX: /^\d*\.?\d{0,4}$/,
    MESSAGE: 'Please enter a valid number',
  },
  PERCENTAGE: {
    REGEX: /^(?:100(\.0+)?|[1-9][0-9]?(\.\d+)?|0(\.\d+)?)$/,
    MESSAGE: 'Please enter a number between 0 to 100',
  },
  INTEGERS_ONLY: {
    REGEX: /^[1-9]\d*(\.\d+)?$/,
    MESSAGE: 'Please enter a number greater than or equal to 0',
  },
  URL: {
    REGEX: /^(http|https):\/\/[a-zA-Z0-9-_.]+\.[a-zA-Z]{2,61}(:[0-9]{1,5})?(\/.*)?$/i,
    MESSAGE: 'Please enter complete URL including https://',
  },
  URL_WITH_PARAMS: {
    REGEX: /^(http|https):\/\/[a-zA-Z0-9-_.]+\.[a-zA-Z]{2,61}(:[0-9]{1,5})?(\/[^?#]*)?(\\?[^#]*)?(#.*)?$/i,
    MESSAGE: 'Please enter complete URL including https://',
  },
  URL_NO_HTTPS: {
    REGEX: /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,61}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)$/i,
    MESSAGE: 'Please enter complete URL including https://',
  },
  URL_HTTP_AGNOSTIC: {
    REGEX:
      /^(http|https):\/\/[a-zA-Z0-9-_.]+\.[a-zA-Z]{2,61}(:[0-9]{1,5})?(\/.*)?$|^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)$/i,
    MESSAGE: 'Please enter a valid URL',
  },
  IS_DOLLAR_AMOUNT: {
    REGEX: /^\d+(\.\d{1,2})?$/,
    MESSAGE: 'Please enter a valid dollar value',
  },
  BANK_ACCOUNT_NUMBER: {
    REGEX: /^[A-Z,a-z,0-9]{7,30}$/,
    MESSAGE: 'Please enter a valid bank account number.',
  },
  CUSTOM_TEXT: {
    REGEX: /^[a-z]+$/,
    MESSAGE: 'Invalid Character',
  },
};

export const URL_STATUSES = {
  EMPTY_WEBSITE: {
    STATUS: '',
    ERROR: 'Please enter a complete URL including http:// or https://',
  },
  INVALID_WEBSITE: {
    STATUS: 'INVALID',
    ERROR: 'Please enter a valid URL',
  },
  ACTIVE_WEBSITE: {
    STATUS: 'ACTIVE',
    ERROR: 'Site is live and secure!',
  },
  INACTIVE_WEBSITE: {
    STATUS: 'INACTIVE',
    ERROR: 'This website is not active, or is inaccessible due to a CORS issue',
  },
  PENDING_WEBSITE: {
    STATUS: 'PENDING',
    ERROR: 'Checking...',
  },
  UNSAFE_WEBSITE: {
    STATUS: 'UNSAFE',
    ERROR: 'Without https:// cannot verify active status.',
  },
};

export const PUBLISHER_API_ACCESS_STATUS = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];

export const TAX_STATUS = [
  {
    label: 'Verified',
    value: 'Verified',
  },
  {
    label: 'Invalid',
    value: 'Invalid',
  },
];

export const ERROR_TYPES: { [key: string]: string } = {
  EMAIL: 'email',
  EMAIL_OPTIONAL: 'emailOptional',
  NOT_EMPTY: 'notEmpty',
  PHONE: 'phone',
  PHONE_OPTIONAL: 'phoneOptional',
  POSTAL_CODE: 'postalCode',
  REGION: 'region',
  SELECTION_REQUIRED: 'selectionRequired',
  END_DATE: 'endDate',
  AT_LEAST_ONE: 'atLeastOne',
  NUMBERS_ONLY: 'numberOnly',
  IS_NUMBERS: 'IsNumber',
  NUMBER_NOT_EMPTY: 'numberNotEmpty',
  IS_DOLLAR_AMOUNT: 'isDollarAmount',
  IS_DOLLAR_AMOUNT_REQUIRE: 'isDollarAmountRequire',
  SELECT_ROLE: 'selectRole',
  TAXATION_STRING: 'taxationString',
  ADDRESS: 'address',
  BANK_ACCOUNT_NUMBER: 'bankAccountNumber',
  NAME: 'name',
  REAL_NUMBER_FEE: 'realNumberFee',
  PRODUCT_EMPTY: 'productEmpty',
  COMMISSION_PRODUCT_EMPTY: 'commissionProductEmpty',
  COMMISSION_NAME: 'commissionName',
};

export const MONETARY_TOOLTIP = 'This is a Monetary field - Enter a number with up to 2 decimal places';

export const RATE_TYPE_OPTIONS = [
  {
    label: 'Posted',
    value: 'Posted',
  },
  {
    label: 'Discounted',
    value: 'Discounted',
  },
];

export const ERROR_MESSAGES: { [key: string]: string } = {
  NUMBERS_ONLY: REGEX_VALIDATORS.NUMBERS_ONLY.MESSAGE,
  FIRST_NAME: 'Please enter your first name.',
  LAST_NAME: 'Please enter your last name.',
  EMAIL: 'Please enter a valid email address.',
  COMPANY_NAME: 'Please enter a valid company name.',
  WEBSITE: 'Please enter a complete URL including http:// or https://',
  ADDRESS: 'Please enter a valid address.',
  CITY: 'Please enter a valid city.',
  REGION: 'Please enter a valid state or province.',
  COUNTRY: 'Please select a country.',
  POSTAL_CODE: 'Please enter a valid zip/postal code.',
  PHONE: 'Please enter a valid international number; must include country code.',
  TAXATION: 'Please enter a valid tax number',
  TAXATION_US_AU: 'Tax number must be 9 digits',
  GST_HST: 'Must be 15 characters including the letters RT with no spaces',
  PST: 'Must be 11 characters including the letters PST with no spaces',
  QST: 'Must be 16 characters including the letters TQ with no spaces',
  SECURITY_ROLE: 'Please select a security role',
  MANDATORY: 'This field is mandatory',
  DOLLAR_AMOUNT: 'Please enter a number or decimal',

  AD_NAME: 'Please enter an ad name',
  CAMPAIGN_NAME: 'Please enter a valid campaign name',
  GROUP_NAME: 'Please enter a valid group name',
  STATUS: 'Please select a status',
  DESCRIPTION: 'Please enter a description',
  LANGUAGE: 'Please select a language',
  START_DATE: 'Please select a valid start date',
  END_DATE: 'Please select a valid end date',
  IMAGE: 'Please choose an image',
  ALT_TEXT: 'Please input an alt name',
  SPECIAL_CHARACTERS: REGEX_VALIDATORS.LETTERS_NUMBERS_ONLY.MESSAGE,
  HTML_CODE: 'Please enter html code',
  HTML_WIDTH: 'Please enter a valid number',
  HTML_HEIGHT: 'Please enter a valid number',

  PRODUCT_NAME: 'Please enter a product name',
  PRODUCT_ID: 'Please enter a product id',
  PRODUCT_CATEGORY: 'Please select a product category',
  TARGET_GENDER: 'Please select the target gender(s)',
  TARGET_COUNTRY: 'Please select a target country',

  GENERATE_CODE_PARAMS: 'Mandatory fields are missing',
  FAIL_TO_SAVE: 'Failed to save changes. Please try again.',

  INVALID_DATE: 'Invalid Date. Date must be in YYYY-MM-DD format.',

  AT_LEAST_ONE: 'Please select at least one option',
  PUBLISHER_DESCRIPTION: 'Business Description is required',

  FEE_AMOUNT: 'Please enter a valid fee.',
  REAL_NUMBER_FEE: 'Please enter a valid fee.',
  FEE_DATE: 'Please select fee date.',
  FEE_TYPEOTHER: 'Please specify type.',
  REFERENCE_NUMBER: 'Please enter a valid reference number.',

  PRODUCT_MATRIX_URL: 'Please enter your product matrix url.',
  PRIMARY_CONTACT: 'Please enter your contact.',
  DESCRIPTION_DETAILED: 'Please enter a description',
  DESCRIPTION_HIGH_LEVEL: 'Please enter a description',

  COMMISSION_BASE: 'Please choose a base',
  FLAT_COMMISSION: 'Please enter a number between 0 to 100',
  COMMISSION_AMOUNT: 'Please enter an amount',
  FLAT_BONUS: 'Please enter an amount',
  COMMISSION_NAME: 'Please enter a name',

  MIN_USAGE_FEE: 'Please enter a valid rate.',
  MINIMUM_BALANCE: 'Please enter a valid balance.',
  MANAGEMENT_FEE: 'Please enter a valid rate.',

  BANKING: 'Please enter a valid rate.',
  CREDIT_CARDS: 'Please enter a valid rate.',
  INVESTMENTS: 'Please enter a valid rate.',
  MORTGAGE: 'Please enter a valid rate.',
  BUSINESSES: 'Please enter a valid rate.',
  CREDIT_REPORTING: 'Please enter a valid rate.',
  CRYPTO: 'Please enter a valid rate.',
  INSURANCE: 'Please enter a valid rate.',
  LOANS: 'Please enter a valid rate.',
  REAL_ESTATE: 'Please enter a valid rate.',
  TAX_SERVICES: 'Please enter a valid rate.',
  PLANNING: 'Please enter a valid rate.',

  ADMIN_TIER_COMMISSION: 'Please enter a valid value.',
  ADMIN_TIER_RATE: 'Please enter a valid rate.',
  AFTER_TIER_RATE: 'Please enter a valid rate.',
  ADMIN_RATE: 'Please enter a valid rate.',

  NOTIFICATION_PRODUCT_CATEGORIES: 'Please select a category.',
  PAYMENT_PAYABLE: 'Please enter personal/company name.',
  PAYMENT_AMOUNT: 'Please enter a valid amount.',
  PAYMENT_EMAIL: 'Please enter a valid email address.',
  PAYMENT_NAME: "Please enter a payer's name.",
  PAYMENT_DATE: 'Please select payment date.',
  PAYMENT_ADDRESS: 'Please enter a valid address.',
  AMOUNT: 'Please enter a valid amount.',
  DEPOSIT_PRODUCT_CATEGORIES: 'Please select a category.',
  DEPOSIT_CURRENCY: 'Please select a curreny.',

  MERCHANT_NAME: 'Please select merchant',
  COMMISSION_PRODUCT: 'Please select a product option',

  BANK_NAME: 'Please enter a valid bank name.',
  BANK_ACCOUNT_NUMBER: 'Please enter a valid bank account number.',
  SWIFT_CODE: 'Please enter a valid Swift code or IBAN.',
  WIRE_TRANSFER_NAME: 'Please enter a valid wire transfer name.',

  ACCOUNT_HOLDER: 'Please enter a valid account holder name.',
};

export const COUNTRIES = {
  CA: 'Canada',
  US: 'United States',
  AU: 'Australia',
  OTHER: 'Other',
};

export const HST_STATES = [
  'New Brunswick',
  'Newfoundland and Labrador',
  'Nova Scotia',
  'Ontario',
  'Prince Edward Island',
];

export const GST_STATES = ['Alberta', 'Northwest Territories', 'Nunavut', 'Yukon Territory'];

export const GST_PST_STATES = ['British Columbia', 'Manitoba', 'Saskatchewan'];

export const QST_STATES = ['Quebec'];

export const NOT_AVAILABLE = 'N/A';

export const USER_TYPES_ID: { [key: string]: number } = {
  ADMIN: 1,
  MERCHANT: 2,
  PUBLISHER: 3,
};

export const USER_TYPES_NAMES: { [key: string]: string } = {
  ADMIN: 'Admin',
  MERCHANT: 'Merchant',
  PUBLISHER: 'Publisher',
};

export const AD_TYPES: { [key: string]: string } = {
  LINK: 'TextLink',
  BANNER: 'Banner',
  HTML: 'Html5',
};

export const COMMISSION_TYPES: { [key: string]: string } = {
  CPA: 'CPA',
  REFERRAL: 'Referral',
  REV_SHARE: 'Revenue Share', // RevShare in DB
  BONUS: 'Bonus',
  REVSHARE: 'RevShare',
};

export const SUCCESS_MESSAGES = {
  CAMPAIGN_CREATE: (name?: string) => `Campaign ${name} created successfully`,
  CAMPAIGN_EDIT: (name?: string) => `Campaign ${name} edited successfully`,
  MERCHANT_EDIT: (name: string) => `Merchant ${name} updated successfully`,
  PUBLISHER_EDIT: (name: string) => `Publisher ${name} updated successfully`,
  PUBLISHER_OVERVIEW_EDIT: () => 'Publisher Overview was successfully saved',
};

export const TOAST_ERR_MESSAGES = (page: string, errorMessage: string) =>
  `Oops! Something went wrong for ${page}. ${errorMessage}`;
export const TOAST_ERR_MESSAGES_NO_PAGE = (errorMessage: string) => `Oops! Something went wrong: ${errorMessage}`;

export const CLEAR_FORM = 'Clear Form';

export const PRODUCT_URL_ERROR = 'Oops! Something went wrong for update program, Please enter the product URL.';

export const FLOAT_ERROR = 'Oops! Something went wrong for update program, Please enter a valid float number.';

export const PRODUCT_CATEGORY = [
  { label: 'All Categories', value: '' },
  { label: 'Banking', value: 'BANKING' },
  { label: 'Business Products', value: 'BUSINESSES' },
  { label: 'Credit Cards', value: 'CREDIT_CARDS' },
  { label: 'Credit Reporting', value: 'CREDIT_REPORTING' },
  { label: 'Cryptocurrency', value: 'CRYPTOCURRENCY' },
  { label: 'Insurance', value: 'INSURANCE' },
  { label: 'Investments', value: 'INVESTMENTS' },
  { label: 'Loans', value: 'LOANS' },
  { label: 'Mortgage', value: 'MORTGAGE' },
  { label: 'Real Estate', value: 'REAL ESTATE' },
  { label: 'Tax Services', value: 'TAX SERVICES' },
  { label: 'Wills & Estate Planning', value: 'WILLS_&_ESTATE_PLANNING' },
];

export const MERCHANT_TYPES = {
  SELF_SERVE_MERCHANT: 'Self',
  RESULTS_MERCHANT: 'Results',
  MANAGED_MERCHANT: 'Managed',
};

export const USER_LANGUAGE_OPTIONS: SelectOption[] = [
  { value: 'English', label: 'English' },
  { value: 'French', label: 'French' },
];

export const STATS_TIER_RATE_KEY = {
  ADMIN_TIER_COMMISSION: 'newAdminTierCommission',
  ADMIN_TIER_RATE: 'newAdminTierRate',
  AFTER_TIER_RATE: 'newAdminAfterTierRate',
  ADMIN_RATE: 'adminRate',
};

export const STATS_TIER_RATE_ERROR = {
  ADMIN_TIER_COMMISSION: 'ADMIN_TIER_COMMISSION',
  ADMIN_TIER_RATE: 'ADMIN_TIER_RATE',
  AFTER_TIER_RATE: 'AFTER_TIER_RATE',
  ADMIN_RATE: 'ADMIN_RATE',
};

export const IMAGE_FILE_TYPES = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];

export const PROFILE_IMAGE_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];

export const ZIP_FILE_TYPES = ['application/zip', 'application/x-zip-compressed'];

export const CSV_FILE_TYPES = [
  'text/plain',
  'text/x-csv',
  'application/vnd.ms-excel',
  'application/csv',
  'application/x-csv',
  'text/csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
  'text/tab-separated-values',
];

export const FILE_UPLOAD_ERR_MESSAGES = (type?: string, size?: number, unit = 'KB') => {
  switch (type) {
    case 'image':
      return 'Please select a .png/.jpg/.jpeg/.gif file';
    case 'zip':
      return 'Please select a .zip file';
    case 'csv':
      return 'Please select a .csv/.txt file';
    case 'maxFileSize':
      return `The maximum file size is ${size}${unit}.`;
    default:
      return 'Please select a valid file';
  }
};

export const PDF_TYPES = {
  PUBLISHER: 'publisher',
  MERCHANT: 'merchant',
};

export const PRODUCT_CATEGORIES = {
  BANKING: 'Banking',
  CRPTO: 'Cryptocurrency',
  INVESTMENTS: 'Investments',
  BUISNESS: 'Business Products',
  LOANS: 'Loans',
  REAL_ESTATE: 'Real Estate',
  CREDIT_CARDS: 'Credit Cards',
  MORTGAGE: 'Mortgage',
  TAX: 'Tax Services',
  CREDIT_REPORTING: 'Credit Reporting',
  INSURANCE: 'Insurance',
  WILLS_PLANING: 'Wills & Estate Planning',
};

export const PRODUCT_SUBCATEGORIES = {
  TRAVEL: 'Travel',
  CASH_BACK: 'Cashback',
  REWARDS: 'Rewards',
  LOW_RATE: 'Low rate',
  NO_FEE: 'No annual fee',
  STUDENT: 'Student',
  SECURE: 'Secure',
  TERM: 'Term',
  FIXED: 'Fixed or Variable',
  HELOC: 'Heloc',
  REFINANCING: 'Refinancing',
  SAVINGS: 'Savings',
  CHEQUING: 'Chequing',
  REGISTERED: 'Registered',
  NON_REGISTERED: 'Non-Registered',
  TFSA: 'TFSA',
  ROBO_ADVISOR: 'Robo-Advisor',
  SELF_DIRECTED: 'Self-Directed',
  GICS: 'GICs',
  HEALTH: 'Health',
  LIFE: 'Life',
  MORTGAGE_PROTECTION: 'Mortgage Protection',
  AUTO: 'Auto',
  RETNAL: 'Rental',
  HOME: 'Home',
  DISABILITY: 'Disability',
  PET: 'Pet',
};

export const CREDIT_CARD_OPTIONS = [
  PRODUCT_SUBCATEGORIES.TRAVEL,
  PRODUCT_SUBCATEGORIES.CASH_BACK,
  PRODUCT_SUBCATEGORIES.REWARDS,
  PRODUCT_SUBCATEGORIES.LOW_RATE,
  PRODUCT_SUBCATEGORIES.NO_FEE,
  PRODUCT_SUBCATEGORIES.STUDENT,
  PRODUCT_SUBCATEGORIES.SECURE,
];

export const MORTGAGE_OPTIONS = [
  PRODUCT_SUBCATEGORIES.TERM,
  PRODUCT_SUBCATEGORIES.FIXED,
  PRODUCT_SUBCATEGORIES.HELOC,
  PRODUCT_SUBCATEGORIES.REFINANCING,
];

export const BANKING_OPTIONS = [PRODUCT_SUBCATEGORIES.SAVINGS, PRODUCT_SUBCATEGORIES.CHEQUING];
export const INVESTMENTS_OPTIONS = [
  PRODUCT_SUBCATEGORIES.REGISTERED,
  PRODUCT_SUBCATEGORIES.NON_REGISTERED,
  PRODUCT_SUBCATEGORIES.TFSA,
  PRODUCT_SUBCATEGORIES.ROBO_ADVISOR,
  PRODUCT_SUBCATEGORIES.SELF_DIRECTED,
  PRODUCT_SUBCATEGORIES.GICS,
];

export const INSURANCE_OPTIONS = [
  PRODUCT_SUBCATEGORIES.HEALTH,
  PRODUCT_SUBCATEGORIES.LIFE,
  PRODUCT_SUBCATEGORIES.MORTGAGE_PROTECTION,
  PRODUCT_SUBCATEGORIES.AUTO,
  PRODUCT_SUBCATEGORIES.TRAVEL,
  PRODUCT_SUBCATEGORIES.RETNAL,
  PRODUCT_SUBCATEGORIES.HOME,
  PRODUCT_SUBCATEGORIES.DISABILITY,
  PRODUCT_SUBCATEGORIES.PET,
];

export const PRODUCT_OPTIONS = [
  PRODUCT_CATEGORIES.BANKING,
  PRODUCT_CATEGORIES.CRPTO,
  PRODUCT_CATEGORIES.INVESTMENTS,
  PRODUCT_CATEGORIES.BUISNESS,
  PRODUCT_CATEGORIES.LOANS,
  PRODUCT_CATEGORIES.REAL_ESTATE,
  PRODUCT_CATEGORIES.CREDIT_CARDS,
  PRODUCT_CATEGORIES.MORTGAGE,
  PRODUCT_CATEGORIES.TAX,
  PRODUCT_CATEGORIES.CREDIT_REPORTING,
  PRODUCT_CATEGORIES.INSURANCE,
  PRODUCT_CATEGORIES.WILLS_PLANING,
];

export const USER_TYPES = ['Personal', 'Business'];

export const CARD_NETWORK = ['Visa', 'Mastercard', 'Amex'];

export const optionsMaker = (options: any) => {
  if (options) {
    return options.map((opt: any) => ({
      label: opt,
      value: opt,
    }));
  }
  return [];
};

export const checksMaker = (values: any, checked: any) =>
  values.map((opt: any) => ({
    label: opt,
    checked: checked === null || checked.length === 0 ? false : checked.includes(opt),
  }));

export const optionMaker = (option: any) => {
  if (option) return { label: option, value: option };
  return { label: '', value: '' };
};

export const subCategories = (productCategory: any) => {
  switch (productCategory) {
    case PRODUCT_CATEGORIES.CREDIT_CARDS:
      return CREDIT_CARD_OPTIONS;
    case PRODUCT_CATEGORIES.BANKING:
      return BANKING_OPTIONS;
    case PRODUCT_CATEGORIES.MORTGAGE:
      return MORTGAGE_OPTIONS;
    case PRODUCT_CATEGORIES.INVESTMENTS:
      return INVESTMENTS_OPTIONS;
    case PRODUCT_CATEGORIES.INSURANCE:
      return INSURANCE_OPTIONS;
    default:
      return [];
  }
};

export const MEMBERSHIPS_STATUS = {
  APPROVED: 'Approved',
  PENDING: 'Pending',
  INVITED: 'Invited',
  DECLINED: 'Declined',
  CLOSED: 'Closed',
};

const lastYear = (new Date().getFullYear() - 1).toString();

export const FintelCheckHistoryOptions = [
  { label: 'Last 3 months', value: 3 },
  { label: 'Last 6 months', value: 6 },
  { label: lastYear, value: null },
];

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MEMBERSHIP_STATUS_OPTIONS = [
  { label: 'Any Membership Status', value: '' },
  { label: 'Invited', value: 'Invited' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Approved', value: 'Approved' },
  { label: 'Declined', value: 'Declined' },
  { label: 'Invitation Declined', value: 'Invitation Declined' },
  { label: 'Closed By Publisher', value: 'Closed By Publisher' },
  { label: 'Closed By Merchant', value: 'Closed By Merchant' },
];

export const ACTIVITY_STATUS_OPTIONS = [
  { label: 'Any Activity Status', value: '' },
  { label: 'Click active', value: 'Click active' },
  { label: 'Approval active', value: 'Approval active' },
  { label: 'Not live', value: 'Not live' },
  { label: 'Not live (new to network)', value: 'New to network' },
];

export const MERCHANT_STATUS_OPTIONS = [
  {
    label: 'Live',
    value: 'Approved',
  },
  {
    label: 'Paused',
    value: 'Paused',
  },
  {
    label: 'Closed',
    value: 'Closed',
  },
  {
    label: 'Pending Application',
    value: 'Pending',
  },
  {
    label: 'Integrating',
    value: 'Integrating',
  },
  {
    label: 'Go Live Pending',
    value: 'LivePending',
  },
];

export const AUDIENCE_SIZE = [
  {
    label: 'Any Audience Size',
    value: '',
  },
  {
    label: 'Under 5,000',
    value: 'Under 5,000',
  },
  {
    label: '5,000 - 25,000',
    value: '5,000 - 25,000',
  },
  {
    label: '25,000 - 100,000',
    value: '25,000 - 100,000',
  },
  {
    label: '100,000 - 250,000',
    value: '100,000 - 250,000',
  },
  {
    label: '250,000 - 500,000',
    value: '250,000 - 500,000',
  },
  {
    label: '500,000 - 1,000,000',
    value: '500,000 - 1,000,000',
  },
  {
    label: '1,000,000 +',
    value: '1,000,000 +',
  },
];

export const AVERAGE_INCOME = [
  {
    label: 'Less than $20,000',
    value: 'Less than $20,000',
  },
  {
    label: '$20,000 - $34,999',
    value: '$20,000 - $34,999',
  },
  {
    label: '$35,000 - $49,999',
    value: '$35,000 - $49,999',
  },
  {
    label: '$50,000 - $74,999',
    value: '$50,000 - $74,999',
  },
  {
    label: '$75,000 - $99,999',
    value: '$75,000 - $99,999',
  },
  {
    label: '$100,000 - $149,999',
    value: '$100,000 - $149,999',
  },
  {
    label: '$150,000 - $199,999',
    value: '$150,000 - $199,999',
  },
  {
    label: '$200,000 or more',
    value: '$200,000 or more',
  },
];

export const PROMOTION_METHOD = [
  {
    label: 'Any Promotion Method',
    value: '',
  },
  {
    label: 'Long Form Content',
    value: 'Long Form Content',
  },
  {
    label: 'Deals/Coupons',
    value: 'Deals/Coupons',
  },
  {
    label: 'Tiktok',
    value: 'Tiktok',
  },
  {
    label: 'Forum',
    value: 'Forum',
  },
  {
    label: 'Cashback/Rewards',
    value: 'Cashback/Rewards',
  },
  {
    label: 'Facebook',
    value: 'Facebook',
  },
  {
    label: 'Product Reviews',
    value: 'Product Reviews',
  },
  {
    label: 'Newsletter',
    value: 'Newsletter',
  },
  {
    label: 'YouTube',
    value: 'YouTube',
  },
  {
    label: 'Comparison',
    value: 'Comparison',
  },
  {
    label: 'Instagram',
    value: 'Instagram',
  },
  {
    label: 'Social Media - Other',
    value: 'Social Media - Other',
  },
];

export const PRODUCT_CATEGORY_SELECT_OPTIONS = [
  {
    label: 'All Categories',
    value: 'All Cateogries',
  },
  {
    label: 'Banking',
    value: 'Banking',
  },
  {
    label: 'Business Products',
    value: 'Businesses',
  },
  {
    label: 'Credit Cards',
    value: 'Credit Cards',
  },
  {
    label: 'Credit Reporting',
    value: 'Credit Reporting',
  },
  {
    label: 'Cryptocurrency',
    value: 'Cryptocurrency',
  },
  {
    label: 'Insurance',
    value: 'Insurance',
  },
  {
    label: 'Investments',
    value: 'Investments',
  },
  {
    label: 'Loans',
    value: 'Loans',
  },
  {
    label: 'Mortgage',
    value: 'Mortgage',
  },
  {
    label: 'Real Estate',
    value: 'Real Estate',
  },
  {
    label: 'Tax Services',
    value: 'Tax Services',
  },
  {
    label: 'Wills & Estate Planning',
    value: 'Wills & Estate Planning',
  },
];

export const PUBLISHER_GROUP_DEFAULT = { label: 'Any Publisher Groups', value: 'all' };

export const PRODUCT_CATEGORY_DEFAULT = { label: 'Any Product Categories', value: '' };

export const PROMOTION_METHOD_DEFAULT = { label: 'Any Promotion Method', value: '' };

export const AVERAGE_INCOME_DEFAULT = { label: 'Any Average Income', value: '' };

export const GENDER_DEFAULT = { label: 'Any Gender', value: '' };

export const AGE_DEFAULT = { label: 'Any Age', value: '' };

export const MARKET_DEFAULT = { label: 'Any Audience Markets', value: '' };

export const RATING_DEFAULT = { label: 'Any Credit Score Rating', value: '' };

export const SEARCH_PUBLISHER_CUSTOMIZE_COLUMNS = [
  {
    label: '',
    columns: [
      'Member Since',
      'Audience Markets',
      'Product Categories',
      'Promotion Method',
      'Monthly Web Visits',
      'Last Login',
      'Publisher Status',
    ],
  },
  {
    label: 'audience demographics',
    columns: ['Age', 'Gender', 'Average Income', 'Credit Score Rating'],
  },
];

export const DEFAULT_TRANSACTION_STATUSES: SelectOption[] = [
  {
    value: '',
    label: 'All Status',
  },
  {
    value: 'Approved',
    label: 'Approved',
  },
  {
    value: 'Pending',
    label: 'Pending',
  },
  {
    value: 'Declined',
    label: 'Declined',
  },
  {
    value: 'Verify',
    label: 'Verify',
  },
  {
    value: 'Soft Declined',
    label: 'Soft Declined',
  },
];

export const ERROR_ICON = faTriangleExclamation;

export const MANAGEMENT_FEES_CATEGORIES: { [key: string]: string } = {
  BANKING: 'banking',
  BUSINESSES: 'businesses',
  'CREDIT CARDS': 'creditCards',
  'CREDIT REPORTING': 'creditReporting',
  CRYPTO: 'crypto',
  INSURANCE: 'insurance',
  INVESTMENTS: 'investments',
  LOANS: 'loans',
  MORTGAGE: 'mortgage',
  'REAL ESTATE': 'realEstate',
  'TAX SERVICES': 'taxServices',
  PLANNING: 'planning',
};
