import { PUBLISHER_PREFIX, path } from '../../../utils';

export const STATISTIC_CARD = {
  COMMISSIONS: {
    TITLE: 'Commissions',
    TOOLTIP: 'This is your total commissions earned for the period selected.',
  },
  APPROVED_TRANSACTIONS: {
    TITLE: 'Approved Transactions',
    TOOLTIP: 'This the total number of approved transactions for the period selected.',
  },
  EPC: {
    TITLE: 'EPC',
    TOOLTIP: 'Earnings per click is the average commission earned per click generated for the period selected. It is calculated by dividing total commissions by total clicks.',
  },
  CONVERSION_RATE: {
    TITLE: 'Conversion Rate',
    TOOLTIP: 'This is the percentage of traffic that completed a transaction (not necessarily an approval) within the period selected. It is calculated as the number of total transactions divided by the total number of unique clicks.',
  },
};

export const SNAPSHOT = {
  TITLE: 'Transactions & Commissions Snapshot',
  TOOLTIP: 'This graph provides a visual overview of total commissions, transactions and approved transactions by month over a 12-month period.',
};

export const UPDATE_PROFILE = {
  TITLE: 'Update Your Profile',
  TOOLTIP: 'Your account overview is the main tool for positioning your brand to potential merchants and gives you the best chance for getting approved to merchant programs.',
  DESCRIPTION: 'To get started promoting, complete overview information on your account.',
  BUTTON_TEXT: 'Complete Overview',
  LINK: `${PUBLISHER_PREFIX}${path.overview.href}`,
};

export const NEW_MERCHANTS = {
  TITLE: 'New Merchants',
  TOOLTIP: 'See the latest brands to launch their partner programs on the Fintel Connect Network. Click on their logos to learn more, or apply directly from the list. To see all brands, click Search Programs below.',
  SEARCH_PROGRAMS: 'Search programs',
};

export const TOP_PRODUCTS = {
  TITLE: 'Top Products',
  TOOLTIP: 'This table displays the top five products that have generated the most commissions in the period selected. Easily see where they have increased or decreased from the previous period.',
  NO_DATA: 'No Data Available',
  NO_DATA_DESCRIPTION: 'Your top products data will be displayed here once you apply to merchant programs.',
  SEARCH_PROGRAMS: 'Search programs',
};

export const PRODUCT_BY_APPROVALS = {
  TITLE: 'Products by Approvals',
  TOOLTIP: 'This graph reports the volume of approved transactions per product category during the period selected. Click on a pie slice to drill down into specific product performance.',
};
