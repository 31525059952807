import { InputText, Spacer, TooltipRadio } from 'components';
import { BrandNameEnum } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/enums';
import { useAddEvaluationRule } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/hooks/useAddEvaluationRule';
import * as Styled from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/styles';
import React from 'react';

export const BrandName = ({ hook }: { hook: ReturnType<typeof useAddEvaluationRule> }): JSX.Element => {
  return (
    <>
      <Spacer size="medium" direction="vertical" />

      <Styled.HeaderInfoText>
        The rule will only be applied to pages that contain the reference name indicated below.
      </Styled.HeaderInfoText>

      <Spacer size="medium" direction="vertical" />

      <Styled.FlexColumn>
        <Styled.FlexRow>
          <Styled.FlexColumn style={{ justifyContent: 'center' }}>
            <TooltipRadio
              label="Use Company Name indicated in Account Settings"
              name="brandNameType"
              value={BrandNameEnum.existing}
              onChange={hook.changeBrandNameType}
              checked={hook.stateMachine.context.brandNameType === BrandNameEnum.existing}
            />
          </Styled.FlexColumn>
          <Styled.FlexColumn>
            <Styled.HeaderInfoText>{hook.existingBrandName}</Styled.HeaderInfoText>
          </Styled.FlexColumn>
        </Styled.FlexRow>
        <Styled.FlexRow>
          <Styled.FlexColumn style={{ justifyContent: 'center' }}>
            <TooltipRadio
              label="Enter a Custom Brand Name"
              name="brandNameType"
              value={BrandNameEnum.customText}
              checked={hook.stateMachine.context.brandNameType === BrandNameEnum.customText}
              onChange={hook.changeBrandNameType}
            />
          </Styled.FlexColumn>

          <Styled.FlexColumn>
            <InputText
              placeholder="Enter Custom Merchant Name Here"
              value={hook.brandNameValue}
              onChange={hook.changeBrandName}
              error={hook.hookRuleNameError}
              type="text"
              maxLength={100}
              required
              disabled={hook.stateMachine.context.brandNameType !== BrandNameEnum.customText}
            />
          </Styled.FlexColumn>
        </Styled.FlexRow>
      </Styled.FlexColumn>
    </>
  );
};
