import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { useUserInfo, useToast } from 'hooks';
import { URL_STATUSES, useValidation } from 'utils';
import { findQueryParamsDuplicates, interpolate } from 'helpers';

import { UPDATE_TRACKING_SETTING } from '../graphql/mutations';
import { GET_COMPANY } from '../graphql/queries';
import en from '../locales/en.json';

interface IUseTrackingSettingsHook {
  hookLandingPageURL: string;
  hookSetLandingpageURL: (e: React.ChangeEvent<HTMLInputElement>) => void;

  hookQueryURL: string;
  hookSetQueryURL: (e: React.ChangeEvent<HTMLInputElement>) => void;

  hookThirdPartyURLFormat: string;
  hookSetThirdPartyURLFormat: (e: React.ChangeEvent<HTMLInputElement>) => void;

  hookThirdPartyStatus: boolean;
  hookCheckThirdPartyStatus: (checked: boolean) => void;

  hookThirdPartyTrackingURL: string;
  hookSetThirdPartyTrackingURL: (e: React.ChangeEvent<HTMLInputElement>) => void;

  hookCookieDuration: string;
  hookNoFollow: null;
  hookStatsRelay: null;

  validateLandingPage: () => void;
  validateAllURLs: () => void;
  isParamsDuplicatesLandingPage: () => boolean;
  hookLandingUrlError: string;
  hookThirdPartyUrlError: string;

  hookTrackingSettingsId: string;

  hookEditTrackingSetting: () => Promise<void>;

  hookLandingPageValidation: string;
  hookTrackingUrl: string;
}

export const useTrackingSettings = (): IUseTrackingSettingsHook => {
  const { hookShowToast } = useToast();
  const userHook = useUserInfo();
  const vali = useValidation();

  const [trackingSettingId, setTrackingSettingId] = useState('');
  const [landingPageURL, setLandingPageURL] = useState('');
  const [queryURL, setQueryURL] = useState('');
  const [thirdPartyURLFormat, setThirdPartyURLFormat] = useState('');
  const [thirdPartyStatusTracker, setThirdPartyStatusTracker] = useState(false); // Checkbox value for third party tracking
  const [thirdPartyTrackingURL, setThirdPartyTrackingURL] = useState(''); // Third party tracking URL text
  const [landingUrlError, setLandingUrlError] = useState('');
  const [thirdPartyUrlError, setThirdPartyUrlError] = useState('');
  const [landingUrlStatus, setLandingUrlStatus] = useState('');
  const [thirdPartyUrlStatus, setThirdPartyUrlStatus] = useState('');
  const [cookieDuration, setCookieDuration] = useState('');
  const [noFollow, setNoFollow] = useState(null);
  const [statsRelay, setStatsRelay] = useState(null);

  const [editTrackingSetting] = useMutation(UPDATE_TRACKING_SETTING);

  const { data } = useQuery(GET_COMPANY, {
    variables: {
      id: userHook.hookWhoAmI.companyId,
    },
    fetchPolicy: 'no-cache',
  });
  const getCompanyHandler = (): void => {
    if (data?.company.program.trackingSettings) {
      setLandingPageURL(data.company.program.trackingSettings.landingPage);
      setQueryURL(data.company.program.trackingSettings.urlQueryString);
      setThirdPartyTrackingURL(data.company.program.trackingSettings.thirdPartyUrl);
      setThirdPartyStatusTracker(data.company.program.trackingSettings.thirdPartyStatus);
      setThirdPartyURLFormat(data.company.program.trackingSettings.thirdPartyUrlFormat);
      setCookieDuration(data.company.program.trackingSettings.cookieDuration);
      setNoFollow(data.company.program.trackingSettings.nofollow);
      setStatsRelay(data.company.program.trackingSettings.statsRelay);
      if (data.company.program.trackingSettings.id) setTrackingSettingId(data.company.program.trackingSettings.id);
    }
  };

  /**
   * Validate landing page URL
   * If the validation fails, set the error message
   */
  const validateLandingPage = (): void => {
    /* Validation for the Landing Page */
    vali.validateUrlStatus(landingPageURL, setLandingUrlStatus);
    vali.renderUrlCheck(landingUrlStatus, setLandingUrlError);
  };

  /**
   * Validate landing AND third party page URL
   * If the validation fails, set the error message
   */
  const validateAllURLs = (): void => {
    validateLandingPage();
    /* Validation for Third Party URL Tracker */
    vali.validateUrlStatus(thirdPartyTrackingURL, setThirdPartyUrlStatus);
    vali.renderUrlCheck(thirdPartyUrlStatus, setThirdPartyUrlError);
  };

  /**
   * Check if landing page with merged query params is had duplicates
   */
  const isParamsDuplicatesLandingPage = (): boolean => {
    try {
      const queryParamDuplicates = findQueryParamsDuplicates(landingPageURL, queryURL);

      if (queryParamDuplicates.length > 0) {
        setLandingUrlError(
          interpolate(en.errors.validation.landingPageUrl.queryParamDuplicates, {
            duplicates: queryParamDuplicates.join(', '),
          })
        );
        return true;
      }

      return false;
    } catch (err) {
      console.error(`Error in parsing the URL: ${landingPageURL}`);
      return false;
    }
  };

  const editTrackingSettingHandler = async (): Promise<void> => {
    if (
      landingPageURL === '' ||
      landingUrlStatus === URL_STATUSES.EMPTY_WEBSITE.STATUS ||
      landingUrlStatus === URL_STATUSES.INVALID_WEBSITE.STATUS ||
      landingUrlStatus === URL_STATUSES.INACTIVE_WEBSITE.STATUS ||
      thirdPartyTrackingURL === '' ||
      thirdPartyUrlStatus === URL_STATUSES.EMPTY_WEBSITE.STATUS ||
      thirdPartyUrlStatus === URL_STATUSES.INVALID_WEBSITE.STATUS ||
      thirdPartyUrlStatus === URL_STATUSES.INACTIVE_WEBSITE.STATUS
    )
      return;

    const { errors } = await editTrackingSetting({
      variables: {
        input: {
          programsId: userHook.hookWhoAmI.programId,
          id: trackingSettingId,
          landingPage: landingPageURL,
          urlQueryString: queryURL,
          thirdPartyUrl: thirdPartyTrackingURL,
          thirdPartyUrlFormat: thirdPartyURLFormat,
          thirdPartyStatus: thirdPartyStatusTracker,
        },
      },
    });

    if (errors) {
      hookShowToast(errors[0].message);
    } else {
      hookShowToast('Tracking settings updated successfully');
    }
  };

  /**
   * Set the third party tracking status and validate the website URL
   */
  const setCheckThirdPartyStatusHandler = (checked: boolean): void => {
    setThirdPartyStatusTracker(checked);
    if (checked) validateAllURLs();
  };

  /**
   * Reset the third party tracking URL error message when checkbox is unchecked and the URL is empty
   */
  useEffect(() => {
    if (!thirdPartyStatusTracker && thirdPartyTrackingURL.length <= 0) {
      setThirdPartyUrlError('');
    }
  }, [thirdPartyStatusTracker]);

  const setQueryURLHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setQueryURL(e?.target.value);

    // Reset the error message if the query params are changed
    if (landingUrlError.includes(en.errors.validation.landingPageUrl.queryParamDuplicates.split('{{')[0].trim())) {
      setLandingUrlError('');
    }
  };

  const setLandingPageURLHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setLandingPageURL(e?.target.value);
  };

  const setThirdPartyURLHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setThirdPartyURLFormat(e?.target.value);
  };

  const setThirdPartyTrackingURLHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setThirdPartyTrackingURL(e?.target.value);
  };

  useEffect(() => {
    getCompanyHandler();
  }, [data]);

  useEffect(() => {
    if (landingPageURL === '') {
      setLandingUrlError('');
    }
    vali.validateUrlStatus(landingPageURL, setLandingUrlStatus); // TODO should we use like this? It checks the status of the url every time the url changes
  }, [landingPageURL]);

  useEffect(() => {
    if (thirdPartyTrackingURL === '') {
      setThirdPartyUrlError('');
    }
    vali.validateUrlStatus(thirdPartyTrackingURL, setThirdPartyUrlStatus); // TODO should we use like this? It checks the status of the url every time the url changes
  }, [thirdPartyTrackingURL]);

  return {
    hookLandingPageURL: landingPageURL,
    hookSetLandingpageURL: setLandingPageURLHandler,

    hookQueryURL: queryURL,
    hookSetQueryURL: setQueryURLHandler,

    hookThirdPartyURLFormat: thirdPartyURLFormat,
    hookSetThirdPartyURLFormat: setThirdPartyURLHandler,

    hookThirdPartyStatus: thirdPartyStatusTracker,
    hookCheckThirdPartyStatus: setCheckThirdPartyStatusHandler,

    hookThirdPartyTrackingURL: thirdPartyTrackingURL,
    hookSetThirdPartyTrackingURL: setThirdPartyTrackingURLHandler,

    hookCookieDuration: cookieDuration,
    hookNoFollow: noFollow,
    hookStatsRelay: statsRelay,

    validateLandingPage,
    validateAllURLs,
    isParamsDuplicatesLandingPage,
    hookLandingUrlError: landingUrlError,
    hookThirdPartyUrlError: thirdPartyUrlError,

    hookTrackingSettingsId: trackingSettingId,

    hookEditTrackingSetting: editTrackingSettingHandler,

    hookLandingPageValidation: landingUrlStatus,
    hookTrackingUrl: thirdPartyUrlStatus,
  };
};
