import * as ICONS from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';

import { VisibilityHidden } from '../../../VisibilityHidden';
import { useSideMenu } from '../../hooks/useSideMenu';

import { COLLAPSE_BUTTON } from './SideMenuCollapseButton.constants';
import { CollapseButton } from './SideMenuCollapseButton.styles';

const SideMenuCollapseButton = (): ReactElement => {
  const { isCollapsed, toggleCollapse } = useSideMenu();

  const icon = isCollapsed ? ICONS.faAngleRight : ICONS.faAngleLeft;

  return (
    <CollapseButton onClick={() => toggleCollapse()}>
      <VisibilityHidden>{COLLAPSE_BUTTON.LABEL}</VisibilityHidden>
      <FontAwesomeIcon icon={icon} />
    </CollapseButton>
  );
};

export default SideMenuCollapseButton;
