import React, { ReactElement } from 'react';

import { AppWrapper } from 'components';
import { DefaultPropsType } from 'types';

import ToolSettingsHeader from './components/ToolSettingsHeader/ToolSettingsHeader.component';
import ToolSettingsContent from './components/ToolSettingsContent/ToolSettingsContent.component';
import { FintelCheckSettingsProvider } from './FintelCheckSettings.context';

type FintelCheckSettingsProps = DefaultPropsType;

const FintelCheckSettings = ({ permissionsCodeList = [] }: FintelCheckSettingsProps): ReactElement => {
  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <FintelCheckSettingsProvider permissionsCodeList={permissionsCodeList}>
        <ToolSettingsHeader />
        <ToolSettingsContent />
      </FintelCheckSettingsProvider>
    </AppWrapper>
  );
};

export default FintelCheckSettings;
