import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, InputText, Select, Checkbox, Spacer } from 'components';
import _ from 'lodash';
import { useAddEvaluationRule } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/hooks/useAddEvaluationRule';
import * as Styled from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/styles';
import { getFeedValue } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/utils';
import React from 'react';

export const ProductCriteria = ({ hook }: { hook: ReturnType<typeof useAddEvaluationRule> }): JSX.Element => (
  <>
    <Spacer size="medium" direction="vertical" />

    <Styled.HeaderInfoText>Value required for evaluation.</Styled.HeaderInfoText>
    <Spacer size="medium" direction="vertical" />

    <Styled.HeaderInfoText>Select one or multiple Product Field(s) to be evaluated by the rule.</Styled.HeaderInfoText>
    <Spacer size="medium" direction="vertical" />
    <Styled.HeaderInfoText>
      If the Field(s) are set to “Required” and the Rule does not find the value on a page or it does not match, the
      Rule Status = Fail.
    </Styled.HeaderInfoText>

    <Spacer size="medium" direction="vertical" />

    <Styled.FlexColumn>
      {hook.stateMachine.context.productCriteria.map((criteria, index) => (
        <Styled.FlexColumn key={`${criteria.label}-${criteria.value}`}>
          {index !== 0 && (
            <>
              <Spacer size="medium" direction="vertical" />
              <Styled.FlexRow>
                <Styled.AndLine />
                <Styled.AndText>And</Styled.AndText>
                <Styled.AndLine />
              </Styled.FlexRow>
              <Spacer size="medium" direction="vertical" />
            </>
          )}

          <Styled.FlexRow>
            <Select
              label="Rule Requirement"
              placeholder="Select Product Feed Info"
              name="productFeedInfo"
              required
              onChange={(selected: SelectOption) =>
                hook.changeProductCriteria(index, selected.label, selected.value, criteria.required)
              }
              options={hook.criteriaProductFeedList.reduce((acc: SelectOption[], item) => {
                if (!hook.selectedCriteria.find((selected) => selected.value === item.value)) {
                  acc.push(item);
                }
                return acc;
              }, [])}
              selected={{
                label: criteria.label,
                value: criteria.value,
              }}
              tooltip="Select a Field here to determine a value for rule evaluation. 
               Any Field(s) selected here will relate to previously indicated Product for the rule to apply. 
               NOTE data must exist in the product's product feed  for fields to be available here,
               if the selection is empty - please update your product catalog feed first before creating your rule."
              tooltipExtraMarginLeft={25}
            />
            <InputText
              disabled
              label="Product Feed Data"
              placeholder="Data associated with product feed!"
              value={getFeedValue(criteria.value, hook.stateMachine.context.product?.productFeedSection)}
              type="text"
              tooltip="Values populated here are pulled from the corresponding Product Catalog Field selected"
              readonly
              required
            />

            <Styled.DeleteRequirementButton
              onClick={() => hook.removeCriteria(index)}
              style={{ paddingLeft: 8, paddingRight: 8, marginTop: 24 }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Styled.DeleteRequirementButton>
          </Styled.FlexRow>
          <Styled.RequiredCheckboxWrapper>
            <Checkbox
              label="Required"
              checked={hook.selectedCriteria[index].required}
              onChange={(required) =>
                hook.changeProductCriteria(
                  index,
                  hook.selectedCriteria[index].label,
                  hook.selectedCriteria[index].value,
                  required
                )
              }
              tooltip="If the Field(s) are set to “Required” and the 
            Rule does not find the value on a page or it does not match, the Rule Status = Fail."
              tooltipExtraMarginLeft={60}
            />
          </Styled.RequiredCheckboxWrapper>
        </Styled.FlexColumn>
      ))}
    </Styled.FlexColumn>

    <Spacer size="large" direction="vertical" />

    <Styled.AddRuleButtonWrapper>
      <Button
        theme="tertiary"
        onClick={() => hook.appendCriteria()}
        width="auto"
        disabled={hook.selectedCriteria.length >= 5}
      >
        <FontAwesomeIcon icon={faPlus} style={{ paddingRight: '8px' }} />
        Add Rule Requirement
      </Button>
    </Styled.AddRuleButtonWrapper>

    <Spacer size="medium" direction="vertical" />

    <Styled.HeaderInfoText>Please note: A maximum of five value(s) are permissible.</Styled.HeaderInfoText>
  </>
);
