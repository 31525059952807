import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TableProps } from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/types';
import {
  CellCenterWrapper,
  IconButtonStyled,
} from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/Tabs/TrackingUrls/styles';
import { URLS_LIST } from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/enums';
import { toRem } from 'utils';

export const EditIcon = ({ val, row, setOpenModal, setModalInfo, isReadOnly }: TableProps): JSX.Element => (
  <CellCenterWrapper>
    <IconButtonStyled
      onClick={() => {
        setOpenModal();
        setModalInfo({
          id: row.id,
          trackingUrl: row.url,
          checked: row.checked,
          loadedCheckType: row.checkType,
          type: URLS_LIST.MODAL.TYPES.EDIT,
        });
      }}
      theme="quaternary"
      disabled={isReadOnly}
    >
      <FontAwesomeIcon icon={val} fontSize={toRem(16)} />
    </IconButtonStyled>
  </CellCenterWrapper>
);
