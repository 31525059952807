import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import * as Styled from './styles';

type CheckboxProps = {
  checked: boolean;
  partial?: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  theme?: Theme;
  className?: string;
  disabled?: boolean;
  tooltip?: string;
  tooltipExtraMarginLeft?: number;
};

export const Checkbox = ({
  checked,
  partial = false,
  onChange,
  label,
  className,
  disabled = false,
  theme,
  tooltip,
  tooltipExtraMarginLeft,
}: CheckboxProps): JSX.Element => (
  <Styled.WrapperStyled className={className} style={{ cursor: disabled ? 'default' : 'pointer' }}>
    <Styled.IconStyled checked={checked || partial} disabled={disabled} theme={theme}>
      {checked && <FontAwesomeIcon icon={faCheck} />}
      {partial && !checked && <FontAwesomeIcon icon={faMinus} />}
    </Styled.IconStyled>

    {label && (
      <Styled.LabelStyled theme={theme}>
        {label} {tooltip && <Styled.TooltipStyled extraMarginLeft={tooltipExtraMarginLeft} text={tooltip} />}
      </Styled.LabelStyled>
    )}

    <Styled.InputStyled type="checkbox" checked={checked} onChange={() => onChange(!checked)} disabled={disabled} />
  </Styled.WrapperStyled>
);
