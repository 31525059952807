import { isPossiblePhoneNumber } from 'react-phone-number-input';
import {
  COUNTRIES,
  ERROR_MESSAGES,
  ERROR_TYPES,
  REGEX_VALIDATORS,
  STATS_TIER_RATE_ERROR,
  STATS_TIER_RATE_KEY,
  URL_STATUSES,
} from './enums';
import { TIRED_RATES } from '../pages/Payments/AccountBalanceManagementUpdateStats/contracts/enum';
import { AMOUNT_ERROR_MESSAGE } from '../pages/Merchants/Commissions/NewCommission/utils';

let abortController: null | AbortController = null;
let lastTimeout: any = null;

export const useValidation = () => {
  const urlExists = async (url: string) => {
    try {
      // check if url is not using https.
      if (url.toLowerCase().startsWith('http://')) {
        return true;
      }

      // Cancel old fetch request if it has not returned
      // Are you trying to check a list of URLs?  this could mess that up if trying to check a list of URL's in parallel.
      //                                          Do not use the notParallel flag in the validateUrlStatus.
      if (abortController) {
        clearTimeout(lastTimeout);
        abortController.abort();
      }

      abortController = new AbortController();
      const localAbortController = abortController;

      const { signal } = abortController;

      // Check if URL is active
      const res = fetch(url, { mode: 'no-cors', signal });
      // Return if request is taking too long
      const timeout = new Promise((resolve) => {
        lastTimeout = setTimeout(() => {
          localAbortController.abort();
          resolve(null);
        }, 2000);
      });

      const returnValue = Promise.race([res, timeout]).catch(() => null);
      return returnValue;
    } catch (e) {
      return null;
    }
  };

  const urlExistsSync = async (url: string) => {
    try {
      // check if url is not using https.
      if (url.toLowerCase().startsWith('http://')) {
        return true;
      }
      const res = await fetch(url, { mode: 'no-cors' });
      return res;
    } catch (e) {
      return null;
    }
  };

  const validateUrlStatus = async (
    url: string,
    setUrlStatus?: any,
    urlStatuses?: any,
    index?: number,
    notParallel?: boolean
  ) => {
    let status;
    if (url !== '' && url !== undefined) {
      if (!REGEX_VALIDATORS.URL_WITH_PARAMS.REGEX.test(url)) {
        status = URL_STATUSES.INVALID_WEBSITE.STATUS;
      } else if (url.toLowerCase().includes('https://')) {
        status = URL_STATUSES.PENDING_WEBSITE.STATUS;
        const res = !notParallel ? await urlExistsSync(url) : await urlExists(url);
        if (res) {
          status = URL_STATUSES.ACTIVE_WEBSITE.STATUS;
        } else {
          status = URL_STATUSES.INACTIVE_WEBSITE.STATUS;
        }
      } else {
        status = URL_STATUSES.UNSAFE_WEBSITE.STATUS;
      }
    } else {
      status = URL_STATUSES.EMPTY_WEBSITE.STATUS;
    }
    if (index === undefined) {
      if (setUrlStatus) setUrlStatus(status);
      return status;
    }
    const newCheckTrackingUrl = urlStatuses;
    newCheckTrackingUrl[index] = status;
    setUrlStatus(newCheckTrackingUrl);
    return status;
  };

  const renderUrlCheck = (urlStatus: string, setUrlError?: any, optional?: boolean) => {
    let message = '';
    switch (urlStatus) {
      case URL_STATUSES.INVALID_WEBSITE.STATUS:
        message = URL_STATUSES.INVALID_WEBSITE.ERROR;
        break;
      case URL_STATUSES.ACTIVE_WEBSITE.STATUS:
        message = URL_STATUSES.ACTIVE_WEBSITE.ERROR;
        break;
      case URL_STATUSES.PENDING_WEBSITE.STATUS:
        message = URL_STATUSES.PENDING_WEBSITE.ERROR;
        break;
      case URL_STATUSES.UNSAFE_WEBSITE.STATUS:
        message = URL_STATUSES.UNSAFE_WEBSITE.ERROR;
        break;
      case URL_STATUSES.INACTIVE_WEBSITE.STATUS:
        message = URL_STATUSES.INACTIVE_WEBSITE.ERROR;
        break;
      default:
        message = optional ? '' : URL_STATUSES.EMPTY_WEBSITE.ERROR;
    }
    if (setUrlError !== undefined) setUrlError(message);
    return message;
  };

  const renderUrlListCheck = (urlStatuses: string[], setUrlErrors: any) => {
    const errors: string[] = [];
    urlStatuses.forEach((err) => {
      errors.push(renderUrlCheck(err, undefined, true));
    });
    setUrlErrors(errors);
  };

  const validatePostalCode = (postalCode: string, country: string) => {
    if (postalCode === '') {
      return false;
    }
    if (country === COUNTRIES.US && !postalCode.match(/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/)) {
      return false;
    }
    if (country === COUNTRIES.CA && !postalCode.match(/^([A-Za-z][0-9][A-Za-z])\s*([0-9][A-Za-z][0-9])$/)) {
      return false;
    }
    if (country === COUNTRIES.AU && !postalCode.match(/^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$/)) {
      return false;
    }
    return true;
  };

  const validatePhoneNumber = (phoneNumber: any, optional: string) => {
    if (optional === ERROR_TYPES.PHONE_OPTIONAL && (phoneNumber === undefined || phoneNumber === '')) return true;
    const valExists = typeof phoneNumber === 'string' && phoneNumber.trim().length > 0;
    const phone = valExists && !phoneNumber.includes('+') ? `+${phoneNumber}` : phoneNumber;
    if ((phone && phone.toLowerCase().match(/[a-z]/i)) || !isPossiblePhoneNumber(phone)) {
      return false;
    }
    return true;
  };

  const validateTax = (type: string, value: string, isDisable: boolean) => {
    if (isDisable) return '';
    if (type.length === 0) return '';
    if (value.length === 0) return '';

    switch (type) {
      case 'taxation':
        return value.match(/^\d{3}\s?\d{3}\s?\d{3}$/) ? '' : ERROR_MESSAGES.TAXATION;
      case 'gst':
        return value.includes('RT') && value.length === 15 ? '' : ERROR_MESSAGES.GST_HST;
      case 'hst':
        return value.includes('RT') && value.length === 15 ? '' : ERROR_MESSAGES.GST_HST;
      case 'pst':
        return value.includes('PST') && value.length === 11 ? '' : ERROR_MESSAGES.PST;
      case 'qst':
        return value.includes('TQ') && value.length === 16 ? '' : ERROR_MESSAGES.QST;
      case 'us':
      case 'au':
        return value.match(/^\d+$/) && value.length === 9 ? '' : ERROR_MESSAGES.TAXATION_US_AU;
      default:
        return '';
    }
  };

  const validateStatRates = (value: any[] | string, type: string, setErr: any) => {
    const errors: { [key: string]: any } = {};
    let noErrors = true;

    if (type === TIRED_RATES.TIER && Array.isArray(value)) {
      value.forEach((row, index) => {
        const roleErrors: { [key: string]: string } = {};
        if (
          !row[STATS_TIER_RATE_KEY.ADMIN_TIER_COMMISSION] ||
          typeof row[STATS_TIER_RATE_KEY.ADMIN_TIER_COMMISSION] !== 'number'
        ) {
          roleErrors[STATS_TIER_RATE_KEY.ADMIN_TIER_COMMISSION] =
            ERROR_MESSAGES[STATS_TIER_RATE_ERROR.ADMIN_TIER_COMMISSION];
          noErrors = false;
        }
        if (!row[STATS_TIER_RATE_KEY.ADMIN_TIER_RATE] || typeof row[STATS_TIER_RATE_KEY.ADMIN_TIER_RATE] !== 'number') {
          roleErrors[STATS_TIER_RATE_KEY.ADMIN_TIER_RATE] = ERROR_MESSAGES[STATS_TIER_RATE_ERROR.ADMIN_TIER_RATE];
          noErrors = false;
        }
        if (
          index === value.length - 1 &&
          (!row[STATS_TIER_RATE_KEY.AFTER_TIER_RATE] || typeof row[STATS_TIER_RATE_KEY.AFTER_TIER_RATE] !== 'number')
        ) {
          roleErrors[STATS_TIER_RATE_KEY.AFTER_TIER_RATE] = ERROR_MESSAGES[STATS_TIER_RATE_ERROR.AFTER_TIER_RATE];
          noErrors = false;
        }
        errors[row.id] = roleErrors;
      });
    } else if (Array.isArray(value) || !value || !value.match(REGEX_VALIDATORS.PERCENTAGE.REGEX)) {
      errors[STATS_TIER_RATE_KEY.ADMIN_RATE] = ERROR_MESSAGES[STATS_TIER_RATE_ERROR.ADMIN_RATE];
      noErrors = false;
    }
    setErr(errors);
    return noErrors;
  };

  const validateArrayAllValues = (values: string[], enumValues: string[]) =>
    enumValues.every((val) => values.includes(val));

  const validateArray = (val: { [key: string]: string[] }, fields: { [key: string]: string }, setErr: any) => {
    const errors: { [key: string]: string } = {};
    let noErrors = true;
    Object.keys(fields).forEach((f: string) => {
      const key = f.replace(/[A-Z]/g, (c) => `_${c}`).toUpperCase();
      switch (fields[f]) {
        case ERROR_TYPES.AT_LEAST_ONE:
          if (val[f]?.length < 1) {
            errors[f] = ERROR_MESSAGES.AT_LEAST_ONE;
          }
          break;
        default:
          errors[f] = ERROR_MESSAGES[key];
      }
      if (noErrors && errors[f] !== '' && errors[f] !== undefined) {
        noErrors = false;
      }
    });
    setErr(errors);
    return noErrors;
  };

  const validateAll = (val: { [key: string]: string }, fields: any, setErr: any, render: boolean) => {
    const errors: { [key: string]: string } = {};
    const fieldNames = Object.keys(fields);
    let noErrors = true;

    fieldNames.forEach((f: string) => {
      const key = f.replaceAll(/[A-Z]/g, (c) => `_${c}`).toUpperCase();
      switch (fields[f]) {
        case ERROR_TYPES.NOT_EMPTY:
          if (val[f] === '' || val[f] === undefined || val[f] === null) {
            errors[f] = ERROR_MESSAGES[key] ? ERROR_MESSAGES[key] : ERROR_MESSAGES.MANDATORY;
            break;
          }
          break;
        case ERROR_TYPES.EMAIL:
          if (!val[f].match(REGEX_VALIDATORS.EMAIL.REGEX)) {
            errors[f] = ERROR_MESSAGES.EMAIL;
          }
          break;
        case ERROR_TYPES.PRODUCT_EMPTY:
          if (
            (!val[f] || val[f] === 'null' || val[f] === 'No Product') &&
            (!val.campaignProductId || val.campaignProductId === 'null')
          ) {
            errors[f] = 'Please select a product.';
          }
          break;
        case ERROR_TYPES.EMAIL_OPTIONAL:
          if (!val[f].match(REGEX_VALIDATORS.EMAIL_OPTIONAL.REGEX)) {
            errors[f] = ERROR_MESSAGES.EMAIL;
          }
          break;
        case ERROR_TYPES.REGION:
          errors[f] = val.country !== '' && val[f] === '' ? ERROR_MESSAGES[key] : '';
          break;
        case ERROR_TYPES.POSTAL_CODE:
          // eslint-disable-next-line no-nested-ternary
          errors[f] =
            val[f] === '' || !validatePostalCode(val[f], val.country)
              ? ERROR_MESSAGES[key] !== undefined
                ? ERROR_MESSAGES[key]
                : ERROR_MESSAGES.POSTAL_CODE
              : '';
          break;
        case ERROR_TYPES.PHONE:
        case ERROR_TYPES.PHONE_OPTIONAL:
          errors[f] = !validatePhoneNumber(val[f], fields[f]) ? ERROR_MESSAGES.PHONE : '';
          break;
        case ERROR_TYPES.SELECTION_REQUIRED:
          if (ERROR_MESSAGES[key]) errors[f] = val[f] === undefined || val[f] === '' ? ERROR_MESSAGES[key] : '';
          else errors[f] = val[f] === undefined || val[f] === '' ? 'Please select an option.' : '';
          break;
        case ERROR_TYPES.END_DATE:
          if (val[f] !== undefined && val.startDate !== undefined) {
            const start = new Date(val.startDate).getTime();
            const end = new Date(val[f]).getTime();
            errors[f] = start > end ? ERROR_MESSAGES[key] : '';
          }
          break;
        case ERROR_TYPES.NUMBERS_ONLY:
          if (val[f] && !val[f].match(REGEX_VALIDATORS.NUMBERS_ONLY.REGEX)) {
            errors[f] = ERROR_MESSAGES[key] === undefined ? ERROR_MESSAGES.NUMBERS_ONLY : ERROR_MESSAGES[key];
          }
          break;
        case ERROR_TYPES.NUMBER_NOT_EMPTY:
          if (!val[f] || !val[f].match(REGEX_VALIDATORS.NUMBERS_ONLY.REGEX)) {
            errors[f] = ERROR_MESSAGES[key];
          }
          break;
        case ERROR_TYPES.COMMISSION_AMOUNT:
          if (val[f] !== undefined && val[f] !== '' && !val[f].match(REGEX_VALIDATORS.NUMBERS_DECIMALS.REGEX)) {
            errors[f] = AMOUNT_ERROR_MESSAGE;
          }
          break;
        case ERROR_TYPES.COMMISSION_PRODUCT_EMPTY:
          if (val[f] === 'empty') {
            errors[f] = ERROR_MESSAGES[key];
          }
          break;
        case ERROR_TYPES.COMMISSION_NAME:
          if (val[f] === '') {
            errors[f] = ERROR_MESSAGES[key];
          }
          break;
        case ERROR_TYPES.IS_NUMBER:
          if (val[f] && (parseFloat(val[f]) < 0 || typeof val[f] !== 'number')) {
            errors[f] = ERROR_MESSAGES[key];
          }
          break;
        case ERROR_TYPES.IS_DOLLAR_AMOUNT_REQUIRE:
          if (!val[f] || !val[f]?.match(REGEX_VALIDATORS.IS_DOLLAR_AMOUNT.REGEX)) {
            errors[f] = ERROR_MESSAGES[key];
          }
          break;
        case ERROR_TYPES.IS_DOLLAR_AMOUNT:
          if (val[f] !== undefined && val[f]?.match(REGEX_VALIDATORS.IS_DOLLAR_AMOUNT.REGEX) === null) {
            errors[f] = ERROR_MESSAGES[key] === undefined ? ERROR_MESSAGES.DOLLAR_AMOUNT : ERROR_MESSAGES[key];
          }
          break;
        case ERROR_TYPES.TAXATION_STRING:
          if (val[f] !== '' && val[f]?.match(REGEX_VALIDATORS.TAXATION_STRING.REGEX) === null) {
            errors[f] =
              ERROR_MESSAGES[key] === undefined ? REGEX_VALIDATORS.TAXATION_STRING.MESSAGE : ERROR_MESSAGES[key];
          }
          break;
        case ERROR_TYPES.SELECT_ROLE:
          if (val[f] === undefined || val[f] === '') {
            errors[f] = 'Please select a role';
          }
          break;
        case ERROR_TYPES.ADDRESS:
          if (val[f].length < 2) {
            errors[f] = ERROR_MESSAGES[key] === undefined ? REGEX_VALIDATORS.ADDRESS.MESSAGE : ERROR_MESSAGES[key];
          }
          break;
        case ERROR_TYPES.BANK_ACCOUNT_NUMBER:
          if (val[f]?.match(REGEX_VALIDATORS.BANK_ACCOUNT_NUMBER.REGEX) === null) {
            errors[f] = ERROR_MESSAGES[key];
          }
          break;
        case ERROR_TYPES.NAME:
          if (val[f]?.match(REGEX_VALIDATORS.NAME.REGEX) === null) {
            errors[f] = ERROR_MESSAGES[key];
          }
          break;
        case ERROR_TYPES.REAL_NUMBER_FEE:
          if (val[f] === undefined || val[f] === '') {
            errors[f] = ERROR_MESSAGES[key];
          }
          break;
        default:
          if (typeof val[f] === 'string' && !val[f].match(fields[f])) {
            errors[f] = ERROR_MESSAGES[key];
          }
      }
      if (noErrors && errors[f] !== '' && errors[f] !== undefined) {
        noErrors = false;
      }
    });
    if (render) setErr(errors);
    return noErrors;
  };

  return {
    validateUrlStatus,
    renderUrlCheck,
    renderUrlListCheck,
    validateAll,
    validateTax,
    validateArray,
    validateStatRates,
    validateArrayAllValues,
  };
};
