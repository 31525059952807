import { InputText, Select, Spacer } from 'components';
import _ from 'lodash';
import { useAddEvaluationRule } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/hooks/useAddEvaluationRule';
import * as Styled from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/styles';
import React from 'react';

export const NameGroupType = ({ hook }: { hook: ReturnType<typeof useAddEvaluationRule> }): JSX.Element => (
  <>
    <Spacer size="medium" direction="vertical" />
    <Styled.HeaderInfoText>
      Evaluation Rules instruct Fintel Check to find and evaluate defined content on an URL
    </Styled.HeaderInfoText>

    <Styled.FormStyled>
      {/* Rule Name */}
      <InputText
        label="Rule Name"
        placeholder="Enter a unique rule name here"
        value={hook.stateMachine.context.ruleName}
        onChange={hook.changeRuleName}
        error={hook.hookRuleNameError}
        type="text"
        required
        width="40vw"
        tooltip="Enter a rule name that describes the type of checks that the Fintel Check tool will perform. 
        A clear and descriptive name will make it easier to search and find the rule."
        tooltipExtraMarginLeft={60}
        maxLength={255}
      />

      {/* Rule Group */}
      <Select
        label="Rule Group"
        placeholder="Select a rule group"
        options={hook.checkRuleGroupsList}
        selected={
          hook.stateMachine.context.ruleGroup
            ? _.find(hook.checkRuleGroupsList, { value: hook.stateMachine.context.ruleGroup })
            : undefined // This ensures that the placeholder is shown when the rule group is not set
        }
        onChange={hook.changeSelectedRuleGroup}
        width="40vw"
        tooltip="Select a group that you would like this rule to be added to. This is optional."
        tooltipExtraMarginLeft={60}
      />

      {/* Rule Type */}
      <Styled.TextDescription>
        Select what text type to evaluate - Custom Text to be provided in the rule or text pulled from the Product
        Catalog.
        <Styled.RequiredStyled>*</Styled.RequiredStyled>
      </Styled.TextDescription>

      <Select
        placeholder="Select a rule type"
        selected={_.find(hook.ruleTypeOptions, { value: hook.stateMachine.context.ruleType })}
        options={hook.ruleTypeOptions}
        onChange={hook.changeSelectedRuleType}
        width="40vw"
        required
      />
    </Styled.FormStyled>
  </>
);
