import styled from 'styled-components';

import { colors } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

export const CollapseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: ${colors.color1};
  border: none;
  font-size: 1rem;
  color: ${colors.color4};
  right: -1rem;
  top: 5.5rem;
  height: ${toRem(30)};
  width: ${toRem(30)};
  border-radius: 100%;
  cursor: pointer;
  z-index: 3;
`;
