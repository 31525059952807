import React, { MouseEvent, FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSideMenu } from '../../../../hooks/useSideMenu';
import { ISideMenuItem } from '../../../../types';
import { MenuItem } from '../../SideMenuItem.styles';

interface ISideMenuNestedAsHrefProps {
  menuItem: ISideMenuItem;
  itemLevel: number;
}

const SideMenuNestedAsHref: FC<ISideMenuNestedAsHrefProps> = ({ menuItem, itemLevel }): ReactElement => {
  const navigate = useNavigate();
  const { toggleSideMenuItem, isUnderManagement } = useSideMenu();

  const handleMenuItemClick = (e: MouseEvent<HTMLAnchorElement>, item: ISideMenuItem, level: number): void => {
    e.preventDefault();
    toggleSideMenuItem(item.id, level, !!item.subSections);
    setTimeout(() => navigate(item.href as string), 100);
  };

  return (
    <MenuItem
      onClick={(e) => handleMenuItemClick(e, menuItem, itemLevel)}
      to={menuItem.href as string}
      level={itemLevel}
      isUnderManagement={isUnderManagement(menuItem.id)}
      key={menuItem.label}
      id={menuItem.label.replace(/ /g, '')}
    >
      {menuItem.label}
    </MenuItem>
  );
};

export default SideMenuNestedAsHref;
