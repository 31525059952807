import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';

import { Permission } from '../../../../entities';

import { IFintelCheckToolSettings, TTouchedToolSettings, IValidationResult } from './types';

interface IFintelCheckSettingsContext {
  toolSettings: IFintelCheckToolSettings;
  touchedToolSettings: TTouchedToolSettings;
  validationResult: IValidationResult;
  isReadOnlyPermissions: boolean;
  isUpdateModalOpened: boolean;
  isPageLoading: boolean;
  isPageFullyLoaded: boolean;
  setIsUpdateModalOpened: Dispatch<SetStateAction<boolean>>;
  setIsPageLoading: Dispatch<SetStateAction<boolean>>;
  setPageIsFullyLoaded: Dispatch<SetStateAction<boolean>>;
  setToolSettings: Dispatch<SetStateAction<IFintelCheckToolSettings>>;
  setTouchedToolSettings: Dispatch<SetStateAction<TTouchedToolSettings>>;
  setValidationResult: Dispatch<SetStateAction<IValidationResult>>;
}

interface IFintelCheckSettingsProviderProps {
  permissionsCodeList: string[];
  children: ReactNode;
}

const FintelCheckSettingsContext = createContext<IFintelCheckSettingsContext | null>(null);

export const useFintelCheckSettingsContext = (): IFintelCheckSettingsContext => {
  const context = useContext(FintelCheckSettingsContext);

  if (!context) {
    throw new Error('useFintelCheckSettings must be used within a FintelCheckSettingsProvider');
  }

  return context;
};

export const FintelCheckSettingsProvider: React.FC<IFintelCheckSettingsProviderProps> = ({
  children,
  permissionsCodeList = [],
}) => {
  const [toolSettings, setToolSettings] = useState<IFintelCheckToolSettings>({} as IFintelCheckToolSettings);
  const [touchedToolSettings, setTouchedToolSettings] = useState<TTouchedToolSettings>({});
  const [validationResult, setValidationResult] = useState<IValidationResult>({
    isValid: true,
    errors: {},
  });
  const [isUpdateModalOpened, setIsUpdateModalOpened] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [isPageFullyLoaded, setPageIsFullyLoaded] = useState(false);
  const [isReadOnlyPermissions, setIsReadOnlyPermissions] = useState<boolean>(true);

  useEffect(() => {
    const hasReadOnlyPermissions = Permission.readOnlyPermissionsList(permissionsCodeList).some(Boolean);
    setIsReadOnlyPermissions(hasReadOnlyPermissions);
  }, [permissionsCodeList]);

  const contextValue = useMemo(
    () => ({
      toolSettings,
      touchedToolSettings,
      validationResult,
      isReadOnlyPermissions,
      isUpdateModalOpened,
      isPageLoading,
      isPageFullyLoaded,
      setIsUpdateModalOpened,
      setIsPageLoading,
      setPageIsFullyLoaded,
      setToolSettings,
      setTouchedToolSettings,
      setValidationResult,
    }),
    [toolSettings, touchedToolSettings, isReadOnlyPermissions, isUpdateModalOpened, isPageLoading, isPageFullyLoaded]
  );

  return <FintelCheckSettingsContext.Provider value={contextValue}>{children}</FintelCheckSettingsContext.Provider>;
};
