import { gql } from '@apollo/client';

export const LIST_PRODUCT_FEED_DATA = gql`
  query ListProductFeedData($input: ListProductFeedDataInput!) {
    listProductFeedData(input: $input) {
      category
      feed {
        productName
        value
        values {
          name
          description
        }
      }
    }
  }
`;
