import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useToast, useUserInfo } from 'hooks';
import {
  DEFAULT_TRANSACTION_STATUSES,
  ERROR_TYPES,
  RangeFormat,
  rangeFormat,
  USER_TYPES_ID,
  useValidation,
} from 'utils';

import { GET_FILTERS, GET_MERCHANTS, GET_REPORT, UPDATE_REPORT } from '../graphql';
import {
  EditColumnProps,
  EditFilterProps,
  ReportProfileProps,
  useEditColumnsReducer,
  useEditFiltersReducer,
  useEditReportReducer,
} from '../reducers';
import { EDIT_REPORT, PERFORMANCE_ALLOWED_FILTERS, TRANSACTION_MASTER_ALLOWED_FILTERS } from '../enums';
import {
  SAVE_REPORT_PROFILE_SCHEDULE_MONTH_OPTIONS,
  SAVE_REPORT_PROFILE_SCHEDULE_FREQUENCY_OPTIONS,
  SAVE_REPORT_PROFILE_SCHEDULE_WEEK_OPTIONS,
} from '../../PerfomanceReport/Modals/SaveReportProfile/enums';
import { immutableProductCategories } from '../../PerfomanceReport/enums';
import { defaultOption } from '../../TransactionMasterReport/enums';

export const useEditReport = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { hookShowToast } = useToast();
  const { hookWhoAmI, hookUserInfo } = useUserInfo();
  const vali = useValidation();

  const originalPublisherOption: SelectOption = (() => {
    if (
      hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER ||
      hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER
    ) {
      const publisherId = hookWhoAmI.companyId?.toString() || '0';
      return { label: '', value: publisherId };
    }
    return EDIT_REPORT.DEFAULT_SELECT_VALUE.PUBLISHER;
  })();

  // reducers
  const [reportState, reportDispatcher] = useReducer(useEditReportReducer, {} as ReportProfileProps);
  const [filtersState, filtersDispatcher] = useReducer(useEditFiltersReducer, {
    merchantId: EDIT_REPORT.DEFAULT_SELECT_VALUE.MERCHANT,
    publisherId: EDIT_REPORT.DEFAULT_SELECT_VALUE.PUBLISHER,
    productId: EDIT_REPORT.DEFAULT_SELECT_VALUE.PRODUCT,
    productCategory: EDIT_REPORT.DEFAULT_SELECT_VALUE.CATEGORY,
    trackingProfile: EDIT_REPORT.DEFAULT_SELECT_VALUE.TRACKING,
    trackingProfileId: EDIT_REPORT.DEFAULT_SELECT_VALUE.TRACKING,
    groupBy: EDIT_REPORT.DEFAULT_SELECT_VALUE.GROUP_BY,
    campaignId: EDIT_REPORT.DEFAULT_SELECT_VALUE.CAMPAIGN,
    publisherGroupId: EDIT_REPORT.DEFAULT_SELECT_VALUE.PUBLISHER_GROUP,
    adId: EDIT_REPORT.DEFAULT_SELECT_VALUE.AD,
    dataType: EDIT_REPORT.DEFAULT_SELECT_VALUE.DATE_TYPE,
    transactionStatus: EDIT_REPORT.DEFAULT_SELECT_VALUE.TRANSACTION_STATUS,
  } as EditFilterProps);
  const [columnsState, columnsDispatcher] = useReducer(useEditColumnsReducer, {} as EditColumnProps);

  // states
  const [reportType, setReportType] = useState('');
  const [merchantsList, setMerchantsList] = useState<SelectOption[]>([]);
  const [publishersList, setPublishersList] = useState<SelectOption[]>([]);
  const [productsList, setProductsList] = useState<SelectOption[]>([]);
  const [categoriesList, setCategoriesList] = useState<SelectOption[]>([]);
  const [trackingProfilesList, setTrackingProfilesList] = useState<SelectOption[]>([]);
  const [adCampaignsList, setAdCampaignsList] = useState<SelectOption[]>([]);
  const [publisherGroupsList, setPublisherGroupsList] = useState<SelectOption[]>([]);
  const [adsList, setAdsList] = useState<SelectOption[]>([]);
  const dateTypeList: SelectOption[] = [defaultOption.transactionDate, defaultOption.processedDate];
  const transactionStatusList = DEFAULT_TRANSACTION_STATUSES;
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [dateLoad, setDateLoad] = useState(false);
  const [inputErrors, setInputErrors] = useState<{ [key: string]: string }>({});
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  // graphql
  const [getFilters, { loading: loadingFilters }] = useLazyQuery(GET_FILTERS);
  const [getMerchants] = useLazyQuery(GET_MERCHANTS);
  const [getReport] = useLazyQuery(GET_REPORT);
  const [updateReport] = useMutation(UPDATE_REPORT);

  const navigateBackHandler = (): void => {
    navigate(-1);
  };

  const getMerchantsHandler = async (): Promise<void> => {
    const { data, error } = await getMerchants({
      variables: {
        input: {
          filter: {
            accountStatusType: 'Approved',
          },
          sortBy: 'id',
          options: { order: 'ASC' },
        },
      },
    });

    if (error) {
      hookShowToast('An error occurred while fetching the merchants.');
      return;
    }

    if (data?.listSearchMerchants !== undefined) {
      const formatMerchants = data.listSearchMerchants.companies?.map((merchant: any) => ({
        label: `${merchant.id} - ${merchant.companyName}`,
        value: merchant.id,
      }));
      setMerchantsList([EDIT_REPORT.DEFAULT_SELECT_VALUE.MERCHANT, ...formatMerchants]);
    }
  };

  const getFiltersHandler = async (merchantId: string): Promise<void> => {
    const { data, error } = await getFilters({
      variables: {
        input: {
          companyType: 'Merchant',
          accountStatus: 'Approved',
          id: merchantId,
        },
      },
    });

    if (error) {
      hookShowToast('An error occurred while fetching the filters.');
      return;
    }

    if (data?.companyOptions?.companies !== undefined && data.companyOptions.companies.length > 0) {
      const formatPublishers = data.companyOptions.companies[0]?.memberships?.map((membership: any) => ({
        label: `${membership.publisher.id} - ${membership.publisher.companyName}`,
        value: membership.publisher.id,
      }));
      setPublishersList([EDIT_REPORT.DEFAULT_SELECT_VALUE.PUBLISHER, ...formatPublishers]);

      const formatProducts = data.companyOptions.companies[0].program?.products?.map((product: any) => ({
        label: `${product.customizedProductId} - ${product.name}`,
        value: product.customizedProductId,
      }));
      setProductsList([EDIT_REPORT.DEFAULT_SELECT_VALUE.PRODUCT, ...formatProducts]);

      const productCategories = new Set<string>();
      data.companyOptions.companies[0].program?.products?.forEach((product: any) =>
        productCategories.add(product.productCategory)
      );
      const formatProductCategories = Array.from(productCategories)
        .sort()
        .map((category: any) => ({
          label: category,
          value: category,
        }));
      setCategoriesList([EDIT_REPORT.DEFAULT_SELECT_VALUE.CATEGORY, ...formatProductCategories]);

      const formatCampaign = data.companyOptions.companies[0].program?.campaigns?.map((campaign: any) => ({
        label: `${campaign.id} - ${campaign.name}`,
        value: campaign.id,
      }));
      setAdCampaignsList([EDIT_REPORT.DEFAULT_SELECT_VALUE.CAMPAIGN, ...formatCampaign]);

      const formatTrackingProfiles = data.companyOptions.companies[0].memberships?.map((membership: any) => {
        const trackings = membership.publisher?.trackings?.reduce((acc: any, cur: any) => ({
          ...acc,
          ...cur,
        }));
        return {
          label: `${trackings.id} - ${trackings.primaryWebsite}`,
          value: trackings.id,
        };
      });
      setTrackingProfilesList([EDIT_REPORT.DEFAULT_SELECT_VALUE.TRACKING, ...formatTrackingProfiles]);

      const formatPublisherGroups = data.companyOptions.companies[0].program?.publisherGroups?.map((group: any) => ({
        label: `${group.id} - ${group.name}`,
        value: group.id,
      }));
      setPublisherGroupsList([EDIT_REPORT.DEFAULT_SELECT_VALUE.PUBLISHER_GROUP, ...formatPublisherGroups]);

      const formatAds = data.companyOptions.companies[0].program?.ads?.map((ad: any) => ({
        label: `${ad.id} - ${ad.adName}`,
        value: ad.id,
      }));
      setAdsList([EDIT_REPORT.DEFAULT_SELECT_VALUE.AD, ...formatAds]);
    } else {
      setCategoriesList([
        EDIT_REPORT.DEFAULT_SELECT_VALUE.CATEGORY,
        ...immutableProductCategories.map((cat) => ({ label: cat, value: cat })),
      ]);
    }
  };

  const getReportHandler = async (): Promise<void> => {
    const { data, error } = await getReport({
      variables: {
        id: searchParams.get('id'),
      },
      fetchPolicy: 'no-cache',
    });

    if (error || !data || !data.reportProfile) {
      hookShowToast('An error occurred while fetching the report.');
      return;
    }

    if (data.reportProfile.report === EDIT_REPORT.TRANSACTION) {
      setReportType(EDIT_REPORT.TRANSACTION);
    } else {
      setReportType(EDIT_REPORT.PERFORMANCE);
    }

    data.reportProfile.filters.map((filter: any) => {
      filtersDispatcher({
        data: {
          [filter.field]: filter.value,
        },
      });
    });

    reportDispatcher({
      data: {
        ...data.reportProfile,
        every: (() => {
          switch (data.reportProfile.frequency) {
            case SAVE_REPORT_PROFILE_SCHEDULE_FREQUENCY_OPTIONS[2].value: {
              return {
                label: SAVE_REPORT_PROFILE_SCHEDULE_WEEK_OPTIONS.find((item) => item.value === data.reportProfile.every)
                  ?.label,
                value: data.reportProfile.every,
              };
            }
            case SAVE_REPORT_PROFILE_SCHEDULE_FREQUENCY_OPTIONS[3].value: {
              return {
                label: SAVE_REPORT_PROFILE_SCHEDULE_MONTH_OPTIONS.find(
                  (item) => item.value === data.reportProfile.every
                )?.label,
                value: data.reportProfile.every,
              };
            }
            default:
              return null;
          }
        })(),
      },
    });

    setDateLoad(true);
  };

  const inputValues: { [key: string]: string } = {
    reportName: reportState.name,
  };

  const inputFields = {
    reportName: ERROR_TYPES.NOT_EMPTY,
  };

  const handleValidation = (): boolean => vali.validateAll(inputValues, inputFields, setInputErrors, true);

  const updateReportHandler = async (): Promise<void> => {
    if (!handleValidation()) return;

    let allowedFilterFields =
      reportType === EDIT_REPORT.TRANSACTION ? TRANSACTION_MASTER_ALLOWED_FILTERS : PERFORMANCE_ALLOWED_FILTERS;
    // if there is a DateRange only save that, otherwise save the startDate & endDate
    if (filtersState?.dateRange?.value) {
      allowedFilterFields = allowedFilterFields.filter((field) => field !== 'startDate' && field !== 'endDate');
    } else {
      allowedFilterFields = allowedFilterFields.filter((field) => field !== 'dateRange');
    }

    let inputFilters = Object.entries(filtersState)
      .map(([value, label]) => ({ field: value, value: { label: label.label, value: label.value } }))
      .filter((filter) => allowedFilterFields.includes(filter.field));

    // Add in the searchValue
    if (reportType === EDIT_REPORT.TRANSACTION) {
      inputFilters = [
        ...inputFilters,
        { field: 'search', value: { label: 'searchValue', value: searchInputRef.current?.value || '' } },
      ];
    }

    const { data, errors } = await updateReport({
      variables: {
        save: {
          id: reportState.id,
          name: reportState.name,
          description: reportState.description,
          report: reportState.report,
          frequency: reportState.frequency,
          every: reportState.every?.value,
          company: reportState.company ? Number(hookWhoAmI?.companyId || 1) : undefined,
          user: {
            id: hookWhoAmI.id,
            email: hookWhoAmI.email,
          },
          filters: inputFilters,
          columns: tableColumns.map((column) => {
            const label = tableData[0][column.dataField];
            const value = column.dataField;

            return { label, value };
          }),
        },
      },
    });

    if (errors) {
      hookShowToast('An error occurred while updating the report.');
      return;
    }

    if (data !== undefined) {
      hookShowToast('Your report was updated successfully.');
    }
  };

  const setOnDragEndHandler = (result: any): void => {
    setTableColumns(result);
  };

  const setOpenCalendarHandler = (): void => {
    setOpenCalendar(!openCalendar);
  };

  const setOnCancelCalendarHandler = (): void => {
    setOpenCalendar(false);
  };

  const setOnApplyCalendarHandler = (startDateValue: Date, endDateValue?: Date, range?: string): void => {
    setOpenCalendar(false);
    const startDate = startDateValue.toISOString();
    const endDate = endDateValue?.toISOString();
    filtersDispatcher({
      data: {
        startDate: {
          label: 'startDate',
          value: startDate,
        },
        endDate: {
          label: 'endDate',
          value: endDate,
        },
        dateRange: {
          label: 'dateRange',
          value: range,
        },
      },
    });
  };

  useEffect(() => {
    getMerchantsHandler();
    getReportHandler();
    reportState.filters?.map((filter) => {
      filtersDispatcher({
        data: {
          [filter.field]: filter.value,
        },
      });
    });

    if (filtersState.dateRange) {
      const { start, end, range } = rangeFormat(filtersState.dateRange.value as RangeFormat);
      filtersDispatcher({
        data: {
          startDate: {
            label: 'startDate',
            value: start.toISOString(),
          },
          endDate: {
            label: 'endDate',
            value: end.toISOString(),
          },
          range: {
            label: 'dateRange',
            value: range,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (filtersState.merchantId !== EDIT_REPORT.DEFAULT_SELECT_VALUE.MERCHANT) {
      getFiltersHandler(filtersState.merchantId.value);
    }
  }, [filtersState.merchantId]);

  useEffect(() => {
    reportState.columns?.map((column) => {
      columnsDispatcher({
        data: {
          [column.value]: {
            label: column.label,
            checked: true,
          },
        },
      });
    });

    if (reportState.columns) {
      reportState.columns.forEach((col, index) => {
        if (col.label === 'IP Address') {
          reportState.columns.splice(index, 1);
        }
      });
      setTableColumns(
        reportState.columns.map((column) => ({
          dataField: column.value,
          text: '',
          width: 'fit-content',
          formatter: (...args: any[]) =>
            `${args[8].findIndex((col: TableColumn) => col.dataField === column.value) + 1}. ${args[0]}`,
        }))
      );
      const data = reportState.columns.reduce(
        (final, current) => ({
          ...final,
          [current.value]: current.label,
        }),
        {}
      );
      setTableData([data]);
    }
  }, [reportState.columns]);

  useEffect(() => {
    const filteredCheckboxes = Object.entries(columnsState).filter(([_, value]) => value?.checked === true);
    const data = filteredCheckboxes.map((item) => ({
      [item[0]]: item[1].label,
    }));
    data.forEach((obj, index) => {
      const objKeys = Object.keys(obj);
      if (objKeys[0] === 'ip') data.splice(index, 1);
    });
    const newData = data.reduce(
      (result, obj) => ({
        ...result,
        ...obj,
      }),
      {}
    );

    setTableColumns(
      data.map((item) => ({
        dataField: Object.keys(item)[0],
        text: '',
        formatter: (...args: any[]) =>
          `${args[8].findIndex((col: TableColumn) => col.dataField === Object.keys(item)[0]) + 1}. ${args[0]}`,
        width: 'fit-content',
      }))
    );
    setTableData([newData]);
  }, [columnsState]);

  useEffect(() => {
    if (dateLoad && searchInputRef.current) {
      searchInputRef.current.value = filtersState.search?.value || '';
    }
  }, [dateLoad]);

  return {
    hookNavigate: navigateBackHandler,
    hookUpdateReport: updateReportHandler,
    hookReportState: reportState,
    hookReportDispatcher: reportDispatcher,
    hookReportType: reportType,
    hookFiltersState: filtersState,
    hookFiltersDispatcher: filtersDispatcher,
    hookLoadingFilters: loadingFilters,
    hookDateLoad: dateLoad,
    hookOpenCalendar: openCalendar,
    hookSetOpenCalendar: setOpenCalendarHandler,
    hookOnCancelCalendar: setOnCancelCalendarHandler,
    hookOnApplyCalendar: setOnApplyCalendarHandler,
    hookColumnsState: columnsState,
    hookColumnsDispatcher: columnsDispatcher,
    hookTableColumns: tableColumns,
    hookTableData: tableData,
    hookSetOnDragEnd: setOnDragEndHandler,
    hookLists: {
      hookMerchantList: merchantsList,
      hookPublisherList: publishersList,
      hookProductList: productsList,
      hookCategoryList: categoriesList,
      hookTrackingList: trackingProfilesList,
      hookCampaignList: adCampaignsList,
      hookPublisherGroupList: publisherGroupsList,
      hookAdlist: adsList,
      hookDateTypeList: dateTypeList,
      hookTransactionStatusList: transactionStatusList,
    },
    hookWhoAmI,
    hookUserInfo,
    hookSearchInputRef: searchInputRef,
    hookDefaultPublisher: originalPublisherOption,
    hookInputErrors: inputErrors,
  };
};
