import { useLazyQuery, useMutation } from '@apollo/client';
import { useToast, useUserInfo } from 'hooks';
import _ from 'lodash';
import { BrandNameEnum } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/enums';
import { defaultRuleGroupOption } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddMonitoringRule/enums';
import { CheckRuleGroup } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddMonitoringRule/types';
import { STATUS_TYPES } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/enums';
import { EDIT_RULE_BY_ID } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/graphql/mutations/editRuleById';
import { GET_FINTEL_CHECK_SETTINGS } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/graphql/queries/getFintelCheckSettings';
import { LIST_AVAILABLE_RULE_GROUPS } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/graphql/queries/listAvailableRuleGroups';
import {
  IEvaluationRuleHook,
  UseEditEvaluationRuleHook,
} from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/types';
import { useEffect, useState } from 'react';
import { TOAST_ERR_MESSAGES_NO_PAGE } from 'utils';

export const useEditEvaluationRule: IEvaluationRuleHook<UseEditEvaluationRuleHook> = (
  stateMachine,
  send,
  refreshRuleManager
) => {
  const { hookWhoAmI } = useUserInfo();
  const { hookShowToast } = useToast();
  // Overall Values
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [existingBrandName, setExistingBrandName] = useState<string>('');
  // Rule Group State
  const [checkRuleGroupsList, setCheckRuleGroupsList] = useState<SelectOption[]>([]);
  const [statusOptions] = useState<SelectOption[]>([
    { label: STATUS_TYPES.ACTIVE, value: STATUS_TYPES.ACTIVE },
    { label: STATUS_TYPES.INACTIVE, value: STATUS_TYPES.INACTIVE },
  ]);
  const [editRuleError, setEditRuleError] = useState<string>('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  const [getFintelCheckSettings] = useLazyQuery(GET_FINTEL_CHECK_SETTINGS);
  const [listAvailableGroupRules] = useLazyQuery(LIST_AVAILABLE_RULE_GROUPS);
  const [editRuleById, { loading: editRuleByIdLoading }] = useMutation(EDIT_RULE_BY_ID);

  /*
   * Overall Handlers
   */
  const getBackendValues = async (): Promise<void> => {
    setErrorMessage('');
    const [settingsResult, ruleGroupResult] = await Promise.allSettled([
      getFintelCheckSettings({
        variables: {
          input: {
            merchantId: Number(hookWhoAmI?.companyId),
          },
        },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(err.message);
        },
      }),
      listAvailableGroupRules({
        variables: {
          input: {
            merchantId: hookWhoAmI?.companyId?.toString(),
          },
        },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(err.message);
        },
      }),
    ]);

    if (settingsResult.status === 'fulfilled' && settingsResult?.value?.data?.getFintelCheckSettings?.settings) {
      const { brandName: brandNameFromSettings } = settingsResult.value.data.getFintelCheckSettings.settings;
      setExistingBrandName(brandNameFromSettings);
      if (stateMachine.context.brandNameType === BrandNameEnum.existing) {
        send({ type: ':brandName', brandName: brandNameFromSettings });
      }
    }

    if (
      ruleGroupResult.status === 'fulfilled' &&
      ruleGroupResult?.value?.data?.listAvailableRuleGroups.checkRuleGroups
    ) {
      let checkRuleGroupOptions: SelectOption[] = [defaultRuleGroupOption];
      checkRuleGroupOptions = checkRuleGroupOptions.concat(
        ruleGroupResult?.value?.data?.listAvailableRuleGroups.checkRuleGroups.map((checkRuleGroup: CheckRuleGroup) => ({
          label: checkRuleGroup.groupName,
          value: checkRuleGroup.groupName,
        }))
      );
      setCheckRuleGroupsList(checkRuleGroupOptions);
    }
  };

  const updateEvaluationRule = async (): Promise<void> => {
    const { data, errors } = await editRuleById({
      variables: {
        input: {
          id: stateMachine.context.id,
          merchantId: hookWhoAmI.companyId?.toString(),
          ruleGroup: stateMachine.context.ruleGroup,
          status: stateMachine.context.status,
          endDate: stateMachine.context.status === STATUS_TYPES.INACTIVE ? new Date() : null,
        },
      },
      onError(err) {
        setEditRuleError(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    if (errors) {
      setEditRuleError(TOAST_ERR_MESSAGES_NO_PAGE(errors[0].message));
    } else if (data) {
      hookShowToast('Rule updated successfully.');
      setIsConfirmationModalOpen(false);
      send({ type: 'EditRule.save' });

      refreshRuleManager();
    }
  };

  const changeSelectedRuleGroup = (newRuleGroup: SelectOption): void => {
    send({ type: ':ruleGroup', ruleGroup: newRuleGroup.value });
  };

  const changeSelectedRuleStatus = (newRuleStatus: SelectOption): void => {
    send({ type: ':status', status: newRuleStatus.value });
  };

  // Get the values for Step One dropdowns
  useEffect(() => {
    getBackendValues();
  }, []);

  return {
    stateMachine,
    send,
    errorMessage,
    existingBrandName,
    checkRuleGroupsList,
    statusOptions,
    changeSelectedRuleGroup,
    changeSelectedRuleStatus,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    updateEvaluationRule,
    editRuleError,
    editRuleByIdLoading,
  };
};
