import { parseInt } from 'lodash';
import React, { createContext, useMemo, useState, useEffect, ReactNode } from 'react';

import { ISideMenuState } from './types';

interface ISideMenuContext {
  sideMenuState: ISideMenuState;
  toggleSideMenuItem: (itemId: string | number, level: number, collapsable: boolean) => void;
  toggleCollapse: () => void;
  isCollapsed: boolean;
}

interface ISideMenuProviderProps {
  toggleCollapse: () => void;
  isCollapsed: boolean;
}

const STORAGE_KEY = 'fintelSideMenuState';

export const SideMenuContext = createContext<ISideMenuContext | undefined>(undefined);

export const SideMenuProvider: React.FC<ISideMenuProviderProps & { children: ReactNode }> = ({
  children,
  isCollapsed,
  toggleCollapse,
}) => {
  const [sideMenuState, setSideMenuState] = useState<ISideMenuState>(() => {
    const stored = localStorage.getItem(STORAGE_KEY);
    return stored ? JSON.parse(stored) : {};
  });

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(sideMenuState));
  }, [sideMenuState]);

  const toggleSideMenuItem = (itemId: string | number, level: number, collapsable: boolean): void => {
    setSideMenuState((prevOpenItems) => {
      const newOpenItems: ISideMenuState = { ...prevOpenItems };

      // Don't need to toggle not collapsed menu items if they opened
      if (!collapsable) {
        newOpenItems[level] = itemId;
      }

      // Toggle collapsed menu items if the menu item is collapsable
      if (collapsable) {
        if (newOpenItems[level] === itemId) {
          delete newOpenItems[level];
        } else {
          newOpenItems[level] = itemId;
        }
      }

      // Check and close all nested menu items if opened
      Object.keys(newOpenItems).forEach((key) => {
        const keyAsNumber = parseInt(key);

        if (keyAsNumber > level) delete newOpenItems[keyAsNumber];
      });

      return newOpenItems;
    });
  };

  const contextValue = useMemo(
    () => ({
      sideMenuState,
      toggleSideMenuItem,
      toggleCollapse,
      isCollapsed,
    }),
    [isCollapsed, toggleCollapse, sideMenuState]
  );

  return <SideMenuContext.Provider value={contextValue}>{children}</SideMenuContext.Provider>;
};
