import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { colors, fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

interface IFaqButtonContainerProps {
  isCollapsed: boolean;
}

export const FaqButtonContainer = styled.a<IFaqButtonContainerProps>`
  display: flex;
  background: ${colors.color2};
  color: ${colors.color4};
  text-decoration: none;
  border-radius: 2rem;
  cursor: pointer;
  ${fonts.gotham.H16M}
  justify-content: center;
  align-items: center;
  width: ${({ isCollapsed }) => (isCollapsed ? `${toRem(40)}` : `${toRem(110)}`)};
  height: ${({ isCollapsed }) => (isCollapsed ? `${toRem(40)}` : `${toRem(50)}`)};
  margin-bottom: 1rem;

  &:hover {
    background: ${colors.color8};
    text-decoration: underline;
  }

  svg {
    margin-right: ${({ isCollapsed }) => (isCollapsed ? '0' : '0.5rem')};
  }
`;

export const FaqIcon = styled(FontAwesomeIcon)`
  height: ${toRem(24)};
`;
