import { gql } from '@apollo/client';

export const SHOW_MERCHANT = gql`
  query companies($input: ShowCompanyInput) {
    showCompany(input: $input) {
      id
      companyName
      activityStatus
      features
      program {
        id
      }
      accountStatus
    }
  }
`;
