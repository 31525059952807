import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AD_TYPES,
  ERROR_TYPES,
  MERCHANT_PREFIX,
  path,
  REGEX_VALIDATORS,
  URL_STATUSES,
  USER_TYPES_ID,
} from '../../../../utils';
import { useToast, useUpload, useUserInfo } from '../../../../hooks';
import { UPDATE_AD } from '../graphql/mutation';
import { AD_OPTIONS, SHOW_AD } from '../graphql/queries';
import { GET_CAMPAIGN_PRODUCT } from '../../NewAd/graphql/queries';
import { useValidation } from '../../../../utils/validation';
import { DUPLICATE_NAME_ERROR, END_DATE_ERROR, INVALID_PID, INVALID_PID_ERROR, TOAST_MESSAGES } from '../../utils';
import { Permission } from '../../../../entities';
import { useProgramsApi } from '../../../../api/hooks/useProgramsApi';
import { IProgramTrackingSettings } from 'api/types';
import { findQueryParamsDuplicates, interpolate } from 'helpers';
import en from '../locales/en.json';

type Option = {
  label: string;
  value: string;
};

type ImageFile = {
  file?: File;
  alt: string;
  height: number;
  width: number;
};

export const useEditAd = (permissionsCodeList: string[] = []) => {
  const { hookShowToast } = useToast();
  const location = useLocation();
  const upload = useUpload();

  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();
  const vali = useValidation();

  // display states
  const [hideAdvancedOptions, setHideAdvancedOptions] = useState(false);
  const [startCalendarOpen, setStartCalendarOpen] = useState(false);
  const [endCalendarOpen, setEndCalendarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [campaignModal, setCampaignModal] = useState(false);
  const [getCampaignProduct] = useLazyQuery(GET_CAMPAIGN_PRODUCT);
  const [campaignProduct, setCampaignProduct] = useState({ productId: '', productName: '' });

  // info that doesn't show up
  const [programId, setProgramId] = useState('');
  const [adType, setAdType] = useState('');

  // basic info
  const [adId, setAdId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [createdDate, setCreatedDate] = useState<Date>();
  const [lastUpdated, setLastUpdated] = useState<Date>();

  // editable details
  const [adName, setAdName] = useState('');
  const [status, setStatus] = useState<Option>();
  const [statusOptions, setStatusOptions] = useState<Option[]>([]);
  const [campaign, setCampaign] = useState<Option>();
  const [campaignOptions, setCampaignOptions] = useState<Option[]>([]);
  const [description, setDescription] = useState('');

  // type specific info
  const [defaultLandingPageURL, setDefaultLandingPageURL] = useState('');
  const [altText, setAltText] = useState('');
  const [adModal, setAdModal] = useState(false);
  const [adContent, setAdContent] = useState('');
  const [jsOnly, setIsJsOnly] = useState(false);
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [assetFile, setAssetFile] = useState<File>();
  const [imageFile, setImageFile] = useState<ImageFile>({
    file: undefined,
    alt: '',
    height: 0,
    width: 0,
  });

  // advanced options
  const [products, setProducts] = useState<Option>();
  const [productsOptions, setProductsOptions] = useState<Option[]>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [tagIds, setTagIds] = useState([]);
  const [tags, setTags] = useState<Option[]>([]);
  const [tagOptions, setTagOptions] = useState<Option[]>([]);
  const [language, setLanguage] = useState<Option>();
  const [languageOptions, setLanguageOptions] = useState<Option[]>([]);
  const [urlPlaceholder, setUrlPlaceholder] = useState('https://www.fintelconnect.com/?');

  // validation
  const [editAdErrors, setEditAdErrors] = useState<{ [key: string]: string }>({});
  const [defaultUrlStatus, setDefaultUrlStatus] = useState('');
  const [defaultUrlError, setDefaultUrlError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [today, setToday] = useState<Date>(new Date());
  const [secondRender, setSecondRender] = useState(false);

  const [adErrorMessage, setAdErrorMessage] = useState('');
  const [errorBanner, setErrorBanner] = useState<boolean>(false);

  const [programTrackingSettings, setProgramTrackingSettings] = useState<IProgramTrackingSettings | null>(null);

  // queries
  const [getExistingAd, { loading: getExistingAdLoading }] = useLazyQuery(SHOW_AD);
  const [editAd, { loading: updateAdLoading }] = useMutation(UPDATE_AD);

  const [getOptions, { loading: getOptionsLoading }] = useLazyQuery(AD_OPTIONS);

  const { fetchTrackingSettingsByProgramId } = useProgramsApi();

  const fetchCampaignProduct = async (value: Option) => {
    const { data } = await getCampaignProduct({
      variables: {
        input: {
          campaignId: value.value,
        },
      },
      fetchPolicy: 'no-cache',
    });
    let productId;
    let productName;
    if (data) {
      productId = data?.getCampaignProduct?.productId;
      if (productId === 'null') productId = '';
      productName = data?.getCampaignProduct?.productName;
    }
    return { productId, productName };
  };

  const setCampaignHandler = async (value: Option) => {
    const { productId, productName } = await fetchCampaignProduct(value);

    // set the campaign product field as always.
    setCampaignProduct({ productId, productName });
    setCampaign(value);
  };

  const setCampaignOnLoad = async (value: Option) => {
    const { productId, productName } = await fetchCampaignProduct(value);
    // set the campaign product field as always.
    setCampaignProduct({ productId, productName });
    setCampaign(value);
  };

  // gets ad here
  const getAdHandler = async () => {
    setLoading(true);

    const { data } = await getExistingAd({
      variables: {
        input: {
          id: location.state.adId,
        },
      },
      fetchPolicy: 'no-cache',
    });
    setAdId(location.state.adId);
    setCompanyName(data.showAd.merchantName);
    setProgramId(data.showAd.programId);
    setAdType(data.showAd.adType);
    setAdName(data.showAd.adName);
    setDescription(data.showAd.description);
    setDefaultLandingPageURL(data.showAd.defaultLandingPage);
    setCreatedDate(new Date(data.showAd.createdAt));
    setLastUpdated(new Date(data.showAd.updatedAt || data.showAd.createdAt));
    if (data.showAd.altText) setAltText(data.showAd.altText);
    if (data.showAd.adStatus) setStatus({ label: data.showAd.adStatus, value: data.showAd.adStatus });
    if (data.showAd.campaignId) {
      setCampaign({ label: data.showAd.campaignName, value: data.showAd.campaignId });
      setCampaignOnLoad({ value: data.showAd.campaignId, label: data.showAd.campaignName } as Option);
    }
    if (data.showAd.productName && data.showAd.productId) {
      setProducts({ label: data.showAd.productName, value: data.showAd.productId });
    } else {
      setProducts({ label: 'No Product', value: '' });
    }
    if (data.showAd.language) setLanguage({ label: data.showAd.language, value: data.showAd.language });
    if (data.showAd.startDate) setStartDate(new Date(data.showAd.startDate));
    if (data.showAd.endDate) setEndDate(new Date(data.showAd.endDate));
    if (data.showAd.height) setHeight(data.showAd.height);
    if (data.showAd.width) setWidth(data.showAd.width);
    if (data.showAd.adContent) setAdContent(data.showAd.adContent);
    if (data.showAd.isJavascript) setIsJsOnly(data.showAd.isJavascript);
    if (data.showAd.tagIds !== null) setTagIds(data.showAd.tagIds);
    if (data.showAd.fileAsset) setAssetFile(data.showAd.fileAsset);
    if (location.state.campaignId && location.state.campaignName)
      setCampaign({ label: location.state.campaignName, value: location.state.campaginId });

    setLoading(false);
  };

  const getTags = () => {
    if (tagIds.length > 0) {
      const tempTags: { label: string; value: string }[] = [];
      tagOptions.map((tag: { label: string; value: string }) => {
        if (tagIds.find((tagId: any) => tagId === tag.value)) {
          tempTags.push(tag);
        }
      });
      setTags(tempTags);
    }
  };

  const getOptionsHandler = async () => {
    const { data } = await getOptions({
      variables: {
        input: {
          programId,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.getAdOptions?.merchant?.companyUrl) setUrlPlaceholder(data.getAdOptions.merchant.companyUrl);

    if (data?.getAdOptions?.adStatusTypes) {
      setStatusOptions(
        data.getAdOptions.adStatusTypes.map((item: any) => ({
          label: item.type,
          value: item.type,
        }))
      );
    }

    // Removed the no-product select option
    if (data?.getAdOptions?.products) {
      setProductsOptions([
        { label: 'No Product', value: '' },
        ...data.getAdOptions.products.map((item: any) => ({
          label: item.name,
          value: item.id,
        })),
      ]);
    }

    if (data?.getAdOptions?.languages) {
      setLanguageOptions(
        data.getAdOptions.languages.map((item: any) => ({
          label: item.language,
          value: item.language,
        }))
      );
    }

    if (data?.getAdOptions?.campaigns) {
      setCampaignOptions([
        { label: '', value: '' },
        ...data.getAdOptions.campaigns.map((item: any) => ({
          label: item.name,
          value: item.id,
        })),
      ]);
    }

    if (data?.getAdOptions?.tags) {
      setTagOptions(
        data.getAdOptions.tags.map((item: any) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  };

  const setProductHandler = (value: Option) => {
    setProducts(value);
  };

  const setLanguageHandler = (value: Option) => {
    setLanguage(value);
  };

  const setStatusHandler = (value: Option) => {
    setStatus(value);
  };

  // create
  const setCampaignAfterCreationHandler = async (value: Option, lang: Option) => {
    setCampaign(value);
    setLanguage(lang);
    const { data } = await getCampaignProduct({
      variables: {
        input: {
          campaignId: value.value,
        },
      },
      fetchPolicy: 'no-cache',
    });
    let productId;
    let productName;
    if (data) {
      productId = data?.getCampaignProduct?.productId;
      productName = data?.getCampaignProduct?.productName;
    }
    setCampaignProduct({ productId, productName });
    setCampaignOptions([...campaignOptions, value]);
  };

  const setTagsHandler = (value: Option[]) => {
    setTags(value);
  };

  const setDefaultLandingPageHandler = (e: any) => {
    setDefaultLandingPageURL(e?.target ? e.target.value : e);
  };

  const setDescriptionHandler = (e: any) => {
    setDescription(e?.target ? e.target.value : e);
  };

  const setAdNameHandler = (e: any) => {
    setAdName(e?.target ? e.target.value : e);
  };

  const setAltTextHandler = (e: any) => {
    setAltText(e?.target ? e.target.value : e);
  };

  const setOpenAdModalHandler = () => {
    setAdModal(true);
  };

  const setCloseAdModalHandler = () => {
    setAdModal(false);
  };

  const setAdContentHandler = (e: any) => {
    setAdContent(e?.target ? e.target.value : e);
  };

  const setHeightHandler = (e: any) => {
    setHeight(e?.target ? e.target.value : e);
  };

  const setWidthHandler = (e: any) => {
    setWidth(e?.target ? e.target.value : e);
  };

  const onApplyStartCalendarHandler = (start: Date) => {
    setStartDate(start);
    setStartCalendarOpen(false);
  };

  const onCancelStartCalendarHandler = () => {
    setStartCalendarOpen(false);
  };

  const onOpenStartCalendarHandler = () => {
    setStartCalendarOpen(true);
  };

  const onApplyEndCalendarHandler = (end: Date) => {
    setEndDate(end);
    setEndCalendarOpen(false);
  };

  const onCancelEndCalendarHandler = () => {
    setEndCalendarOpen(false);
    setEndDate(undefined);
  };

  const onOpenEndCalendarHandler = () => {
    setEndCalendarOpen(true);
  };

  const setJsOnlyHandler = () => {
    setIsJsOnly(!jsOnly);
  };

  const isParamsDuplicatesLandingPage = (url: string, onError: (message: string) => void): boolean => {
    try {
      const queryParamDuplicates = findQueryParamsDuplicates(url, programTrackingSettings?.urlQueryString || '');

      if (queryParamDuplicates.length > 0) {
        onError(
          interpolate(en.errors.validation.landingPageUrl.queryParamDuplicates, {
            duplicates: queryParamDuplicates.join(', '),
          })
        );
        return true;
      }

      return false;
    } catch (err) {
      console.error(`Error in parsing the URL: ${url}`);
      return false;
    }
  };

  const validateWebsite = () => {
    if (defaultLandingPageURL !== '') {
      if (
        isParamsDuplicatesLandingPage(defaultLandingPageURL, (message: string) => {
          setDefaultUrlError(message);
          setDefaultUrlStatus(URL_STATUSES.INVALID_WEBSITE.STATUS);
        })
      ) {
        return;
      }

      vali.validateUrlStatus(defaultLandingPageURL, setDefaultUrlStatus);
      vali.renderUrlCheck(defaultUrlStatus, setDefaultUrlError, true);
    }
  };

  const globalValues: { [key: string]: string } = {
    adName,
    status: status?.label || '',
    description,
    language: language?.label || '',
    startDate: startDate?.toString() || '',
    endDate: endDate?.toString() || '',
    productId: products?.label || '',
    campaignProductId: campaignProduct?.productId || '',
  };

  const globalFields = {
    adName: ERROR_TYPES.NOT_EMPTY,
    status: ERROR_TYPES.SELECTION_REQUIRED,
    description: ERROR_TYPES.NOT_EMPTY,
    language: ERROR_TYPES.SELECTION_REQUIRED,
    endDate: ERROR_TYPES.END_DATE,
    productId: ERROR_TYPES.PRODUCT_EMPTY,
  };

  const bannerValues: { [key: string]: string } = {
    image: adContent || '',
    altText,
    specialCharacters: altText,
  };

  const bannerFields = {
    image: ERROR_TYPES.NOT_EMPTY,
    altText: ERROR_TYPES.NOT_EMPTY,
    specialCharacters: REGEX_VALIDATORS.LETTERS_NUMBERS_ONLY.REGEX,
  };

  const htmlValues: { [key: string]: string } = {
    htmlCode: adContent,
    htmlWidth: width,
    htmlHeight: height,
  };

  const htmlFields = {
    htmlCode: ERROR_TYPES.NOT_EMPTY,
    htmlWidth: REGEX_VALIDATORS.NUMBERS_ONLY.REGEX,
    htmlHeight: REGEX_VALIDATORS.NUMBERS_ONLY.REGEX,
  };

  const copy = (toCopy: { [key: string]: any }) => JSON.parse(JSON.stringify(toCopy));

  const handleValidation = () => {
    let noErrors = true;
    const allValues = copy(globalValues);
    const allFields = copy(globalFields);

    if (defaultLandingPageURL !== '' && defaultLandingPageURL !== null) {
      validateWebsite();
      if (
        defaultUrlError !== '' &&
        defaultUrlStatus !== URL_STATUSES.ACTIVE_WEBSITE.STATUS &&
        defaultUrlStatus !== URL_STATUSES.INACTIVE_WEBSITE.STATUS &&
        defaultUrlStatus !== URL_STATUSES.UNSAFE_WEBSITE.STATUS
      ) {
        noErrors = false;
      }
    }
    if (endDate !== undefined) {
      if (endDate < today) {
        setEndDateError(END_DATE_ERROR);
        noErrors = false;
      } else {
        setEndDateError('');
      }
    }
    if (adType === AD_TYPES.BANNER) {
      Object.assign(allValues, copy(bannerValues));
      Object.assign(allFields, bannerFields);
    } else if (adType === AD_TYPES.HTML) {
      Object.assign(allValues, copy(htmlValues));
      Object.assign(allFields, htmlFields);
    }
    return vali.validateAll(allValues, allFields, setEditAdErrors, secondRender) && noErrors;
  };

  const onSuccessHandler = () => {
    setErrorBanner(false);
    setAdErrorMessage('');
    hookShowToast(TOAST_MESSAGES.SUCCESS);
    if (
      hookWhoAmI.userTypeId === USER_TYPES_ID.MERCHANT ||
      hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT
    ) {
      navigate(`${MERCHANT_PREFIX}${path.adSearch.href}`);
    } else {
      navigate(path.adSearch.href);
    }
  };

  // create new ad here
  const editAdHandler = async () => {
    setSecondRender(true);
    const validated = handleValidation();
    if (!validated) return;

    let link;
    let imgHeight;
    let imgWidth;

    if (adType === AD_TYPES.BANNER && imageFile.file) {
      const result = await upload.hookUploadImageFile(imageFile.file);
      if (!result.url) {
        return;
      }

      link = result.url;
      imgHeight = imageFile.height.toString();
      imgWidth = imageFile.width.toString();
    }

    const formattedTags = tags.map((tag) => tag?.label);
    let productId = null;
    let localCampaignId = null;
    if (products?.value !== undefined) productId = products?.value === '' ? null : products?.value;
    if (campaign?.value !== '') localCampaignId = campaign?.value;
    const { data, errors } = await editAd({
      variables: {
        input: {
          adName,
          description,
          defaultLandingPage: defaultLandingPageURL,
          startDate: startDate || '',
          endDate: endDate || '',
          altText,
          tags: formattedTags,
          adSize: {
            width: imgWidth || width,
            height: imgHeight || height,
          },
          adContent: link || adContent,
          language: language?.value,
          adStatus: status?.value,
          productId,
          campaignId: localCampaignId,
          id: adId,
          programId,
          adType,
          isJavascript: jsOnly,
        },
      },
    });

    if (errors) {
      setErrorBanner(true);
      for (let i = 0; i < errors.length; i += 1) {
        if (errors[i].message === 'invalid_pid') {
          setAdErrorMessage(INVALID_PID_ERROR);
        } else {
          setAdErrorMessage(DUPLICATE_NAME_ERROR(adName));
          break;
        }
      }
      return;
    }

    if (data) {
      if (assetFile !== undefined) await upload.hookUploadZipFile(assetFile, data.editAd.ad.id);
      onSuccessHandler();
    }
  };

  const onAssetFileUploadHandler = (file: any) => {
    if (file instanceof File) setAssetFile(file);
  };

  const onUploadHandler = (file: any) => {
    if (file instanceof File) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const image: ImageFile = {
          file,
          alt: altText,
          height: img.naturalHeight,
          width: img.naturalWidth,
        };
        setImageFile(image);
      };
    }
  };

  const cancelButtonHandler = () => {
    if (location?.state?.from) {
      navigate(location.state.from, {
        state: {
          ...location.state,
          from: `${hookWhoAmI.companyId ? `${MERCHANT_PREFIX}` : ''}${path.editAd.href}`,
        },
      });
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (secondRender) {
      handleValidation();
    }
  }, [secondRender, startDate, endDate]);

  useEffect(() => {
    if (defaultLandingPageURL === '') {
      setDefaultUrlError('');
    }
    vali.validateUrlStatus(defaultLandingPageURL, setDefaultUrlStatus);
  }, [defaultLandingPageURL]);

  useEffect(() => {
    setLoading(true);
    getOptionsHandler();
    setLoading(false);
  }, [campaign]);

  useEffect(() => {
    setLoading(true);
    getTags();
    setLoading(false);
  }, [tagOptions]);

  useEffect(() => {
    setLoading(true);
    getAdHandler();
    setLoading(false);
    setToday(new Date(today.getFullYear(), today.getMonth(), today.getDate()));
  }, []);

  useEffect(() => {
    const fetchTrackingSettings = async (): Promise<void> => {
      try {
        const trackingSettings = await fetchTrackingSettingsByProgramId({ id: programId });
        setProgramTrackingSettings(trackingSettings);
      } catch (error) {
        setProgramTrackingSettings(null);
      }
    };

    setLoading(true);
    getOptionsHandler();
    fetchTrackingSettings();
    setLoading(false);
  }, [programId]);

  return {
    hookLoading: getExistingAdLoading || loading || updateAdLoading || getOptionsLoading,

    hookAdId: adId,
    hookCompanyName: companyName,
    hookCreatedDate: createdDate,
    hookLastUpdated: lastUpdated,
    hookProgramId: programId,

    hookAdType: adType,
    hookAdName: adName,
    hookCampaign: campaign,
    hookCampaignProduct: campaignProduct,
    hookAdStatus: status,
    hookDescription: description,
    hookLandingPage: defaultLandingPageURL,
    hookProduct: products,
    hookLanguage: language,
    hookTags: tags,
    hookAltText: altText,
    hookHeight: height,
    hookWidth: width,
    hookAdContent: adContent,
    hookJsOnly: jsOnly,
    hookAssetFile: assetFile,

    hookLanguageOptions: languageOptions,
    hookCampaignOptions: campaignOptions,
    hookProductOptions: productsOptions,
    hookStatusOptions: statusOptions,
    hookTagOptions: tagOptions,

    hookSetProducts: setProductHandler,
    hookSetDefaultLandingPageUrl: setDefaultLandingPageHandler,
    hookSetStatus: setStatusHandler,
    hookSetLanguage: setLanguageHandler,
    hookSetCampaign: setCampaignHandler,
    hookSetDescription: setDescriptionHandler,
    hookSetAdName: setAdNameHandler,
    hookSetAltText: setAltTextHandler,
    hookSetHeight: setHeightHandler,
    hookSetWidth: setWidthHandler,
    hookSetAdContent: setAdContentHandler,
    hookSetTags: setTagsHandler,
    hookSetJsOnly: setJsOnlyHandler,
    hookUrlPlaceholder: urlPlaceholder,

    hookStartDate: startDate,
    hookStartCalendarOpen: startCalendarOpen,
    hookOnOpenStartCalendar: onOpenStartCalendarHandler,
    hookOnApplyStartCalendar: onApplyStartCalendarHandler,
    hookOnCancelStartCalendar: onCancelStartCalendarHandler,

    hookEndDate: endDate,
    hookEndCalendarOpen: endCalendarOpen,
    hookOnOpenEndCalendar: onOpenEndCalendarHandler,
    hookOnApplyEndCalendar: onApplyEndCalendarHandler,
    hookOnCancelEndCalendar: onCancelEndCalendarHandler,

    hookOnSave: editAdHandler,
    hookOnCancel: cancelButtonHandler,
    hookErrorBanner: errorBanner,
    hookAdErrorMessage: adErrorMessage,

    hookHideOptions: hideAdvancedOptions,
    hookSetHideOptions: setHideAdvancedOptions,

    hookUploadImage: onUploadHandler,
    hookOnAssetFileUpload: onAssetFileUploadHandler,
    hookCompanyId: hookWhoAmI.companyId,

    hookValidate: handleValidation,
    hookValidateWebsite: validateWebsite,
    hookEditAdErrors: editAdErrors,
    hookDefaultUrlError: defaultUrlError,
    hookEndDateError: endDateError,

    hookCampaignModal: campaignModal,
    hookSetCampaignModal: setCampaignModal,

    hookAdModal: adModal,
    hookSetOpenAdModal: setOpenAdModalHandler,
    hookSetCloseAdModal: setCloseAdModalHandler,

    hookSetCampaignAfterCreation: setCampaignAfterCreationHandler,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
