import styled from 'styled-components';

import { colors, fonts } from '../../../styles/theme';
import { Tooltip } from '../../Tooltip';

type IconProps = {
  readonly checked: boolean;
  readonly disabled: boolean;
  readonly theme?: string;
};

type LabelStyledProps = {
  readonly theme?: string;
};

export const WrapperStyled = styled.label`
  display: flex;
  align-items: center;
`;

export const InputStyled = styled.input`
  display: none;
`;

export const LabelStyled = styled.span<LabelStyledProps>`
  display: inline-block;
  color: ${({ theme }) => (theme === 'secondary' ? colors.color28 : colors.color1)};
  ${fonts.lato.H14};
`;

export const IconStyled = styled.span<IconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  margin-right: 0.5rem;

  border: ${({ theme, checked }) => {
    switch (theme) {
      case 'secondary':
        return `1px solid ${checked ? colors.color2 : colors.color19};`;
      case 'tertiary':
        return `1px solid ${checked ? colors.color2 : colors.color19};`;
      default:
        return `1px solid ${checked ? colors.color12 : colors.color19};`;
    }
  }};
  background-color: ${({ theme, checked }) => {
    switch (theme) {
      case 'secondary':
        return `${checked ? colors.color2 : colors.color4};`;
      case 'tertiary':
        return `${checked ? colors.color2 : colors.color4};`;
      default:
        return `${checked ? colors.color12 : colors.color4};`;
    }
  }};

  color: ${colors.color4};
  font-size: 0.8rem;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.5s ease;
`;

export const TooltipStyled = styled(Tooltip)`
  height: auto;

  div {
    margin-left: 2px;
  }
`;
