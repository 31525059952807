import React from 'react';
import { Checkbox } from 'components';

import { CellWrapper } from '../../styles';

export const Header = (checked: boolean, onChange: (state: boolean) => void, isReadOnly: boolean) => (
  <CellWrapper>
    <Checkbox checked={checked} onChange={onChange} disabled={isReadOnly} />
  </CellWrapper>
);
