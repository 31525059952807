import { Spacer } from 'components';
import AddCustomTextComponent from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddCustomTextComponent';
import { useAddEvaluationRule } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/hooks/useAddEvaluationRule';
import * as Styled from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/styles';
import React from 'react';

export const TextEligibility = ({ hook }: { hook: ReturnType<typeof useAddEvaluationRule> }): JSX.Element => (
  <>
    <Spacer size="medium" direction="vertical" />

    <Styled.HeaderInfoText>
      Indicate here any additional value (word or phrase) that must be found on the page in order for the rule to be
      applied. You can enter up to five values, in which case the rule will be applied when at least one of these values
      are found.
    </Styled.HeaderInfoText>

    <Spacer size="medium" direction="vertical" />

    <AddCustomTextComponent
      inputLabelText="Custom Text Value"
      inputTooltipText="Please make sure the values are unique"
      textList={hook.eligibilityCustomTextList}
      inputPlaceholderText="Text value to find"
      errors={hook.textEligibilityErrors}
      onBlur={() => hook.onBlurCheck(false)}
      onChange={(id, value) => hook.updateCustomTextValue(id, value, false)}
      removeTextHandler={(id) => hook.removeCustomTextById(id, false)}
      addTextHandler={() => hook.addNewCustomText(false)}
      isAddButtonEnabled={hook.canAddMoreCustomText}
      addButtonText="Add Text Value"
      customInputWidth="60vw"
      customInfoText="Enter one or more Custom Text Values (words or phrases) for the rule to evaluate on a page(s)."
    />
  </>
);
