import { gql } from '@apollo/client';

export const GET_TRACKING_URL_LIST = gql`
  query getTrackingUrlList($input: TrackingUrlsListInput!) {
    getAllTrackingUrls(input: $input) {
      trackingUrlList {
        id
        url
        category
        checkType
        lastActive
        status
      }
    }
  }
`;
