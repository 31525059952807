import React, { ReactElement } from 'react';

import { imageList } from '../../../../utils';
import { useSideMenu } from '../../hooks/useSideMenu';

import { LogoContainer } from './SideMenuLogo.styles';

const SideMenuLogo = (): ReactElement => {
  const { isCollapsed } = useSideMenu();

  const logoSrc = isCollapsed ? imageList.logoSmall.src : imageList.logoFull.src;
  const logoAlt = imageList.logoFull.alt;

  return (
    <LogoContainer isCollapsed={isCollapsed}>
      <img src={logoSrc} alt={logoAlt} />
    </LogoContainer>
  );
};

export default SideMenuLogo;
