import { faCircleQuestion, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import * as Styled from './styles';

type TooltipProps = {
  text: string | React.ReactNode;
  className?: string;
  width?: number;
  icon?: IconDefinition;
  iconColor?: string;
  extraMarginLeft?: number;
};

export const Tooltip = ({ text, className, width, icon, iconColor, extraMarginLeft }: TooltipProps): JSX.Element => (
  <Styled.WrapperStyled className={className}>
    <Styled.IconWrapperStyled>
      <FontAwesomeIcon icon={icon === undefined ? faCircleQuestion : icon} color={iconColor} />
    </Styled.IconWrapperStyled>
    <Styled.InvisibleBridge />
    <Styled.BalloonStyled style={{ marginLeft: extraMarginLeft }} extraMarginLeft={extraMarginLeft} width={width}>
      {text}
    </Styled.BalloonStyled>
  </Styled.WrapperStyled>
);
