import React, { ChangeEvent, ReactElement } from 'react';

import { useFintelCheckSettings } from '../../../../../hooks/useFintelCheckSettings';
import ToolSettingsTabItem from '../../../../ToolSettingsTabItem/ToolSettingsTabItem.component';
import { ToolSettings } from '../../../../../types';
import en from '../../../../../locales/en.json';

import { ControlsContainer, InputControl } from './BrandNameSetting.styles';

const BrandNameSetting = (): ReactElement => {
  const { validationResult, toolSettings, isReadOnlyPermissions, isPageLoading, updateToolSetting } =
    useFintelCheckSettings();

  return (
    <ToolSettingsTabItem
      title={en.settings.tabs.check.items.brandName.title}
      description={en.settings.tabs.check.items.brandName.description}
    >
      <ControlsContainer>
        <InputControl
          type="text"
          value={toolSettings?.brandName ?? ''}
          error={validationResult?.errors?.brandName ?? ''}
          placeholder={en.settings.tabs.check.items.brandName.input.placeholder}
          disabled={isReadOnlyPermissions || isPageLoading}
          onChange={(e: ChangeEvent<HTMLInputElement>) => updateToolSetting(ToolSettings.BrandName, e.target.value)}
        />
      </ControlsContainer>
    </ToolSettingsTabItem>
  );
};

export default BrandNameSetting;
