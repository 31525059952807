import { gql } from '@apollo/client';

export const GET_FINTEL_CHECK_SETTINGS = gql`
  query getFintelCheckSettings($input: FintelCheckSettingsInput!) {
    getFintelCheckSettings(input: $input) {
      settings {
        id
        merchantId
        frequencySettings
        brandName
        publisherSettings
        checkAllDomains
        crawlDepthSettings
        trackingDomainDaySetting
        trackingDomainDayNumberSetting
        enabled
        dayToRun
        urlCheckType
        timesToWait
        blacklistDomains
        brandName
      }
    }
  }
`;
