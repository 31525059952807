import { useState, useEffect } from 'react';
import {
  optionMaker,
  dateFormatter,
  checksMaker,
  USER_TYPES,
  CARD_NETWORK,
  ERROR_TYPES,
  PRODUCT_CATEGORIES,
  nonAlphanumericChars,
} from 'utils';
import { useValidation } from 'utils/validation';
import { USER } from 'pages/Merchants/Program/ProductCatalog/EditProduct/enums';

type ProductFeedSection = {
  validUntil: Date;
  cardNetwork: [string];
  userTypes: [string];
  annualFee: string;
  monthlyFee: string;
  signupBonus: string;
  supplementaryCardFee: string;
  balanceTransferIntroRate: string;
  balanceTransferRegular: string;
  interestRate: string;
  rewardsEarningRate: string;
  annualInterestRate: string;
  balanceTransferIntroFee: string;
  balanceTransferFee: string;
  cashAdvanceFee: string;
  foreignCurrencyConversion: string;
  dishonouredPaymentFee: string;
  gracePeriod: string;
  marketingItems: [string];
  legalReferenceItems: [object];
  legalReference: string;
  promoInterestRate: string;
  transactionFeeATM: string;
  transactionFeeETR: string;
  transactionFeeInternal: string;
  freeTransactionVolume: string;
  closingBalanceToWaiveMonthlyFee: string;
  noMinimumBalance: boolean;
  term: string;
  redeemable: string;
  insurer: string;
  minimumAccountBalance: string;
  managementFee: string;
  tradeCost: string;
  numberOfFreeMonthlyTrade: string;
  mer: string;
  coverageAmount: string;
  guaranteedPremiums: string;
  exclusionItems: [string];

  productUsageGuidelines: string;
  productWelcomeOffers: string;
  minimumOpenDeposit: string;
  interestPaid: string;
  interestPaidDays: string;
  interestCalculated: string;
  interestCalculatedDays: string;
  bankingProductInsurance: string;
  apyTiers: string;
  apyRate: string;
  mortgageType: string;
  openMortgageType: string;
  convertibleMortgageType: string;
  preapprovalMortgageType: string;
  mortgageRateType: string;
  mortgageTypeDiscountedRate: string;
  mortgageAprRate: string;
  mortgageTermYear: string;

  monthlyFeesDetails: string;
  balanceTransferRegularDetails: string;
  interestRateDetails: string;
  AnnualInterestRateDetails: string;
  bankingMinimumBalanceRate: string;
};
type fieldsType = { [key: string]: any };

export const useProductFeed = (
  feedSection: ProductFeedSection,
  setIsUpdateDisabled: any,
  productCategory: any,
  geoAvalibility: string[],
  loading: any,
  geoStatesHanlder: (value: React.ChangeEvent<HTMLTextAreaElement>) => void
) => {
  const vali = useValidation();
  const enableUpdate = () => setIsUpdateDisabled(false);
  const [usageValue, setUsageValue] = useState(feedSection.productUsageGuidelines);
  const [validUntil, setValidUntil] = useState(dateFormatter(new Date(feedSection.validUntil)));
  const [isCalendar, setIsCalendar] = useState(false);
  const [monthlyFee, setMonthlyFee] = useState(feedSection.monthlyFee);
  const [monthlyDetails, setMonthlyDetails] = useState(feedSection.monthlyFeesDetails);
  const [closingBalance, setClosingBalance] = useState(feedSection.closingBalanceToWaiveMonthlyFee);
  const [apy, setApy] = useState(feedSection.apyRate);
  const [apyDetails, setApyDetails] = useState(feedSection.apyTiers);
  const [interestRate, setInterestRate] = useState(feedSection.interestRate);
  const [interestDetails, setInterestDetails] = useState(feedSection.interestRateDetails);

  const [interestCalculated, setInterestCalculated] = useState<SelectOption>();
  const [interestCalculatedDays, setInterestCalculatedDays] = useState(feedSection.interestCalculatedDays);
  const [interestPaid, setInterestPaid] = useState<SelectOption>();
  const [interestPaidDays, setInterestPaidDays] = useState(feedSection.interestPaidDays);

  const [promoRate, setPromoRate] = useState(feedSection.promoInterestRate);
  const [minimumOpenDeposit, setOpenDeposit] = useState(feedSection.minimumOpenDeposit);
  const [transAtm, setTransAtm] = useState(feedSection.transactionFeeATM);
  const [etr, setEtr] = useState(feedSection.transactionFeeETR);
  const [transInternal, setInternal] = useState(feedSection.transactionFeeInternal);
  const [minBalance, setMinBalance] = useState(
    productCategory === 'Banking' ? feedSection.bankingMinimumBalanceRate : feedSection.minimumAccountBalance
  );
  const [minChecked, setMinChecked] = useState(feedSection.noMinimumBalance);
  const [marketingItems, setMarketingItems] = useState(['']);
  const [legalReferenceItems, setLegalReferenceItems] = useState<any[]>([{ name: '', description: '' }]);
  const [legal, setLegal] = useState(feedSection.legalReference);
  const [welcome, setWelcome] = useState(feedSection.productWelcomeOffers);
  const [insurance, setInsurance] = useState(feedSection.bankingProductInsurance);
  const [term, setTerm] = useState(feedSection.term);
  const [coverage, setCoverage] = useState(feedSection.coverageAmount);
  const [guaranteedPremiums, setGuaranteedPremiums] = useState(feedSection.guaranteedPremiums);
  const [exclusionItems, setExclusionItems] = useState(['', '', '', '']);
  const [balanceIntro, setBalanceIntro] = useState(feedSection.balanceTransferIntroFee);
  const [balanceFee, setBalanceFee] = useState(feedSection.balanceTransferFee);
  const [cashAdvanceFee, setCashAdvanceFee] = useState(feedSection.cashAdvanceFee);
  const [FCC, setFCC] = useState(feedSection.foreignCurrencyConversion);
  const [dishonouredPaymentFee, setDishonouredFee] = useState(feedSection.dishonouredPaymentFee);
  const [gracePeriod, setGracePeriod] = useState(feedSection.gracePeriod);
  const [rewards, setRewards] = useState(feedSection.rewardsEarningRate);
  const [annualRate, setAnnualRate] = useState(feedSection.annualInterestRate);
  const [annualDetails, setAnnualDetails] = useState(feedSection.AnnualInterestRateDetails);
  const [balanceTransferRegular, setBalanceRegular] = useState(feedSection.balanceTransferRegular);
  const [balanceTransferRegularDetails, setBalanceTransferDetails] = useState(
    feedSection.balanceTransferRegularDetails
  );
  const [signupBonus, setBonus] = useState(feedSection.signupBonus);
  const [annualFee, setAnnualFee] = useState(feedSection.annualFee);
  const [cardFee, setCardFee] = useState(feedSection.supplementaryCardFee);
  const [introRate, setIntroRate] = useState(feedSection.balanceTransferIntroRate);
  const [userTypes, setUserTypes] = useState(checksMaker(USER_TYPES, feedSection.userTypes));
  const [cardNetwork, setCardNetwork] = useState(checksMaker(CARD_NETWORK, feedSection.cardNetwork));

  const [mortgageTerm, setMortgageTerm] = useState(feedSection.mortgageTermYear);
  const [mortgageType, setMortgageType] = useState<SelectOption>();
  const [open, setOpen] = useState<SelectOption>();
  const [convertible, setConvertible] = useState<SelectOption>();
  const [pre, setPre] = useState<SelectOption>();
  const [mortgageRateType, setMortgageRateType] = useState<SelectOption>();

  const [apr, setApr] = useState(feedSection.mortgageAprRate);
  const [selectTerm, setSelectTerm] = useState(optionMaker(term));
  const [redeemable, setRedeemable] = useState(optionMaker(feedSection.redeemable));
  const [insurer, setInsurer] = useState(feedSection.insurer);
  const [manFees, setManFees] = useState(feedSection.managementFee);
  const [tradeCosts, setTradeCosts] = useState(feedSection.tradeCost);
  const [monthlyTrades, setMonthlyTrades] = useState(feedSection.numberOfFreeMonthlyTrade);
  const [mer, setMer] = useState(feedSection.mer);
  const [free, setFree] = useState(feedSection.freeTransactionVolume);

  const [secondRender, setSecondRender] = useState(false);
  const [newProductErrors, setNewProductErrors] = useState<Record<string, string>>({});

  const values = {
    monthlyFee,
    closingBalance,
    apy,
    interestRate,
    interestCalculatedDays,
    interestPaidDays,
    promoRate,
    minimumOpenDeposit,
    transAtm,
    etr,
    transInternal,
    minBalance,
    term,
    mortgageTerm,
    apr,
    annualFee,
    cardFee,
    introRate,
    balanceTransferRegular,
    annualRate,
    balanceIntro,
    balanceFee,
    cashAdvanceFee,
    FCC,
    free,
    dishonouredPaymentFee,
    gracePeriod,
    manFees,
    tradeCosts,
    monthlyTrades,
    mer,
    coverage,
    guaranteedPremiums,
  };

  const fields: fieldsType = {
    balanceFee: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    monthlyFee: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    closingBalance: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    apy: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    interestRate: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    interestCalculatedDays: ERROR_TYPES.SELECTION_REQUIRED,
    interestPaidDays: ERROR_TYPES.SELECTION_REQUIRED,
    promoRate: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    minimumOpenDeposit: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    guaranteedPremiums: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    transAtm: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    etr: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    transInternal: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    minBalance: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    term: ERROR_TYPES.SELECTION_REQUIRED,
    mortgageTerm: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    apr: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    annualFee: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    cardFee: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    introRate: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    balanceTransferRegular: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    annualRate: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    balanceIntro: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    cashAdvanceFee: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    FCC: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    free: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    dishonouredPaymentFee: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    gracePeriod: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    manFees: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    tradeCosts: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    monthlyTrades: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    mer: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    coverage: ERROR_TYPES.IS_DOLLAR_AMOUNT,
  };

  const handleValidation = () => {
    const nonEmptyValues: { [key: string]: string } = Object.entries(values)
      .filter(([_, value]) => typeof value === 'string' && value !== '')
      .reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {} as { [key: string]: string }
      );

    const filteredKeys = Object.keys(nonEmptyValues);

    const filterNonEmptyField: fieldsType = Object.keys(fields)
      .filter((key: string) => filteredKeys.includes(key))
      .reduce((acc, key) => {
        acc[key] = fields[key];
        return acc;
      }, {} as fieldsType);

    return vali.validateAll(nonEmptyValues, filterNonEmptyField, setNewProductErrors, secondRender);
  };

  const handleMonthlyTrades = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setMonthlyTrades(newValue);
    enableUpdate();
  };

  const handleFree = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setFree(newValue);
    enableUpdate();
  };

  const handleMer = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setMer(newValue);
    enableUpdate();
  };

  const handleInsurer = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setInsurer(newValue);
    enableUpdate();
  };

  const handleManFees = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setManFees(newValue);
    enableUpdate();
  };

  const handleTradeCosts = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setTradeCosts(newValue);
    enableUpdate();
  };

  const handleSelectTerm = (value: any) => {
    const newValue = value.replace(nonAlphanumericChars, '');
    setSelectTerm(newValue);
    setTerm(newValue.label);
    enableUpdate();
  };

  const handleRedeemable = (value: any) => {
    const newValue = value.replace(nonAlphanumericChars, '');
    setRedeemable(newValue);
    enableUpdate();
  };

  const handleRateType = (value: any) => {
    const newValue = value.replace(nonAlphanumericChars, '');
    setMortgageRateType(newValue);
    enableUpdate();
  };

  const handleMortgageTerm = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setMortgageTerm(newValue);
    enableUpdate();
  };

  const handleApr = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setApr(newValue);
    enableUpdate();
  };

  const handleOpen = (value: any) => {
    const newValue = value.replace(nonAlphanumericChars, '');
    setOpen(newValue);
    enableUpdate();
  };

  const handleConvertible = (value: any) => {
    const newValue = value.replace(nonAlphanumericChars, '');
    setConvertible(newValue);
    enableUpdate();
  };

  const handlePre = (value: any) => {
    const newValue = value.replace(nonAlphanumericChars, '');
    setPre(newValue);
    enableUpdate();
  };

  const handleMortgageType = (value: any) => {
    const newValue = value.replace(nonAlphanumericChars, '');
    setMortgageType(newValue);
    enableUpdate();
  };

  const handleChangeChecks = (index: any, listType: any) => {
    const newList = listType === USER ? [...userTypes] : [...cardNetwork];
    newList[index] = { ...newList[index], checked: !newList[index].checked };

    enableUpdate();
    if (listType === USER) {
      setUserTypes(newList);
    } else {
      setCardNetwork(newList);
    }
  };

  const handleXButton = (index: number) => {
    const newList = [...marketingItems];
    newList.splice(index, 1);
    setMarketingItems(newList);

    enableUpdate();
  };

  const handleLegalReferenceXButton = (index: number) => {
    const newList = [...legalReferenceItems];
    newList.splice(index, 1);
    setLegalReferenceItems(newList);

    enableUpdate();
  };

  const handleAnnualFee = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setAnnualFee(newValue);
    enableUpdate();
  };

  const handleCardFee = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setCardFee(newValue);
    enableUpdate();
  };

  const handleIntroRate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setIntroRate(newValue);
    enableUpdate();
  };

  const handleBalanceRegular = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setBalanceRegular(newValue);
    enableUpdate();
  };

  const handleRegularTransferDetails = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setBalanceTransferDetails(newValue);
    enableUpdate();
  };

  const handleSignup = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setBonus(newValue);
    enableUpdate();
  };

  const handleRewards = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setRewards(newValue);
    enableUpdate();
  };

  const handleAnnualRate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setAnnualRate(newValue);
    enableUpdate();
  };

  const handleAnnualDetails = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setAnnualDetails(newValue);
    enableUpdate();
  };

  const handleSetExclusion = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const newList = [...exclusionItems]; // create a new copy of the array
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    newList[index] = newValue; // update the value at the specified index
    setExclusionItems(newList);
  };

  const handleBalanceIntro = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setBalanceIntro(newValue);
    enableUpdate();
  };

  const handleBalanceFee = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setBalanceFee(newValue);
    enableUpdate();
  };

  const handleCashAdvanceFee = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setCashAdvanceFee(newValue);
    enableUpdate();
  };

  const handleFCC = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setFCC(newValue);
    enableUpdate();
  };

  const handleDishnoured = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setDishonouredFee(newValue);
    enableUpdate();
  };

  const handleGrace = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setGracePeriod(newValue);
    enableUpdate();
  };
  const handleTerm = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setTerm(newValue);
    enableUpdate();
  };

  const handleCoverage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setCoverage(newValue);
    enableUpdate();
  };

  const handleGuaranteed = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setGuaranteedPremiums(newValue);
    enableUpdate();
  };

  const handleLegal = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setLegal(newValue);
    enableUpdate();
  };

  const handleWelcome = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setWelcome(newValue);
    enableUpdate();
  };

  const handleInsurance = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setInsurance(newValue);
    enableUpdate();
  };

  const handleSetMarketingItems = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const newList = [...marketingItems]; // create a new copy of the array
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    newList[index] = newValue; // update the value at the specified index
    setMarketingItems(newList);
    enableUpdate();
  };

  const handleAddMarketingItems = () => {
    const newList = [...marketingItems];
    newList.push('');
    setMarketingItems(newList);
  };

  const handleAddLegalReferenceItems = () => {
    if (legalReferenceItems && legalReferenceItems.length >= 10) {
      // eslint-disable-next-line no-alert
      alert('The maximum limit is 10 legal references');
      return;
    }
    const newList = [...legalReferenceItems];
    newList.push({ name: '', description: '' });
    setLegalReferenceItems(newList);
  };

  const handleSetLegalReferenceItems = (value: string, index: number, field: 'name' | 'description') => {
    // Define a regular expression to match any character that is NOT a letter, number, or allowed special character.
    // This will ensure that only alphanumeric characters and specific symbols are kept in the input.
    let newValue = value.replace(nonAlphanumericChars, '');

    if (index > 10) {
      return;
    }

    if (field === 'name') {
      newValue = newValue.substring(0, 49);
    }
    const newList = [...legalReferenceItems];
    if (typeof newList[index] !== 'object' || newList[index] === null) {
      newList[index] = { name: '', description: legal };
      newList[index][field] = newValue;
    } else {
      newList[index][field] = newValue;
    }

    setLegalReferenceItems(newList);
    enableUpdate();
  };

  const handleMinBalanceChecked = () => {
    setMinChecked(!minChecked);
    enableUpdate();
  };

  const handleMinBalance = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setMinBalance(newValue);
    enableUpdate();
  };

  const handlePromoRate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setPromoRate(newValue);
    enableUpdate();
  };

  const handleOpenDeposit = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setOpenDeposit(newValue);
    enableUpdate();
  };

  const handleTransAtm = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setTransAtm(newValue);
    enableUpdate();
  };

  const handleEtr = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setEtr(newValue);
    enableUpdate();
  };

  const handleInternal = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setInternal(newValue);
    enableUpdate();
  };

  const handleCalculated = (value: any) => {
    const newValue = value.replace(nonAlphanumericChars, '');
    setInterestCalculated(newValue);
    if (newValue.label !== 'Other') setInterestCalculatedDays('');
    enableUpdate();
  };

  const handleCalculatedDays = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setInterestCalculatedDays(newValue);
    enableUpdate();
  };

  const handleInterestPaid = (value: any) => {
    const newValue = value.replace(nonAlphanumericChars, '');
    setInterestPaid(newValue);
    if (newValue.label !== 'Other') setInterestPaidDays('');
    enableUpdate();
  };

  const handleInterestPaidDays = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setInterestPaidDays(newValue);
    enableUpdate();
  };

  const handleApy = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setApy(newValue);
    enableUpdate();
  };

  const handleApyDetails = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setApyDetails(newValue);
    enableUpdate();
  };

  const handleInterest = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setInterestRate(newValue);
    enableUpdate();
  };

  const handleInterestDetails = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setInterestDetails(newValue);
    enableUpdate();
  };

  const handleApply = (date: Date) => {
    setValidUntil(dateFormatter(date));
    setIsCalendar(false);
    enableUpdate();
  };

  const handleCancelCalendar = () => {
    setIsCalendar(false);
  };

  const handleShowCalendar = () => {
    setIsCalendar(true);
  };

  const handleUsageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setUsageValue(newValue);
    enableUpdate();
  };

  const handleMonthlyFee = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setMonthlyFee(newValue);
    enableUpdate();
  };

  const handleMonthlyDetails = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setMonthlyDetails(newValue);
    enableUpdate();
  };

  const handleClosingBalance = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value.replace(nonAlphanumericChars, '');
    setClosingBalance(newValue);
    enableUpdate();
  };

  useEffect(() => {
    let newList;

    if (feedSection.marketingItems) {
      newList = feedSection.marketingItems;
      if ([PRODUCT_CATEGORIES.INVESTMENTS, PRODUCT_CATEGORIES.INSURANCE].includes(productCategory)) {
        while (newList?.length < 6) {
          newList.push('');
        }
      }
    } else {
      newList = [''];
      if ([PRODUCT_CATEGORIES.INVESTMENTS, PRODUCT_CATEGORIES.INSURANCE].includes(productCategory)) {
        newList = ['', '', '', '', '', ''];
      }
    }

    const newListLF = [{ name: '', description: legal }]; // Initialize with an empty object

    setMarketingItems(newList);
    if (feedSection.legalReferenceItems && feedSection.legalReferenceItems.length > 0) {
      setLegalReferenceItems(feedSection.legalReferenceItems);
    } else {
      setLegalReferenceItems([{ name: '', description: '' }]);
    }

    if (feedSection.validUntil) {
      setValidUntil(dateFormatter(new Date(feedSection.validUntil)));
    } else {
      setValidUntil('');
    }
    if (feedSection.exclusionItems) {
      setExclusionItems(feedSection.exclusionItems);
    }

    if (feedSection.interestCalculated) setInterestCalculated(optionMaker(feedSection.interestCalculated));
    if (feedSection.interestPaid) setInterestPaid(optionMaker(feedSection.interestPaid));
    if (feedSection.mortgageType) setMortgageType(optionMaker(feedSection.mortgageType));
    if (feedSection.openMortgageType) setOpen(optionMaker(feedSection.openMortgageType));
    if (feedSection.convertibleMortgageType) setConvertible(optionMaker(feedSection.convertibleMortgageType));
    if (feedSection.preapprovalMortgageType) setPre(optionMaker(feedSection.preapprovalMortgageType));
    if (feedSection.mortgageRateType) setMortgageRateType(optionMaker(feedSection.mortgageRateType));
  }, []);

  const handleUpdateProduct = () => {
    enableUpdate();
    setSecondRender(true);
    if (handleValidation()) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    handleValidation();
  }, [secondRender]);

  return {
    hookChangeApy: handleApy,

    hookChangeApyDetails: handleApyDetails,

    hookChangeInterestRate: handleInterest,

    hookChangeInterestRateDtails: handleInterestDetails,

    hookCancelCalendar: handleCancelCalendar,

    hookUsageChange: handleUsageChange,

    hookApplyCalendar: handleApply,

    hookShowCalendar: handleShowCalendar,

    hookChangeMonthlyFee: handleMonthlyFee,

    hookChangeMonthlyDetails: handleMonthlyDetails,

    hookChangeClosingBalance: handleClosingBalance,

    hookChangeCalculated: handleCalculated,

    hookChangeCalculatedDays: handleCalculatedDays,

    hookChangePaid: handleInterestPaid,

    hookChangePaidDays: handleInterestPaidDays,

    hookChangePromo: handlePromoRate,

    hookChangeMinimumOpen: handleOpenDeposit,

    hookChangeTransFees: handleTransAtm,

    hookChangeTransET: handleEtr,

    hookChangeTransInternal: handleInternal,

    hookChangeMinChecked: handleMinBalanceChecked,

    hookChangeMinBalance: handleMinBalance,

    hookSetMarketingItems: handleSetMarketingItems,
    // lf
    hookSetLegalReferenceItems: handleSetLegalReferenceItems,

    hookAddMarketingItem: handleAddMarketingItems,
    // lf
    hookAddLegalReferenceItem: handleAddLegalReferenceItems,

    hookChangeGeoAval: geoStatesHanlder,

    hookChangeInsurance: handleInsurance,

    hookChangeWelcome: handleWelcome,

    hookChangeLegalValue: handleLegal,

    hookChangeTerm: handleTerm,

    hookChangeCoverage: handleCoverage,

    hookChangeGuaranteed: handleGuaranteed,

    hookChangeExclusion: handleSetExclusion,

    hookChangeBalanceFee: handleBalanceFee,

    hookChangeBalanceIntro: handleBalanceIntro,

    hookHandleX: handleXButton,

    hookHandleLegalReferenceX: handleLegalReferenceXButton,

    hookChangeCash: handleCashAdvanceFee,

    hookChangeDishonoured: handleDishnoured,

    hookChangeFCC: handleFCC,

    hookChangeGrace: handleGrace,

    hookChangeRewards: handleRewards,

    hookChangeAnnualRate: handleAnnualRate,

    hookChangeAnnualDetails: handleAnnualDetails,

    hookChangeRegularDetails: handleRegularTransferDetails,

    hookChangeTransferRegular: handleBalanceRegular,

    hookChangeSignUp: handleSignup,

    hookChangeAnnualFee: handleAnnualFee,

    hookChangeCardFee: handleCardFee,

    hookChangeIntroRate: handleIntroRate,

    hookHandleChangeChecks: handleChangeChecks,

    hookChangeMortgageType: handleMortgageType,

    hookChangePre: handlePre,

    hookChangeOpen: handleOpen,

    hookChangeConvertible: handleConvertible,

    hookChangeMortageTerm: handleMortgageTerm,

    hookChangeApr: handleApr,

    hookChangeRateType: handleRateType,

    hookChangeSelectTerm: handleSelectTerm,

    hookChangeRedeemAble: handleRedeemable,

    hookChangeTrade: handleTradeCosts,

    hookChangeManFees: handleManFees,

    hookChangeInsurer: handleInsurer,

    hookChangeMer: handleMer,

    hookChangeMonthlyTrades: handleMonthlyTrades,

    hookChangeFree: handleFree,

    hookHandleUpdateProduct: handleUpdateProduct,

    hookValidate: handleValidation,

    hookNewProductErrors: newProductErrors,

    hookFree: free,

    hookMer: mer,

    hookMonthlyTrades: monthlyTrades,

    hookInsurer: insurer,

    hookManFees: manFees,

    hookTrade: tradeCosts,

    hookSelectTerm: selectTerm,

    hookRedeemable: redeemable,

    hookMortgageTerm: mortgageTerm,

    hookApr: apr,

    hookRateType: mortgageRateType,

    hookPre: pre,

    hookConvertible: convertible,

    hookOpen: open,

    hookMortgageType: mortgageType,

    hookUserTypes: userTypes,

    hookCardNetwork: cardNetwork,

    hookSignUp: signupBonus,

    hookAnnualFee: annualFee,

    hookCardFee: cardFee,

    hookIntroRate: introRate,

    hookBalanceRegular: balanceTransferRegular,

    hookBalanceDetails: balanceTransferRegularDetails,

    hookRewardsValue: rewards,

    hookAnnualRate: annualRate,

    hookAnnualDetails: annualDetails,

    hookGrace: gracePeriod,

    hookFCC: FCC,

    hookDishonoured: dishonouredPaymentFee,

    hookCash: cashAdvanceFee,

    hookBalanceTransferFee: balanceFee,

    hookBalanceIntro: balanceIntro,

    hookExclusion: exclusionItems,

    hookTerm: term,

    hookCoverage: coverage,

    hookGuaranteed: guaranteedPremiums,

    hookMarketingItems: marketingItems,
    // lf
    hookLegalReferenceItems: legalReferenceItems,

    hookInterestCalculated: interestCalculated,

    hookInterestCalculatedDays: interestCalculatedDays,

    hookInterestPaid: interestPaid,

    hookInterestPaidDays: interestPaidDays,

    hookApy: apy,

    hookApyDetails: apyDetails,

    hookInterestRate: interestRate,

    hookInterestRateDetails: interestDetails,

    hookClosingBalance: closingBalance,

    hookMonthlyFee: monthlyFee,

    hookMonthlyDetails: monthlyDetails,

    hookUsageValue: usageValue,

    hookValidValue: validUntil,

    hookCalendarOpen: isCalendar,

    hookPromo: promoRate,

    hookMinimumOpen: minimumOpenDeposit,

    hookTransAtm: transAtm,

    hookTransET: etr,

    hookTransInternal: transInternal,

    hookMinBalance: minBalance,

    hookMinBalanceChecked: minChecked,

    hookLegalValue: legal,

    hookWelcomeOffer: welcome,

    hookInsurance: insurance,

    hookGeoAval: geoAvalibility?.join(', ') || '',

    hookLoading: loading,
  };
};
