import React, { useId } from 'react';

import * as Styled from './styles';

type RadioProps = {
  name: string;
  value: string | number;
  label?: string;
  onChange: (changeEvent: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  tooltip?: string;
  partial?: 'full' | 'half' | 'none';
  tooltipExtraMarginLeft?: number;
};

export const TooltipRadio = ({
  onChange,
  value,
  name,
  label,
  checked,
  className,
  disabled = false,
  tooltip,
  partial,
  tooltipExtraMarginLeft,
}: RadioProps): JSX.Element => {
  const id = useId();
  return (
    <Styled.WrapperStyled className={className}>
      <input id={id} name={name} value={value} type="radio" onChange={onChange} checked={checked} disabled={disabled} />
      <Styled.BodyStyled disabled={disabled} partial={partial} checked={checked}>
        <Styled.InsideStyled />
      </Styled.BodyStyled>
      {label && (
        <Styled.LabelStyled htmlFor={id}>
          {label} {tooltip && <Styled.TooltipStyled extraMarginLeft={tooltipExtraMarginLeft} text={tooltip} />}
        </Styled.LabelStyled>
      )}
    </Styled.WrapperStyled>
  );
};
