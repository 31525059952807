import { gql } from '@apollo/client';

export const GET_MONITORING_REVIEW_STATUS_DATA = gql`
  query getMonitoringReviewStatusData($input: MonitoringReviewStatusInput!) {
    getMonitoringReviewStatusData(input: $input) {
      statusChanges {
        status
        reviewer
        reviewDate
        note
      }
    }
  }
`;
