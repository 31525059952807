import styled from 'styled-components';

import { colors, fonts } from '../../styles/theme';

type BalloonStyledPropTypes = {
  readonly width?: number;
  readonly extraMarginLeft?: number;
};

type IconProps = {
  readonly color?: string;
};

export const BalloonStyled = styled.div<BalloonStyledPropTypes>`
  position: absolute;
  background-color: ${colors.color1};
  color: ${colors.color4};
  border-radius: 4px;
  line-height: 1.5;
  z-index: 50;
  ${fonts.gotham.H12M}
  bottom: 180%;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  max-width: ${({ width }) => width || '350'}px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  text-transform: none;
  text-align: center;
  padding: 0.5rem;
  white-space: break-spaces;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -0.3rem;
    height: 0px;
    width: 0px;
    border-right: solid 5px transparent;
    border-left: solid 5px transparent;
    border-top: solid 5px ${colors.color1};
    top: 100%;
    left: calc(50% - ${({ extraMarginLeft }) => extraMarginLeft || '5'}px);
    margin-left: 0px;
  }

  &:hover {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
`;

export const InvisibleBridge = styled.div<BalloonStyledPropTypes>`
  position: absolute;
  background-color: transparent;
  z-index: 49;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  height: 40px;

  &:hover {
    ${BalloonStyled} {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }
`;

export const WrapperStyled = styled.div`
  display: inline-flex;
  position: relative;
  height: 100%;
  margin: 0 0 0 4px;

  &:hover {
    ${BalloonStyled} {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
    ${InvisibleBridge} {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }
`;

export const IconWrapperStyled = styled.div<IconProps>`
  color: ${({ color }) => color || colors.color23};
  font-size: 14px;
`;
