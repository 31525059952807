import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
`;

export const SettingsList = styled.div`
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
