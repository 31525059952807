import React, { ReactElement } from 'react';

import en from '../../locales/en.json';

import { InstructionsWrapper, PlaceholderContainer } from './ToolSettingsDisabledPlaceholder.styles';

const ToolSettingsDisabledPlaceholder = (): ReactElement => {
  return (
    <PlaceholderContainer>
      <p>{en.settings.state.disabled.title}</p>
      <InstructionsWrapper>
        <p>{en.settings.state.disabled.instruction}</p>
        <p>{en.settings.state.disabled.description}</p>
      </InstructionsWrapper>
    </PlaceholderContainer>
  );
};

export default ToolSettingsDisabledPlaceholder;
