import React, { ReactElement } from 'react';

import { Button, FixedFooter } from 'components';

import { useFintelCheckSettings } from '../../hooks/useFintelCheckSettings';
import en from '../../locales/en.json';

const ToolSettingsTabFooter = (): ReactElement => {
  const { validationResult, isReadOnlyPermissions, isToolSettingsTouched, toggleUpdateModal } =
    useFintelCheckSettings();

  const handleClickBtn = (): void => {
    toggleUpdateModal(true);
  };

  return (
    <FixedFooter>
      <Button
        onClick={handleClickBtn}
        disabled={isReadOnlyPermissions || !validationResult.isValid || !isToolSettingsTouched}
      >
        {en.settings.footer.buttons.save.label}
      </Button>
    </FixedFooter>
  );
};

export default ToolSettingsTabFooter;
