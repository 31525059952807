import React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Checkbox, LoadingDots, Pagination, ReportPlaceholder,
} from '../../../../../../components';
import * as Styled from './styles';
import { INVITE_PUBLISHERS } from './enum';
import { columns } from '../../contracts';
import type { usePublisherInvitation } from '../../hooks/usePublisherInvitation';

type InvitePublishersTabProps = {
  isReadOnly: boolean
  hook: ReturnType<typeof usePublisherInvitation>
}

const InvitePublishersTab = ({ isReadOnly, hook }: InvitePublishersTabProps) => (
  <Styled.WrapperStyled>
    <Styled.DescriptionStyled>
      {INVITE_PUBLISHERS.DESC.FIRST}
      <br />
      {INVITE_PUBLISHERS.DESC.SECOND}
    </Styled.DescriptionStyled>

    <Styled.FiltersStyled>
      <Styled.InputTextStyled
        placeholder={INVITE_PUBLISHERS.PLACEHOLDER.SEARCH_BY}
        value={hook.hookSearch}
        type="text"
        faIcon={faSearch}
        onChange={hook.hookSetSearch}
        onKeyPress={hook.hookStartSearch}
      />
      <Styled.SelectStyled
        placeholder={INVITE_PUBLISHERS.PLACEHOLDER.SELECT_MEMBER}
        name="member"
        options={INVITE_PUBLISHERS.SELECT.MEMBER}
        onChange={hook.hookSetMemberType}
        selected={hook.hookMemberType}
        isDisabled={hook.hookIsTableLoading}
      />
      <Styled.SelectStyled
        placeholder={INVITE_PUBLISHERS.PLACEHOLDER.PRIMARY_AUDIENCE}
        name="member"
        options={hook.hookPrimaryAudienceOptions}
        onChange={hook.hookSetPrimaryAudience}
        selected={hook.hookPrimaryAudience}
        isDisabled={hook.hookIsTableLoading}
      />
      <Styled.SelectStyled
        placeholder={INVITE_PUBLISHERS.PLACEHOLDER.PRODUCT_CATEGORY}
        name="member"
        options={INVITE_PUBLISHERS.SELECT.PRODUCT_CATEGORY}
        onChange={hook.hookSetProductCategory}
        selected={hook.hookProductCategory}
        isDisabled={hook.hookIsTableLoading}
      />
      <Styled.SelectStyled
        placeholder={INVITE_PUBLISHERS.PLACEHOLDER.PROMOTION_METHOD}
        name="member"
        options={INVITE_PUBLISHERS.SELECT.PROMOTION_METHOD}
        onChange={hook.hookSetPromotionMethod}
        selected={hook.hookPromotionMethod}
        isDisabled={hook.hookIsTableLoading}
      />
      <Styled.LeftSideStyled>
        <Checkbox
          checked={hook.hookChecked}
          onChange={hook.hookSetChecked}
        />
        {INVITE_PUBLISHERS.SHOW_ADVANCED}
      </Styled.LeftSideStyled>
      <Styled.RightSideStyled>
        <Button
          theme="text-only"
          onClick={hook.hookClearFormHandler}
        >
          {INVITE_PUBLISHERS.CLEAR_FORM}
        </Button>
      </Styled.RightSideStyled>
    </Styled.FiltersStyled>
    {hook.hookChecked && (
    <Styled.AdvancedSearchStyled isOpen>
      <Styled.SelectStyled
        placeholder={INVITE_PUBLISHERS.PLACEHOLDER.AVERAGE_MONTHLY}
        name="member"
        options={INVITE_PUBLISHERS.SELECT.AUDIENCE}
        onChange={hook.hookSetAudienceSize}
        selected={hook.hookAudienceSize}
        isDisabled={hook.hookIsTableLoading}
      />
      <Styled.SelectStyled
        placeholder={INVITE_PUBLISHERS.PLACEHOLDER.ACTIVITY}
        name="member"
        options={INVITE_PUBLISHERS.SELECT.ACTIVITY_STATUS}
        onChange={hook.hookSetActivityStatus}
        selected={hook.hookActivityStatus}
        isDisabled={hook.hookIsTableLoading}
      />
      <Styled.SelectStyled
        placeholder={INVITE_PUBLISHERS.PLACEHOLDER.AGE}
        name="member"
        options={hook.hookAgeOptions}
        onChange={hook.hookSetAge}
        selected={hook.hookAge}
        isDisabled={hook.hookIsTableLoading}
      />
      <Styled.SelectStyled
        placeholder={INVITE_PUBLISHERS.PLACEHOLDER.GENDER}
        name="member"
        options={hook.hookGenderOptions}
        onChange={hook.hookSetGender}
        selected={hook.hookGender}
        isDisabled={hook.hookIsTableLoading}
      />
      <Styled.SelectStyled
        placeholder={INVITE_PUBLISHERS.PLACEHOLDER.CREDIT_SCORE}
        name="member"
        options={hook.hookCreditScoreOptions}
        onChange={hook.hookSetCreditScore}
        selected={hook.hookCreditScore}
        isDisabled={hook.hookIsTableLoading}
      />
      <Styled.SelectStyled
        placeholder={INVITE_PUBLISHERS.PLACEHOLDER.AVERAGE_INCOME}
        name="member"
        options={INVITE_PUBLISHERS.SELECT.AVERAGE_INCOME}
        onChange={hook.hookSetAverageIncome}
        selected={hook.hookAverageIncome}
        isDisabled={hook.hookIsTableLoading}
      />
    </Styled.AdvancedSearchStyled>
    )}

    <Styled.SearchRowStyled>
      <Button
        onClick={hook.hookSearchButtonHandler}
        disabled={hook.hookIsTableLoading || hook.hookIsLoading}
        loading={hook.hookIsTableLoading}
        width="120px"
      >
        <FontAwesomeIcon
          icon={faSearch}
          style={{ marginRight: 5 }}
        />
        {' '}
        Search
      </Button>
    </Styled.SearchRowStyled>

    {((hook.hookPublisher && hook?.hookPublisher?.length > 0) || (hook.hookIsTableLoading && hook.hookSearchClicked)) && (
      (hook.hookIsLoading || hook.hookIsTableLoading) ? (
        <LoadingDots />
      ) : (
        <>
          <Styled.TableStyled
            columns={columns(isReadOnly, hook.hookCurrentState)}
            data={hook.hookPublisher}
            sortColumn={hook.hookSortBy}
            onSort={hook.hookSetSortBy}
            isLoading={hook.hookIsTableLoading}
            onRowClick={hook.hookNavgationHandler}
          />
          <Pagination
            total={hook.hookNumberOfPages}
            currentPage={hook.hookCurrentPage}
            onPageChange={hook.hookSetCurrentPage}
          />
        </>
      )
    )}
    {((!hook.hookPublisher || hook.hookPublisher.length < 1) && !(hook.hookIsTableLoading && hook.hookSearchClicked)) && (
    <ReportPlaceholder
      submitted={hook.hookSearchClicked}
      text={hook.hookSearchClicked ? 'Sorry! No results found' : 'Click search to get started'}
      subtext={hook.hookSearchClicked ? "We couldn't find what you're looking for. Please try it again." : ''}
    />
    )}
  </Styled.WrapperStyled>
);

export default InvitePublishersTab;
