import styled from 'styled-components';

import { Button } from 'components';
import { colors, fonts } from 'styles/theme';
import { toRem } from 'utils';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: ${toRem(24)};
  width: ${toRem(518)};
`;

export const ModalTitle = styled.h3`
  ${fonts.gotham.H24M}
  color: ${colors.color1};
`;

export const ModalDescription = styled.p`
  ${fonts.lato.H14}
  color: ${colors.color17};
  margin: 2rem 0;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const SaveBtn = styled(Button)`
  margin-left: 1rem;
`;
