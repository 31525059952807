import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { TOGGLE_MANUAL_URL_MONITORING } from 'pages/Merchants/FintelCheck/FintelCheckManualUrls/graphql/toggleManualUrlMonitoring';
import { useToast, useUserInfo } from 'hooks';
import { GET_FCHECK_SETTINGS } from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/Tabs/Publishers/graphql/queries';

import { Permission } from '../../../../../entities';

export const useManualUrls = (
  permissionsCodeList: string[] = []
): {
  hookNavigate: NavigateFunction;
  hookIsReadOnlyList: boolean[];
  manualUrlsToggle: boolean;
  setManualUrlsToggle: (value: boolean) => void;
  loading: boolean;
  isConfirmationModalOpen: boolean;
  setIsConfirmationModalOpen: (value: boolean) => void;
} => {
  const urlCheckTypeOptions = { manual: 'manual', relevant: 'relevant', both: 'both' };
  const [manualUrlsToggle, setManualUrlsToggle] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const { hookWhoAmI } = useUserInfo();
  const { hookShowToast } = useToast();
  const navigate = useNavigate();

  const [getFintelCheckSettings, { loading: getSettingsLoading }] = useLazyQuery(GET_FCHECK_SETTINGS);
  const [toggleManualUrlMonitoring, { loading: toggleManualUrlMonitoringLoading }] =
    useMutation(TOGGLE_MANUAL_URL_MONITORING);

  const getCheckSettings = async (): Promise<void> => {
    const { data, error } = await getFintelCheckSettings({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI?.companyId),
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (error) {
      hookShowToast('Could not get fintel check settings');
    }
    if (data?.getFintelCheckSettings) {
      /* Will be enabled if the setting is Manual or Both */
      setManualUrlsToggle(
        [urlCheckTypeOptions.both, urlCheckTypeOptions.manual].includes(
          data.getFintelCheckSettings.settings.urlCheckType
        )
      );
    }
  };

  const updateManualUrlsToggle = async (value: boolean): Promise<void> => {
    const { data, errors } = await toggleManualUrlMonitoring({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI?.companyId),
          enabled: value,
        },
      },
    });
    setIsConfirmationModalOpen(false);
    if (errors) {
      hookShowToast('Could not update manual urls toggle');
      return;
    }
    if (data?.toggleManualUrlMonitoring) {
      /* Will be enabled if the setting is Manual or Both */
      setManualUrlsToggle(
        [urlCheckTypeOptions.both, urlCheckTypeOptions.manual].includes(
          data.toggleManualUrlMonitoring.settings.urlCheckType
        )
      );
    }
  };

  const setManualUrlsToggleHandler = (value: boolean): void => {
    updateManualUrlsToggle(value);
  };

  useEffect(() => {
    getCheckSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    hookNavigate: navigate,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),

    manualUrlsToggle,
    setManualUrlsToggle: setManualUrlsToggleHandler,
    loading: toggleManualUrlMonitoringLoading || getSettingsLoading,

    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
  };
};
