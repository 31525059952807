import jwtDecode from 'jwt-decode';
import { useState } from 'react';

import environment from '../config/environment';
import { useActAsContext } from '../context';
import { getCookie, MERCHANT_PREFIX, path, PUBLISHER_PREFIX, USER_TYPES_ID } from '../utils';

type WhoAmI = {
  id: string;
  email: string;
  companyId?: string;
  companyName?: string;
  companyFeatures?: [string];
  activityStatus?: string;
  programId?: string;
  isActingAsUserTypeId?: number;
  userTypeId?: number;
  accountStatus?: string;
};

export const useUserInfo = () => {
  const { actingAsMerchant, actingAsPublisher } = useActAsContext();

  const getUserCookie = () => {
    const userCookie = getCookie(environment.app.cookieName);
    const decrypt: any = userCookie ? jwtDecode(userCookie) : {};

    if (decrypt?.merchantsId && decrypt?.publishersId) {
      decrypt.userLevel = 'admin';
    } else if (decrypt?.merchantsId) {
      decrypt.userLevel = 'merchant';
    } else if (decrypt?.publishersId) {
      decrypt.userLevel = 'publisher';
    }

    return decrypt;
  };

  const getUserPermissions = () => {
    const userPermissions = localStorage.getItem(environment.app.permissionStorageName);
    const decrypt: any = userPermissions ? jwtDecode(userPermissions) : {};

    return decrypt.permissions;
  };

  const whoAmIFn = (): WhoAmI => {
    const userCookie = getUserCookie();

    const user: WhoAmI = {
      id: userCookie.id,
      email: userCookie.email,
    };

    if (!userCookie.merchantsId && !userCookie.publishersId) {
      const pathNow = window.location.pathname;
      const merchantRegex = new RegExp(`^${MERCHANT_PREFIX}/(.*)|^${path.merchantManagementDetail.href}`);
      const publisherRegex = new RegExp(`^${PUBLISHER_PREFIX}/(.*)|^${path.publisherSearchDetail.href}`);

      if (merchantRegex.test(pathNow)) {
        if (actingAsMerchant) {
          user.companyId = actingAsMerchant.id;
          user.companyName = actingAsMerchant.name;
          user.activityStatus = actingAsMerchant.activityStatus;
          user.programId = actingAsMerchant.programId;
          user.isActingAsUserTypeId = USER_TYPES_ID.MERCHANT;
          user.accountStatus = actingAsMerchant.accountStatus;
        }
      }

      if (publisherRegex.test(pathNow)) {
        if (actingAsPublisher) {
          user.companyId = actingAsPublisher.id;
          user.isActingAsUserTypeId = USER_TYPES_ID.PUBLISHER;
          user.companyName = actingAsPublisher.name;
          user.activityStatus = actingAsPublisher.activityStatus;
          user.accountStatus = actingAsPublisher.accountStatus;
        }
      }
    } else if (userCookie.merchantsId) {
      user.companyId = userCookie.merchantsId?.toString();
      user.programId = userCookie.programsId;
      user.companyName = userCookie.companyName;
      user.activityStatus = userCookie.activityStatus;
      user.accountStatus = userCookie.accStatus;
    } else if (userCookie.publishersId) {
      user.companyId = userCookie.publishersId?.toString();
      user.companyName = userCookie.companyName;
      user.activityStatus = userCookie.activityStatus;
      user.accountStatus = userCookie.accStatus;
    }

    return user;
  };

  const [userInfo] = useState<any>(getUserCookie());
  const [userPermissions] = useState<any>(getUserPermissions());

  const getSubSectionsPermissionsBySectionCode = (code: string) => {
    const permissions = userPermissions.filter((item: any) => item.code.includes(`${code}.`));

    return permissions;
  };

  const getPermissionByCode = (code: string) => {
    const section = userPermissions.find((item: any) => item.code === code);
    if (section && !section.code.includes('.')) {
      const list = getSubSectionsPermissionsBySectionCode(code);
      const checkIfOneIsTrue = list.some((item: any) => item.permission === 'read' || item.permission === 'write');

      return {
        ...section,
        permission: section.permission === 'noAccess' && checkIfOneIsTrue ? 'read' : section.permission,
        section: true,
        skip: false,
      };
    }

    if (section && section.code.includes('.')) {
      return {
        ...section,
        section: false,
        skip: false,
      };
    }

    return {
      code,
      skip: true,
    };
  };

  return {
    hookUserInfo: userInfo,
    hookUserPermissions: userPermissions,
    hookGetPermissionByCode: getPermissionByCode,
    hookWhoAmI: whoAmIFn(),
  };
};
