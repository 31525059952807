import * as ICONS from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, ReactElement } from 'react';

import { useSideMenu } from '../../../../hooks/useSideMenu';
import { ISideMenuItem } from '../../../../types';

import {
  NestedMenuItemHeader,
  NestedMenuItemContent,
  NestedMenuItemContainer,
} from './SideMenuNestedExpandable.styles';

interface ISideMenuNestedExpandableProps {
  menuItem: ISideMenuItem;
  itemLevel: number;
  renderNestedMenuItem: (item: ISideMenuItem, itemLevel: number) => ReactElement | null;
}

const SideMenuNestedExpandable: FC<ISideMenuNestedExpandableProps> = ({
  menuItem,
  itemLevel,
  renderNestedMenuItem,
}): ReactElement => {
  const { toggleSideMenuItem, isSideMenuItemOpened, isUnderManagement } = useSideMenu();

  const isOpen = isSideMenuItemOpened(itemLevel, menuItem.id);

  return (
    <NestedMenuItemContainer show={isSideMenuItemOpened(itemLevel, menuItem.id)} key={menuItem.id}>
      <NestedMenuItemHeader
        onClick={() => toggleSideMenuItem(menuItem.id, itemLevel, !!menuItem.subSections)}
        level={itemLevel}
        isUnderManagement={isUnderManagement(menuItem.id)}
        isOpen={isOpen}
      >
        <p>{menuItem.label}</p>
        <FontAwesomeIcon icon={ICONS.faChevronRight} />
      </NestedMenuItemHeader>

      <NestedMenuItemContent key={menuItem.id}>
        {isOpen &&
          menuItem.subSections &&
          menuItem.subSections.map((item: ISideMenuItem) => renderNestedMenuItem(item, itemLevel + 1))}
      </NestedMenuItemContent>
    </NestedMenuItemContainer>
  );
};

export default SideMenuNestedExpandable;
