export type TSummaryDashboardData = {
  checks: {
    total: number;
    urlsMonitored: number;
    urlsIncomplete: number;
    urlsNotApplicable: number;
  };
  passed: {
    total: number;
    firstCheck: number;
    newFinding: number;
    closed: number;
    awaitingInternalAction: number;
    awaitingExternalAction: number;
  };
  failed: {
    total: number;
    firstCheck: number;
    newFinding: number;
    closed: number;
    awaitingInternalAction: number;
    awaitingExternalAction: number;
  };
  incomplete: {
    open: number;
  };
  notApplicable: {
    open: number;
  };
};

export const EmptySummaryDashboardData: TSummaryDashboardData = {
  checks: {
    total: 0,
    urlsMonitored: 0,
    urlsIncomplete: 0,
    urlsNotApplicable: 0,
  },
  passed: {
    total: 0,
    firstCheck: 0,
    newFinding: 0,
    closed: 0,
    awaitingInternalAction: 0,
    awaitingExternalAction: 0,
  },
  failed: {
    total: 0,
    firstCheck: 0,
    newFinding: 0,
    closed: 0,
    awaitingInternalAction: 0,
    awaitingExternalAction: 0,
  },
  incomplete: {
    open: 0,
  },
  notApplicable: {
    open: 0,
  },
};
