import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileDownload, faFolder, faInfoCircle, faPlus,
} from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';
import {
  Button, Calendar, Link, Pagination, PLACEHOLDER, RecordsDropdown, ReportPlaceholder, Select, Spinner, Table,
} from '../../../../components';
import { RECORDS_PER_PAGE_OPTIONS_WITH_150 } from '../../../../utils';
import { initialState, SUMMARY_REPORT_DROPDOWN_ACTIONS } from '../Reducers';
import { STATUS_DEFAULT_OPTIONS, SUMMARY } from '../enums';
import { ChecksDropdown } from '../hooks';
import type { useSummaryReport } from '../hooks';
import { InputCalendar } from '../../../../components/Input/Calendar';

type SummaryReportProps = {
  isMainReport?: boolean
  hook: ReturnType<typeof useSummaryReport>
}

export const SummaryReport = ({ isMainReport = false, hook }: SummaryReportProps) => (
  <>
    <Styled.HeaderStyled>
      <Styled.HeaderLeftStyled>
        <Styled.TitleStyled>{SUMMARY.TITLE}</Styled.TitleStyled>
      </Styled.HeaderLeftStyled>

      <Styled.HeaderRightStyled>
        <Styled.InfoStyled show={isMainReport}>
          <FontAwesomeIcon icon={faInfoCircle} />
          {SUMMARY.MAX_DOWNLOAD}
        </Styled.InfoStyled>
        {!isMainReport && hook.hookContext.zipFile && (
          <Link
            target="_blank"
            to={hook.hookContext.zipFile}
          >
            <Button
              onClick={() => undefined}
            >
              <Styled.IconStyled icon={faFolder} />
              {SUMMARY.DOWNLOAD_ALL}
            </Button>
          </Link>
        )}
        <Button
          theme="tertiary"
          onClick={hook.hookHandleCSV}
          loading={hook.hookCSVLoading}
        >
          <Styled.IconStyled icon={faFileDownload} />
          {SUMMARY.DOWNLOAD_CSV}
        </Button>
      </Styled.HeaderRightStyled>
    </Styled.HeaderStyled>
    <Styled.FilterWrapperStyled>
      <Select
        label="Merchants"
        selected={hook.hookFiltersState.merchant}
        isLoading={hook.hookFilterOptionsLoading}
        isDisabled={hook.hookFilterOptionsLoading || hook.hookGenerateLoading}
        options={hook.hookMerchantOptions}
        onChange={(e: SelectOption) => hook.hookFiltersDispatch(e, SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_MERCHANT)}
      />
      <Select
        label="Status"
        selected={hook.hookFiltersState.status}
        isLoading={hook.hookFilterOptionsLoading}
        isDisabled={hook.hookFilterOptionsLoading || hook.hookGenerateLoading}
        options={STATUS_DEFAULT_OPTIONS}
        onChange={(e: SelectOption) => hook.hookFiltersDispatch(e, SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_STATUS)}
      />

      <InputCalendar
        label="Date Range"
        value={hook.hookSelectedDateRange()}
        onClick={() => hook.setIsCalendarOpen(!hook.isCalendarOpen)}
        isDisabled={hook.hookFilterOptionsLoading}
        width="100%"
      />
      <Select
        label="Publisher Id - Name"
        selected={hook.hookFiltersState.publisher}
        options={hook.hookPublisherOptions}
        isLoading={hook.hookFilterOptionsLoading}
        isDisabled={hook.hookFilterOptionsLoading || hook.hookGenerateLoading}
        onChange={(e: SelectOption) => hook.hookFiltersDispatch(e, SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PUBLISHER)}
      />
      <Select
        label="Product Category"
        selected={hook.hookFiltersState.productCategory}
        options={hook.hookCategoryOptions}
        isLoading={hook.hookFilterOptionsLoading}
        isDisabled={hook.hookFilterOptionsLoading || hook.hookGenerateLoading}
        onChange={(e: SelectOption) => {
          hook.hookUpdateProductOptions(e);
          hook.hookFiltersDispatch(e, SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PRODUCT_CATEGORY);
        }}
      />
      <Select
        label="Product Id - Name"
        selected={hook.hookFiltersState.product}
        options={hook.hookFilteredProductOptions}
        isLoading={hook.hookFilterOptionsLoading}
        isDisabled={hook.hookFilterOptionsLoading || hook.hookGenerateLoading}
        onChange={(e: SelectOption) => hook.hookFiltersDispatch(e, SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PRODUCT)}
      />
      <Calendar
        date={hook.hookDateRangeCal.startDate}
        endDate={hook.hookDateRangeCal.endDate}
        onApply={hook.onChangeDateHandler}
        onCancel={() => hook.setIsCalendarOpen(false)}
        isOpen={hook.isCalendarOpen}
        hasRange
      />
      <Styled.ButtonStyled
        theme="text-only"
        onClick={() => hook.hookClearForm(initialState, SUMMARY_REPORT_DROPDOWN_ACTIONS.CLEAR_FORM)}
      >
        {SUMMARY.CLEAR_FORM}
      </Styled.ButtonStyled>
    </Styled.FilterWrapperStyled>
    <Styled.HeaderStyled>
      <RecordsDropdown
        selected={hook.hookFiltersState.records}
        options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
        onChange={(e: SelectOption) => hook.hookFiltersDispatch(e, SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_RECORDS)}
        currentPage={hook.hookCurrentPage}
        totalRecords={hook.hookTotalRecords}
        isDisabled={hook.hookFilterOptionsLoading || hook.hookGenerateLoading}
      />
      <Button
        onClick={() => hook.hookHandleGenerate()}
        loading={hook.hookGenerateLoading}
        disabled={hook.hookGenerateLoading || hook.hookFilterOptionsLoading}
        width="160px"
      >
        <Styled.IconStyled icon={faPlus} />
        {SUMMARY.GENERATE_REPORT}
      </Button>
    </Styled.HeaderStyled>
    {!hook.hookData?.length && (
      <Styled.PlaceholderBox>
        <ReportPlaceholder
          submitted={hook.hookDataGenerated}
          text={hook.hookDataGenerated ? PLACEHOLDER.TEXT_NO_RESULTS : PLACEHOLDER.TEXT_PENDING}
          subtext={hook.hookDataGenerated ? PLACEHOLDER.SUBTEXT_NO_RESULTS : PLACEHOLDER.SUBTEXT_PENDING}
        />
      </Styled.PlaceholderBox>
    )}
    {(hook.hookData && hook.hookData.length > 0) && (
    <Styled.ReportWrapper>
      <Table
        key={hook.hookTableKey}
        data={hook.hookData}
        columns={hook.hookTableColumns}
        isLoading={hook.hookGenerateLoading}
        sortColumn={hook.hookSortColumn}
        setModalInfo={hook.hookSetContext}
        onSort={hook.hookHandleSort}
      />
      <Pagination
        total={hook.hookTotalPages}
        currentPage={hook.hookCurrentPage}
        onPageChange={hook.hookOnPageChange}
      />
    </Styled.ReportWrapper>
    )}
  </>
);
