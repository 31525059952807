/* eslint-disable max-len */
import { InputText, Select, Spacer, TooltipRadio } from 'components';
import { EligibilityEnum } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/enums';
import { useAddEvaluationRule } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/hooks/useAddEvaluationRule';
import * as Styled from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/styles';
import React from 'react';

export const ProductEligibility = ({ hook }: { hook: ReturnType<typeof useAddEvaluationRule> }): JSX.Element => {
  return (
    <>
      <Spacer size="medium" direction="vertical" />

      <Styled.HeaderInfoText>
        Select one additional value from the below that must be found on the page in order for the rule to be applied.
      </Styled.HeaderInfoText>

      <Spacer size="medium" direction="vertical" />

      <Styled.FlexColumn>
        <Styled.FlexRow>
          <Styled.FlexColumn style={{ justifyContent: 'center' }}>
            <TooltipRadio
              label="Product Name"
              name="productEligibility"
              value={EligibilityEnum.PRODUCT_NAME}
              checked={hook.stateMachine.context.productEligibility === EligibilityEnum.PRODUCT_NAME}
              onChange={hook.changeProductEligibility}
              tooltip="Product Name is pulled from its corresponding Product Catalog Field.  The rule will be applied to a page only if this value is found on the page."
              tooltipExtraMarginLeft={15}
            />
          </Styled.FlexColumn>
          <Styled.FlexColumn>
            <Styled.HeaderInfoText>{hook.stateMachine.context.product?.name}</Styled.HeaderInfoText>
          </Styled.FlexColumn>
        </Styled.FlexRow>

        {hook.eligibilityProductFeedList.length > 0 && (
          <Styled.FlexRow>
            <Styled.FlexColumn style={{ justifyContent: 'center' }}>
              <TooltipRadio
                label="Product Field Term"
                name="productEligibility"
                value={EligibilityEnum.PRODUCT_FIELD_TERM}
                checked={hook.stateMachine.context.productEligibility === EligibilityEnum.PRODUCT_FIELD_TERM}
                onChange={hook.changeProductEligibility}
                tooltip="When selecting Product Field as an eligibility requirement, the rule will be applied to a page only if this value or any commonly used alternatives is found on the page."
              />
            </Styled.FlexColumn>

            <Styled.FlexColumn>
              <Select
                placeholder="Select a product feed term"
                selected={hook.selectedProductFeed}
                options={hook.eligibilityProductFeedList}
                onChange={hook.changeSelectedProductFeed}
                width="20vw"
                required={hook.stateMachine.context.productEligibility === EligibilityEnum.PRODUCT_FIELD_TERM}
                isDisabled={hook.stateMachine.context.productEligibility !== EligibilityEnum.PRODUCT_FIELD_TERM}
              />
            </Styled.FlexColumn>
          </Styled.FlexRow>
        )}
        <Styled.FlexRow>
          <Styled.FlexColumn style={{ justifyContent: 'center' }}>
            <TooltipRadio
              label="Text Value"
              name="productEligibility"
              value={EligibilityEnum.CUSTOM_TEXT}
              checked={hook.stateMachine.context.productEligibility === EligibilityEnum.CUSTOM_TEXT}
              onChange={hook.changeProductEligibility}
              tooltip="When entering a custom word or phrase, the rule will be applied to a page only if this value is found on the page."
              tooltipExtraMarginLeft={45}
            />
          </Styled.FlexColumn>

          <Styled.FlexColumn>
            <InputText
              placeholder="Text Value"
              value={hook.productEligibilityCustomText}
              onChange={hook.changeProductEligibilityCustomText}
              type="text"
              required
              width="20vw"
              disabled={hook.stateMachine.context.productEligibility !== EligibilityEnum.CUSTOM_TEXT}
            />
          </Styled.FlexColumn>
        </Styled.FlexRow>
      </Styled.FlexColumn>
    </>
  );
};
