import React from 'react';
import { AppWrapper, PageTitle, Spacer, Tabs, Toggle } from 'components';
import { DefaultPropsType } from 'types';
import { path } from 'utils';
import FintelCheckUrlBulkUpload from 'pages/Merchants/FintelCheck/FintelCheckManualUrls/Tabs/BulkUpload';
import FintelCheckUrlsList from 'pages/Merchants/FintelCheck/FintelCheckManualUrls/Tabs/UrlsList';
import { useManualUrls } from 'pages/Merchants/FintelCheck/FintelCheckManualUrls/hooks';
import { ConfirmationModal } from 'components/Modal/ConfirmationModal';

import * as Styled from './styles';

type FintelCheckManualUrlsProps = DefaultPropsType;

const FintelCheckManualUrls = ({ permissionsCodeList = [] }: FintelCheckManualUrlsProps): JSX.Element => {
  const { hookIsReadOnlyList, ...hook } = useManualUrls(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <PageTitle>{path.fintelCheckManualUrlsList.name}</PageTitle>

        <Styled.HeaderToggleWrapper>
          <Styled.ToggleLabelStyled>Enable Manual Urls</Styled.ToggleLabelStyled>
          <Spacer direction="horizontal" size="small" />
          <Toggle
            checked={hook.manualUrlsToggle}
            onChange={() => hook.setIsConfirmationModalOpen(true)}
            disabled={isReadOnly || hook.loading}
          />
        </Styled.HeaderToggleWrapper>
      </Styled.HeaderStyled>

      {hook.manualUrlsToggle && (
        <>
          <Spacer direction="vertical" size="medium" />

          <Tabs
            tabNames={['URLs List', 'Bulk Upload']}
            elements={[
              <FintelCheckUrlsList key="URLs List" isReadOnly={isReadOnly} />,
              <FintelCheckUrlBulkUpload key="Bulk Upload" isReadOnly={isReadOnly} />,
            ]}
          />
        </>
      )}

      <ConfirmationModal
        isOpen={hook.isConfirmationModalOpen}
        onConfirmText="Save"
        title="Save Changes"
        desc={`Changes to settings will immediately impact the
          monitoring & reporting on URLs. Are you sure you want to save changes?`}
        onConfirm={() => hook.setManualUrlsToggle(!hook.manualUrlsToggle)}
        onClose={() => hook.setIsConfirmationModalOpen(false)}
        onCloseText="Cancel"
        isLoading={hook.loading}
      />
    </AppWrapper>
  );
};

export default FintelCheckManualUrls;
