/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useToast, useUserInfo } from 'hooks';
import _ from 'lodash';
import { CustomTextType } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddCustomTextComponent';
import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
  EligibilityEnum,
  BrandNameEnum,
} from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/enums';
import { defaultRuleGroupOption } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddMonitoringRule/enums';
import { CheckRuleGroup } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddMonitoringRule/types';
import { RuleStatusSettingsType } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/RuleStatusSettingsComponent/enums';
import { RULE_TYPES } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/enums';
import { CREATE_EVALUATION_RULE } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/graphql/mutations/createEvaluationRule';
import { CHECK_UNIQUE_RULE_NAME } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/graphql/queries/checkUniqueName';
import { LIST_PRODUCTS_AND_CATEGORIES } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/graphql/queries/listAvailableProducts';
import { LIST_AVAILABLE_RULE_GROUPS } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/graphql/queries/listAvailableRuleGroups';
import {
  AddEvaluationRuleProductType,
  IEvaluationRuleHook,
  IndexedObject,
  UseAddEvaluationRuleReturnType,
  SelectedProductCriteriaType,
} from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/types';
import { getFeedValue, humanizeProductFeed } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/utils';
import { GET_FINTEL_CHECK_SETTINGS } from 'pages/Merchants/FintelCheck/FintelCheckToolSettings/components/ToolSettingsTabs/CheckTab/graphql/queries';
import { useEffect, useState, useMemo } from 'react';
import { TOAST_ERR_MESSAGES_NO_PAGE, useDebounce } from 'utils';

export type ListProductCategoriesReturnType = {
  category: string;
  productFields: string[];
  products: AddEvaluationRuleProductType[];
};

export type CriteriaOption = {
  value: string;
  label: string;
  description: string;
  validFor: string[];
};

export const useAddEvaluationRule: IEvaluationRuleHook<UseAddEvaluationRuleReturnType> = (
  stateMachine,
  send,
  refreshRuleManager
) => {
  // Global Constants
  const { hookWhoAmI } = useUserInfo();
  const { hookShowToast } = useToast();
  // Overall Values
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedEligibility, setSelectedEligibility] = useState<EligibilityEnum>(EligibilityEnum.NOT_SELECTED);

  // Step One's State
  const [productCategoryList, setProductCategoryList] = useState<SelectOption[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<AddEvaluationRuleProductType>();
  const [allProductsList, setAllProductsList] = useState<ListProductCategoriesReturnType[]>([]);
  const [availableProducts, setAvailableProducts] = useState<SelectOption[]>([]);
  const [ruleName, setRuleName] = useState<string>('');
  const [ruleNameError, setRuleNameError] = useState<string>('');

  const [selectedCriteria, setSelectedCriteria] = useState<SelectedProductCriteriaType[]>([]);
  const [selectedProductFeed, setSelectedProductFeed] = useState<SelectOption>();
  const [productEligibilityCustomText, setProductEligibilityCustomText] = useState<string>('');

  const [onBlurOn, setOnBlurOn] = useState<boolean>(false);

  const [createRuleError, setCreateRuleError] = useState<string>('');

  // Step Brand Name
  const [brandNameValue, setBrandNameValue] = useState<string>('');
  const [existingBrandName, setExistingBrandName] = useState<string>('');

  // Cancel Modal State
  const [cancelOpen, setCancelOpen] = useState<boolean>(false);

  // Rule Group State
  const [checkRuleGroupsList, setCheckRuleGroupsList] = useState<SelectOption[]>([]);

  // Rule Type State
  const [ruleTypeOptions] = useState<SelectOption[]>([
    { label: 'Evaluate Custom Text', value: RULE_TYPES.EVAL_TEXT },
    { label: 'Evaluate Product Catalog text', value: RULE_TYPES.EVAL_PRODUCT },
  ]);

  // Text Eligibility States
  const [eligibilityCustomTextList, setEligibilityCustomTextList] = useState<CustomTextType[]>([]);
  const [canAddMoreCustomText, setCanAddMoreCustomText] = useState<boolean>(true);
  const [textEligibilityErrors, setTextEligibilityErrors] = useState<IndexedObject<string>>({});
  const [ruleStatusSettings, setRuleStatusSettings] = useState<RuleStatusSettingsType>({
    fieldsRequired: null,
    fieldState: null,
    ruleState: null,
  });

  // Text Criteria States
  const [ruleStatusErrors, setRuleStatusErrors] = useState<IndexedObject<string>>({});
  const [criteriaCustomTextList, setCriteriaCustomTextList] = useState<CustomTextType[]>([]);
  const [textCriteriaErrors, setTextCriteriaErrors] = useState<IndexedObject<string>>({});
  const [canAddMoreCriteriaCustomText, setCanAddMoreCriteriaCustomText] = useState<boolean>(true);

  // Queries & Mutations
  const [checkUniqueName] = useLazyQuery(CHECK_UNIQUE_RULE_NAME);
  const [getFintelCheckSettings] = useLazyQuery(GET_FINTEL_CHECK_SETTINGS);
  const [getProductData, { loading: getProductDataLoading }] = useLazyQuery(LIST_PRODUCTS_AND_CATEGORIES);
  const [createEvaluationRule] = useMutation(CREATE_EVALUATION_RULE);
  const [listAvailableGroupRules] = useLazyQuery(LIST_AVAILABLE_RULE_GROUPS);

  /**
   * Debounced validations
   */
  const debouncedRuleName = useDebounce(ruleName, 800);

  const appendCriteria = (): void => {
    const newCriteria = [...selectedCriteria, { label: '', name: '', value: '', required: false }];
    setSelectedCriteria(newCriteria);
    send({ type: ':productCriteria', productCriteria: newCriteria });
  };

  const removeCriteria = (index: number): void => {
    const newCriteria = selectedCriteria.filter((_, i) => i !== index);
    setSelectedCriteria(newCriteria);
    send({ type: ':productCriteria', productCriteria: newCriteria });
  };

  /*
   * Overall Handlers
   */
  const getBackendValues = async (): Promise<void> => {
    setErrorMessage('');
    const [settingsResult, ruleGroupResult, productResult] = await Promise.allSettled([
      getFintelCheckSettings({
        variables: {
          input: {
            merchantId: Number(hookWhoAmI?.companyId),
          },
        },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(err.message);
        },
      }),
      listAvailableGroupRules({
        variables: {
          input: {
            merchantId: hookWhoAmI?.companyId?.toString(),
          },
        },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(err.message);
        },
      }),
      getProductData({
        variables: {
          input: {
            merchantId: hookWhoAmI?.companyId?.toString(),
          },
        },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(err.message);
        },
      }),
    ]);

    if (settingsResult.status === 'fulfilled' && settingsResult?.value?.data?.getFintelCheckSettings?.settings) {
      const { brandName: brandNameFromSettings } = settingsResult.value.data.getFintelCheckSettings.settings;
      setExistingBrandName(brandNameFromSettings);
      send({ type: ':brandNameType', brandNameType: BrandNameEnum.existing });
      send({ type: ':brandName', brandName: brandNameFromSettings });
    }

    if (
      ruleGroupResult.status === 'fulfilled' &&
      ruleGroupResult?.value?.data?.listAvailableRuleGroups.checkRuleGroups
    ) {
      let checkRuleGroupOptions: SelectOption[] = [defaultRuleGroupOption];
      checkRuleGroupOptions = checkRuleGroupOptions.concat(
        ruleGroupResult?.value?.data?.listAvailableRuleGroups.checkRuleGroups.map((checkRuleGroup: CheckRuleGroup) => ({
          label: checkRuleGroup.groupName,
          value: checkRuleGroup.groupName,
        }))
      );
      setCheckRuleGroupsList(checkRuleGroupOptions);
    }

    if (productResult.status === 'fulfilled' && productResult?.value?.data?.listAvailableProductsForRule?.products) {
      setAllProductsList(productResult.value.data.listAvailableProductsForRule.products);
      setProductCategoryList(
        productResult.value.data.listAvailableProductsForRule.products.map(({ category }: { category: string }) => ({
          label: category,
          value: category,
        }))
      );
    } else {
      setAllProductsList([]);
      setProductCategoryList([]);
    }
  };

  const cancelButtonHandler = (state: boolean): void => {
    setCancelOpen(state);
  };

  /*
   * Cancel Modal Handlers and Logic
   */
  const exitAddRuleModalHandler = (): void => {
    cancelButtonHandler(false);
    send({ type: 'AddEvaluationRule.cancel' });
  };

  /*
   * Step One Handlers & Logic
   */
  const changeRuleName = (changeEvent: React.ChangeEvent<HTMLInputElement>): void => {
    setRuleNameError('');
    setRuleName(changeEvent.target.value);
    send({ type: ':ruleName', ruleName: changeEvent.target.value });
  };

  const changeSelectedRuleGroup = (newRuleGroup: SelectOption): void => {
    send({ type: ':ruleGroup', ruleGroup: newRuleGroup.value });
  };

  const changeSelectedRuleType = (newRuleType: SelectOption): void => {
    send({ type: ':ruleType', ruleType: newRuleType.value });
  };

  const updateAvailableProductsForSelectedCategory = (newProductCategory: SelectOption): void => {
    if (allProductsList) {
      const index = allProductsList.findIndex((category) => category.category === newProductCategory.value);
      if (index === -1) {
        setAvailableProducts([]);
        return;
      }
      const available = allProductsList[index].products
        .filter((product) => product.productCategory === newProductCategory.value)
        .map((product) => ({
          label: product.name ? `${product.customizedProductId} - ${product.name}` : product.customizedProductId,
          value: product.id,
        }));
      setAvailableProducts(available as SelectOption[]);
    }
  };

  const changeSelectedProductCategory = (newSelectedProductCategory: SelectOption): void => {
    updateAvailableProductsForSelectedCategory(newSelectedProductCategory);
    setSelectedProduct(undefined);
    send({ type: ':productCategory', productCategory: newSelectedProductCategory.value });
  };

  const changeSelectedProduct = (newSelectedProduct: SelectOption): void => {
    let product;
    const index = allProductsList.findIndex((category) => category.category === stateMachine.context.productCategory);
    if (index === -1) {
      setSelectedProduct(product);
    } else {
      product = allProductsList[index].products.find((p) => p.id === newSelectedProduct.value);
      setSelectedProduct(product);
    }
    send({ type: ':product', product, selectedProduct: newSelectedProduct });
    setSelectedProductFeed(undefined); // Reset Product Feed
    setSelectedCriteria([]); // Reset Criteria
  };

  const validateRuleUniqueName = async (): Promise<void> => {
    // Error Logic for missing fields
    setErrorMessage('');
    const { data, error } = await checkUniqueName({
      variables: {
        input: {
          merchantId: hookWhoAmI?.companyId?.toString(),
          ruleName: ruleName.trim(),
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    if (error) {
      setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(error.message));
      return;
    }

    if (data?.checkUniqueRuleName?.unique) {
      setRuleNameError('');
      send({ type: ':ruleName', ruleName: data?.checkUniqueRuleName?.ruleName });
      send({ type: ':ruleNameUnique', ruleNameUnique: data?.checkUniqueRuleName?.unique });
    } else {
      setRuleNameError(ERROR_MESSAGES.RULE_NAME_TAKEN);
    }
  };

  const eligibilityProductFeedList: SelectOption[] = useMemo(() => {
    let feedList: SelectOption[] = [];
    if (selectedProduct) {
      if (!selectedProduct.validRuleRequirements || selectedProduct.validRuleRequirements.length <= 0) {
        return [];
      }
      feedList = selectedProduct.validRuleRequirements
        .filter((requirement) => requirement.name !== 'legalReferenceItems')
        .map(
          (requirement): SelectOption => ({ label: humanizeProductFeed(requirement.name), value: requirement.name })
        );
    }
    return feedList;
  }, [selectedProduct]);

  const criteriaProductFeedList: SelectOption[] = useMemo(() => {
    let feedList: SelectOption[] = [];
    if (selectedProduct) {
      if (!selectedProduct.validRuleRequirements || selectedProduct.validRuleRequirements.length <= 0) {
        return [];
      }
      const baseList = selectedProduct.validRuleRequirements
        .filter((requirement) => requirement.name !== 'legalReferenceItems')
        .filter((requirement) => requirement.value)
        .map(
          (requirement): SelectOption => ({ label: humanizeProductFeed(requirement.name), value: requirement.name })
        );
      feedList = [...baseList];
      const legalReferenceItems = selectedProduct.validRuleRequirements.filter(
        (requirement) => requirement.name === 'legalReferenceItems'
      );
      if (legalReferenceItems.length > 0) {
        const legalReferenceItem = legalReferenceItems[0];
        if (legalReferenceItem.values && legalReferenceItem.values.length > 0) {
          const legalReferenceItemOptions = legalReferenceItem.values.map(
            (requirement: { name: string; description: string }): SelectOption => ({
              label: `Legal Reference: ${requirement.name}`,
              value: `legalReferenceItems:${requirement.name}`,
            })
          );
          feedList = [...feedList, ...legalReferenceItemOptions];
        }
      }
    }
    return feedList;
  }, [selectedProduct]);

  const changeProductEligibility = (changeEvent: React.ChangeEvent<HTMLInputElement>): void => {
    switch (changeEvent.target.value) {
      case EligibilityEnum.PRODUCT_NAME: {
        setSelectedProductFeed(undefined);
        send({ type: ':productEligibility', productEligibility: EligibilityEnum.PRODUCT_NAME });
        send({
          type: ':productEligibilityValue',
          productEligibilityValue: stateMachine.context.product?.name || '',
        });
        break;
      }
      case EligibilityEnum.PRODUCT_FIELD_TERM: {
        send({ type: ':productEligibility', productEligibility: EligibilityEnum.PRODUCT_FIELD_TERM });
        send({ type: ':productEligibilityValue', productEligibilityValue: selectedProductFeed?.value || '' });
        break;
      }
      case EligibilityEnum.CUSTOM_TEXT: {
        setSelectedProductFeed(undefined);
        send({ type: ':productEligibility', productEligibility: EligibilityEnum.CUSTOM_TEXT });
        send({ type: ':productEligibilityValue', productEligibilityValue: productEligibilityCustomText });
        break;
      }
      default:
        break;
    }
  };

  const changeSelectedProductFeed = (changeEvent: SelectOption): void => {
    setSelectedProductFeed(changeEvent);
    send({ type: ':productEligibilityValue', productEligibilityValue: changeEvent.value });
  };

  const changeProductEligibilityCustomText = (changeEvent: React.ChangeEvent<HTMLInputElement>): void => {
    setProductEligibilityCustomText(changeEvent.target.value);
    send({ type: ':productEligibilityValue', productEligibilityValue: changeEvent.target.value });
  };

  const changeRuleStatusSettings = (newRuleStatusSettings: RuleStatusSettingsType): void => {
    send({ type: ':ruleStatusSettings', ruleStatusSettings: newRuleStatusSettings });
  };

  const createRule = async (): Promise<void> => {
    setCreateRuleError('');
    const newDate = new Date();
    newDate.setUTCHours(0, 0, 0, 0);
    let newRuleInput;
    if (stateMachine.context.ruleType === RULE_TYPES.EVAL_TEXT) {
      newRuleInput = {
        merchantId: hookWhoAmI?.companyId?.toString() || '',
        ruleName: stateMachine.context.ruleName,
        ruleType: stateMachine.context.ruleType,
        ruleGroup: stateMachine.context.ruleGroup,
        applicableProducts: 'No Specific Products',
        brandName:
          stateMachine.context.brandNameType === BrandNameEnum.existing ? null : stateMachine.context.brandName,
        ruleStatusSettings: stateMachine.context.ruleStatusSettings,
        eligibility: stateMachine.context.textEligibility.map((eligibility) => ({
          type: EligibilityEnum.CUSTOM_TEXT,
          value: eligibility.value,
        })),
        fieldChecks: stateMachine.context.textCriteria.map((criteria) => ({
          fieldName: 'customText', // TODO: change this to EligibilityEnum.CUSTOM_TEXT,
          expectedValue: criteria.value,
        })),
        startDate: newDate,
      };
    } else if (stateMachine.context.ruleType === RULE_TYPES.EVAL_PRODUCT) {
      newRuleInput = {
        merchantId: hookWhoAmI?.companyId?.toString() || '',
        ruleName: stateMachine.context.ruleName,
        ruleType: stateMachine.context.ruleType,
        ruleGroup: stateMachine.context.ruleGroup,
        applicableProducts: stateMachine.context.applicableProducts,
        brandName:
          stateMachine.context.brandNameType === BrandNameEnum.existing ? null : stateMachine.context.brandName,
        productCategory: stateMachine.context.product?.productCategory,
        productId: stateMachine.context.product?.id,
        productName: stateMachine.context.product?.name,
        eligibility: [
          {
            type: stateMachine.context.productEligibility,
            value: stateMachine.context.productEligibilityValue,
          },
        ],
        productFeed: stateMachine.context.productCriteria.map((criteria) => {
          if (criteria.label.indexOf('Legal Reference') === 0) {
            return {
              productFeed: 'legalReferenceItems',
              productFeedData: criteria.label.replace(/^Legal Reference: /, ''), // This is not ideal, but it works for now
              required: criteria.required,
            };
          }
          return {
            productFeed: criteria.value,
            productFeedData: getFeedValue(criteria.value, stateMachine.context.product?.productFeedSection),
            required: criteria.required,
          };
        }),
        startDate: newDate,
      };
    }

    const { errors } = await createEvaluationRule({
      variables: {
        input: newRuleInput,
      },
      onError(err) {
        setCreateRuleError(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    if (errors) {
      setCreateRuleError(TOAST_ERR_MESSAGES_NO_PAGE(errors[0].message));
    } else {
      hookShowToast(SUCCESS_MESSAGES.CREATED_RULE_TOAST);
      exitAddRuleModalHandler();

      // Clear Cache after update as it is no longer valid.
      refreshRuleManager();
    }
  };

  const changeProductCriteria = (index: number, label: string, value: string, required: boolean): void => {
    const criteria = [...selectedCriteria];
    const selectedProductCriteria: SelectedProductCriteriaType = {
      label,
      name: humanizeProductFeed(value),
      value,
      required,
    };
    criteria[index] = selectedProductCriteria;
    setSelectedCriteria(criteria);
    send({ type: ':productCriteria', productCriteria: criteria });
  };

  const changeBrandName = (changeEvent: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = changeEvent.target;

    const isValid = /^[a-zA-Z0-9.,/'"?;:*&%$#@`()\-_\s]*$/.test(value);

    if (!isValid) {
      return;
    }

    setBrandNameValue(value);
    send({ type: ':brandName', brandName: value });
  };

  const changeBrandNameType = (changeEvent: React.ChangeEvent<HTMLInputElement>): void => {
    send({ type: ':brandNameType', brandNameType: changeEvent.target.value });
    send({
      type: ':brandName',
      brandName: changeEvent.target.value === BrandNameEnum.existing ? existingBrandName : brandNameValue,
    });
  };

  /**
   * Validate the custom text values input fields
   * @param {CustomTextType[]} newCustomTextList - The new custom text list
   * @param {boolean} isCriteria - flag to determine if the custom text is for criteria or eligibility
   * @param {boolean} skipEmptyArrayCheck - flag to determine if the empty array check should be skipped
   * @returns {boolean} - The validation result
   */
  const validateTextValuesInputFields = (
    newCustomTextList: CustomTextType[],
    isCriteria?: boolean,
    skipEmptyArrayCheck = false
  ): boolean => {
    const errors: IndexedObject<string> = {};
    const textList = isCriteria ? criteriaCustomTextList : eligibilityCustomTextList;
    const setErrors = isCriteria ? setTextCriteriaErrors : setTextEligibilityErrors;

    const targetCustomTextList = _.size(newCustomTextList) !== 0 || skipEmptyArrayCheck ? newCustomTextList : textList;
    targetCustomTextList.forEach((r) => {
      if (!r.value) {
        errors[r.id] = ERROR_MESSAGES.MISSING_VALUE;
      }
    });

    const uniqueValues = _.uniqBy(targetCustomTextList, 'value');
    if (uniqueValues.length !== targetCustomTextList.length) {
      targetCustomTextList.forEach((r) => {
        if (uniqueValues.filter((u) => u.value === r.value && u.id !== r.id).length) {
          errors[r.id] = errors[r.id] || ERROR_MESSAGES.UNIQUE_VALUE;
        }
      });
    }
    setErrors(errors);

    const hasTextErrors = Object.keys(errors).length > 0;
    send({ type: ':hasTextErrors', hasTextErrors });

    return !hasTextErrors;
  };

  /**
   * Add a new custom text to the list
   * @param {boolean} isCriteria - flag to determine if the custom text is for criteria or eligibility
   */
  const addNewCustomText = (isCriteria?: boolean): void => {
    const textList = isCriteria ? criteriaCustomTextList : eligibilityCustomTextList;
    const setCanAddMore = isCriteria ? setCanAddMoreCriteriaCustomText : setCanAddMoreCustomText;
    const setTextList = isCriteria ? setCriteriaCustomTextList : setEligibilityCustomTextList;

    setCanAddMore(textList.length < 4);

    const newCustomTextList: CustomTextType = {
      id: textList ? textList.reduce((highest, curr) => (highest > curr.id ? highest : curr.id), 0) + 1 : 1,
      value: undefined,
    };

    setTextList([...textList, newCustomTextList]);

    if (!validateTextValuesInputFields([], isCriteria)) {
      setOnBlurOn(true);
    }
  };

  /**
   * Remove a custom text by id
   * @param id - The id of the custom text
   * @param {boolean} isCriteria - flag to determine if the custom text is for criteria or eligibility
   */
  const removeCustomTextById = (id: number, isCriteria?: boolean): void => {
    const textList = isCriteria ? criteriaCustomTextList : eligibilityCustomTextList;
    const newCustomTextListLocal = textList.filter((customText) => customText.id !== id);
    const setCanAddMore = isCriteria ? setCanAddMoreCriteriaCustomText : setCanAddMoreCustomText;
    const setTextList = isCriteria ? setCriteriaCustomTextList : setEligibilityCustomTextList;

    setTextList(newCustomTextListLocal);
    setCanAddMore(true);

    if (newCustomTextListLocal.length >= 5) {
      setCanAddMore(false);
    } else {
      setCanAddMore(true);
    }

    validateTextValuesInputFields(newCustomTextListLocal, isCriteria, newCustomTextListLocal.length === 0);
  };

  const onBlurCheck = (isCriteria = false): void => {
    if (onBlurOn) validateTextValuesInputFields([], isCriteria);
  };

  /**
   * Update the custom text value
   * @param id - id of the custom text to be updated
   * @param value - the new value of the custom text
   * @param {boolean} isCriteria - flag to determine if the custom text is for criteria or eligibility
   */
  const updateCustomTextValue = (
    id: number,
    value: React.ChangeEvent<HTMLInputElement> & { nativeEvent: { inputType?: string } },
    isCriteria?: boolean
  ): void => {
    const errors: IndexedObject<string> = {};
    const setErrors = isCriteria ? setTextCriteriaErrors : setTextEligibilityErrors;
    const setTextList = isCriteria ? setCriteriaCustomTextList : setEligibilityCustomTextList;

    const { inputType } = value.nativeEvent;
    const regex = /^[\w\d.,/`?;:*&#_$%()+@'" -]+$/;
    const regval = regex.test(value.target.value);
    if (!regval && inputType !== 'deleteContentBackward') {
      errors[id] = ERROR_MESSAGES.INVALID_CHAR;
      setErrors(errors);
      return;
    }

    setErrors({});

    const textList = isCriteria ? criteriaCustomTextList : eligibilityCustomTextList;

    const newCustomTextList = textList.map((customText): CustomTextType => {
      if (customText.id === id) {
        return {
          ...customText,
          value: value.target.value,
        };
      }
      return customText;
    });

    validateTextValuesInputFields(newCustomTextList, isCriteria);

    setTextList(newCustomTextList);
  };

  // Rule Status Settings
  const validateRuleStatusSettings = (compareWith: string | null): boolean => {
    const errors: IndexedObject<string> = {};

    // Validates Rule Status values
    if (stateMachine.context.ruleStatusSettings.fieldsRequired === compareWith) {
      errors.fieldsRequired = ERROR_MESSAGES.MISSING_VALUE;
    }
    if (stateMachine.context.ruleStatusSettings.fieldState === compareWith) {
      errors.fieldState = ERROR_MESSAGES.MISSING_VALUE;
    }
    if (stateMachine.context.ruleStatusSettings.ruleState === compareWith) {
      errors.ruleState = ERROR_MESSAGES.MISSING_VALUE;
    }
    setRuleStatusErrors(errors);

    if (errors.fieldsRequired || errors.fieldState || errors.ruleState) {
      return false;
    }
    return true;
  };

  /**
   * State Machine validation for Text Eligiblity custom text
   */
  useEffect(() => {
    if (stateMachine.matches({ AddEvaluationRule: 'TextEligibility' })) {
      send({ type: ':textEligibility', textEligibility: eligibilityCustomTextList });
    }
  }, [eligibilityCustomTextList]);

  /**
   * State Machine validation for Text Criteria custom text
   */
  useEffect(() => {
    if (stateMachine.matches({ AddEvaluationRule: 'TextCriteria' })) {
      send({ type: ':textCriteria', textCriteria: criteriaCustomTextList });
    }
  }, [criteriaCustomTextList]);

  useEffect(() => {
    validateRuleStatusSettings('');
  }, [stateMachine.context.ruleStatusSettings]);

  // Get the values for Step One dropdowns
  useEffect(() => {
    getBackendValues();
  }, []);

  useEffect(() => {
    if (debouncedRuleName) {
      validateRuleUniqueName();
    }
  }, [debouncedRuleName]);

  return {
    stateMachine,
    send,
    // Overall Values
    hookCancelOpen: cancelOpen,
    selectedProduct,
    hookCancelButtonHandler: cancelButtonHandler,
    errorMessage,

    // Step One
    // Rule Name
    changeRuleName,
    ruleName,
    hookRuleNameError: ruleNameError,
    // Product Category
    productCategoryList,
    changeSelectedProductCategory,
    getProductDataLoading,
    // Product
    availableProducts,
    changeSelectedProduct,

    selectedEligibility,
    setSelectedEligibility,

    // Step Two
    // Criteria
    selectedCriteria,
    // Product Feed Select
    eligibilityProductFeedList,
    criteriaProductFeedList,
    selectedProductFeed,
    changeSelectedProductFeed,
    // Product Custm Term
    productEligibilityCustomText,
    changeProductEligibilityCustomText,

    onBlurCheck,

    // Leave Rule
    hookExitAddRuleModalHandler: exitAddRuleModalHandler,

    // Rule Groups
    checkRuleGroupsList,
    changeSelectedRuleGroup,

    // Rule Type
    ruleTypeOptions,
    changeSelectedRuleType,

    // Brand Name
    changeBrandNameType,
    changeBrandName,
    brandNameValue,
    existingBrandName,

    // Text Eligibility
    eligibilityCustomTextList,
    addNewCustomText,
    updateCustomTextValue,
    removeCustomTextById,
    canAddMoreCustomText,
    textEligibilityErrors,

    // Product Eligibility
    changeProductEligibility,

    // Rule Status Settings
    ruleStatusSettings,
    setRuleStatusSettings,
    changeRuleStatusSettings,
    ruleStatusErrors,
    // Text Criteria
    criteriaCustomTextList,
    textCriteriaErrors,
    canAddMoreCriteriaCustomText,

    // Product Criteria
    changeProductCriteria,
    appendCriteria,
    removeCriteria,
    createRule,
    createRuleError,
  };
};
