import React, { ReactElement, useEffect } from 'react';

import { LeavePage, Tabs } from 'components';

import ToolSettingsPageLoader from '../ToolSettingsPageLoader/ToolSettingsPageLoader.component';
import ToolSettingsUpdateModal from '../ToolSettingsUpdateModal/ToolSettingsUpdateModal.component';
import CheckTab from '../ToolSettingsTabs/CheckTab/CheckTab.component';
import { useFintelCheckSettings } from '../../hooks/useFintelCheckSettings';
import en from '../../locales/en.json';

const ToolSettingsContent = (): ReactElement => {
  const { isPageFullyLoaded, isLeavePageModalOpened, fetchToolSettings, handlePageLeave, handlePageStay } =
    useFintelCheckSettings();

  useEffect(() => {
    (async () => {
      await fetchToolSettings();
    })();
  }, []);

  if (!isPageFullyLoaded) {
    return <ToolSettingsPageLoader />;
  }

  const SETTING_TABS = {
    NAMES: [en.settings.tabs.check.label],
    ELEMENTS: [<CheckTab key={en.settings.tabs.check.label} />],
  };

  return (
    <>
      <Tabs tabNames={SETTING_TABS.NAMES} elements={SETTING_TABS.ELEMENTS} />
      <ToolSettingsUpdateModal />
      <LeavePage isOpen={isLeavePageModalOpened} onStay={handlePageStay} onLeave={handlePageLeave} />
    </>
  );
};

export default ToolSettingsContent;
