import React from 'react';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AdGenerateCodeModal,
  AppWrapper, Button, Checkbox, InputText, PageTitle, Pagination, Select, AddAdModal, RecordsDropdown, ReportPlaceholder,
} from '../../../components';
import { Table } from '../../../components/Table';
import {
  path, USER_TYPES_ID,
} from '../../../utils';
import { columns } from './contracts';
import { useAdSearch } from './hooks';
import { useUserInfo } from '../../../hooks';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type AdSearchPropsType = DefaultPropsType

const AdSearch = ({ permissionsCodeList = [] }: AdSearchPropsType) => {
  const { hookIsReadOnlyList, ...hook } = useAdSearch(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  const userHook = useUserInfo();
  const loadingText = 'Loading Ads';

  return (
    <AppWrapper
      permissionsCodeList={permissionsCodeList}
      modalOpen={hook.hookAdModal || hook.hookAddModal}
    >
      <Styled.WrapperStyled>
        <Styled.HeaderStyled>
          <PageTitle
            loadingText={loadingText}
            isLoading={hook.hookLoading}
          >
            Ad&nbsp;
            {path.adSearch.name}
          </PageTitle>

          {userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.PUBLISHER && userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.PUBLISHER && (
          <Styled.HeaderButtonsAreaStyled>
            <Styled.ButtonStyled
              theme="tertiary"
              onClick={hook.hookSetAddModal}
              disabled={isReadOnly}
            >
              <FontAwesomeIcon icon={faPlus} />
              {' '}
              Add Ad
            </Styled.ButtonStyled>
          </Styled.HeaderButtonsAreaStyled>
          )}
        </Styled.HeaderStyled>

        <Styled.FiltersStyled>
          <Styled.DefaultFiltersStyled isPublisher={userHook.hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER || userHook.hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER}>
            <InputText
              type="text"
              placeholder="Search by Ad ID / Name / Landing Page URL"
              faIcon={faSearch}
              name="search"
              label="Keyword Search"
              value={hook.hookSearch}
              onChange={hook.hookSetSearch}
              width="100%"
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !hook.hookLoading) hook.hookSearchButtonHandler();
              }}
            />

            {userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.PUBLISHER && userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.PUBLISHER && (
            <Select
              label="Status"
              name="status"
              selected={hook.hookSelectedStatus}
              onChange={hook.hookSetSelectedStatus}
              options={hook.hookAdStatusTypesList}
              isLoading={hook.hookOptionsLoading}
              isDisabled={hook.hookOptionsLoading}
              width="100%"
            />
            )}

            <Styled.FiltersAdvancedStyled isPublisher={userHook.hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER || userHook.hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER}>
              <Checkbox
                label="Show Advanced Search"
                onChange={hook.hookSetAdvancedSearchBox}
                partial={false}
                checked={hook.hookAdvancedSearchBox}
              />
              <Button
                theme="text-only"
                onClick={hook.hookClearForm}
              >
                Clear Form
              </Button>
            </Styled.FiltersAdvancedStyled>
          </Styled.DefaultFiltersStyled>

          {hook.hookOpenAdvancedSearch && (
          <Styled.AdvanceFiltersStyled isAdvance>
            {((userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT && userHook.hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN) || userHook.hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER) && (
              <Select
                label="Merchant"
                name="merchant"
                selected={hook.hookSelectedMerchant}
                onChange={hook.hookSetSelectedMerchant}
                options={hook.hookMerchantList}
                isDisabled={hook.hookOptionsLoading}
                isLoading={hook.hookOptionsLoading}
                width="100%"
              />
            )}
            <Select
              label="Ad Type"
              name="adType"
              onChange={hook.hookSetAdType}
              selected={hook.hookSelectedAdType}
              options={hook.hookAdTypesList}
              isLoading={hook.hookOptionsLoading}
              isDisabled={hook.hookOptionsLoading}
              width="100%"
            />
            <Select
              label="Ad Group Campaign"
              name="adGroupCampaign"
              onChange={hook.hookSetSelectedAdCampaign}
              selected={hook.hookSelectedAdCampaign}
              options={hook.hookAdCampaignList}
              isDisabled={hook.hookOptionsLoading}
              isLoading={hook.hookOptionsLoading}
              width="100%"
            />
            <Select
              label="Language"
              name="language"
              selected={hook.hookSelectedLanguage}
              onChange={hook.hookSetSelectedLanguage}
              options={hook.hookLanguagesList}
              isLoading={hook.hookOptionsLoading}
              isDisabled={hook.hookOptionsLoading}
              width="100%"
            />
            <Select
              label="Product Category"
              name="productCategory"
              onChange={hook.hookSetSelectedProductCategory}
              selected={hook.hookSelectedProductCategory}
              options={hook.hookProductCategoryList}
              isLoading={hook.hookOptionsLoading}
              isDisabled={hook.hookOptionsLoading}
              width="100%"
            />
            <Select
              label="Product"
              name="product"
              onChange={hook.hookSetSelectedProduct}
              selected={hook.hookSelectedProduct}
              options={hook.hookProductList}
              isDisabled={hook.hookOptionsLoading}
              isLoading={hook.hookOptionsLoading}
              width="100%"
            />
            {(userHook.hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT || userHook.hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT) && (
              <Select
                label="Publisher Group"
                name="publisherGroup"
                onChange={hook.hookSetSelectedPublisherGroup}
                selected={hook.hookSelectedPublisherGroups}
                options={hook.hookPublisherGroupList}
                isDisabled={hook.hookOptionsLoading}
                isLoading={hook.hookOptionsLoading}
                width="100%"
              />
            )}
          </Styled.AdvanceFiltersStyled>
          )}
        </Styled.FiltersStyled>

        <Styled.OptionsStyled>
          <RecordsDropdown
            selected={hook.hookRecords}
            options={hook.hookRecordOptions}
            currentPage={hook.hookCurrentPage}
            totalRecords={hook.hookTotalRecords}
            onChange={hook.hookSetRecords}
          />
          <Styled.SearchRowStyled>
            <Button
              onClick={hook.hookSearchButtonHandler}
              disabled={hook.hookLoading || hook.hookTableLoading || hook.hookOptionsLoading}
              loading={hook.hookTableLoading}
              width="120px"
            >
              <FontAwesomeIcon
                icon={faSearch}
                style={{ marginRight: 5 }}
              />
              {' '}
              Search
            </Button>
          </Styled.SearchRowStyled>
        </Styled.OptionsStyled>

        {((hook.hookAds && hook?.hookAds?.length > 0) || (hook.hookTableLoading && hook.hookSearchClicked)) && (
          <>
            <Table
              columns={columns(hook.hookSetOpenAdModal, userHook.hookUserInfo.userTypesId, userHook.hookWhoAmI.isActingAsUserTypeId, true, hook.hookHandleRowClick)}
              data={hook.hookAds}
              isLoading={hook.hookTableLoading}
              onSort={hook.hookHandleSort}
              sortColumn={hook.hookSortColumn}
              onRowClick={hook.hookHandleRowClick}
              noTableOverflow
              noHorizontalScroll
            />
            <Pagination
              total={hook.hookTotalPages}
              currentPage={hook.hookCurrentPage}
              onPageChange={hook.hookChangePage}
            />
          </>
        )}

        {((!hook.hookAds || hook.hookAds.length < 1) && !(hook.hookTableLoading && hook.hookSearchClicked)) && (
        <ReportPlaceholder
          submitted={hook.hookSearchClicked}
          text={hook.hookSearchClicked ? 'Sorry! No results found' : 'Click search to get started'}
          subtext={hook.hookSearchClicked ? "We couldn't find what you're looking for. Please try it again." : ''}
        />
        )}
      </Styled.WrapperStyled>

      <AdGenerateCodeModal
        isOpen={hook.hookAdModal}
        adId={hook.hookSelectedAd}
        onClose={hook.hookSetCloseAdModal}
      />

      <AddAdModal
        isOpen={hook.hookAddModal}
        onClose={hook.hookSetAddModal}
      />
    </AppWrapper>
  );
};

export default AdSearch;
