import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast, useUpload, useUserInfo } from '../../../../hooks';
import { useValidation } from '../../../../utils/validation';
import { CREATE_AD } from '../graphql/mutations/createAd';
import { GET_OPTIONS, GET_CAMPAIGN_PRODUCT } from '../graphql/queries';
import {
  AD_TYPES,
  ERROR_MESSAGES,
  ERROR_TYPES,
  MERCHANT_PREFIX,
  path,
  REGEX_VALIDATORS,
  URL_STATUSES,
  USER_TYPES_ID,
} from '../../../../utils';
import { DUPLICATE_NAME, INVALID_PID, INVALID_PID_ERROR, DUPLICATE_NAME_ERROR } from '../../utils';
import { Permission } from '../../../../entities';
import { useProgramsApi } from 'api/hooks/useProgramsApi';
import { IProgramTrackingSettings } from 'api/types';
import { findQueryParamsDuplicates, interpolate } from 'helpers';
import en from '../locales/en.json';

type ImageList = {
  file?: File;
  alt: string;
  height: number;
  width: number;
};

type TrackingLink = {
  content?: string;
  landingPage: string;
};

type adInput = {
  adContent?: string;
  adName: string;
  adStatus: string;
  adType: string;
  altText?: string;
  campaignId?: string;
  coverUrl?: string;
  defaultLandingPage?: string;
  description?: string;
  endDate?: Date;
  fileAsset?: string;
  height?: string;
  html5Code?: string;
  isJavascript: boolean;
  language: string;
  productId?: any;
  programId: string;
  publisherGroupsId?: string[];
  startDate?: Date;
  tags?: string[];
  width?: string;
};

export const useNewAd = (permissionsCodeList: string[] = []) => {
  const [name, setName] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<SelectOption>();
  const [selectedCampaign, setSelectedCampaign] = useState<SelectOption>();
  const [description, setDescription] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<SelectOption>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [tags, setTags] = useState<SelectOption[]>([]);
  const [language, setLanguage] = useState<SelectOption>({ label: 'English', value: 'English' });
  const [adStatusTypeList, setAdStatusTypeList] = useState<SelectOption[]>();
  const [campaignList, setCampaignList] = useState<SelectOption[]>();
  const [productList, setProductList] = useState<SelectOption[]>();
  const [languageList, setLanguageList] = useState<SelectOption[]>();
  const [tagList, setTagList] = useState<SelectOption[]>();
  const [startCalendarOpen, setStartCalendarOpen] = useState(false);
  const [endCalendarOpen, setEndCalendarOpen] = useState(false);
  const [trackingLinkList, setTrackingLinkList] = useState<TrackingLink[]>([{ content: '', landingPage: '' }]);
  const [assetFile, setAssetFile] = useState<File>();
  const [imagesList, setImagesList] = useState<ImageList[]>([
    {
      file: undefined,
      alt: '',
      height: 0,
      width: 0,
    },
  ]);
  const [html5, setHtml5] = useState('');
  const [isJsOnly, setIsJsOnly] = useState(false);
  const [htmlWidth, setHtmlWidth] = useState('');
  const [htmlHeight, setHtmlHeight] = useState('');
  const [urlPlaceholder, setUrlPlaceholder] = useState('https://www.fintelconnect.com/?');
  const [landingPageForBanner, setLandingPageForBanner] = useState('');
  const [landingPageForHTML, setLandingPageForHTML] = useState('');
  const [campaignModal, setCampaignModal] = useState(false);

  const [adErrorMessage, setAdErrorMessage] = useState('');
  const [errorBanner, setErrorBanner] = useState<boolean>(false);
  const [fileErrors, setFileErrors] = useState<string[]>([]);
  const [fileAltErrors, setFileAltErrors] = useState<string[]>([]);
  const [trackingLinkErrors, setTrackingLinkErrors] = useState<string[]>([]);

  const [singleUrlStatus, setSingleUrlStatus] = useState('');
  const [singleUrlError, setSingleUrlError] = useState('');
  const [trackingLinkStatuses, setTrackingLinkStatuses] = useState<string[]>([]);

  const [secondRender, setSecondRender] = useState(false);
  const [createAdErrors, setCreateAdErrors] = useState<{ [key: string]: string }>({});
  const [programTrackingSettings, setProgramTrackingSettings] = useState<IProgramTrackingSettings | null>(null);

  const location: any = useLocation();
  const navigate = useNavigate();
  const upload = useUpload();
  const vali = useValidation();

  const { fetchTrackingSettingsByProgramId } = useProgramsApi();

  const program = location?.state?.program;
  const adType = location?.state?.adType?.value;

  const [getOptions, { loading: optionsLoading }] = useLazyQuery(GET_OPTIONS);
  const [getCampaignProduct] = useLazyQuery(GET_CAMPAIGN_PRODUCT);
  const [campaignProduct, setCampaignProduct] = useState({ productId: '', productName: '' });
  const [createLinkAd] = useMutation(CREATE_AD);
  const [createBannerAd] = useMutation(CREATE_AD);
  const [createHtmlAd] = useMutation(CREATE_AD);

  const userHook = useUserInfo();
  const { hookShowToast } = useToast();

  /**
   * Fetch tracking settings by program id to be able to check the query params duplicates in landing page URL
   */
  useEffect(() => {
    const fetchTrackingSettings = async (): Promise<void> => {
      try {
        const trackingSettings = await fetchTrackingSettingsByProgramId({ id: program ?? '' });
        setProgramTrackingSettings(trackingSettings);
      } catch (error) {
        setProgramTrackingSettings(null);
      }
    };

    fetchTrackingSettings();
  }, [program]);

  // ===== fetching options =====

  const getOptionsHandler = async () => {
    const { data } = await getOptions({
      variables: {
        input: {
          programId: program,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.getAdOptions?.merchant?.companyUrl) setUrlPlaceholder(data.getAdOptions.merchant.companyUrl);

    if (data?.getAdOptions?.adStatusTypes) {
      setAdStatusTypeList(
        data.getAdOptions.adStatusTypes.map((item: any) => ({
          label: item.type,
          value: item.type,
        }))
      );
    }

    if (data?.getAdOptions?.products) {
      setProductList([
        { label: 'No Product', value: '' },
        ...data.getAdOptions.products.map((item: any) => ({
          label: item.name,
          value: item.id,
        })),
      ]);
    }

    if (data?.getAdOptions?.languages) {
      setLanguageList(
        data.getAdOptions.languages.map((item: any) => ({
          label: item.language,
          value: item.language,
        }))
      );
    }

    if (data?.getAdOptions?.campaigns) {
      setCampaignList([
        { label: '', value: '' },
        ...data.getAdOptions.campaigns.map((item: any) => ({
          label: item.name,
          value: item.id,
        })),
      ]);
    }

    if (data?.getAdOptions?.tags) {
      setTagList(
        data.getAdOptions.tags.map((item: any) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  };

  // ===== handle fields =====

  const resetAdErrors = () => {
    setFileErrors([]);
    setFileAltErrors([]);
    setTrackingLinkStatuses([]);
    setTrackingLinkErrors([]);
  };

  const setNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const setSelectedStatusHandler = (value: SelectOption) => {
    setSelectedStatus(value);
  };

  const fetchSetCampaignProduct = async (value: SelectOption) => {
    const { data } = await getCampaignProduct({
      variables: {
        input: {
          campaignId: value.value,
        },
      },
      fetchPolicy: 'no-cache',
    });
    let productId;
    let productName;
    if (data) {
      productId = data?.getCampaignProduct?.productId;
      productName = data?.getCampaignProduct?.productName;
    }
    setCampaignProduct({ productId, productName });
  };

  const setSelectedCampaignHandler = async (value: SelectOption) => {
    await fetchSetCampaignProduct(value);
    setSelectedCampaign(value);
  };

  const setDescriptionHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const setContentHandler = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newTrackingLinkList = [...trackingLinkList];
    newTrackingLinkList[index].content = e.target.value;
    setTrackingLinkList(newTrackingLinkList);
  };

  const setLandingPageHandler = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newTrackingLinkList = [...trackingLinkList];
    newTrackingLinkList[index].landingPage = e.target.value;
    setTrackingLinkList(newTrackingLinkList);
  };

  const setStartDateHandler = (value: Date) => {
    setStartDate(value);
  };

  const setEndDateHandler = (value: Date) => {
    setEndDate(value);
  };

  const setTagsHandler = (value: SelectOption[]) => {
    setTags(value);
  };

  const setLanguagesHandler = (value: SelectOption) => {
    setLanguage(value);
  };

  const setHtml5Handler = (value: any) => {
    setHtml5(value);
  };

  const setJsOnlyHandler = () => {
    setIsJsOnly(!isJsOnly);
  };

  const setHtmlWidthHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHtmlWidth(e.target.value);
  };

  const setHtmlHeightHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHtmlHeight(e.target.value);
  };

  const onApplyStartCalendarHandler = (start: Date) => {
    setStartDate(start);
    setStartCalendarOpen(false);
  };

  const onCancelStartCalendarHandler = () => {
    setStartCalendarOpen(false);
  };

  const onOpenStartCalendarHandler = () => {
    setStartCalendarOpen(true);
  };

  const onApplyEndCalendarHandler = (end: Date) => {
    setEndDate(end);
    setEndCalendarOpen(false);
  };

  const onCancelEndCalendarHandler = () => {
    setEndCalendarOpen(false);
    setEndDate(undefined);
  };

  const onOpenEndCalendarHandler = () => {
    setEndCalendarOpen(true);
  };

  const setLandingPageForBannerHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLandingPageForBanner(e.target.value);
  };

  const setLandingPageForHTMLHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLandingPageForHTML(e.target.value);
  };

  const addNewTrackingLinkHandler = () => {
    setTrackingLinkList([
      ...trackingLinkList,
      {
        content: '',
        landingPage: '',
      },
    ]);
  };

  const addNewImageListHandler = () => {
    setImagesList([
      ...imagesList,
      {
        file: undefined,
        alt: '',
        height: 0,
        width: 0,
      },
    ]);
  };

  const removeTrackingLinkHandler = (index: number) => {
    if (trackingLinkList.length > 1) {
      const newTrackingLinkList = [...trackingLinkList];
      newTrackingLinkList.splice(index, 1);
      setTrackingLinkList(newTrackingLinkList);
    }
  };

  const removeImageListHandler = (index: number) => {
    if (imagesList.length > 1) {
      const newImagesList = [...imagesList];
      newImagesList.splice(index, 1);
      setImagesList(newImagesList);
    }
  };

  const setCampaignAfterCreationHandler = async (value: SelectOption, lang: SelectOption) => {
    await fetchSetCampaignProduct(value);
    setSelectedCampaign(value);
    setLanguage(lang);
    if (campaignList !== undefined) {
      setCampaignList([...campaignList, value]);
    } else {
      setCampaignList([value]);
    }
  };

  // ===== validations =====

  const isParamsDuplicatesLandingPage = (url: string, onError: (message: string) => void): boolean => {
    try {
      const queryParamDuplicates = findQueryParamsDuplicates(url, programTrackingSettings?.urlQueryString || '');

      if (queryParamDuplicates.length > 0) {
        onError(
          interpolate(en.errors.validation.landingPageUrl.queryParamDuplicates, {
            duplicates: queryParamDuplicates.join(', '),
          })
        );
        return true;
      }

      return false;
    } catch (err) {
      console.error(`Error in parsing the URL: ${url}`);
      return false;
    }
  };

  const validateWebsite = () => {
    if (
      (adType === AD_TYPES.BANNER && landingPageForBanner !== '') ||
      (adType === AD_TYPES.HTML && landingPageForHTML !== '')
    ) {
      const url = adType === AD_TYPES.BANNER ? landingPageForBanner : landingPageForHTML;

      if (
        isParamsDuplicatesLandingPage(url, (message: string) => {
          setSingleUrlError(message);
          setSingleUrlStatus(URL_STATUSES.INVALID_WEBSITE.STATUS);
        })
      ) {
        return;
      }
    }

    if (adType === AD_TYPES.BANNER && landingPageForBanner !== '') {
      vali.validateUrlStatus(landingPageForBanner, setSingleUrlStatus);
    }

    if (adType === AD_TYPES.HTML && landingPageForHTML !== '') {
      vali.validateUrlStatus(landingPageForHTML, setSingleUrlStatus);
    }

    vali.renderUrlCheck(singleUrlStatus, setSingleUrlError, true);
  };

  const validateTrackingWebsites = () => {
    const errors: string[] = [];

    if (adType === AD_TYPES.LINK && trackingLinkList.length > 0) {
      trackingLinkList.forEach((link, index) => {
        vali.validateUrlStatus(link.landingPage, setTrackingLinkStatuses, trackingLinkStatuses, index);
      });

      trackingLinkList.forEach((link) => {
        if (isParamsDuplicatesLandingPage(link.landingPage, (message: string) => errors.push(message))) {
          // If there are duplicate parameters, the error message is already added by the callback
          // You can add additional logic here if needed
        } else {
          errors.push(String());
        }
      });

      if (errors.every((item) => item === String())) {
        vali.renderUrlListCheck(trackingLinkStatuses, setTrackingLinkErrors);
      }
    }

    let noErrors = true;
    if (trackingLinkErrors.length > 0) {
      trackingLinkErrors.forEach((error, index) => {
        if (
          error !== '' &&
          trackingLinkStatuses[index] !== URL_STATUSES.ACTIVE_WEBSITE.STATUS &&
          trackingLinkStatuses[index] !== URL_STATUSES.INACTIVE_WEBSITE.STATUS &&
          trackingLinkStatuses[index] !== URL_STATUSES.UNSAFE_WEBSITE.STATUS
        ) {
          noErrors = false;
        }
      });
    }

    if (errors.length > 0) {
      errors.forEach((error) => {
        if (error !== String()) {
          noErrors = false;
        }
      });

      if (!noErrors) setTrackingLinkErrors(errors);
    }

    return noErrors;
  };

  const validateBannerFiles = (index?: number): boolean => {
    let noErrors = true;
    if (index !== undefined) {
      const newFileErrors = fileErrors;
      newFileErrors[index] = imagesList[index].file === undefined ? ERROR_MESSAGES.IMAGE : '';
      setFileErrors(newFileErrors);
    } else {
      setFileErrors([]);
      imagesList.forEach((img) => {
        if (img.file === undefined) {
          setFileErrors((prevErrors) => [...prevErrors, ERROR_MESSAGES.IMAGE]);
          noErrors = false;
        } else {
          setFileErrors((prevErrors) => [...prevErrors, '']);
        }
      });
    }
    return noErrors;
  };

  const validateBannerAlts = (index?: number): boolean => {
    let noErrors = true;
    if (index !== undefined) {
      const newFileAltErrors = fileAltErrors;
      newFileAltErrors[index] = imagesList[index].alt === undefined ? ERROR_MESSAGES.ALT_TEXT : '';
      setFileAltErrors(newFileAltErrors);
    } else {
      setFileAltErrors([]);
      imagesList.forEach((img) => {
        if (img.alt === '') {
          setFileAltErrors((prevErrors) => [...prevErrors, ERROR_MESSAGES.ALT_TEXT]);
          noErrors = false;
        } else if (!img.alt.match(REGEX_VALIDATORS.LETTERS_NUMBERS_ONLY.REGEX)) {
          setFileAltErrors((prevErrors) => [...prevErrors, REGEX_VALIDATORS.LETTERS_NUMBERS_ONLY.MESSAGE]);
          noErrors = false;
        } else {
          setFileAltErrors((prevErrors) => [...prevErrors, '']);
        }
      });
    }
    return noErrors;
  };

  // validation logic, add campaignProductId to compare during validations with product.
  const globalValues: { [key: string]: string } = {
    adName: name,
    status: selectedStatus?.label || '',
    description,
    productId: selectedProduct?.label || '',
    language: language?.label || '',
    startDate: startDate?.toString() || '',
    endDate: endDate?.toString() || '',
    campaignProductId: campaignProduct?.productId || '',
  };

  const globalFields = {
    adName: ERROR_TYPES.NOT_EMPTY,
    status: ERROR_TYPES.SELECTION_REQUIRED,
    description: ERROR_TYPES.SELECTION_REQUIRED,
    language: ERROR_TYPES.SELECTION_REQUIRED,
    endDate: ERROR_TYPES.END_DATE,
    productId: ERROR_TYPES.PRODUCT_EMPTY,
  };

  const htmlValues: { [key: string]: string } = {
    htmlCode: html5,
    htmlWidth,
    htmlHeight,
  };

  const htmlFields = {
    htmlCode: ERROR_TYPES.NOT_EMPTY,
    htmlWidth: REGEX_VALIDATORS.NUMBERS_ONLY.REGEX,
    htmlHeight: REGEX_VALIDATORS.NUMBERS_ONLY.REGEX,
  };

  const copy = (toCopy: { [key: string]: any }) => JSON.parse(JSON.stringify(toCopy));

  const handleValidation = () => {
    let noErrors = true;
    const allValues = copy(globalValues);
    const allFields = copy(globalFields);

    if (
      (adType === AD_TYPES.BANNER && landingPageForBanner !== '') ||
      (adType === AD_TYPES.HTML && landingPageForHTML !== '')
    ) {
      validateWebsite();

      if (
        singleUrlError !== '' &&
        singleUrlStatus !== URL_STATUSES.ACTIVE_WEBSITE.STATUS &&
        singleUrlStatus !== URL_STATUSES.INACTIVE_WEBSITE.STATUS &&
        singleUrlStatus !== URL_STATUSES.UNSAFE_WEBSITE.STATUS
      ) {
        noErrors = false;
      }
    }
    if (adType === AD_TYPES.HTML) {
      Object.assign(allValues, copy(htmlValues));
      Object.assign(allFields, htmlFields);
    }
    return vali.validateAll(allValues, allFields, setCreateAdErrors, secondRender) && noErrors;
  };

  // ===== image & file =====

  const setImageAltHandler = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newImagesList = [...imagesList];
    newImagesList[index].alt = e.target.value;
    setImagesList(newImagesList);
    validateBannerAlts(index);
  };

  const onUploadHandler = (file: any, index: number) => {
    if (file instanceof File) {
      const newImagesList = [...imagesList];
      newImagesList[index].file = file;

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        newImagesList[index].height = img.naturalHeight;
        newImagesList[index].width = img.naturalWidth;
        setImagesList(newImagesList);
      };
      validateBannerFiles(index);
    }
  };

  const setSelectedProductHandler = (value: SelectOption) => {
    setSelectedProduct(value);
  };

  const onAssetFileUploadHandler = (file: any) => {
    if (file instanceof File) setAssetFile(file);
  };

  // ===== create & submit =====

  // if there a campaign is selected - discard advanced options
  const hasCampaign: boolean = selectedCampaign?.value !== undefined && selectedCampaign?.value !== '';

  const defaultInput: adInput = {
    adName: name,
    description,
    startDate: hasCampaign ? undefined : startDate,
    adType,
    language: language?.value || 'English',
    programId: program,
    productId: selectedProduct?.label,
    adStatus: selectedStatus?.value || 'Active',
    isJavascript: isJsOnly,
  };

  const onSuccessHandler = (type: string, adName: string) => {
    setErrorBanner(false);
    setAdErrorMessage('');
    hookShowToast(`${type} ad ${adName} was created successfully`);
    if (
      userHook.hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT ||
      userHook.hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT
    ) {
      navigate(`${MERCHANT_PREFIX}${path.adSearch.href}`);
    } else {
      navigate(path.adSearch.href);
    }
  };

  const createLinkAdHandler = async () => {
    setSecondRender(true);
    const validated = handleValidation() && validateTrackingWebsites();
    if (!validated) return;

    const linkInput = trackingLinkList.map((linkItem, index: number) => {
      const inputs = { ...defaultInput };
      if (index > 0) inputs.adName = `${name}'${index}`;
      if (linkItem.content !== '') inputs.adContent = linkItem.content;
      if (linkItem.landingPage !== '') inputs.defaultLandingPage = linkItem.landingPage;
      if (hasCampaign) inputs.campaignId = selectedCampaign?.value;
      if (endDate !== undefined) inputs.endDate = hasCampaign ? undefined : endDate;
      if (selectedProduct?.value !== undefined)
        inputs.productId = selectedProduct?.value === '' ? null : selectedProduct.value;
      if (tags.length > 0) inputs.tags = hasCampaign ? undefined : tags.map((item) => item.label);

      return inputs;
    });

    if (trackingLinkList.length > 0) {
      try {
        const resultArr = await Promise.all(
          linkInput.map((linkItem) =>
            createLinkAd({
              variables: {
                input: linkItem,
              },
            })
          )
        );
        resultArr.map((item) => {
          if (item.errors) throw new Error(DUPLICATE_NAME);
        });
        onSuccessHandler('Link', name);
      } catch (err: any) {
        if (err?.message === DUPLICATE_NAME) {
          setErrorBanner(true);
          setAdErrorMessage(DUPLICATE_NAME_ERROR(name));
        } else {
          hookShowToast(ERROR_MESSAGES.FAIL_TO_SAVE);
        }
      }
    }
  };

  const createBannerAdHandler = async () => {
    setSecondRender(true);
    const validated = handleValidation();
    const fileVali = validateBannerFiles();
    const altVali = validateBannerAlts();
    if (!validated || !fileVali || !altVali) return;

    const links = await Promise.all(
      imagesList.map(async (item) => {
        if (item.file) {
          const result = await upload.hookUploadImageFile(item.file);
          if (result.url === undefined || result.url === null) return undefined;

          return {
            alt: item.alt,
            file: result.url,
            height: item.height.toString(),
            width: item.width.toString(),
          };
        }

        return undefined;
      })
    );

    const bannerInput = links.map((bannerItem, index: number) => {
      const inputs = { ...defaultInput };
      if (index > 0) inputs.adName = `${name}'${index}`;

      if (endDate !== undefined) inputs.endDate = hasCampaign ? undefined : endDate;
      if (landingPageForBanner !== '') inputs.defaultLandingPage = hasCampaign ? '' : landingPageForBanner;
      if (selectedProduct?.value !== undefined)
        inputs.productId = selectedProduct?.value === '' ? null : selectedProduct.value;
      if (hasCampaign) inputs.campaignId = selectedCampaign?.value;
      if (tags.length > 0) inputs.tags = hasCampaign ? undefined : tags.map((item) => item.label);
      if (bannerItem?.file !== undefined) inputs.adContent = bannerItem?.file;
      if (bannerItem?.alt !== '') inputs.altText = bannerItem?.alt;
      if (bannerItem?.width !== '') inputs.width = bannerItem?.width;
      if (bannerItem?.height !== '') inputs.height = bannerItem?.height;
      return inputs;
    });

    if (links.length > 0) {
      try {
        const resultArr = await Promise.all(
          bannerInput.map((banneritem) =>
            createBannerAd({
              variables: {
                input: banneritem,
              },
            })
          )
        );
        resultArr.map((item) => {
          if (item.errors) {
            for (let i = 0; i < item.errors.length; i += 1) {
              if (item.errors[i].message === INVALID_PID) {
                throw new Error(INVALID_PID);
              }
            }
            throw new Error(DUPLICATE_NAME);
          }
        });
        onSuccessHandler('Banner', name);
      } catch (err: any) {
        if (err?.message === DUPLICATE_NAME) {
          setErrorBanner(true);
          setAdErrorMessage(DUPLICATE_NAME_ERROR(name));
        } else if (err?.message === INVALID_PID) {
          hookShowToast(INVALID_PID_ERROR);
        } else {
          hookShowToast(ERROR_MESSAGES.FAIL_TO_SAVE);
        }
      }
    }
  };

  const createHtmlAdHandler = async () => {
    setSecondRender(true);
    const validated = handleValidation();
    if (!validated) return;

    const inputs = { ...defaultInput };
    if (html5 !== '') inputs.adContent = html5;
    if (htmlWidth !== '') inputs.width = htmlWidth;
    if (htmlHeight !== '') inputs.height = htmlHeight;
    if (landingPageForHTML !== '') inputs.defaultLandingPage = hasCampaign ? '' : landingPageForHTML;
    if (endDate !== undefined) inputs.endDate = hasCampaign ? undefined : endDate;
    if (selectedProduct?.value !== undefined)
      inputs.productId = selectedProduct?.value === '' ? null : selectedProduct.value;
    if (hasCampaign) inputs.campaignId = selectedCampaign?.value;
    if (tags.length > 0) inputs.tags = hasCampaign ? undefined : tags.map((item) => item.label);

    try {
      const { data, errors } = await createHtmlAd({
        variables: {
          input: inputs,
        },
      });
      if (data?.newAd?.ad) {
        if (assetFile !== undefined) await upload.hookUploadZipFile(assetFile, data.newAd.ad.id);
        onSuccessHandler('HTML', name);
      } else if (errors) {
        setErrorBanner(true);
        setAdErrorMessage(DUPLICATE_NAME_ERROR(name));
      }
    } catch (err) {
      hookShowToast(ERROR_MESSAGES.FAIL_TO_SAVE);
    }
  };

  const onBackHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    getOptionsHandler();
    resetAdErrors();
  }, []);

  useEffect(() => {
    if (secondRender) {
      handleValidation();
    }
  }, [secondRender, startDate, endDate]);

  useEffect(() => {
    vali.validateUrlStatus(landingPageForBanner, setSingleUrlStatus);
    vali.validateUrlStatus(landingPageForHTML, setSingleUrlStatus);
  }, [landingPageForBanner, landingPageForHTML]);

  useEffect(() => {
    setTrackingLinkStatuses([]);
    trackingLinkList.forEach((link, index) => {
      vali.validateUrlStatus(link.landingPage, setTrackingLinkStatuses, trackingLinkStatuses, index);
    });
  }, [trackingLinkList]);

  useEffect(() => {
    if (adStatusTypeList !== undefined) setSelectedStatus(adStatusTypeList[0]);
  }, [adStatusTypeList, languageList, tagList]);

  return {
    hookAdErrors: createAdErrors,
    hookAdStatusTypeList: adStatusTypeList,
    hookAdType: adType,
    hookAssetFile: assetFile,
    hookCampaignList: campaignList,
    hookCampaignModal: campaignModal,
    hookDescription: description,
    hookEndCalendarOpen: endCalendarOpen,
    hookEndDate: endDate,
    hookErrorBanner: errorBanner,
    hookFileAltErrors: fileAltErrors,
    hookFileErrors: fileErrors,
    hookHtml5: html5,
    hookHtmlHeight: htmlHeight,
    hookHtmlWidth: htmlWidth,
    hookImagesList: imagesList,
    hookJsOnly: isJsOnly,
    hookLandingPageForBanner: landingPageForBanner,
    hookLandingPageForHTML: landingPageForHTML,
    hookLanguage: language,
    hookLanguageList: languageList,
    hookLoading: optionsLoading,
    hookName: name,
    hookCampaignProduct: campaignProduct,
    hookProductList: productList,
    hookProgramId: program,
    hookSelectedCampaign: selectedCampaign,
    hookSelectedProduct: selectedProduct,
    hookSelectedStatus: selectedStatus,
    hookSingleUrlError: singleUrlError,
    hookStartCalendarOpen: startCalendarOpen,
    hookStartDate: startDate,
    hookTagList: tagList,
    hookTags: tags,
    hookTrackingLinkErrors: trackingLinkErrors,
    hookTrackingLinkList: trackingLinkList,
    hookAdErrorMessage: adErrorMessage,
    hookUrlPlaceholder: urlPlaceholder,

    hookAddNewTrackingLink: addNewTrackingLinkHandler,
    hookCreateBannerAd: createBannerAdHandler,
    hookCreateHtmlAd: createHtmlAdHandler,
    hookCreateLinkAd: createLinkAdHandler,
    hookHandleValidation: handleValidation,
    hookNewImageList: addNewImageListHandler,
    hookOnApplyEndCalendar: onApplyEndCalendarHandler,
    hookOnApplyStartCalendar: onApplyStartCalendarHandler,
    hookOnAssetFileUploadHandler: onAssetFileUploadHandler,
    hookOnBack: onBackHandler,
    hookOnCancelEndCalendar: onCancelEndCalendarHandler,
    hookOnCancelStartCalendar: onCancelStartCalendarHandler,
    hookOnOpenEndCalendar: onOpenEndCalendarHandler,
    hookOnOpenStartCalendar: onOpenStartCalendarHandler,
    hookOnUpload: onUploadHandler,
    hookRemoveImageList: removeImageListHandler,
    hookRemoveTrackingLink: removeTrackingLinkHandler,
    hookSetBannerLandingPage: setLandingPageForBannerHandler,
    hookSetCampaignAfterCreation: setCampaignAfterCreationHandler,
    hookSetCampaignModal: setCampaignModal,
    hookSetContent: setContentHandler,
    hookSetDescription: setDescriptionHandler,
    hookSetEndDate: setEndDateHandler,
    hookSetHtml5: setHtml5Handler,
    hooksetHtmlHeight: setHtmlHeightHandler,
    hookSetHTMLLandingPage: setLandingPageForHTMLHandler,
    hookSetHtmlWidth: setHtmlWidthHandler,
    hookSetImageAlt: setImageAltHandler,
    hookSetJsOnly: setJsOnlyHandler,
    hookSetLandingPage: setLandingPageHandler,
    hookSetLanguage: setLanguagesHandler,
    hookSetName: setNameHandler,
    hookSetSelectedCampaign: setSelectedCampaignHandler,
    hookSetSelectedProduct: setSelectedProductHandler,
    hookSetSelectedStatus: setSelectedStatusHandler,
    hookSetStartDate: setStartDateHandler,
    hookSetTags: setTagsHandler,
    hookValidateTrackingWebsite: validateTrackingWebsites,
    hookValidateWebsite: validateWebsite,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
