import { MERCHANT_PREFIX, path, securityMap } from '../../../utils';

export const merchant = [
  {
    id: 'dashboard',
    key: securityMap.merchant.merchantDashboard,
    merchantSelf: true,
    merchantManaged: false,
    merchantResults: true,
    icon: path.dashboard.icon,
    href: MERCHANT_PREFIX + path.dashboard.href,
    label: path.dashboard.name,
  },
  {
    id: 'account',
    key: securityMap.merchant.merchantAccount,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    label: path.account.name,
    icon: path.account.icon,
    subSections: [
      {
        id: 'accountDetails',
        key: [securityMap.merchant.merchantAccountAccountDetails, securityMap.merchant.merchantAccountUserManagement],
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.accountDetails.href,
        label: path.accountDetails.name,
      },
      {
        id: 'accountBalance',
        key: securityMap.merchant.merchantAccountAccountBalance,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.accountBalance.href,
        label: path.accountBalance.name,
      },
      {
        id: 'accountProgramSettings',
        key: securityMap.merchant.merchantAccountProgramSettings,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.programSettings.href,
        label: path.programSettings.name,
      },
      {
        id: 'accountProductCatalog',
        key: securityMap.merchant.merchantAccountProductCatalogue,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.productCatalog.href,
        label: path.productCatalog.name,
      },
      {
        id: 'accountTrackingTransactionSettings',
        key: securityMap.merchant.merchantAccountTrackingTransactionSettings,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.trackingSettings.href,
        label: path.trackingSettings.name,
      },
      {
        id: 'accountPostbackDetails',
        key: securityMap.merchant.merchantPostbackDetails,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.postbackDetails.href,
        label: path.postbackDetails.name,
      },
    ],
  },
  {
    id: 'commissions',
    key: securityMap.merchant.merchantCommissions,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    icon: path.commissions.icon,
    href: MERCHANT_PREFIX + path.commissions.href,
    label: path.commissions.name,
  },
  {
    id: 'publishers',
    key: securityMap.merchant.merchantPublishers,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    label: path.publishers.name,
    icon: path.publishers.icon,
    subSections: [
      {
        id: 'publishersMembershipManagement',
        key: securityMap.merchant.merchantPublishersMembershipManagement,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.membershipManagement.href,
        label: path.membershipManagement.name,
      },
      {
        id: 'publishersPublisherInvitations',
        key: securityMap.merchant.merchantPublishersPublisherInvitations,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.publisherInvitations.href,
        label: path.publisherInvitations.name,
      },
      {
        id: 'publishersPublisherSearch',
        key: securityMap.merchant.merchantPublishersPublisherSearch,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.publisherSearch.href,
        label: path.publisherSearch.name,
      },
      {
        id: 'publishersPublisherGroup',
        key: securityMap.merchant.merchantPublishersPublisherGroup,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.publisherGroups.href,
        label: path.publisherGroups.name,
      },
      {
        id: 'publishersInternalPublisher',
        key: securityMap.merchant.merchantPublishersInternalPublisher,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.internalPublisher.href,
        label: path.internalPublisher.name,
      },
    ],
  },
  {
    id: 'ads',
    key: securityMap.merchant.merchantAds,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    label: path.ads.name,
    icon: path.ads.icon,
    subSections: [
      {
        id: 'adsAdSearch',
        key: securityMap.merchant.merchantAdSearch,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.adSearch.href,
        label: path.adSearch.name,
      },
      {
        id: 'adsCampaignManagement',
        key: securityMap.merchant.merchantAdsCampaignManagement,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.campaignManagement.href,
        label: path.campaignManagement.name,
      },
    ],
  },
  {
    id: 'reports',
    key: securityMap.merchant.merchantReports,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    label: path.reports.name,
    icon: path.reports.icon,
    subSections: [
      {
        id: 'reportsPerformanceReport',
        key: securityMap.merchant.merchantReportsPerformanceReport,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.performanceReport.href,
        label: path.performanceReport.name,
      },
      {
        id: 'reportsTransactionMaster',
        key: securityMap.merchant.merchantReportsTransactionMaster,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.transactionMaster.href,
        label: path.transactionMaster.name,
      },
      {
        id: 'reportsPaymentReport',
        key: securityMap.merchant.merchantReportsPaymentReport,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.paymentReport.href,
        label: path.paymentReport.name,
      },
      {
        id: 'fintelCheckFintelCheckLinkManager',
        key: securityMap.merchant.merchantReportsLinkManager,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.linkManager.href,
        label: path.linkManager.name,
      },
      {
        id: 'fintelCheckFintelCheckNewSiteMonitoring',
        key: securityMap.merchant.merchantReportsNewSiteMonitoring,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.newSiteMonitoring.href,
        label: path.newSiteMonitoring.name,
      },
      {
        id: 'reportsSavedReports',
        key: securityMap.merchant.merchantReportsManageSavedReports,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.manageSavedReports.href,
        label: path.manageSavedReports.name,
      },
    ],
  },
  {
    id: 'fintelCheck',
    key: securityMap.merchant.merchantFintelCheck,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    label: 'Fintel Check',
    icon: path.fintelChecks.icon,
    subSections: [
      {
        id: 'fintelCheckReports',
        key: securityMap.merchant.merchantFintelCheck,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        label: 'Reports',
        subSections: [
          {
            id: 'fintelCheckReportsEvaluationRulesReport',
            key: securityMap.merchant.merchantFintelEvaluationRulesReport,
            merchantSelf: true,
            merchantManaged: true,
            merchantResults: true,
            href: MERCHANT_PREFIX + path.evaluationRulesReport.href,
            label: 'Evaluation Rules Report',
          },
          {
            id: 'fintelCheckReportsMonitoringRulesReport',
            key: securityMap.merchant.merchantFintelMonitoringRulesReport,
            merchantSelf: true,
            merchantManaged: true,
            merchantResults: true,
            href: MERCHANT_PREFIX + path.monitoringRulesReport.href,
            label: 'Monitoring Report',
          },
          {
            id: 'fintelCheckReportsCheckChangeMonitoring',
            key: securityMap.merchant.merchantFintelCheckChangeMonitoring,
            merchantSelf: true,
            merchantManaged: true,
            merchantResults: true,
            href: MERCHANT_PREFIX + path.fintelCheckChangeMonitoring.href,
            label: 'Page Change Report',
          },
          {
            id: 'fintelCheckReportsFintelCheckReport',
            key: securityMap.merchant.merchantFintelCheckFintelCheckReport,
            merchantSelf: true,
            merchantManaged: true,
            merchantResults: true,
            href: MERCHANT_PREFIX + path.fintelCheckReport.href,
            label: 'Check Report (Legacy)',
          },
        ],
      },
      {
        id: 'fintelCheckRuleManager',
        key: securityMap.merchant.merchantFintelCheck,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        label: 'Rule Manager',
        subSections: [
          {
            id: 'fintelCheckRuleManagerRuleManager',
            key: securityMap.merchant.merchantFintelCheckRuleManager,
            merchantSelf: true,
            merchantManaged: true,
            merchantResults: true,
            href: MERCHANT_PREFIX + path.ruleManager.href,
            label: 'Rules',
          },
        ],
      },
      {
        id: 'fintelCheckSettings',
        key: securityMap.merchant.merchantFintelCheck,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        label: 'Settings',
        subSections: [
          {
            id: 'settingsFintelCheckingSettings',
            key: securityMap.merchant.merchantFintelCheckFintelCheckingSettings,
            merchantSelf: true,
            merchantManaged: true,
            merchantResults: true,
            href: MERCHANT_PREFIX + path.fintelCheckSettings.href,
            label: path.fintelCheckSettings.name,
          },
          {
            id: 'settingsFintelCheckManualUrlsList',
            key: securityMap.merchant.merchantFintelCheckManualUrls,
            merchantSelf: true,
            merchantManaged: true,
            merchantResults: true,
            href: MERCHANT_PREFIX + path.fintelCheckManualUrlsList.href,
            label: 'Manual URLs',
          },
          {
            id: 'settingsFintelCheckCustomPublisherList',
            key: securityMap.merchant.merchantFintelCheckPublisherList,
            merchantSelf: true,
            merchantManaged: true,
            merchantResults: true,
            href: MERCHANT_PREFIX + path.fintelCheckCustomPublisherList.href,
            label: 'Tracking URLs',
          },
          {
            id: 'settingsFintelCheckBlacklistedUrls',
            key: securityMap.merchant.merchantFintelCheckBlacklist,
            merchantSelf: true,
            merchantManaged: true,
            merchantResults: true,
            href: MERCHANT_PREFIX + path.fintelCheckBlacklist.href,
            label: 'Blacklisted URLs',
          },
        ],
      },
    ],
  },
  {
    id: 'transactions',
    key: securityMap.merchant.merchantTransactions,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    label: path.transactions.name,
    icon: path.transactions.icon,
    subSections: [
      {
        id: 'transactionsPendingTransactions',
        key: securityMap.merchant.merchantTransactionsPendingTransactions,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.pendingTransactions.href,
        label: path.pendingTransactions.name,
      },
      {
        id: 'transactionsAddTransactions',
        key: securityMap.merchant.merchantTransactionsAddTransactions,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.addTransactions.href,
        label: path.addTransactions.name,
      },
    ],
  },
  {
    id: 'messageHub',
    key: securityMap.merchant.merchantMessageHub,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    label: path.messageHub.name,
    icon: path.messageHub.icon,
    href: path.messageHub.href,
    link: path.messageHub.link,
  },
];
