import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { colors, fonts } from '../../../../../../styles/theme';
import { toRem } from '../../../../../../utils';
import { sideMenuActiveStyles } from '../../SideMenuItem.styles';

interface IMenuItemContainerProps {
  isActive: boolean;
}

interface IMenuItemProps {
  isCollapsed?: boolean;
}

export const MenuItemContainer = styled.div<IMenuItemContainerProps>`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  border-left: ${toRem(3)} solid transparent;

  ${({ isActive }) => isActive && sideMenuActiveStyles}

  &:hover {
    cursor: pointer;
    ${sideMenuActiveStyles}
  }
`;

export const MenuItem = styled(NavLink).withConfig({
  shouldForwardProp: (prop) => !['isCollapsed'].includes(prop),
})<IMenuItemProps>`
  display: grid;
  grid-template-columns: ${toRem(30)} 1fr ${toRem(15)};
  align-content: center;
  color: ${colors.color7};
  text-decoration: none;
  padding: 1rem;
  ${fonts.gotham.H12M}

  &.active {
    color: ${colors.color4};
  }

  p {
    margin-top: ${toRem(2)};

    &:nth-child(2) {
      display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
    }
  }

  svg {
    margin-right: ${({ isCollapsed }) => (isCollapsed ? '0' : '0.7rem')};
    font-size: ${({ isCollapsed }) => (isCollapsed ? '1.3rem' : '1rem')};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${toRem(48)};

  &:hover {
    cursor: pointer;
  }
`;
