import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faEye, faEyeSlash, faMinusCircle, faTimes, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef, useEffect, useState } from 'react';

import { colors } from '../../../styles/theme';
import { REGEX_VALIDATORS, dateFormatter } from '../../../utils';
import { Button } from '../../Button';

import * as Styled from './styles';

type RegExCheck = {
  REGEX: RegExp;
  MESSAGE: string;
};

export type InputTextProps = {
  allowSpecialCharacters?: boolean;
  className?: string;
  disabled?: boolean;
  disclaimer?: string;
  error?: string;
  faIcon?: IconDefinition;
  forceResetButtonHandler?: () => void;
  label?: string;
  labelStatus?: string;
  labelStatusDate?: Date;
  maxLength?: number;
  name?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onlyAcceptIntegerNumber?: boolean;
  onWheel?: any;
  placeholder?: string;
  readonly?: boolean;
  regex?: RegExCheck;
  required?: boolean;
  showPassword?: boolean;
  theme?: 'default' | 'readonly' | 'readonlySecondary' | 'readonlyTernary';
  tooltip?: string;
  tooltipExtraMarginLeft?: number;
  type: 'text' | 'password' | 'email' | 'number' | 'url';
  validateAndLimitNumber?: boolean;
  value?: string | number;
  width?: string;
};

export const InputText = ({
  label = '',
  name,
  type,
  value,
  onWheel,
  onChange,
  onKeyPress,
  onKeyDown,
  onKeyUp,
  placeholder = '',
  disabled = false,
  required = false,
  readonly = false,
  maxLength = 1000,
  showPassword = false,
  faIcon,
  error,
  width = '100%',
  className,
  theme = 'default',
  tooltip,
  regex,
  onBlur,
  onClear,
  labelStatus,
  labelStatusDate,
  disclaimer,
  forceResetButtonHandler,
  validateAndLimitNumber = false,
  onlyAcceptIntegerNumber = false,
  allowSpecialCharacters = true,
  tooltipExtraMarginLeft,
}: InputTextProps): JSX.Element => {
  const [showPasswordValue, setShowPasswordValue] = useState(showPassword);
  const [typeValue, setTypeValue] = useState(type);
  const [showErrorText, setShowErrorText] = useState('');
  const [showErrorBox, setShowErrorBox] = useState(false);

  const handleShowPassword = (): void => {
    setShowPasswordValue(!showPasswordValue);
    setTypeValue(showPasswordValue ? 'password' : 'text');
  };

  const checkUrlError = (): void => {
    if (!error) {
      setShowErrorBox(false);
    } else if (error === 'Site is live and secure!') {
      setShowErrorBox(false);
      setShowErrorText('green');
    } else if (error === 'Without https:// cannot verify active status.' || error === 'Checking...') {
      setShowErrorBox(false);
      setShowErrorText('grey');
    } else if (error === 'This website is not active, or is inaccessible due to a CORS issue') {
      setShowErrorBox(true);
      setShowErrorText('orange');
    } else {
      setShowErrorBox(true);
      setShowErrorText('red');
    }
  };

  const statusIcons: { [key: string]: IconDefinition } = {
    Unverified: faCheckCircle,
    Verified: faCheckCircle,
    Invalid: faMinusCircle,
  };

  useEffect(() => {
    checkUrlError();
  }, [error]);

  const handleOnKeyDownValidation = (e: any): void => {
    const val = e.target.value;

    if (onlyAcceptIntegerNumber && !/[0-9]/.test(e.key) && e.key !== 'Backspace') {
      e.preventDefault();
    } else if (!onlyAcceptIntegerNumber && e.key === 'Backspace' && val.length === 2 && val[0] === '.') {
      e.target.value = '';
    }
    if (!allowSpecialCharacters && !/^[A-Za-z0-9\s]+$/.test(e.key) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  };

  const handleOnKeyUpValidation = (e: any): void => {
    if (e.target.validity.badInput && e.key !== '.') {
      e.target.value = '';
    }
  };

  /* Limits the number input to 4 decimal places and validates bad input */
  const handleOnChangeNumberValidation = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.validity.badInput || !onChange) return;

    if (
      e.target.value === undefined ||
      REGEX_VALIDATORS.NUMBERS_DECIMALS.REGEX.test(e.target.value) ||
      e.target.value === ''
    ) {
      onChange(e);
    }
  };

  return (
    <Styled.WrapperStyled width={width} className={className}>
      {label && (
        <Styled.LabelStyled>
          {label} {required && <Styled.RequiredStyled>*</Styled.RequiredStyled>}{' '}
          {tooltip && <Styled.TooltipStyled extraMarginLeft={tooltipExtraMarginLeft} text={tooltip} />}{' '}
          {labelStatus && !readonly && (
            <Styled.LabelStatusTextStyled>
              <Styled.LabelStatusIconStyled theme={labelStatus}>
                <FontAwesomeIcon icon={statusIcons[labelStatus]} />
              </Styled.LabelStatusIconStyled>
              {labelStatus}
            </Styled.LabelStatusTextStyled>
          )}
          {labelStatus && labelStatusDate && readonly && (
            <Styled.LabelStatusDateStyled>Verified {dateFormatter(labelStatusDate)}</Styled.LabelStatusDateStyled>
          )}
          {disclaimer && <Styled.DisclaimerStyled>{disclaimer}</Styled.DisclaimerStyled>}
        </Styled.LabelStyled>
      )}
      <Styled.InputContainerStyled
        error={showErrorBox}
        theme={theme}
        disabled={disabled}
        noHoverColor={!!(labelStatus && readonly)}
      >
        {faIcon && <FontAwesomeIcon icon={faIcon} />}
        {labelStatus && readonly && (
          <Styled.LabelStatusIconStyled theme={labelStatus} noMarginLeft>
            <FontAwesomeIcon icon={statusIcons[labelStatus]} />
          </Styled.LabelStatusIconStyled>
        )}
        <Styled.InputStyled
          type={typeValue}
          name={name}
          value={value}
          onChange={validateAndLimitNumber ? handleOnChangeNumberValidation : onChange}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          readOnly={readonly}
          maxLength={maxLength}
          onBlur={onBlur}
          onWheel={(e: any) => (validateAndLimitNumber ? e.target.blur() : undefined)}
          onKeyDown={
            validateAndLimitNumber || onlyAcceptIntegerNumber || !allowSpecialCharacters
              ? (e: any) => handleOnKeyDownValidation(e)
              : undefined
          }
          onKeyUp={validateAndLimitNumber ? (e: any) => handleOnKeyUpValidation(e) : undefined}
          onKeyPress={onKeyPress}
        />
        {showErrorBox && <FontAwesomeIcon icon={faTimes} style={{ color: colors.color14 }} />}
        {type === 'password' && !forceResetButtonHandler && (
          <Styled.ShowPasswordStyled type="button" onClick={handleShowPassword}>
            <FontAwesomeIcon icon={showPasswordValue ? faEyeSlash : faEye} />
          </Styled.ShowPasswordStyled>
        )}
        {type === 'password' && forceResetButtonHandler && (
          <Styled.PasswordResetStyled>
            <Styled.PasswordResetButton type="button" onClick={forceResetButtonHandler}>
              Force&nbsp;Reset
            </Styled.PasswordResetButton>
          </Styled.PasswordResetStyled>
        )}
        {onClear !== undefined && (
          <Button
            onClick={() => {
              onClear();
            }}
            theme="text-only"
          >
            <FontAwesomeIcon icon={faXmark} />
          </Button>
        )}
      </Styled.InputContainerStyled>
      {error && <Styled.ErrorStyled error={showErrorText}>{error}</Styled.ErrorStyled>}
    </Styled.WrapperStyled>
  );
};

export const InputTextRef = forwardRef(
  (
    {
      label = '',
      name,
      type,
      value,
      onChange,
      onKeyPress,
      placeholder = '',
      disabled = false,
      readonly = false,
      maxLength = 1000,
      faIcon,
      width = '100%',
      className,
      theme = 'default',
      onBlur,
    }: Partial<InputTextProps>,
    ref: React.ForwardedRef<HTMLInputElement | null>
  ) => (
    <Styled.WrapperStyled width={width} className={className}>
      {label && <Styled.LabelStyled>{label}</Styled.LabelStyled>}
      <Styled.InputContainerStyled error={false} theme={theme} disabled={disabled}>
        {faIcon && <FontAwesomeIcon icon={faIcon} />}
        <Styled.InputStyled
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readonly}
          maxLength={maxLength}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          ref={ref}
        />
      </Styled.InputContainerStyled>
    </Styled.WrapperStyled>
  )
);
