import React, { ReactElement } from 'react';

import { Button, Modal } from 'components';
import { useToast } from 'hooks';

import { useFintelCheckSettings } from '../../hooks/useFintelCheckSettings';
import en from '../../locales/en.json';

import {
  ModalContainer,
  ModalTitle,
  ModalDescription,
  ButtonContainer,
  SaveBtn,
} from './ToolSettingsUpdateModal.styles';

const ToolSettingsUpdateModal = (): ReactElement => {
  const { hookShowToast } = useToast();
  const { isUpdateModalOpened, toggleUpdateModal, updateToolSettings } = useFintelCheckSettings();

  const getDate = (): string => {
    const currentDate = new Date();
    return `${currentDate.getMonth() + 1}/${currentDate.getDate() + 1}/${currentDate.getFullYear()}`;
  };

  const handleSaveBtnClick = async (): Promise<void> => {
    await updateToolSettings(
      {},
      () => hookShowToast(en.settings.notifications.toasts.toolSettings.updated.success.title),
      () => hookShowToast(en.settings.notifications.toasts.toolSettings.updated.error.title)
    );
    toggleUpdateModal(false);
  };

  return (
    <Modal isOpen={isUpdateModalOpened}>
      <ModalContainer>
        <ModalTitle>{en.settings.modals.update.title}</ModalTitle>
        <ModalDescription>{`${en.settings.modals.update.description} ${getDate()}.`}</ModalDescription>
        <ButtonContainer>
          <Button onClick={() => toggleUpdateModal(false)} theme="secondary">
            {en.settings.modals.update.buttons.cancel.label}
          </Button>
          <SaveBtn onClick={handleSaveBtnClick}>{en.settings.modals.update.buttons.save.label}</SaveBtn>
        </ButtonContainer>
      </ModalContainer>
    </Modal>
  );
};

export default ToolSettingsUpdateModal;
