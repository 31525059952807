import { Checkbox, Spacer } from 'components';
import { EligibilityEnum } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/enums';
import * as Styled from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/styles';
import { machine } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/machine';
import { getFeedValue, humanizeProductFeed } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/utils';
import React from 'react';
import { toRem } from 'utils';
import { SnapshotFrom } from 'xstate';

export const ProductSummary = ({ stateMachine }: { stateMachine: SnapshotFrom<typeof machine> }): JSX.Element => {
  return (
    <>
      <Styled.SubTitleTextStyled>Product for Which the Rule Will Apply</Styled.SubTitleTextStyled>
      <Styled.FlexRow>
        <Styled.FlexColumn>
          <Styled.TextFieldLabel>Product Category</Styled.TextFieldLabel>
          <Styled.TextFieldValue>{stateMachine.context.product?.productCategory}</Styled.TextFieldValue>
        </Styled.FlexColumn>
        <Styled.FlexColumn>
          <Styled.TextFieldLabel>Product</Styled.TextFieldLabel>
          <Styled.TextFieldValue>{stateMachine.context.applicableProducts}</Styled.TextFieldValue>
        </Styled.FlexColumn>
      </Styled.FlexRow>

      <Spacer size="xlarge" direction="vertical" />

      <Styled.SubTitleTextStyled>Rule Eligibility</Styled.SubTitleTextStyled>
      <Spacer size="medium" direction="vertical" />
      <Styled.HeaderInfoText>
        These are the minimum requirements for a URL to be eligible for the rule to be applied
      </Styled.HeaderInfoText>
      <Spacer size="medium" direction="vertical" />

      <Styled.FlexRow>
        <Styled.FlexColumn>
          <Styled.TextFieldLabel>Brand Name</Styled.TextFieldLabel>
          <Styled.TextFieldLabelDisclaimer>This name must appear on the page</Styled.TextFieldLabelDisclaimer>
          <Styled.TextFieldValue>{stateMachine.context.brandName}</Styled.TextFieldValue>
        </Styled.FlexColumn>
        <Styled.FlexColumn>
          <Styled.TextFieldLabel>Nominated Value</Styled.TextFieldLabel>
          <Styled.TextFieldLabelDisclaimer>This must appear on the page</Styled.TextFieldLabelDisclaimer>
          <Styled.TextFieldValue>
            {stateMachine.context.productEligibility === EligibilityEnum.PRODUCT_FIELD_TERM
              ? humanizeProductFeed(stateMachine.context.productEligibilityValue)
              : stateMachine.context.productEligibilityValue}
          </Styled.TextFieldValue>
        </Styled.FlexColumn>
      </Styled.FlexRow>

      <Spacer size="xlarge" direction="vertical" />

      <Styled.SubTitleTextStyled>Rule Criteria</Styled.SubTitleTextStyled>
      <Spacer size="medium" direction="vertical" />
      <Styled.HeaderInfoText>Values for the rule to evaluate</Styled.HeaderInfoText>
      {stateMachine.context.productCriteria.map((criteria, index) => (
        <Styled.FlexColumn key={`${criteria.label}-${criteria.value}`}>
          {index !== 0 && (
            <Styled.FlexRow>
              <Styled.AndLine />
              <Styled.AndText>And</Styled.AndText>
              <Styled.AndLine />
            </Styled.FlexRow>
          )}
          <Styled.FlexRow style={{ marginBottom: toRem(8) }}>
            <Styled.FlexRow>
              <Styled.FlexColumn>
                <Styled.TextFieldLabel>Rule Requirement</Styled.TextFieldLabel>
                <Styled.TextFieldValue>{criteria.name}</Styled.TextFieldValue>
              </Styled.FlexColumn>
            </Styled.FlexRow>
            <Styled.FlexRow>
              <Styled.FlexColumn>
                <Styled.TextFieldLabel>Data Associated with Product Field</Styled.TextFieldLabel>
                <Styled.TextFieldValue>
                  {getFeedValue(criteria.value, stateMachine.context.product?.productFeedSection)}
                </Styled.TextFieldValue>
              </Styled.FlexColumn>
            </Styled.FlexRow>
          </Styled.FlexRow>

          <Checkbox
            label="Required"
            theme="secondary"
            checked={criteria.required}
            onChange={() => undefined}
            disabled
          />
        </Styled.FlexColumn>
      ))}
    </>
  );
};
