/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

import { useToast, useUserInfo } from 'hooks';
import { MERCHANT_PREFIX, path } from 'utils';

import {
  UPDATE_FCHECK_SETTINGS,
  TOGGLE_FCHECK_SETTINGS,
} from '../components/ToolSettingsTabs/CheckTab/graphql/mutations';
import { GET_FINTEL_CHECK_SETTINGS } from '../components/ToolSettingsTabs/CheckTab/graphql/queries';
import { useFintelCheckSettingsContext } from '../FintelCheckSettings.context';
import { IFintelCheckToolSettings, ToolSettingTypes, TTouchedToolSettings, IValidationResult } from '../types';
import { toolSettingsConfig } from '../configs/toolSettings.config';
import { validationRules } from '../validations/validationRules';
import en from '../locales/en.json';

interface IUseFintelCheckSettingsHook {
  toolSettings: IFintelCheckToolSettings;
  validationResult: IValidationResult;
  isReadOnlyPermissions: boolean;
  isPageLoading: boolean;
  isPageFullyLoaded: boolean;
  isToolSettingsEnabled: boolean;
  isToolSettingsTouched: boolean;
  isUpdateModalOpened: boolean;
  isLeavePageModalOpened: boolean;
  validateField: (field: keyof IFintelCheckToolSettings, value: any) => void;
  toggleUpdateModal: (state?: boolean) => void;
  fetchToolSettings: () => Promise<void>;
  updateToolSettings: (
    settingsToUpdate: Partial<IFintelCheckToolSettings>,
    onSuccess?: () => void,
    onError?: () => void
  ) => Promise<void>;
  updateToolSetting: <K extends keyof Omit<IFintelCheckToolSettings, 'merchantId'>>(
    key: K,
    value: IFintelCheckToolSettings[K]
  ) => Promise<void>;
  dropTouchedSetting: (key: keyof TTouchedToolSettings) => void;
  handlePageStay: () => void;
  handlePageLeave: () => void;
}

/**
 * Hook for managing Fintel Check tool settings.
 * Handles fetching, updating, and tracking changes to settings.
 */
export const useFintelCheckSettings = (): IUseFintelCheckSettingsHook => {
  const userHook = useUserInfo();
  const { hookShowToast } = useToast();

  // Tool Settings Context
  const {
    toolSettings,
    touchedToolSettings,
    validationResult,
    isReadOnlyPermissions,
    isPageLoading,
    isPageFullyLoaded,
    isUpdateModalOpened,
    setToolSettings,
    setTouchedToolSettings,
    setIsPageLoading,
    setPageIsFullyLoaded,
    setIsUpdateModalOpened,
    setValidationResult,
  } = useFintelCheckSettingsContext();

  // Queries & Mutations
  const [getFintelCheckSettings, { loading: isToolSettingsLoading }] = useLazyQuery(GET_FINTEL_CHECK_SETTINGS);
  const [updateFintelCheckSettings, { loading: isToolSettingsUpdating }] = useMutation(UPDATE_FCHECK_SETTINGS);
  const [toggleFintelCheckSettings, { loading: isToolSettingsToggling }] = useMutation(TOGGLE_FCHECK_SETTINGS);

  const isToolSettingsTouched = Object.values(touchedToolSettings).some(Boolean);

  const leavePageModalInstance = useBlocker(isToolSettingsTouched);
  const isLeavePageModalOpened =
    leavePageModalInstance.state === 'blocked' &&
    leavePageModalInstance.location.pathname !== `${MERCHANT_PREFIX}${path.fintelCheckSettings.href}`;

  /**
   * Validates a single field.
   */
  const validateField = (field: keyof IFintelCheckToolSettings, value: any): void => {
    const error = validationRules[field](value);

    setValidationResult((prev: IValidationResult): IValidationResult => {
      const newErrors = {
        ...prev.errors,
        [field]: error || '',
      };
      const isValid = Object.values(newErrors).every((err: string): boolean => !err);

      return {
        isValid,
        errors: newErrors,
      };
    });
  };

  /**
   * Fetches Fintel Check tool settings.
   */
  const fetchToolSettings = async (): Promise<void> => {
    try {
      const { data: settings } = await getFintelCheckSettings({
        variables: {
          input: {
            merchantId: Number(userHook?.hookWhoAmI?.companyId),
          },
        },
        fetchPolicy: 'no-cache',
      });

      const currentSettings = settings?.getFintelCheckSettings?.settings;

      if (!currentSettings) {
        hookShowToast(en.settings.notifications.toasts.toolSettings.fetch.error.title);
        setPageIsFullyLoaded(false);
        return;
      }

      setToolSettings(currentSettings);
      setPageIsFullyLoaded(true);
    } catch {
      setPageIsFullyLoaded(false);
    }
  };

  /**
   * Updates Fintel Check tool settings.
   */
  const updateToolSettings = async (
    settingsToUpdate: Partial<IFintelCheckToolSettings>,
    onSuccess?: () => void,
    onError?: () => void
  ): Promise<void> => {
    const updatedSettings = { ...toolSettings, ...settingsToUpdate };

    const { data, errors } = await updateFintelCheckSettings({
      variables: {
        input: {
          activeDate: new Date(),
          merchantId: Number(userHook.hookWhoAmI.companyId),
          enabled: updatedSettings.enabled,
          frequencySettings: updatedSettings.frequencySettings,
          brandName: updatedSettings.brandName,
        },
      },
    });

    if (errors) {
      onError?.();
    }

    if (data) {
      setToolSettings(updatedSettings);
      setTouchedToolSettings({});
      onSuccess?.();
    }
  };

  /**
   * Toggles the Fintel Check tool settings.
   */
  const toggleToolSettings = async (enabled: boolean, onSuccess?: () => void, onError?: () => void): Promise<void> => {
    const updatedSettings = { ...toolSettings, ...{ enabled } };

    const { data, errors } = await toggleFintelCheckSettings({
      variables: {
        input: {
          merchantId: Number(userHook.hookWhoAmI.companyId),
          enabled,
        },
      },
    });

    if (errors) {
      onError?.();
    }

    if (data) {
      setToolSettings(updatedSettings);
      onSuccess?.();
    }
  };

  /**
   * Updates a single Fintel Check tool setting.
   */
  const updateToolSetting = async <K extends keyof Omit<IFintelCheckToolSettings, 'merchantId'>>(
    key: K,
    value: IFintelCheckToolSettings[K]
  ): Promise<void> => {
    const settingType = toolSettingsConfig[key];
    const updatedSettings = { ...toolSettings, [key]: value };

    switch (settingType) {
      case ToolSettingTypes.ToolSettingsToggle:
        await toggleToolSettings(
          updatedSettings.enabled,
          () => {
            hookShowToast(
              updatedSettings.enabled
                ? en.settings.notifications.toasts.toolSettings.enabled.title
                : en.settings.notifications.toasts.toolSettings.disabled.title
            );
          },
          () => {
            hookShowToast(en.settings.notifications.toasts.toolSettings.updated.error.title);
          }
        );
        break;
      case ToolSettingTypes.ToolSettingsOnly:
        setToolSettings(updatedSettings);
        setTouchedToolSettings((prev: TTouchedToolSettings) => ({ ...prev, [key]: true }));
        validateField(key, value);
        break;
      default:
        throw new Error('Invalid setting type');
    }
  };

  /**
   * Drop a setting from the touched settings.
   */
  const dropTouchedSetting = (key: keyof TTouchedToolSettings): void => {
    setTouchedToolSettings((prev: TTouchedToolSettings) => {
      const updated = { ...prev };
      delete updated[key];
      return updated;
    });
  };

  /**
   * Updates the page loading state when tool settings are loading.
   */
  useEffect(() => {
    setIsPageLoading(isToolSettingsLoading || isToolSettingsUpdating || isToolSettingsToggling);
  }, [isToolSettingsLoading, isToolSettingsUpdating, isToolSettingsToggling, setIsPageLoading]);

  /**
   * Prevents page unload if tool settings are touched.
   */
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent): void => {
      if (isToolSettingsTouched) {
        e.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return (): void => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isToolSettingsTouched]);

  return {
    toolSettings,
    validationResult,
    isReadOnlyPermissions,
    isPageLoading,
    isPageFullyLoaded,
    isToolSettingsEnabled: Boolean(toolSettings?.enabled ?? false),
    isToolSettingsTouched,
    isUpdateModalOpened,
    isLeavePageModalOpened,
    validateField,
    toggleUpdateModal: (state?: boolean) => setIsUpdateModalOpened((prev) => state || !prev),
    fetchToolSettings,
    updateToolSettings,
    updateToolSetting,
    dropTouchedSetting,
    handlePageStay: () => leavePageModalInstance.reset?.(),
    handlePageLeave: () => leavePageModalInstance.proceed?.(),
  };
};
