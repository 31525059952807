import { IMerchantProgram, IProgramTrackingSettings, IGraphQLState } from '../types';
import { GET_TRACKING_SETTINGS_BY_PROGRAM_ID } from '../modules/merchants/programs/graphql/queries';

import { useGraphQLQuery } from './useGraphQLQuery';

interface IUseProgramsApiHook {
  fetchTrackingSettingsByProgramId: ({ id }: { id: string }) => Promise<IProgramTrackingSettings>;
  queryStates: {
    trackingSettings: IGraphQLState<IMerchantProgram>;
  };
}

export const useProgramsApi = (): IUseProgramsApiHook => {
  const { fetch: fetchMerchantProgramById, queryState: trackingSettings } = useGraphQLQuery<
    IMerchantProgram,
    { id: string }
  >(GET_TRACKING_SETTINGS_BY_PROGRAM_ID, 'getTrackingSettingsByProgramId', 'programV2');

  const fetchTrackingSettingsByProgramId = async ({ id }: { id: string }): Promise<IProgramTrackingSettings> => {
    const result = await fetchMerchantProgramById({ id });
    return result.trackingSettings ?? {};
  };

  return {
    fetchTrackingSettingsByProgramId,
    queryStates: {
      trackingSettings,
    },
  };
};
