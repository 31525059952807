import { gql } from '@apollo/client';

export const GET_TRACKING_SETTINGS_BY_PROGRAM_ID = gql`
  query getProgram($id: ID!) {
    programV2(id: $id) {
      id
      trackingSettings {
        landingPage
        urlQueryString
        cookieDuration
        statsRelay
        nofollow
        thirdPartyUrl
        thirdPartyUrlFormat
        thirdPartyStatus
      }
    }
  }
`;
