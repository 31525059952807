import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { ErrorBanner, FileUpload, Select } from '../../../../../components';
import { useBulkUpload } from './hooks';
import { BulkUploadConfirmModal } from './BulkUploadConfirmModal';
import { useUserInfo } from '../../../../../hooks';
import { FILE_EXTENSIONS, USER_TYPES_ID } from '../../../../../utils';
import { STEPS, SUCCESS_TEXT } from './utils';
import { DefaultPropsType } from '../../../../../types';

import * as Styled from './styles';

type BulkUploadProps = DefaultPropsType;

export const BulkUpload = ({ permissionsCodeList = [] }: BulkUploadProps) => {
  const { hookIsReadOnlyList, ...hook } = useBulkUpload(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  const userHook = useUserInfo();

  return (
    hook.hookFileDetails === true ? (
      <Styled.WrapperStyled>
        <ErrorBanner
          isOpen={!!hook.hookErrorMessage}
          message={hook.hookErrorMessage}
        />
        {hook.hookSelectedMerchant && (
          <Styled.MerchantNameStyled bold>
            {`${hook.hookSelectedMerchant.label}`}
          </Styled.MerchantNameStyled>
        )}
        <Styled.DetailsStyled>
          {SUCCESS_TEXT.SUCCESSFUL_UPLOAD}
          <br />
          {hook.hookTransactionCount <= 1
            ? `It contains ${hook.hookTransactionCount} transaction at ${hook.hookEarliestDate}`
            : `It contains ${hook.hookTransactionCount} transactions from ${hook.hookEarliestDate} to ${hook.hookLatestDate}`}
          <br />
          {SUCCESS_TEXT.TOTAL_VALUE}
        </Styled.DetailsStyled>
        <Styled.DetailsStyled bold>
          {`${hook.hookGrossRevenue} ${SUCCESS_TEXT.GROSS_SALES}`}
          <br />
          {`${hook.hookNetRevenue} ${SUCCESS_TEXT.NET_SALES}`}
        </Styled.DetailsStyled>
        <Styled.DetailsStyled>
          {SUCCESS_TEXT.MATCH_RECORDS_ONE}
          <br />
          {SUCCESS_TEXT.MATCH_RECORDS_TWO}
        </Styled.DetailsStyled>
        <Styled.DetailsStyled>
          {SUCCESS_TEXT.NOTE_ONE}
          <br />
          {SUCCESS_TEXT.NOTE_TWO}
        </Styled.DetailsStyled>
        <Styled.FooterStyled>
          <Styled.ButtonsStyled>
            <Styled.ButtonStyled
              theme="secondary"
              onClick={hook.hookBack}
              width="150px"
            >
              Back
            </Styled.ButtonStyled>
            <Styled.ButtonStyled
              onClick={hook.hookSetModal}
              width="150px"
              disabled={isReadOnly}
            >
              Save Transactions
            </Styled.ButtonStyled>
          </Styled.ButtonsStyled>
        </Styled.FooterStyled>
        <BulkUploadConfirmModal
          isOpen={hook.hookModal}
          onClose={hook.hookCloseModal}
          grossRevenue={hook.hookGrossRevenue}
          netRevenue={hook.hookNetRevenue}
          transactionCount={hook.hookTransactionCount || 0}
          earliestDate={hook.hookEarliestDate}
          latestDate={hook.hookLatestDate}
          next={hook.hookConfirmSubmit}
          loading={hook.hookLoading}
        />
      </Styled.WrapperStyled>
    ) : (
      <Styled.WrapperStyled>
        <ErrorBanner
          isOpen={!!hook.hookErrorMessage}
          message={hook.hookErrorMessage}
        />
        {(userHook.hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN && userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT) ? (
          <>
            <Styled.RowStyled>
              <Select
                required
                label="Merchant"
                name="merchantSelect"
                selected={hook.hookSelectedMerchant}
                onChange={hook.hookSetSelectedMerchant}
                options={hook.hookMerchantOptions}
                isLoading={hook.hookLoading}
                width="75%"
                error={hook.hookMerchantError}
                isDisabled={isReadOnly}
              />
              <Styled.ButtonAreaStyled>
                <Styled.ButtonStyled
                  theme="tertiary"
                  onClick={hook.hookTXTDownload}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                  {' '}
                  Download .TXT
                </Styled.ButtonStyled>

                <Styled.ButtonStyled
                  theme="tertiary"
                  onClick={hook.hookCSVDownload}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                  {' '}
                  Download .CSV
                </Styled.ButtonStyled>
              </Styled.ButtonAreaStyled>
            </Styled.RowStyled>
            <Styled.StepStyled>
              <h2>Step 1</h2>

              <p>{STEPS.ONE}</p>
            </Styled.StepStyled>
          </>
        ) : (
          <Styled.RowStyled merchant>
            <Styled.StepStyled>
              <h2>Step 1</h2>
              <p>{STEPS.ONE}</p>
            </Styled.StepStyled>
            <Styled.ButtonAreaStyled>
              <Styled.ButtonStyled
                theme="tertiary"
                onClick={hook.hookTXTDownload}
              >
                <FontAwesomeIcon icon={faFileDownload} />
                {' '}
                Download .TXT
              </Styled.ButtonStyled>

              <Styled.ButtonStyled
                theme="tertiary"
                onClick={hook.hookCSVDownload}
              >
                <FontAwesomeIcon icon={faFileDownload} />
                {' '}
                Download .CSV
              </Styled.ButtonStyled>
            </Styled.ButtonAreaStyled>

          </Styled.RowStyled>
        )}

        <Styled.StepStyled>
          <h2>Step 2</h2>
          <p>
            {STEPS.TWO_ONE}
            <br />
            <br />
            {STEPS.TWO_TWO}
          </p>
        </Styled.StepStyled>

        <Styled.StepStyled>
          <h2>Step 3</h2>
          <p>
            {STEPS.THREE_ONE}
            <br />
            {STEPS.THREE_TWO}
          </p>
        </Styled.StepStyled>

        <FileUpload
          label="Upload CSV or TXT"
          info="Max filesize is 4MB"
          width="70%"
          maxFileSize={4000000}
          fileSizeUnit="MB"
          onChange={hook.hookOnUpload}
          accept={`${FILE_EXTENSIONS.CSV}, ${FILE_EXTENSIONS.MS_EXCEL}, ${FILE_EXTENSIONS.SPREADSHEET}, ${FILE_EXTENSIONS.TXT}`}
          key={hook.hookResetFileUpload}
          error={hook.hookFileSubmitError}
          disabled={isReadOnly}
        />

        <Styled.FooterStyled>
          <Styled.ButtonStyled
            theme="secondary"
            onClick={hook.hookCancel}
            disabled={hook.hookFile === undefined && hook.hookSelectedMerchant === undefined}
            width="150px"
          >
            Cancel
          </Styled.ButtonStyled>

          <Styled.ButtonStyled
            theme="primary"
            onClick={hook.hookSubmit}
            disabled={hook.hookFile === undefined || hook.hookSelectedMerchant === undefined || hook.hookSelectedMerchant.value === '' || isReadOnly}
            width="150px"
            loading={hook.hookLoading}
          >
            Next
          </Styled.ButtonStyled>
        </Styled.FooterStyled>
      </Styled.WrapperStyled>
    )
  );
};
