import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as ICONS from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, ReactElement } from 'react';

import { useActAsContext } from '../../../../../../context';
import { path } from '../../../../../../utils';
import { useSideMenu } from '../../../../hooks/useSideMenu';
import { ISideMenuItem } from '../../../../types';
import { SIDE_MENU_LEVELS } from '../../SideMenuItem.constants';
import { IconAsLink, MenuItem } from '../../SideMenuItem.styles';
import SideMenuCompanySection, {
  CompanyManagementTypes,
} from '../SideMenuCompanySection/SideMenuCompanySection.component';
import SideMenuNestedAsHref from '../SideMenuNestedAsHref/SideMenuNestedAsHref.component';
import SideMenuNestedExpandable from '../SideMenuNestedExpandable/SideMenuNestedExpandable.component';

import { MenuItemContainer, ItemHeaderContainer, ItemContentContainer } from './SideMenuItemExpandable.styles';

interface ISideMenuItemExpandableProps {
  menuItem: ISideMenuItem;
}

const SideMenuItemExpandable: FC<ISideMenuItemExpandableProps> = ({ menuItem }): ReactElement => {
  const { actingAsMerchant, actingAsPublisher } = useActAsContext();
  const { isCollapsed, isSideMenuItemOpened, isSideMenuItemAvailable, isUnderManagement, toggleSideMenuItem } =
    useSideMenu();

  const renderNestedMenuItem = (nestedMenuItem: ISideMenuItem, level: number): ReactElement | null => {
    if (!isSideMenuItemAvailable(nestedMenuItem)) return null;

    return nestedMenuItem.href ? (
      <SideMenuNestedAsHref menuItem={nestedMenuItem} itemLevel={level} key={nestedMenuItem.id} />
    ) : (
      <SideMenuNestedExpandable
        menuItem={nestedMenuItem}
        itemLevel={level}
        renderNestedMenuItem={renderNestedMenuItem}
        key={nestedMenuItem.id}
      />
    );
  };

  return (
    <MenuItemContainer
      isCollapsed={isCollapsed}
      show={isSideMenuItemOpened(SIDE_MENU_LEVELS.TOP_LEVEL, menuItem.id)}
      key={menuItem.label}
      id={menuItem.label.replace(/ /g, '')}
    >
      <ItemHeaderContainer
        onClick={() => toggleSideMenuItem(menuItem.id, SIDE_MENU_LEVELS.TOP_LEVEL, !!menuItem.subSections)}
      >
        <IconAsLink to={menuItem.management ? menuItem.management.href : menuItem?.subSections?.[0].href || '#'}>
          <FontAwesomeIcon icon={ICONS[menuItem.icon as keyof IconProp]} />
        </IconAsLink>
        <p>{menuItem.label}</p>
        <FontAwesomeIcon icon={ICONS.faChevronRight} />
      </ItemHeaderContainer>

      <ItemContentContainer>
        {/* Render company menu section if management section defined in item */}
        {menuItem.management && (
          <>
            <MenuItem
              to={menuItem.management.href}
              level={SIDE_MENU_LEVELS.SUB_LEVEL}
              isUnderManagement={isUnderManagement(menuItem.id)}
            >
              {menuItem.management.label}
            </MenuItem>
            {/* Company menu section for the Merchants */}
            <SideMenuCompanySection
              type={CompanyManagementTypes.MERCHANT}
              menuItem={menuItem}
              actingAs={actingAsMerchant}
              pathName={path.merchantManagement.name}
              renderNestedMenuItem={renderNestedMenuItem}
            />
            {/* Company menu section for the Publishers */}
            <SideMenuCompanySection
              type={CompanyManagementTypes.PUBLISHER}
              menuItem={menuItem}
              actingAs={actingAsPublisher}
              pathName={path.publisherSearch.name}
              renderNestedMenuItem={renderNestedMenuItem}
            />
          </>
        )}

        {/* Render subsections for the menu if this item have subsections and not management menu */}
        {!menuItem.management &&
          menuItem.subSections &&
          menuItem.subSections.map((nestedMenuItem: ISideMenuItem) =>
            renderNestedMenuItem(nestedMenuItem, SIDE_MENU_LEVELS.SUB_LEVEL)
          )}
      </ItemContentContainer>
    </MenuItemContainer>
  );
};

export default SideMenuItemExpandable;
