import React from 'react';
import { faPlus, faFileDownload, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import {
  AppWrapper,
  Button,
  PageTitle,
  Pagination,
  RecordsDropdown,
  ReportPlaceholder,
  Table,
} from '../../../components';
import { path } from '../../../utils';
import { tableColumn } from './contracts';
import { useMerchantManagement } from './hook';
import { MERCHANTS_LOADING } from './utils';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type MerchantManagementProps = DefaultPropsType;

const MerchantManagement = ({ permissionsCodeList = [] }: MerchantManagementProps) => {
  const { hookIsReadOnlyList, ...hook } = useMerchantManagement(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.WrapperStyled>
        <Styled.HeaderStyled>
          <PageTitle loadingText={MERCHANTS_LOADING} isLoading={hook.hookLoading}>
            {path.merchantManagement.name}
          </PageTitle>

          <Styled.HeaderButtonsAreaStyled>
            {!isReadOnly && (
              <Styled.ButtonStyled onClick={hook.hookNavigate}>
                <FontAwesomeIcon icon={faPlus} /> Add Merchant
              </Styled.ButtonStyled>
            )}

            <Styled.ButtonStyled theme="tertiary" onClick={hook.hookMerchantCSV} loading={hook.hookCSVLoading}>
              <FontAwesomeIcon icon={faFileDownload} /> Download CSV
            </Styled.ButtonStyled>
          </Styled.HeaderButtonsAreaStyled>
        </Styled.HeaderStyled>

        <Styled.FiltersStyled>
          <Styled.InputTextStyled
            type="text"
            placeholder="Search from Name/ID/URL..."
            faIcon={faSearch}
            name="search"
            value={hook.hookSearch}
            onChange={hook.hookSetSearch}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !hook.hookLoading) hook.hookSearchButtonHandler();
            }}
          />

          <Styled.FiltersSelectStyled>
            <span>Filter</span>
            <Styled.SelectStyled
              name="status"
              placeholder="Status"
              selected={hook.hookStatus}
              options={hook.hookStatusList}
              isLoading={hook.hookLoading}
              isDisabled={hook.hookLoading}
              onChange={hook.hookSetStatus}
            />
            <Styled.SelectStyled
              name="merchantTypes"
              placeholder="Merchant Type"
              selected={hook.hookType}
              options={hook.hookTypeList}
              isLoading={hook.hookLoading}
              isDisabled={hook.hookLoading}
              onChange={hook.hookSetType}
            />
            <Styled.SelectStyled
              name="countries"
              placeholder="Country"
              selected={hook.hookCountry}
              options={hook.hookCountryOptions}
              isLoading={hook.hookLoading}
              isDisabled={hook.hookLoading}
              onChange={hook.hookSetCountry}
            />
          </Styled.FiltersSelectStyled>
        </Styled.FiltersStyled>

        <Styled.OptionsStyled>
          <RecordsDropdown
            selected={hook.hookRecords}
            options={hook.hookRecordOptions}
            currentPage={hook.hookCurrentPage}
            totalRecords={hook.hookTotalRecords}
            onChange={hook.hookSetRecords}
          />
          <Styled.RightButtonsStyled>
            <Button type="button" theme="text-only" onClick={hook.hookClearForm}>
              Clear Form
            </Button>
            <Button
              onClick={hook.hookSearchButtonHandler}
              disabled={hook.hookLoading}
              loading={hook.hookLoading}
              width="120px"
            >
              <FontAwesomeIcon icon={faSearch} style={{ marginRight: 5 }} /> Search
            </Button>
          </Styled.RightButtonsStyled>
        </Styled.OptionsStyled>

        {((hook.hookMerchantList && hook?.hookMerchantList?.length > 0) ||
          (hook.hookLoading && hook.hookSearchClicked)) && (
          <>
            <Table
              columns={tableColumn}
              data={hook.hookMerchantList}
              isLoading={hook.hookLoading}
              onSort={hook.hookHandleSort}
              sortColumn={hook.hookSortColumn}
              onRowClick={hook.hookHandleRowClickActAs}
            />

            <Pagination
              total={hook.hookTotalPages}
              onPageChange={hook.hookSetPage}
              currentPage={hook.hookCurrentPage}
            />
          </>
        )}

        {(!hook.hookMerchantList || hook.hookMerchantList.length < 1) &&
          !(hook.hookLoading && hook.hookSearchClicked) && (
            <ReportPlaceholder
              submitted={hook.hookSearchClicked}
              text={hook.hookSearchClicked ? 'Sorry! No results found' : 'Click search to get started'}
              subtext={hook.hookSearchClicked ? "We couldn't find what you're looking for. Please try it again." : ''}
            />
          )}
      </Styled.WrapperStyled>
    </AppWrapper>
  );
};

export default MerchantManagement;
