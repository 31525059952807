/* eslint-disable max-len */
export const RULE_MANAGER = {
  DELETE_TITLE: 'Delete Rule',
  ADD_RULE_GROUP_TITLE: 'Add Rule Group',
  ADD_RULE_GROUP_DESCRIPTION: 'Enter a new group name to be used to categorize rules and group similar ones together.',
  ADD_RULE_GROUP_INPUT_LABEL: 'Group Name',
  ADD_RULE_GROUP_INPUT_PLACEHOLDER: 'Enter group name here',
  ADD_RULE_GROUP_SUBMIT_BUTTON: 'Save',
  ADD_RULE_GROUP_DUPLICATE_NAME_ERROR: 'A rule with this name already exists. Please enter a different name.',
  ADD_RULE_GROUP_ERROR: 'Could not create the rule group. Please try again.',
  ADD_RULE_GROUP_SUCCESS: 'Rule group created successfully',
  ADD_EVALUATION_RULE: 'Add Evaluation Rule',
  ADD_MONITORING_RULE: 'Add Monitoring Rule',
  RULE_CSV_BUTTON_TITLE: 'Download CSV',
  CLEAR_FORM_BUTTON: 'Clear Form',
  DEACTIVATE_RULE: 'Deactivate Rule',
  LABELS: {
    PRODUCT_CATEGORY: 'Product Category',
    PRODUCT_NAME: 'Product Name/ID',
    STATUS: 'Status',
    RULES: 'Rules',
    RULE_TYPES: 'Rule Types',
    RULE_GROUPS: 'Rule Groups',
  },
  RULE_DEACTIVATED_TOAST: 'Rule successfully deactivated.',
  RULE_DELETED_TOAST: 'Rule successfully deleted.',
  RULE_DELETE_WARNING: 'Are you sure you want to delete this rule?',
  RULE_DELETE_CANCEL: 'Cancel',
  RULE_DELETE: 'Delete',
  EDIT_RULES: {
    RULE_NAME: 'Rule Name',
    CREATED_ON: 'Created On',
    RULE_GROUP: 'Rule Group',
    RULE_STATUS: 'Rule Status',
    MERCHANT_NAME: 'Merchant Name',
    MERCHANT_NAME_DISCLAIMER: 'This name must appear on the page.',
    NOMINATED_FIELD: 'Nominated Field',
    NOMINATED_FIELD_DISCLAIMER: 'This value must appear on the page.',
    TEXT_MATCH: 'Text Match',
    TEXT_MATCH_DISCLAIMER: 'At least one of these text inputs must appear on the page.',
    DESCRIPTION: 'Update rule settings - any changes take effect immediately.',
    MINIMUM_CONDITIONS: 'Minimum conditions for the rule to be applicable',
    RULE_CRITERIA: 'Rule Criteria',
    RULE_REQUIREMENT: 'Rule Requirement',
    PRODUCT_FEED_DATA: 'Product Feed Data',
    RULE_GROUP_NAME: 'Rule Group Name',
    SAVE_BUTTON: 'Save Changes',
    CANCEL_BUTTON: 'Cancel',
    DELETE_BUTTON: 'Delete Rule',
    RULE_UPDATED_SUCCESS: 'Rule updated successfully.',
    RULE_UPDATED_WARNING: 'Are you sure you want to update this rule?',
    RULE_UPDATED_ERROR: 'Could not update the rule. Please try again.',
    RULE_DUPLICATE_NAME_ERROR: 'A rule with this name already exists. Please enter a different name.',
    RULE_MISSING_NAME_ERROR: 'Please enter a rule name.',
    RULE_STATUS_SETTINGS_DESCRIPTION:
      'Complete the following statement using the provided dropdowns in order to indicate what Rule Status will apply to a finding.',
  },
};

export const defaultProductCategoryOption: SelectOption = {
  label: 'All Categories',
  value: 'All Categories',
};

export const defaultRuleTypesOptions: SelectOption[] = [
  {
    label: 'All Rule Types',
    value: 'All Rule Types',
  },
  {
    label: 'Evaluation Product',
    value: 'Evaluation Product',
  },
  {
    label: 'Evaluation Text',
    value: 'Evaluation Text',
  },
  {
    label: 'Monitoring',
    value: 'Monitoring',
  },
];

export const defaultProductNameId: SelectOption[] = [
  {
    label: 'All Products',
    value: 'All Products',
  },
  {
    label: 'No Specific Products',
    value: 'No Specific Products',
  },
];

export const defaultRuleGroup: SelectOption = {
  label: 'All Rule Groups',
  value: 'All Rule Groups',
};

export const defaultRules: SelectOption = {
  label: 'All Rules',
  value: 'All Rules',
};

export const statusOptions: SelectOption[] = [
  {
    label: 'All Status',
    value: 'All Status',
  },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
];

export const STATUS_TYPES = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const RULE_TYPES = {
  EVAL_PRODUCT: 'Evaluation Product',
  EVAL_GROUP: 'Evaluation Group',
  EVAL_TEXT: 'Evaluation Text',
  MONITORING: 'Monitoring',
};
