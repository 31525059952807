import styled from 'styled-components';
import { colors, fonts } from 'styles/theme';
import { Button, InputText, Select } from 'components/index';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 34rem;
  padding: 1rem;
`;

export const TitleStyled = styled.p`
  color: ${colors.color1};
  ${fonts.gotham.H26M};
  margin-bottom: 2rem;
`;

export const InputStyled = styled(InputText)`
  margin-bottom: 2rem;
`;

export const SelectStyled = styled(Select)`
  margin-bottom: 2rem;
`;

export const ButtonsWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  white-space: nowrap;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1.5rem;
`;

export const RemovePromptStyled = styled.div`
  margin: 1.5rem 0;
  line-height: 1.5rem;
`;

export const BoldText = styled.span`
  ${fonts.lato.H16B}
`;
export const BoldTextDiv = styled.div`
  ${fonts.lato.H16B}
`;

export const UrlWrapper = styled.div`
  padding: 0.5rem 0;
  width: 100%;
  word-break: break-word;
`;
