import React, { ReactElement } from 'react';

import { LoadingDots } from 'components';

import { LoaderContainer } from './ToolSettingsPageLoader.styles';

const ToolSettingsPageLoader = (): ReactElement => {
  return (
    <LoaderContainer>
      <LoadingDots />
    </LoaderContainer>
  );
};

export default ToolSettingsPageLoader;
