import React from 'react';
import * as Styled from '../../styles';
import {
  InputTextarea,
  InputCalendar,
  Calendar,
  InputText,
  Select,
  Checkbox,
} from '../../../../../../../../../components';
import { MarketingItems } from '../../MarketingItemComponent';
import { PRODUCT_FEED } from '../../../../enums';
import { PERIOD_OPTIONS } from '../../../../../../../../../utils';

type BankingProps = {
  hook: any;
  isReadOnly: boolean;
};

export const Banking = ({ hook, isReadOnly }: BankingProps) => (
  <Styled.WrapperStyled>
    <Styled.InnerWrapperStyled isFirst>
      <Styled.InputWrappers inverted>
        <InputTextarea
          label={PRODUCT_FEED.USAGE.label}
          placeholder={PRODUCT_FEED.USAGE.placeholder}
          value={hook?.hookUsageValue}
          onChange={hook.hookUsageChange}
          disabled={isReadOnly}
        />
      </Styled.InputWrappers>
      <Styled.InputWrappers>
        <InputCalendar
          isDisabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.VALID_UNTIL.label}
          value={hook.hookValidValue}
          onClick={hook.hookShowCalendar}
          placeholder={PRODUCT_FEED.VALID_UNTIL.placeholder}
        />
        <Calendar
          onCancel={hook.hookCancelCalendar}
          onApply={hook.hookApplyCalendar}
          isOpen={hook.hookCalendarOpen}
          isSingle
        />
      </Styled.InputWrappers>
      <Styled.InputWrappers>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          tooltip={PRODUCT_FEED.MONETARY_TOOL_TIP}
          label={PRODUCT_FEED.MONTHLY_FEES.label}
          value={hook.hookMonthlyFee}
          type="text"
          onChange={hook.hookChangeMonthlyFee}
          placeholder={PRODUCT_FEED.MONTHLY_FEES.placeholder}
          error={hook.hookNewProductErrors.monthlyFee}
          onBlur={hook.hookValidate}
        />
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.MONTHLY_FEES_DETAILS.label}
          value={hook.hookMonthlyDetails}
          onChange={hook.hookChangeMonthlyDetails}
          type="text"
          placeholder={PRODUCT_FEED.MONTHLY_FEES_DETAILS.placeholder}
        />
      </Styled.InputWrappers>
      <Styled.InputWrappers>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.CLOSING_BALANCE.label}
          value={hook.hookClosingBalance}
          type="text"
          onChange={hook.hookChangeClosingBalance}
          placeholder={PRODUCT_FEED.CLOSING_BALANCE.placeholder}
          error={hook.hookNewProductErrors.closingBalance}
          onBlur={hook.hookValidate}
        />
      </Styled.InputWrappers>
      <Styled.InputWrappers>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          tooltip={PRODUCT_FEED.MONETARY_TOOL_TIP}
          label="apy ( % )"
          value={hook.hookApy}
          type="text"
          onChange={hook.hookChangeApy}
          placeholder={PRODUCT_FEED.APY.placeholder}
          error={hook.hookNewProductErrors.apy}
          onBlur={hook.hookValidate}
        />
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.APY_DETAILS.label}
          value={hook.hookApyDetails}
          onChange={hook.hookChangeApyDetails}
          type="text"
          placeholder={PRODUCT_FEED.APY_DETAILS.placeholder}
        />
      </Styled.InputWrappers>
      <Styled.InputWrappers>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.INTEREST_RATE.label}
          value={hook.hookInterestRate}
          type="text"
          onChange={hook.hookChangeInterestRate}
          placeholder={PRODUCT_FEED.INTEREST_RATE.placeholder}
          error={hook.hookNewProductErrors.interestRate}
          onBlur={hook.hookValidate}
        />
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.INTEREST_RATE_DETAILS.label}
          value={hook.hookInterestRateDetails}
          onChange={hook.hookChangeInterestRateDtails}
          type="text"
          placeholder={PRODUCT_FEED.INTEREST_RATE_DETAILS.placeholder}
        />
      </Styled.InputWrappers>
      <Styled.InputWrappers>
        <Select
          isDisabled={hook.hookLoading || isReadOnly}
          selected={hook.hookInterestCalculated}
          onChange={hook.hookChangeCalculated}
          label={PRODUCT_FEED.INTEREST_CALCULATED.label}
          placeholder={PRODUCT_FEED.INTEREST_CALCULATED.placeholder}
          options={PERIOD_OPTIONS}
        />
        {hook.hookInterestCalculated?.label === 'Other' && (
          <InputText
            disabled={hook.hookLoading || isReadOnly}
            label={PRODUCT_FEED.INTEREST_CALCULATED_DAYS.label}
            value={hook.hookInterestCalculatedDays}
            onChange={hook.hookChangeCalculatedDays}
            type="text"
            placeholder={PRODUCT_FEED.INTEREST_CALCULATED_DAYS.placeholder}
            error={hook.hookNewProductErrors.interestCalculatedDays}
            onBlur={hook.hookValidate}
          />
        )}
      </Styled.InputWrappers>
      <Styled.InputWrappers>
        <Select
          isDisabled={hook.hookLoading || isReadOnly}
          selected={hook.hookInterestPaid}
          onChange={hook.hookChangePaid}
          label={PRODUCT_FEED.INTEREST_PAID.label}
          placeholder={PRODUCT_FEED.INTEREST_PAID.placeholder}
          options={PERIOD_OPTIONS}
        />
        {hook.hookInterestPaid?.label === 'Other' && (
          <InputText
            disabled={hook.hookLoading || isReadOnly}
            label={PRODUCT_FEED.INTEREST_PAID_DAYS.label}
            value={hook.hookInterestPaidDays}
            onChange={hook.hookChangePaidDays}
            type="text"
            placeholder={PRODUCT_FEED.INTEREST_PAID_DAYS.placeholder}
            error={hook.hookNewProductErrors.interestPaidDays}
            onBlur={hook.hookValidate}
          />
        )}
      </Styled.InputWrappers>
      <Styled.InputWrappers>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.PROMO_INTEREST.label}
          value={hook.hookPromo}
          type="text"
          onChange={hook.hookChangePromo}
          placeholder={PRODUCT_FEED.PROMO_INTEREST.placeholder}
          error={hook.hookNewProductErrors.promoRate}
          tooltip={PRODUCT_FEED.MONETARY_TOOL_TIP}
          onBlur={hook.hookValidate}
        />
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.MIN_OPEN_DEPOSIT.label}
          value={hook.hookMinimumOpen}
          onChange={hook.hookChangeMinimumOpen}
          type="text"
          placeholder={PRODUCT_FEED.MIN_OPEN_DEPOSIT.placeholder}
          error={hook.hookNewProductErrors.minimumOpenDeposit}
          onBlur={hook.hookValidate}
        />
      </Styled.InputWrappers>
      <Styled.InputWrappers>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.TRANS_ATM.label}
          value={hook.hookTransAtm}
          type="text"
          onChange={hook.hookChangeTransFees}
          placeholder={PRODUCT_FEED.TRANS_ATM.placeholder}
          error={hook.hookNewProductErrors.transAtm}
          onBlur={hook.hookValidate}
        />
        <Styled.DoubleWrapper>
          <InputText
            disabled={hook.hookLoading || isReadOnly}
            label={PRODUCT_FEED.TRANS_ETR.label}
            value={hook.hookTransET}
            onChange={hook.hookChangeTransET}
            type="text"
            placeholder={PRODUCT_FEED.TRANS_ETR.placeholder}
            error={hook.hookNewProductErrors.etr}
            onBlur={hook.hookValidate}
          />
          <InputText
            disabled={hook.hookLoading || isReadOnly}
            label={PRODUCT_FEED.TRANS_INTERNAL.label}
            value={hook.hookTransInternal}
            onChange={hook.hookChangeTransInternal}
            type="text"
            placeholder={PRODUCT_FEED.TRANS_INTERNAL.placeholder}
            error={hook.hookNewProductErrors.transInternal}
            onBlur={hook.hookValidate}
          />
        </Styled.DoubleWrapper>
      </Styled.InputWrappers>
      <Styled.InputWrappers>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.FREE_VOLUME.label}
          value={hook.hookFree}
          type="text"
          onChange={hook.hookChangeFree}
          placeholder={PRODUCT_FEED.FREE_VOLUME.placeholder}
          error={hook.hookNewProductErrors.free}
          onBlur={hook.hookValidate}
        />
      </Styled.InputWrappers>
      <Styled.InputWrappers>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.MINIMUM_BALANCE.label}
          value={hook.hookMinBalance}
          type="text"
          onChange={hook.hookChangeMinBalance}
          placeholder={PRODUCT_FEED.FREE_VOLUME.placeholder}
          error={hook.hookNewProductErrors.minBalance}
          onBlur={hook.hookValidate}
        />
        <Checkbox
          label={PRODUCT_FEED.NO_MIN_BALANCE.label}
          checked={hook.hookMinBalanceChecked}
          onChange={hook.hookChangeMinChecked}
          disabled={isReadOnly}
        />
      </Styled.InputWrappers>
    </Styled.InnerWrapperStyled>
    <MarketingItems
      setMarketingItems={hook.hookSetMarketingItems}
      disabled={hook.hookLoading || isReadOnly}
      addMarketingItem={hook.hookAddMarketingItem}
      marketingItems={hook.hookMarketingItems}
      handleXbutton={hook.hookHandleX}
    />
    <Styled.InnerWrapperStyled>
      <Styled.WrapperTitleStyled>{PRODUCT_FEED.BANKING_INFO}</Styled.WrapperTitleStyled>
      <Styled.InputWrappers inverted>
        <InputTextarea
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.WELCOME_OFFERS.label}
          placeholder={PRODUCT_FEED.WELCOME_OFFERS.placeholder}
          value={hook.hookWelcomeOffer}
          onChange={hook.hookChangeWelcome}
        />
      </Styled.InputWrappers>
      <Styled.InputWrappers inverted>
        <InputTextarea
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.INSURANCE.label}
          placeholder={PRODUCT_FEED.INSURANCE.placeholder}
          value={hook.hookInsurance}
          onChange={hook.hookChangeInsurance}
        />
      </Styled.InputWrappers>
      <Styled.InputWrappers inverted>
        <InputTextarea
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.GEO_AVAL.label}
          placeholder={PRODUCT_FEED.GEO_AVAL.placeholder}
          value={hook.hookGeoAval}
          onChange={hook.hookChangeGeoAval}
        />
      </Styled.InputWrappers>
    </Styled.InnerWrapperStyled>
  </Styled.WrapperStyled>
);
