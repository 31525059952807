import { gql } from '@apollo/client';

export const LIST_PRODUCTS_AND_CATEGORIES = gql`
  query ListAvailableProductsForRuleInput($input: ListAvailableProductsForRuleInput!) {
    listAvailableProductsForRule(input: $input) {
      products {
        category
        productFields
        products {
          id
          customizedProductId
          productCategory
          name
          productFeedSection {
            apyRate
            interestRate
            legalReferenceItems {
              name
              description
            }
            mortgageAprRate
            annualFee
            balanceTransferRegular
            balanceTransferIntroRate
          }
          validRuleRequirements {
            ... on CheckRuleValidRuleBasicRequirementType {
              name
              value
            }
            ... on CheckRuleValidRuleLegalReferenceItemRequirementType {
              name
              values: value {
                name
                description
              }
            }
          }
        }
      }
    }
  }
`;
