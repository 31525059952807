import Joi from 'joi';

import { IFintelCheckToolSettings, CheckFrequencySettingEnum } from '../../types';
import en from '../../locales/en.json';

export const toolSettingsSchema: Record<keyof Omit<IFintelCheckToolSettings, 'merchantId'>, Joi.Schema> = {
  enabled: Joi.boolean().required(),
  brandName: Joi.string().trim().max(100).allow('').default('').messages({
    'string.max': en.settings.tabs.check.items.brandName.input.errors.validation.max,
  }),
  frequencySettings: Joi.string()
    .valid(...Object.values(CheckFrequencySettingEnum))
    .required(),
};
