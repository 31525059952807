import { useCallback, useMemo } from 'react';
import { useLazyQuery, OperationVariables } from '@apollo/client';
import { DocumentNode } from 'graphql';

import { IGraphQLState } from '../types';
import { handleGraphQLResponse, handleOperationError } from '../helpers/graphql.helpers';

interface IGraphQLQueryReturn<TData, TVariables> {
  fetch: (variables: TVariables) => Promise<TData>;
  queryState: IGraphQLState<TData>;
}

export const useGraphQLQuery = <TData, TVariables extends OperationVariables>(
  query: DocumentNode,
  operationName: string,
  responsePath: string
): IGraphQLQueryReturn<TData, TVariables> => {
  const [executeQuery, queryResult] = useLazyQuery(query);

  const fetch = useCallback(
    async (variables: TVariables): Promise<TData> => {
      try {
        const result = await executeQuery({ variables, errorPolicy: 'all' });
        return handleGraphQLResponse(operationName, result, responsePath);
      } catch (error: unknown) {
        return handleOperationError(operationName, error);
      }
    },
    [executeQuery, operationName, responsePath]
  );

  const queryState: IGraphQLState<TData> = useMemo(
    () => ({
      loading: queryResult.loading,
      error: queryResult.error ?? null,
      data: queryResult.data ? queryResult.data[responsePath] : null,
    }),
    [queryResult, responsePath]
  );

  return { fetch, queryState };
};
