/* eslint-disable max-len */
import { Select, Spacer } from 'components';
import _ from 'lodash';
import { useAddEvaluationRule } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/hooks/useAddEvaluationRule';
import * as Styled from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/styles';
import React from 'react';

export const ProductSelection = ({ hook }: { hook: ReturnType<typeof useAddEvaluationRule> }): JSX.Element => (
  <Styled.FormStyled>
    <Select
      required
      name="productCategory"
      label="Select Product Category"
      tooltip="Select a type of product for which the rule will apply. Product categories can be maintained and updated in the Product Catalog in your Account Settings."
      placeholder="Select a product category"
      options={hook.productCategoryList}
      selected={_.find(hook.productCategoryList, { value: hook.stateMachine.context.productCategory })}
      onChange={hook.changeSelectedProductCategory}
      isLoading={hook.getProductDataLoading}
      width="40vw"
    />

    <Select
      required
      menuPlacement="auto"
      name="product"
      isDisabled={!hook.stateMachine.context.productCategory}
      label="Select Product"
      tooltip="Select a specific prodduct for which the rule will apply. Products can be added, maintained, and updated in the Product Catalog in your Account Settings."
      placeholder="Select a product"
      selected={hook.stateMachine.context.selectedProduct}
      options={hook.availableProducts}
      onChange={hook.changeSelectedProduct}
      tooltipExtraMarginLeft={20}
      width="40vw"
    >
      <Styled.TextSubDescription>
        Choose a product to apply to the rule. The rule will use values assigned to the product in the product catalog.
      </Styled.TextSubDescription>
      <Spacer size="medium" direction="vertical" />
    </Select>
  </Styled.FormStyled>
);
