import { gql } from '@apollo/client';

export const TOGGLE_MANUAL_URL_MONITORING = gql`
  mutation toggleManualUrlMonitoring($input: ToggleManualUrlMonitoringInput!) {
    toggleManualUrlMonitoring(input: $input) {
      settings {
        id
        urlCheckType
      }
    }
  }
`;
