import React, { ReactElement } from 'react';

import { useSideMenu } from '../../hooks/useSideMenu';
import { ISideMenuItem } from '../../types';
import SideMenuItem from '../SideMenuItem/SideMenuItem.component';

import { SideMenuItemsContainer } from './SideMenuItems.styles';

const SideMenuItems = (): ReactElement => {
  const { getSideMenu } = useSideMenu();

  return (
    <SideMenuItemsContainer>
      {getSideMenu().map((sideMenuItem: ISideMenuItem) => (
        <SideMenuItem sideMenuItem={sideMenuItem} key={sideMenuItem.id} />
      ))}
    </SideMenuItemsContainer>
  );
};

export default SideMenuItems;
