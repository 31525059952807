import styled from 'styled-components';

import { Select } from '../../../../../../components';
import { colors, fonts } from '../../../../../../styles/theme';

export const RuleStatusSettingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
`;

export const DescriptionSettingsText = styled.p`
  ${fonts.lato.H14};
  color: ${colors.color1};
`;

export const RuleStatusSettingsSelect = styled(Select)`
  width: 8rem;
`;
