import React from 'react';

// How to use: https://fintelconnect.atlassian.net/wiki/spaces/INFRA/pages/352747528/React+Advanced+Code+Guideline#Custom-Spacer-Component
type SpacerProps = {
  size: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  direction: 'horizontal' | 'vertical';
};

export const Spacer = (props: SpacerProps): JSX.Element => {
  const { size, direction } = props;

  const sizesMap = {
    xsmall: '0.25rem',
    small: '0.5rem',
    medium: '1rem',
    large: '2rem',
    xlarge: '3rem',
  };

  return (
    <div
      style={{
        height: direction === 'vertical' ? sizesMap[size] : 'auto',
        width: direction === 'horizontal' ? sizesMap[size] : 'auto',
      }}
    />
  );
};
