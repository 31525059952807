export const CANCEL_ADD_RULE = {
  TITLE: 'Leave Rule?',
  WARNING: 'You have not finished adding your rule yet. Do you want to leave without finishing?',
  CANCEL_BUTTON: 'Cancel',
  LEAVE_BUTTON: 'Leave',
};

export const defaultRuleGroupOption: SelectOption = {
  label: '',
  value: '',
};

export const MONITORING_NAME_RULEGROUP = {
  TITLE: 'Add Monitoring Rule',
  RULE_NAME: {
    LABEL: 'Rule Name',
    TOOLTIP:
      'Enter a rule name that describes the type of checks that the Fintel Check tool will perform. The clearer the description, the easier you will be able to search and find the rule.',
    PLACEHOLDER: 'Name this rule',
  },
  DESCRIPTION: 'Select the product(s) for which the rule will apply',
  GROUP_RULES: {
    LABEL: 'Rule Groups',
    TOOLTIP: 'Select a rule group',
    PLACEHOLDER: 'Select a Rule Group',
  },
  PRODUCT: {
    LABEL: 'Proudct(s)',
    TOOLTIP:
      'Select a specific prodduct for which the rule will apply. Products can be added, maintained, and updated in the Product Catalog in your Account Settings.',
    PLACEHOLDER: 'Select a category',
  },
  STEP_COUNTER: 'Step 1 of 2',
  CANCEL_BUTTON: 'Cancel',
  CONTINUE_BUTTON: 'Continue',
};

export const MONITORING_RULE_CRITERIA = {
  TITLE: 'Add Custom Text',
  DESCRIPTION: 'Define the parameters for the rule to check',
  AND: 'And',
  CUSTOM_TEXT: {
    LABEL: 'Custom Text Value',
    TOOLTIP: 'Please make sure the values are unique',
    PLACEHOLDER: {
      NO_PROUDCTS: 'Please enter the value to be checked',
      DEFAULT: 'Data associated with product feed',
    },
    BUTTON: 'Click to view all product feed data',
  },
  ADD_CUSTOM_TEXT: 'Add custom text',
  STEP_COUNTER: 'Step 2 of 2',
  CANCEL_BUTTON: 'Cancel',
  CONTINUE_BUTTON: 'Continue',
};

export const SUCCESS_MESSAGES = {
  CREATED_RULE_TOAST: 'Rule successfully created.',
};

export const ERROR_MESSAGES = {
  MISSING_VALUE: 'Please enter a value',
  RULE_NAME_TAKEN: 'Please select a unique rule name.',
  MAX_LIMIT: 'Maximum limit reached: You can only add up to 5 custom text',
  INVALID_CHAR: 'This is an invalid character',
  UNIQUE_VALUE: 'Values must be unique',
};
