import React from 'react'
import styled from 'styled-components'

import { colors, fonts } from '../../../../../../../../styles/theme'

export const CellStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  ${fonts.lato.H14};
  color: ${colors.color1};
  max-height: 60px;
  text-overflow: ellipsis;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  a {
    color: ${colors.color12};
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    :hover {
      color: ${colors.color2};
    }
  }
`

export const PageUrlCell = ({ val }: any): JSX.Element => (
  <CellStyled onClick={(e) => e.stopPropagation()}>
    <a href={val} target="_blank" rel="noreferrer">
      {val}
    </a>
  </CellStyled>
)
