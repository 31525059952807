import { GFCSCSummaryType } from '../graphql/queries';

export const CSVColumns = [
  {
    dataField: 'merchantId',
    text: 'MERCHANT ID',
  },
  {
    dataField: 'merchantName',
    text: 'MERCHANT NAME',
  },
  {
    dataField: 'publisherId',
    text: 'PUBLISHER ID',
  },
  {
    dataField: 'publisherName',
    text: 'PUBLISHER NAME',
  },
  {
    dataField: 'ruleStatus',
    text: 'STATUS',
  },
  {
    dataField: 'referralUrl',
    text: 'PAGE URL',
  },
  {
    dataField: 'productCategory',
    text: 'CATEGORY',
  },
  {
    dataField: 'productId',
    text: 'PRODUCT',
  },
  {
    dataField: 'productName',
    text: 'PRODUCT NAME',
  },
  {
    dataField: 'ruleName',
    text: 'RULE NAME',
  },
  {
    dataField: 'trackingEventDate',
    text: 'STATUS SINCE',
  },
  {
    dataField: 'screenshotName',
    text: 'SCREENSHOT',
  },
  {
    dataField: 'id',
    text: 'HISTORY',
  },
];

export const processDataToCSVString = (
  allTableData: any[],
  allReportColumns: { text: string; dataField: string }[]
) => {
  const csvReportColumns: { text: string; dataField: string }[] = [];
  // Create the Row of Column Titles
  const headerRow = allReportColumns
    .map((column) => {
      if (!column?.text || !column?.dataField) return null;
      csvReportColumns.push(column);
      return column?.text;
    })
    .filter((i) => i)
    .toString();

  // Create the Data Rows
  const newData = allTableData.map((row) => {
    const rowArray = csvReportColumns.map((column) => {
      if (!column?.dataField || row[column?.dataField] == null) return 'N/A';
      if (!['"', '\r', '\n', ','].some((e) => row[column.dataField].toString().indexOf(e) !== -1)) {
        if (typeof row[column.dataField] === 'number') return +row[column.dataField].toFixed(2);
        return row[column.dataField];
      }
      return `"${row[column.dataField].toString().replace(/"/g, '""')}"`;
    });
    return rowArray.toString();
  });

  return [headerRow, ...newData].join('\n');
};

export const formatDataForCSV = (summaryReportItem: GFCSCSummaryType[]) =>
  summaryReportItem.map((summary) => ({
    ...summary,
    ruleStatus: (() => {
      switch (summary.ruleStatus) {
        case 'NF':
          return 'Not Applicable';
        case 'Check Fail':
          return 'Fail';
        default:
          return summary.ruleStatus;
      }
    })(),
  }));
