import styled from 'styled-components';

import { colors, fonts } from '../../../../../../styles/theme';

export const CustomTextWraper = styled.div`
  margin-top: 1rem;
`;

export const CustomTextWrapper = styled.div`
  display: flex;
  padding-top: 2rem;
  flex-direction: column;
`;

export const CustomTextListHeader = styled.p`
  ${fonts.lato.H16B};
  color: ${colors.color1};
  margin-bottom: 1rem;
`;

export const CustomTextInfo1 = styled.div`
  width: 100%;
  margin-bottom: 0.75rem;
  ${fonts.lato.H14};
  color: ${colors.color1};
`;

export const CustomTextValuesWrapper = styled.p`
  width: 100%;
  color: ${colors.color1};
  ${fonts.lato.H16};
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const CustomTextValue = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
  align-items: center;
`;

export const CTSpanIndex = styled.span`
  margin-right: 0.75rem;
  text-align: center;
  ${fonts.lato.H16B};
  color: ${colors.color1};
`;

export const InfoWrapper = styled.div`
  margin-top: 0.75rem;
  margin-bottom: 1rem;
`;

export const InfoRow = styled.div`
  ${fonts.lato.H16};
  color: ${colors.color1};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const InfoRowBold = styled.div`
  ${fonts.lato.H16};
  color: ${colors.color1};
  margin-top: 0.5rem;
`;

export const CustomTextRowStyled = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr 40px;
  grid-template-rows: 1fr auto;
  gap: 1rem;
  padding: 0.25rem 0;
`;

export const DeleteRequirementButton = styled.button`
  height: 40px;
  width: 40px;
  border-radius: 5px;
  border: 1px solid ${colors.color3};
  background-color: ${colors.color4};
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;
export const AddRuleButtonWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
